import { Stack, Box, FormControl, FormLabel, MenuItem, Menu, ListItemText } from "@mui/material";
import React, { Component } from "react";
import { Button } from "../Button";
import { DocumentTable } from "./DocumentTable";
import { ReactComponent as AttachIcon } from '../../resources/images/icons-attachment.svg';
import { DragDropFileUpload } from "../DragDropFileUpload";
import { ControlLabel } from 'klayowebshared';

export class DocumentList extends Component {

    static defaultProps = {
        label: 'Documents'
    };

    constructor(props) {
        super(props);

        this.state = {
            showUploadComponent: false,
            actionTarget: null,
            actionDocument: null,
            showDeleted: false
        };
    }

    onShowUploadComponent(e) {
        this.setState({ showUploadComponent: true });
    }

    onDocumentAction(e, doc) {
        this.setState({ actionTarget: e.target, actionDocument: doc });
    }

    onCloseActionMenu() {
        this.setState({ actionTarget: null, actionDocument: null });
    }

    onViewDocument(e) {
        const { onViewDocument } = this.props;
        const { actionDocument } = this.state;
        if (onViewDocument) onViewDocument(e, actionDocument);
        this.onCloseActionMenu();
    }

    onDeleteDocument(e, doc) {
        const { onDeleteDocument } = this.props;
        const { actionDocument } = this.state;
        if (onDeleteDocument) onDeleteDocument(e, actionDocument);
        this.onCloseActionMenu();
    }

    render() {
        const { theme, documents, label, files, allowedMimeTypes, maxFileSize, errorMimeType, errorFileSize, onFilesChange, showDeleted, disabled } = this.props;
        const { showUploadComponent, actionTarget } = this.state;

        const docs = documents ? (showDeleted ? documents : documents.filter(d => !d.isDeleted && !d.pendingDelete)) : null;
        const hasDocs = docs && docs.length > 0;
        const hasFiles = files && Object.entries(files).length > 0;

        return <Box
            className='klayo-doclist'
            sx={{ width: '100%' }}>
            <ControlLabel text={label} />
            <Stack spacing={3}>
                {hasDocs &&
                    <div>
                        <DocumentTable
                            theme={theme}
                            showHeader={false}
                            pagination={false}
                            showFooter={false}
                            hideFirstLastBorder={false}
                            dense={false}
                            onDocumentAction={this.onDocumentAction.bind(this)}
                            paper={false}
                            rows={docs}
                            sx={{ borderBottom: '1px solid var(--grey-gr-200)', borderRadius: '0!important' }} />

                        <Menu
                            open={actionTarget !== null}
                            anchorEl={actionTarget}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            onClose={this.onCloseActionMenu.bind(this)}
                        >
                            <MenuItem onClick={this.onViewDocument.bind(this)}>
                                <ListItemText>View</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={this.onDeleteDocument.bind(this)}>
                                <ListItemText>Delete</ListItemText>
                            </MenuItem>
                        </Menu>
                    </div>
                }

                {!showUploadComponent && hasDocs && !hasFiles && <div>
                    <Button
                        size='md'
                        startIcon={<AttachIcon />}
                        onClick={this.onShowUploadComponent.bind(this)}
                        disabled={disabled}
                        label='Add an attachment' />
                </div>}

                {(showUploadComponent || !hasDocs || hasFiles) &&
                    <DragDropFileUpload
                        label={null}
                        files={files}
                        accept='csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                        onChange={onFilesChange}
                        allowedMimeTypes={allowedMimeTypes}
                        maxFileSize={maxFileSize}
                        errorMimeType={errorMimeType}
                        errorFileSize={errorFileSize} />}
            </Stack >
        </Box>
    }
}