
import { Chip, Link, Stack } from "@mui/material";
import { Component } from "react";
import { AppContext } from '../common/AppContext';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { TeamFilterModel } from "../common/models/TeamFilterModel";
import { ReactComponent as RemoveIcon } from '../resources/images/icons-remove.svg';
import { Utils } from "../common/Utils";

export class TeamFilterChips extends Component {

    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            filterModel: this.props.filterModel
        };
    }

    onRemoveCompliance(e) {
        this.state.filterModel.compliance = null;
        this.setState({ filterModel: this.state.filterModel }, () => this.onFilterChange(e, this.state.filterModel));
    }

    onRemoveAttributeType(e) {
        this.state.filterModel.attributeType = null;
        this.setState({ filterModel: this.state.filterModel }, () => this.onFilterChange(e, this.state.filterModel));
    }

    onRemoveAttribute(e, attr) {
        this.state.filterModel.attributes = this.state.filterModel.attributes.filter(a => a.attributeDefinitionId !== attr.attributeDefinitionId);
        this.setState({ filterModel: this.state.filterModel }, () => this.onFilterChange(e, this.state.filterModel));
    }

    onRemovePerson(e, person) {
        this.state.filterModel.people = this.state.filterModel.people.filter(p => p.employeeId !== person.employeeId);
        this.setState({ filterModel: this.state.filterModel }, () => this.onFilterChange(e, this.state.filterModel));
    }

    onRemoveJob(e, job) {
        this.state.filterModel.jobs = this.state.filterModel.jobs.filter(j => j !== job);
        this.setState({ filterModel: this.state.filterModel }, () => this.onFilterChange(e, this.state.filterModel));
    }

    onRemoveLocation(e, location) {
        this.state.filterModel.locations = this.state.filterModel.locations.filter(l => l !== location);
        this.setState({ filterModel: this.state.filterModel }, () => this.onFilterChange(e, this.state.filterModel));
    }

    onRemoveAttributeExpiry(e) {
        this.state.filterModel.expireAfter = null;
        this.state.filterModel.expireBefore = null;
        this.setState({ filterModel: this.state.filterModel }, () => this.onFilterChange(e, this.state.filterModel));
    }

    onRemoveAll(e, attr) {
        this.state.filterModel = new TeamFilterModel();
        this.setState({ filterModel: this.state.filterModel }, () => this.onFilterChange(e, this.state.filterModel));
    }

    onFilterChange(e, filterModel) {
        this.props.onChange(e, filterModel);
    }

    componentDidUpdate(prevProps) {
        if (this.props.filterModel !== prevProps.filterModel) this.setState({ filterModel: this.props.filterModel });
    }

    render() {
        const { sx, theme, showSingleToBreakpoint } = this.props;
        const { filterModel } = this.state;

        const filterCount = filterModel.countFilters();

        return filterModel &&
            <Stack className='klaro-chiparray' direction='row' spacing={1} sx={{ ...sx, flexWrap: 'wrap' }}>
                <Chip
                    label={filterCount === 1 ? '1 filter applied' : filterModel.countFilters() + ' filters applied'}
                    onDelete={this.onRemoveAll.bind(this)}
                    deleteIcon={<RemoveIcon />}
                    sx={{
                        [theme.breakpoints.up(showSingleToBreakpoint)]: { display: 'none' }
                    }}
                />

                {filterModel.people.map((person, i) => (
                    <Chip
                        key={i}
                        label={<div>Person: <b>{person.fullName} </b></div>}
                        onDelete={e => this.onRemovePerson(e, person)}
                        deleteIcon={<RemoveIcon />}
                        sx={{
                            [theme.breakpoints.down(showSingleToBreakpoint)]: { display: 'none' }
                        }}
                    />
                ))
                }

                {filterModel.jobs.map((job, i) => (
                    <Chip
                        key={i}
                        label={<div>Job: <b>{job} </b></div>}
                        onDelete={e => this.onRemoveJob(e, job)}
                        deleteIcon={<RemoveIcon />}
                        sx={{
                            [theme.breakpoints.down(showSingleToBreakpoint)]: { display: 'none' }
                        }} />
                ))
                }

                {filterModel.locations.map((location, i) => (
                    <Chip key={i}
                        label={<div>Location: <b>{location} </b></div>}
                        onDelete={e => this.onRemoveLocation(e, location)}
                        deleteIcon={<RemoveIcon />}
                        sx={{
                            [theme.breakpoints.down(showSingleToBreakpoint)]: { display: 'none' }
                        }} />
                ))
                }

                {filterModel.compliance && <Chip
                    label={<div>Compliance: <b>{filterModel.compliance.label} </b></div>}
                    onDelete={this.onRemoveCompliance.bind(this)}
                    deleteIcon={<RemoveIcon />}
                    sx={{
                        [theme.breakpoints.down(showSingleToBreakpoint)]: { display: 'none' }
                    }} />}

                {filterModel.attributes.map((a, i) => (
                    <Chip
                        key={i}
                        label={<div>Competency: <b>{a.attribute} </b></div>}
                        onDelete={e => this.onRemoveAttribute(e, a)}
                        deleteIcon={<RemoveIcon />}
                        sx={{
                            [theme.breakpoints.down(showSingleToBreakpoint)]: { display: 'none' }
                        }} />
                ))
                }

                {/*filterModel.attributes.length > 0 && */filterModel.attributeType &&
                    <Chip
                        label={<div>Competency type: <b>{filterModel.attributeType.label} </b></div>}
                        onDelete={this.onRemoveAttributeType.bind(this)}
                        deleteIcon={<RemoveIcon />}
                        sx={{
                            [theme.breakpoints.down(showSingleToBreakpoint)]: { display: 'none' }
                        }} />}

                {(filterModel.expireAfter || filterModel.expireBefore) &&
                    <Chip
                        label={<div>Competency expiry: <b>{filterModel.expireAfter && filterModel.expireBefore ?
                            Utils.formatReadableDate(filterModel.expireAfter) + ' to ' + Utils.formatReadableDate(filterModel.expireBefore)
                            : Utils.formatReadableDate(filterModel.expireAfter) ? 'on or after ' + Utils.formatReadableDate(filterModel.expireAfter) : 'before or on ' + Utils.formatReadableDate(filterModel.expireBefore)} </b></div>}
                        onDelete={this.onRemoveAttributeExpiry.bind(this)}
                        deleteIcon={<RemoveIcon />}
                        sx={{
                            [theme.breakpoints.down(showSingleToBreakpoint)]: { display: 'none' }
                        }} />}

                {filterModel.countFilters() > 1 &&
                    <Link className='klaro-chiparray_clear'
                        onClick={this.onRemoveAll.bind(this)}
                        sx={{
                            whiteSpace: 'no-wrap',
                            cursor: 'pointer',
                            lineHeight: '32px',
                            [theme.breakpoints.down(showSingleToBreakpoint)]: { display: 'none' }
                        }}>
                        Clear all
                    </Link>}

            </Stack>
    }
}