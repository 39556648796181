import { Typography, Grid, Accordion, AccordionSummary, AccordionDetails, List } from '@mui/material';
import { React, Component } from 'react';
import { RequestActivityItem } from './RequestActivityItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from '@emotion/styled';

export class RequestActivityFeed extends Component {

    render() {
        const { user, employee, activity, sx, onDocumentClick, documents, style } = this.props;
        const { documents: feedDocs, latestActivity, previousActivity } = activity;

        return (
            <Grid className='klayo-feed' sx={sx} item xs={12} md={12} lg={12} style={style ? style : {}}>

                {latestActivity && (

                    <StyledAdminFeedbackList>
                        <RequestActivityItem
                            user={user}
                            employee={employee}
                            documents={documents ?? feedDocs}
                            activity={latestActivity}
                            onDocumentClick={onDocumentClick} />
                    </StyledAdminFeedbackList>
                )}

                {previousActivity && previousActivity.length !== 0 && (
                    <Accordion elevation={0}>
                        <StyledActivityAccordionSummary
                            expandIcon={<ExpandMoreIcon className='klayo-feed_expandicon' />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{ padding: '16px' }}
                        >
                            <Typography>Previous activity</Typography>
                        </StyledActivityAccordionSummary>
                        <AccordionDetails sx={{ padding: '0px' }}>
                            <List>
                                {previousActivity.map((a, i) =>
                                    <RequestActivityItem
                                        key={i}
                                        user={user}
                                        employee={employee}
                                        documents={documents ?? feedDocs}
                                        activity={a}
                                        onDocumentClick={onDocumentClick} />
                                )}
                            </List>
                        </AccordionDetails>
                    </Accordion>
                )}

            </Grid>
        );
    }
}

const StyledAdminFeedbackList = styled(List)`
    padding: 0px;
    
    .klayo-feeditem_content {
        margin: 0px;
        padding-top: 0px;
    }
`

const StyledActivityAccordionSummary = styled(AccordionSummary)`
    padding: 16px;

    .MuiAccordionSummary-content {
        margin: 0px;
    }

    .MuiAccordionSummary-content.Mui-expanded {
        margin: 0px;
    }
`