import { Table } from "./Table";
import { SelectMenu } from "../SelectMenu";
import { COURSE_RECURRENCE } from "../../constants";
import { Tooltip } from "@mui/material";
import styled from "@emotion/styled";
import { KLAYO_COLORS } from "../../themes";

export class TrainingTable extends Table {
	
    static columns = {
        courseName: {
          field: 'courseName',
          label: 'Course name',
        },
		provider: {
			field: 'provider',
			label: 'Provider',
		},
        attributes: {
          field: 'attributes',
          label: 'Competencies',
        },
        recurrence: {
          field: 'recurrence',
          label: 'Recurrence',
        },
    };

    static defaultProps = {
        ...Table.defaultProps,
        columns: [
			{type: TrainingTable.columns.courseName},
			{type: TrainingTable.columns.provider},
			{type: TrainingTable.columns.attributes},
			{type: TrainingTable.columns.recurrence},
		],
        noRowsMessage: 'No courses available',
    }

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            sortModel: this.props.sortModel ? this.props.sortModel : [{
                field: 'courseName',
                sort: 'asc',
            }]
        };
    }

	getItemLabel(value) {
		const allItems = COURSE_RECURRENCE;

		for (const key in allItems) {
			if (allItems.hasOwnProperty(key)) {
				if (allItems[key].index === value) {
					return allItems[key];
				}
			}
		}
	
		return null; // Return null if the value is not found in the data
	}

    initColumns() {
        const { columns, sortable, onRecurrentChange, showDialog } = this.props;

        this.columns = [];

        columns.forEach(c => {
            if (this.hasColumn(c)) {

				if (c.type === TrainingTable.columns.courseName) this.columns.push({
					field: 'courseName',
					headerName: c.label || c.type.label,
					sortable: sortable,
					filterable: false,
					disableReorder: true,
                    flex: 1,
					valueGetter: params => params.row.name,
					renderHeader: params => <Tooltip
						classes={{tooltip: 'klayo__training-header-tooltip'}}
						title={<div>The name of the course comes <br /> from the integrated system</div>} 
						placement='top' 
						arrow
					>
						<div className='MuiDataGrid-columnHeaderTitle'>{params.colDef.headerName}</div>
					</Tooltip>
				});

				if (c.type === TrainingTable.columns.provider) this.columns.push({
					field: 'provider',
					headerName: c.label || c.type.label,
					sortable: sortable,
					filterable: false,
					disableReorder: true,
                    width: 120,
					headerAlign: 'right',
					align: 'right',
					valueGetter: params => params.row?.organizationIntegrationAbbreviation ? 
						params.row?.organizationIntegrationAbbreviation : 
						params.row?.organizationIntegrationName,
				});

				if (c.type === TrainingTable.columns.attributes) this.columns.push({
					field: 'attributes',
					headerName: c.label || c.type.label,
					sortable: sortable,
					filterable: false,
					disableReorder: true,
					width: 130,
					headerAlign: 'right',
					align: 'right',
					cellClassName: 'Klayo_associated-attribute-cell',
					renderHeader: params => <Tooltip
						classes={{tooltip: 'klayo__training-header-tooltip'}}
						title={<div>The number of competencies that are mapped to this course</div>} 
						placement='top' 
						arrow
					>
						<div className='MuiDataGrid-columnHeaderTitle'>{params.colDef.headerName}</div>
					</Tooltip>,
					valueGetter: params => params.row.numberOfAssociatedAttributes,
					renderCell: params => <KlayoTag 
						isTag={params.row.numberOfAssociatedAttributes!== 0} 
						onClick={() => params.row.numberOfAssociatedAttributes!== 0 ? showDialog(params.row) : {}}>{params.row.numberOfAssociatedAttributes}
					</KlayoTag>
				});

				if (c.type === TrainingTable.columns.recurrence) this.columns.push({
					field: 'recurrence',
					headerName: c.label || c.type.label,
					sortable: sortable,
					filterable: false,
					disableReorder: true,
					width: 160,
					headerAlign: 'right',
					align: 'right',
                    cellClassName: 'Klayo_recurrence-cell',
					renderHeader: params => <Tooltip
						classes={{tooltip: 'klayo__training-header-tooltip'}}
						title={<div>When this course is completed, it must be re-done in this many months, and any mapped competencies will have their expiry date automatically updated.</div>} 
						placement='top' 
						arrow
					>
						<div className='MuiDataGrid-columnHeaderTitle'>{params.colDef.headerName}</div>
					</Tooltip>,
					valueGetter: params => params.row.recurrence,
					renderCell: params => <SelectMenu
                        className='klayo-table_select'
						menuClassName='Klayo_recurrence-menu-list'
                        clearable={false}
                        dense={true}
                        noSelectionError={true}
                        placeholder='Select'
                        value={this.getItemLabel(params.row.recurrence)}
                        items={Object.entries(COURSE_RECURRENCE)}
                        onChange={(e, value) => onRecurrentChange(params.row, e.target.value)}
                        sx={{ width: '100%' }}
                    />
                    
				});
            }
        });
    }
};

const KlayoTag = styled.div`
	padding: 2px 12px;
	background-color: ${props => (props.isTag ? KLAYO_COLORS.NeonBlueNB050 : KLAYO_COLORS.transparent)};
	border-radius: 100px;

	color: ${ props => (props.isTag ? KLAYO_COLORS.NeonBlueNB500 : KLAYO_COLORS.GreyGR900)};
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;

	:hover {
		filter: ${props => (props.isTag ? 'brightness(95%)' : '')};
		cursor: ${props => (props.isTag ? 'pointer' : '')};
	}

    :focus {
        background-color: ${props => (props.isTag ? '#c6c7fc' : '')};
    }
`