import { React, Component } from 'react';
import { Autocomplete, ListItem, ListItemText, createFilterOptions } from '@mui/material';
import { AppContext } from '../../common/AppContext';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { ReactComponent as DropIcon } from '../../resources/images/icons-chevron-double.svg';
import { ReactComponent as RemoveIcon } from '../../resources/images/icons-remove.svg';
import { TextField } from '../TextField';
import debounce from 'lodash/debounce';

export class AbstractFilterSelect extends Component {

    static contextType = AppContext;

    static defaultProps = {
        listType: 'Filter',
        maxResults: 50,
        initialSearch: false,
        multiple: true,
        clearable: true,
        hasNoneItem: false,
        noneItemLabel: 'None',
        hasAllItem: false,
        hasNewItem: false,
        allItemLabel: 'All',
        getItemId: null,
        debounce: true,
        clearOnSelection: false,
        clearResultsOnSelection: false,
        noOptionsText: 'No search results',
        excludeItems: [],
        matchFrom: 'any',    //start or any
    }

    constructor(props) {
        super(props);

        const allItems = this.filterItems(props.items || props.initialItems || []);

        this.state = {
            selectedValues: props.value ? this.findValue(allItems, props.value) : null,
            allValues: allItems,
            valid: null,
            forceBlur: false
        };

        this.initialItems = props.initialItems;
        this.value = props.value;
        this.hasTyped = false;

        this.filterOptions = createFilterOptions({
            matchFrom: props.matchFrom,
            limit: props.maxResults,
            ignoreCase: true,
            stringify: option => this.getItemLabel(option),
        });

        this.debounceLoadItems = debounce(s => this.loadItems(s), 500);
        this.ignorePendingResults = false;
    }

    onChange(e, selected) {
        const { onChange, clearOnSelection, clearResultsOnSelection, validationMethod } = this.props;
        const { valid } = this.state;

        if (validationMethod) {
            const newValid = validationMethod(e, e.target.value);
            if (newValid !== valid) this.setState({ valid: newValid });
        }

        if (clearOnSelection) this.setState({ selectedValues: null, forceBlur: true, ...(clearResultsOnSelection && { allValues: null }) }, state => {
            onChange(e, selected);
        });
        else this.setState({ selectedValues: selected }, state => {
            onChange(e, selected);
        });
    }

    getItemLabel(item) {
        const { getItemLabel, hasNoneItem, noneItemLabel, hasAllItem, allItemLabel, hasNewItem, newItemLabel } = this.props;
        if (getItemLabel)
            return getItemLabel(item);

        if (hasNoneItem && item.isNoneItem === true) return noneItemLabel;
        else if (hasAllItem && item.isAllItem === true) return allItemLabel;
        else if (hasNewItem && item.isNewItem === true) return newItemLabel;
        return item.label;
    }

    getItemSecondaryLabel(item) {
    }

    getItemState(item) {
        const { getItemState } = this.props;
        return getItemState ? getItemState(item) : null;
    }

    getItemSecondaryAction(item) {
    }

    getList(dataList) {
    }

    compareItems(a, b) {
        const { compareItems } = this.props;
        if (compareItems) return compareItems(a, b);
        return a === b;
    }

    onInputChange(e, value, reason) {
        const { debounce, items, onInputChange } = this.props;

        if (value === null) {
            this.value = null;
            if (onInputChange) onInputChange('');
        } else if (value === '' || reason === 'reset') {
            this.clearResults();
            if (onInputChange) onInputChange('');
            return;
        }
        if (e && reason === 'input') {
            this.hasTyped = true;
            this.ignorePendingResults = false;
            if (onInputChange) onInputChange(value);
            if (debounce) this.debounceLoadItems(value);
            else this.loadItems(value);
        }
    }

    clearResults() {
        const { items } = this.props;
        this.ignorePendingResults = true;
        this.value = '';
        this.setItems(this.initialItems ? this.initialItems : items ? items : []);
    }

    onBlur(e) {
        const { onBlur, validationMethod } = this.props;
        const { valid, forceBlur } = this.state;

        if (validationMethod) {
            const newValid = validationMethod(e, e.target.value);
            if (newValid !== valid) this.setState({ valid: newValid });
        }

        if (forceBlur) this.setState({ forceBlur: false });

        if (onBlur) onBlur(e);
    }

    componentDidMount() {
        if (this.props.initialSearch) this.loadItems(null, true);
    }

    componentDidUpdate(prevProps, prevState) {
        const { allValues } = this.state;

        if (prevProps.initialItems !== this.props.initialItems) {
            this.initialItems = this.props.initialItems;
            this.setItems(this.props.initialItems);
        }
        if (prevProps.items !== this.props.items) {
            this.setItems(this.props.items);
        }
        if (prevProps.value !== this.props.value) {
            this.value = this.props.value;
            this.setState({ selectedValues: this.findValue(allValues, this.value) });
        }
    }

    findValue(items, value) {
        if (value && (typeof value === 'object')) return value;
        return this.findValueItem(items, value);
    }

    findValueItem(items, value) {
        return null;
    }

    setItems(allValues) {
        const filteredItems = this.filterItems(allValues);
        this.setState({ allValues: filteredItems, selectedValues: this.findValue(filteredItems, this.props.value) });
    }

    filterItems(allValues) {
        const { filterItems } = this.props;
        if (filterItems) return filterItems(allValues);
        return allValues;
    }

    loadItems(value, initialSearch) {
        const { callApi, listType } = this.props;

        if (callApi === false && initialSearch !== true) return;

        this.value = value;
        if (initialSearch !== true && (!value || value.length === 0)) return;

        this.context.setLoading('search' + listType, true);

        this.getData(value)
            .then(list => {
                if(this.ignorePendingResults) this.ignorePendingResults = false;
                else {
                    const values = this.getList(list);
                    //if (!this.initialItems) this.initialItems = values;
                    this.setItems(values);
                }
            }).catch(error => {
                //Todo: handle error
                //alert('error 4545365: ' + error);
            })
            .finally(() => this.context.setLoading('search' + listType, false));
    }

    getData(value) {
        const { getData } = this.props;
        const customGetData = getData ? getData(value) : null;
        return customGetData ? customGetData : this.dataList.get(this.context, value);
    }

    filter(options, inputValue) {
        const { maxResults, multiple } = this.props;
        const { allValues, selectedValues } = this.state;

        /*if (selectedValues && this.hasTyped) {
            if(multiple) return options.filter(o => selectedValues.find(s => this.compareItems(s, o)) === undefined).slice(0, maxResults);
            else return options.filter(o => this.compareItems(selectedValues, o)).slice(0, maxResults);
        }
        else */return options.slice(0, maxResults);
    }

    renderOption(props, option, state) {
        const { getItemId } = this.props;

        const id = getItemId ? getItemId(option) : option.id;
        const name = this.getItemLabel(option);
        const itemState = this.getItemState(option);
        const secondaryLabel = this.getItemSecondaryLabel(option);
        const secondaryAction = this.getItemSecondaryAction(option);
        const matches = match(name, state.inputValue);
        const parts = parse(name, matches);

        return <ListItem
            {...props}
            className={'klayo-filterselector_option' + (itemState ? ' klayo-filterselector_option--' + itemState : '')}
            key={id}
            secondaryAction={secondaryAction ? secondaryAction : null}>
            <ListItemText
                className='klayo-filterselector_option_text'
                primary={parts.length === 1 ? name : <div>{parts.map((part, index) => (
                    <span key={index} className={'klayo-selector_itempart' + (part.highlight === true ? ' klayo-selector_highlight' : '')}>
                        {part.text}
                    </span>
                ))}</div>}
                secondary={secondaryLabel ? secondaryLabel : null}
            />
        </ListItem>
    }

    isOptionEqualToValue(a, b) {
        if (a === null || b === null) return 0;

        const { getItemId } = this.props;
        if (getItemId === null) return a === b;

        return getItemId(a) === getItemId(b);
    }

    getItemId(item) {
        const { getItemId } = this.props;
        return getItemId ? getItemId(item) : item;
    }

    compareItems(a, b) {
        if (a === null || b === null) return 0;
        return this.getItemId(a) === this.getItemId(b);
    }

    filter(items) {
        const { excludeItems } = this.props;
        return items.filter(o => o === null || (excludeItems.find(s => this.compareItems(s, o)) === undefined));
    }

    render() {
        const { label, sx, placeholder, focusPlaceholder, multiple, clearable, validationText, helperText, disabled, loading, hasNoneItem, noneItemLabel, hasAllItem, allItemLabel, hasNewItem, newItemLabel, getItemId, noOptionsText } = this.props;
        const { allValues, selectedValues, valid, forceBlur } = this.state;

        const options = [];
        if (hasNoneItem) options.push({ id: -10, name: noneItemLabel, isNoneItem: true });
        if (hasAllItem) options.push({ id: -11, name: allItemLabel, isAllItem: true });
        if (hasNewItem) options.push({ id: -12, name: '', isNewItem: true });

        if (allValues && allValues.length !== 0) options.push(...allValues);
        else if (this.initialItems) options.push(...this.initialItems);

        return <Autocomplete
            className="klayo-filterselector klayo-selector"
            options={this.filter(options)}
            value={selectedValues ? selectedValues : null}
            getOptionLabel={this.getItemLabel.bind(this)}
            //filterOptions={this.filter.bind(this)}
            filterOptions={this.filterOptions}
            isOptionEqualToValue={this.isOptionEqualToValue.bind(this)}
            autoComplete
            includeInputInList
            disableClearable={clearable === false}
            filterSelectedOptions
            popupIcon={<DropIcon />}
            clearIcon={<RemoveIcon />}
            onBlur={this.onBlur.bind(this)}
            handleHomeEndKeys
            noOptionsText={noOptionsText}
            ChipProps={{
                deleteIcon: <RemoveIcon />
            }}
            multiple={multiple}
            renderOption={this.renderOption.bind(this)}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label={label}
                    disabled={disabled}
                    loading={loading}
                    placeholder={placeholder || "Search"}
                    focusPlaceholder={focusPlaceholder}
                    error={valid === false}
                    helperText={valid === false ? validationText : helperText}
                    forceBlur={forceBlur}
                    sx={{ width: '100%' }}
                    endAdornment={params.InputProps.endAdornment}
                />
            }
            onChange={this.onChange.bind(this)}
            onInputChange={this.onInputChange.bind(this)}
            sx={sx}
        />
    }
}