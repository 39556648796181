import React, { Component } from "react";
import { AppContext } from '../../common/AppContext';
import uniqueId from 'lodash.uniqueid'
import { SectionLoader } from '../SectionLoader';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Box } from "@mui/material";
import { Paper } from "../Paper";
import { ReactComponent as ChevronRightIcon } from '../../resources/images/icons-chevron-right.svg';
import { ReactComponent as ChevronDownIcon } from '../../resources/images/icons-chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from '../../resources/images/icons-chevron-up.svg';
import { TablePlaceholder } from "../TablePlaceholder";
import { ReactComponent as SearchIcon } from '../../resources/images/icons-search.svg';
import { isEqual } from "lodash";
export class Table extends Component {
    static contextType = AppContext;

    static defaultProps = {
        columns: [],
        density: 'standard',
        showFooterRowCount: true,
        pagination: true,
        paper: true,
        showHeader: true,
        showHeaderFromBreakpoint: null,
        showFooter: true,
        readOnly: false,
        sortable: true,
        noRowsMessage: 'No rows',
        noRowsComponent: null,
        noResultsMessage: 'No results',
        noResultsComponent: null,
        tree: false,
        selectable: true,
        autoHeight: true,
        rowCount: null,
        pageSize: 50,
        rowsPerPageOptions: [50],
        paginationMode: 'client',
        filterMode: 'client',
        sortingMode: 'client',
        searchText: ''
    }

    constructor(props) {
        super(props);

        this.state = {
            sortModel: this.props.sortModel,
            selectionModel: [],
        };

        this.ref = React.createRef();
        this.apiRef = React.createRef();

        this.tableId = uniqueId("klayo-table_");

        this.initColumns();

        window.addEventListener('resize', this.onWindowResize.bind(this));
    }

    onWindowResize(e) {
        this.forceUpdate();
    }

    componentDidMount() {
        this._isMounted = true;
        // Attach the event listener when the component mounts
        document.addEventListener('click', this.handleOutsideClick.bind(this));
        document.addEventListener('keydown', this.handleEscapeKey.bind(this));
    }

    componentDidUpdate(prevProps, prevState) {
        const { onSortModelChange } = this.props;

        if (prevProps.sortModel !== this.props.sortModel && onSortModelChange) this.setState({ sortModel: this.props.sortModel });
        if (prevProps.columns !== this.props.columns) {
            this.initColumns();
        }
        if (prevProps.rows !== this.props.rows || prevProps.columns !== this.props.columns) this.forceUpdate();
    }

    componentWillUnmount() {
        this._isMounted = false;
        // Clean up the event listener when the component unmounts
        document.removeEventListener('click', this.handleOutsideClick.bind(this));
        document.removeEventListener('keydown', this.handleEscapeKey.bind(this));
    }

    handleOutsideClick(event) {
        const { disableSelection } = this.props;
        if (disableSelection) return
        if (this.ref.current && this._isMounted) {
            const clickedInsideDataGrid = this.ref.current.contains(event.target);
            if (!clickedInsideDataGrid) {
                // Clicked outside the DataGrid rows, so clear the focused row
                this.clearFocusRow();
            }
        }
    };

    handleEscapeKey(e) {
        // Click ESC clear table focus row
        if ((e.key === 'Escape' || e.keyCode === 27) && this._isMounted) {
            this.clearFocusRow();
            this.forceUpdate()
        }
    }

    setSortModel(model) {
        // model is an array contain object with field & sort
        this.setState({ sortModel: model });
    }

    setApiRef(apiRef) {
        if (!this.apiRef || !apiRef) return;
        this.apiRef = apiRef;
    }

    initColumns() {
    }

    hasColumn(col) {
        const { theme } = this.props;

        //Moved to columnVisibilityModel
        /*if (theme && col.visibleFrom) {
            if (!theme.isBreakpointUp(col.visibleFrom)) return false;
        }*/

        return this.hasColumnType(col.type);
    }

    hasColumnType(type) {
        return !this.props.columns || this.props.columns.some(c => c.type === type);
    }

    onUserClick(e, user, attr) {
        if (this.props.onUserClick) this.props.onUserClick(e, user, attr);
    }

    getRow(e) {
        return this.apiRef ? this.apiRef.current.getRowElement(this.getRowId(e)) : null;
    }

    getRowId(e) {
        return uniqueId(this.tableId + '_');
    }

    onRowClick(params, e) {
    }

    getFocusRow(selectionModel) {
        const { disableSelection } = this.props;
        if (disableSelection) return;
        this.setState({ selectionModel });
    }

    clearFocusRow() {
        this.setState({ selectionModel: [] });
    }

    onHighlightRow(params) {
        const { onHighlightRowComplete } = this.props;

        this.gridApi = params.api;

        if (this.gridApi) setTimeout(() => {

            const rowEl = this.apiRef.current.getRowElement(params.id);
            if (rowEl) {
                rowEl.classList.add('highlight');
                const y = rowEl.getBoundingClientRect().top + window.pageYOffset - 200;
                window.scrollTo({ top: y, behavior: 'smooth' });

                setTimeout(() => rowEl.classList.remove('highlight'), 1000);
                if (onHighlightRowComplete) onHighlightRowComplete(params.id);
            }
        }, 500);
    }

    getColumnBoundingClientRect(col) {
        if (!this.apiRef || !this.apiRef.current) return null;
        const colEl = this.apiRef.current.getColumnHeaderElement(col.field);
        if (!colEl || !colEl.parentElement) return null;

        const parentBounds = colEl.parentElement.getBoundingClientRect();
        const colBounds = colEl.getBoundingClientRect();

        const relativeBounds = {
            top: colBounds.top - parentBounds.top,
            right: colBounds.right - parentBounds.right,
            bottom: colBounds.bottom - parentBounds.bottom,
            left: colBounds.left - parentBounds.left,
            width: colBounds.right - colBounds.left,
            height: colBounds.bottom - colBounds.top
        };

        return relativeBounds;
    }

    getTreeDataPath(row) {
        return null;
    }

    shouldComponentUpdate(prevProps, prevState) {
        // props
        const { tree, rows, filterItems,
            highlightRow, disabled,
            paginationModel, disableSelection } = this.props;
        if (!disableSelection) return true;
        if (!isEqual(prevProps.rows, rows)) { return true; }
        if (!isEqual(prevProps.paginationModel, paginationModel)) { return true; }
        if (!isEqual(prevProps.tree, tree)) { return true; }
        if (!isEqual(prevProps.highlightRow, highlightRow)) {return disableSelection ? false : true; }
        if (!isEqual(prevProps.disabled, disabled)) { return true; }
        if (!isEqual(prevProps.filterItems, filterItems)) { return true; }
        //  state
        const { sortModel, selectionModel } = this.state;
        if (!isEqual(prevState.sortModel, sortModel)) { return true; }
        if (!isEqual(prevState.selectionModel, selectionModel)) { return disableSelection ? false : true; }
        return false;
    }


    renderTable() {
        const { tree, rows, autoHeight, totalCount, pageSize, paginationMode, onPageChange, onPageSizeChange, rowsPerPageOptions, onSortModelChange, selectable, density, minHeight, pagination, showFooterRowCount, noRowsMessage, noRowsComponent, noResultsMessage, noResultsComponent, showFooter, filterItems, filterMode, filterOperator, rowHasSelect, theme, tableSx, dense, showHeaderFromBreakpoint, highlightRow, disabled, onPaginationModelChange, paginationModel, icon, searchText, sortingMode } = this.props;
        const { sortModel, selectionModel } = this.state;

        this.highlightRow = highlightRow;

        let numRows = 1;

        const columnVisibilityModel = {};
        this.columns.forEach(c => {
            columnVisibilityModel[c.field] = c.visibleFromBreakpoint ? theme.isBreakpointUp(c.visibleFromBreakpoint) : c.visibleToBreakpoint ? theme.isBreakpointDown(c.visibleToBreakpoint) : true;
            if (columnVisibilityModel[c.field] && c.numRows && c.numRows > numRows) numRows = c.numRows;
        });

        const rowHeight = (numRows * 24) + (dense ? 32 : 42);

        let showHeader = this.props.showHeader !== false;
        if (showHeaderFromBreakpoint && theme) showHeader = theme.isBreakpointUp(showHeaderFromBreakpoint);

        const tableStyle = {
            '& .MuiDataGrid-row': { cursor: selectable ? 'pointer' : 'default' },
            '& .MuiDataGrid-cell': {
            },
            ...tableSx
        };

        if (minHeight) tableStyle['minHeight'] = minHeight;

        if (!rows) return <SectionLoader />;
        if (searchText && rows && !rows.length) return noResultsComponent || <TablePlaceholder text={noResultsMessage} icon={icon ?? <SearchIcon />} />;
        if (rows && !rows.length) return noRowsComponent || <TablePlaceholder text={noRowsMessage} icon={icon ?? <SearchIcon />} />;
        return <DataGridPro
            className={'klayo-table_grid' + (theme ? ' klayo-table--breakpoint-' + theme.getCurrentBreakpoint()[0] : '')}
            ref={this.ref}
            apiRef={this.apiRef}
            disabled={disabled}
            groupingColDef={{
                headerName: '',
                field: 'job',
                width: 34,
                minWidth: 34,
                cellClassName: 'klayo-table_expandbuttoncell',
                valueGetter: () => ''
            }}
            treeData={tree}
            getRowClassName={params => {
                const rowNode = this.apiRef.current.getRowNode(params.id);

                if (params.row.isParent && rowNode.children) {
                    let lastChild = null;
                    let lastChildIndex = -1;
                    rowNode.children.forEach(c => {
                        const childRowEl = this.apiRef.current.getRowElement(c);
                        const rowIndex = this.apiRef.current.getRowIndexRelativeToVisibleRows(c);

                        if (rowIndex > lastChildIndex) {
                            lastChild = childRowEl;
                            lastChildIndex = rowIndex;
                        }
                    });

                    if (lastChild) {
                        lastChild.classList.add('klayo-table_childrow--lastchild');
                    }
                }

                return (params.row.isParent ? 'klayo-table_parentrow' + (rowNode && rowNode.childrenExpanded ? ' klayo-table_parentrow--expanded' : '') : params.row.isChild ? 'klayo-table_childrow' : 'klayo-table_row') + (params.isLastVisible ? ' klayo-table_row--lastrow' : '') + (params.isFirstVisible ? ' klayo-table_row--firstrow' : '');
            }}
            rows={rows ? rows : []}
            rowCount={paginationMode === 'server' ? totalCount : undefined}
            density={density}
            columns={this.columns}
            columnHeaderHeight={showHeader !== false ? 56 : 0}
            getRowId={this.getRowId.bind(this)}
            pageSize={pageSize}
            rowHeight={rowHeight}
            pagination={pagination}
            paginationMode={paginationMode}
            pageSizeOptions={rowsPerPageOptions}
            disableColumnMenu={true}
            disableColumnFilter={true}
            hideFooterRowCount={!showFooterRowCount}
            hideFooter={!showFooter}
            disableMultipleRowSelection={true}
            disableRowSelectionOnClick={!selectable}
            sortModel={sortModel ? sortModel : undefined}
            paginationModel={paginationModel ? paginationModel : undefined}
            onPaginationModelChange={onPaginationModelChange ? onPaginationModelChange : undefined}
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange={this.getFocusRow.bind(this)}
            onRowClick={(params, e) => !rowHasSelect || rowHasSelect(params.row) ? this.onRowClick(params, e) : null}
            onPageChange={(page) => paginationMode === 'server' ? onPageChange(page, pageSize) : {}}
            onPageSizeChange={(pageSize) => onPageSizeChange(pageSize, paginationMode)}
            onSortModelChange={onSortModelChange ? onSortModelChange : this.setSortModel.bind(this)}
            sortingOrder={['desc', 'asc']}
            sortingMode={sortingMode}
            autoHeight={autoHeight}
            getTreeDataPath={this.getTreeDataPath.bind(this)}
            sx={tableStyle}
            filterMode={filterMode}
            filterModel={{
                items: filterItems ? filterItems : [],
                logicOperator: filterOperator || 'or'
            }}
            columnVisibilityModel={columnVisibilityModel}
            slots={{
                noRowsOverlay: () => noRowsComponent || <TablePlaceholder text={noRowsMessage} />,
                noResultsOverlay: () => noResultsComponent || <TablePlaceholder text={noResultsMessage} icon={<SearchIcon />} />,
                treeDataCollapseIcon: theme.isBreakpointDown('md') ? ChevronUpIcon : ChevronDownIcon,
                treeDataExpandIcon: theme.isBreakpointDown('md') ? ChevronDownIcon : ChevronRightIcon,
            }}
        />;
    }

    render() {
        const { sx, tree, theme, paper, title, hideFirstLastBorder, showHeaderFromBreakpoint } = this.props;

        let showHeader = this.props.showHeader !== false;
        if (showHeaderFromBreakpoint && theme) showHeader = theme.isBreakpointUp(showHeaderFromBreakpoint);

        const tableClass = 'klayo-table' + (showHeader !== false ? ' klayo-table--withheader' : '') + (hideFirstLastBorder ? ' klayo-table--hidefirstlastborder' : '') + (tree ? ' klayo-table--tree' : '');

        return paper ?
            <Paper
                className={tableClass}
                title={title}
                sx={{ padding: '10px 16px 23px 16px', ...sx }}>
                {this.renderTable()}
            </Paper> :
            <Box
                className={tableClass}
                sx={sx}>
                {this.renderTable()}
            </Box>
    }
};