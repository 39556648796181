import { Component } from "react";
import { DialogContent, DialogTitle } from "@mui/material";
import { Paper } from "./Paper";
import { Dialog } from "./dialogs/Dialog";
import { ActionsContainer } from "klayowebshared";

export class PaperOrDialog extends Component {

    static defaultProps = {
        scrollToTopOnMount: true
    };

    componentDidMount() {
        const { scrollToTopOnMount, dialog } = this.props;
        if (!dialog && scrollToTopOnMount) window.scrollTo(0, 0);
    }

    render() {
        const { dialog, dialogToBreakpoint, className, disabledToBreakpoint, actions, justifyActions, theme, title, titleSize, children, sx, paperPadding, backButton, onBack, paperBorderFromBreakpoint, titleFontSize, titleSx } = this.props;

        return dialog === true || (theme && theme.isBreakpointDown(dialogToBreakpoint)) ?
            <Dialog
                open={true}
                theme={theme}
                disabledToBreakpoint={disabledToBreakpoint}
                fullWidth={true}
                maxWidth='sm'
                title={title}
                onClose={onBack}
                sx={sx}
                actions={actions}
                justifyActions={justifyActions}>
                {children}
            </Dialog>
            :
            <Paper
                className={`${className ? className : ''}`}
                sx={sx}
                theme={theme}
                padding={paperPadding}
                title={title}
                titleSize={titleSize}
                backButton={backButton}
                borderFromBreakpoint={paperBorderFromBreakpoint}
                titleFontSize={titleFontSize}
                titleSx={titleSx}
                onBack={onBack}>
                {children}
                <ActionsContainer
                    actions={actions}
                    justifyActions={justifyActions}
                    onClose={onBack}
                    sx={{ paddingTop: '40px' }} />
            </Paper>
    }
};