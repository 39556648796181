import { Data } from "./Data";
import { TeamAttributeList } from "./attributes/TeamAttributeList"
import { TeamGapList } from "./attributes/TeamGapList"
import { GapAttributeList } from "./attributes/GapAttributeList"
import { EmployeeAttributeList } from "./attributes/EmployeeAttributeList"
import { NotificationsData } from "./notifications/NotificationsData"

export class DashboardData extends Data {

    static mode  = {
        personal: {
            name: 'Personal',
            api: '/Dashboard',
            notificationsApi: '/Notifications'
        },
        team: {
            name: 'Team',
            api: '/Dashboard/Teams',
            notificationsApi: '/Notifications/Teams'
        },
        groups: {
            name: 'Groups',
            api: '/Dashboard/Groups',
            notificationsApi: '/Notifications/Group'
        }
    };

    static instance = { };

    constructor(options) {
        super({
            api: options.mode.api, callback: data => {
                options.resolve(this, false);
            }, ...options
        });

        this.mode = options.mode;
    }
    
    onDataReceived(data) {
        this.data = data;

        if(this.mode === DashboardData.mode.team) {
            this.attributes = new TeamAttributeList({ data: data.topAttributes });
            this.gaps = new TeamGapList({ data: data.topGapAttributes });
            this.notifications = new NotificationsData({ data: data.latestTeamRequests, mode: this.mode });
        }
        else {
            this.gaps = new GapAttributeList({ data: data.gapAttributes });
            this.attributes = new EmployeeAttributeList({ data: data.employeeAttributes });
            this.notifications = new NotificationsData({ data: data.notifications, mode: this.mode });
        }
    }

    static get(context, mode) {
        return new Promise((resolve, reject) => {
            if (DashboardData.instance[mode.name]) resolve(DashboardData.instance[mode.name], true);
            else DashboardData.instance[mode.name] = new DashboardData({ resolve, reject, context, mode });
        });
    }

    static getModeFromIndex(index) {
        return Object.values(DashboardData.mode)[index];
    }
}