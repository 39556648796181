import React from 'react';
import App from '../../App';
import { AbstractView } from '../AbstractPage';
import { AppContext } from '../../common/AppContext';
import { SettingsCompanyView } from './SettingsCompanyView';
import { SettingsAttributesView } from './SettingsAttributesView';
import { SettingsJobsView } from './SettingsJobsView';
import { SettingsEmployeesView } from './SettingsEmployeesView';
import { SettingsBillingView } from './SettingsBillingView';
import { SettingsGroupsView } from './SettingsGroupsView';
import { SettingsTrainingView } from './SettingTrainingView';
import { Route, Switch } from 'react-router-dom';
import { ReactComponent as CompanyIcon } from '../../resources/images/icons-office.svg';
import { ReactComponent as JobsIcon } from '../../resources/images/icons-briefcase.svg';
import { ReactComponent as AttributesIcon } from '../../resources/images/icons-attribute.svg';
import { ReactComponent as EmployeesIcon } from '../../resources/images/icons-users-1.svg';
import { ReactComponent as BillingIcon } from '../../resources/images/icons-invoice.svg';
import { ReactComponent as GroupsIcon } from '../../resources/images/icons-departments.svg';
import { OrganizationSettings } from '../../data/settings/OrganizationSettings';
import { AddEditCategoryView } from './AddEditCategoryView';
import { AddEditAttributeView } from './AddEditAttributeView';
import { View } from '../../components/View';
import { Data } from '../../data/Data';
import { ErrorModel } from '../../common/models/ErrorModel';
import { SettingsAttributeCategoryList } from "../../data/settings/SettingsAttributeCategoryList";
import { SettingsJobList } from '../../data/settings/SettingsJobList';
import { SettingsEmployeeList } from '../../data/settings/SettingsEmployeeList';
import { Utils } from '../../common/Utils';
import { SettingsLandingView } from './SettingsLandingView';
import { SettingsAttributeList } from '../../data/settings/SettingsAttributeList';
import { SettingsAttributeCategory } from '../../data/settings/SettingsAttributeCategory';
import { SettingsAttribute } from '../../data/settings/SettingsAttribute';
import { SettingsManagerList } from '../../data/settings/SettingsManagerList';
import { SettingsGroupList } from '../../data/settings/SettingsGroupList';
import { BillingSettings } from '../../data/settings/BillingSettings';
import { BillingPlansDialog } from '../billing/BillingPlansDialog';
import { BillingPlans } from '../../data/billing/BillingPlans';
import { Organization } from '../../data/Organization';
import { Alert } from '../../components/Alert';
import { BillingDetailsDialog } from '../billing/BillingDetailsDialog';
import { BillingInvoices } from '../../data/billing/BillingInvoices';
import { CreditCardDetailsDialog } from '../billing/CreditCardDetailsDialog';
import { SettingsCourseList } from '../../data/settings/SettingCourseList'; 
import { SettingsIntegrationList } from '../../data/settings/SettingsIntegrationList';
const axios = require('axios').default;

export class Settings extends AbstractView {
    static contextType = AppContext;

    static defaultProps = {
        landingEnabled: false
    };

    static subPages = {
        jobs: {
            name: 'Jobs',
            icon: <JobsIcon />,
            paths: [
                '/settings/jobs',
                '/settings/jobs/new',
                '/settings/jobs/edit/:id',
                '/settings/jobs/edit/:id/competencies',
                '/settings/jobs/assign-manager/:id',
                '/settings/jobs/assign-employee/:id',
            ],
            component: SettingsJobsView,
            hasAccess: (user, organization) => user && user.isOrganizationAdmin && organization.status !== Organization.status.inactive,
            isDefault: user => true
        },
        employees: {
            name: 'Employees',
            icon: <EmployeesIcon />,
            paths: [
                '/settings/employees',
                '/settings/employees/new',
                '/settings/employees/edit/:id'
            ],
            component: SettingsEmployeesView,
            hasAccess: (user, organization) => user && user.isOrganizationAdmin && organization.status !== Organization.status.inactive
        },
        groups: {
            name: 'Groups',
            icon: <GroupsIcon />,
            paths: [
                '/settings/groups',
                '/settings/groups/new',
                '/settings/groups/edit/:id'
            ],
            component: SettingsGroupsView,
            hasAccess: (user, organization) => user && user.isOrganizationAdmin && organization.status !== Organization.status.inactive
        },
        competencies: {
            name: 'Competencies',
            icon: <AttributesIcon />,
            paths: [
                '/settings/competencies',
                '/settings/competencies/new',
                '/settings/competencies/new/parent',
                '/settings/competencies/edit/:id',
                '/settings/competencies/categories',
                '/settings/competencies/categories/new',
                '/settings/competencies/categories/edit/:id'
            ],
            component: SettingsAttributesView,
            hasAccess: (user, organization) => user && user.isOrganizationAdmin && organization.status !== Organization.status.inactive

        },
        training: {
            name: 'Training',
            icon: <AttributesIcon />,
            paths: [
                '/settings/training',
            ],
            component: SettingsTrainingView,
            hasAccess: (user, organization) => user && user.isOrganizationAdmin && organization.status !== Organization.status.inactive && organization.hasIntegration
        },
        company: {
            name: 'Company',
            icon: <CompanyIcon />,
            paths: [
                '/settings/company',
                '/settings/company/edit',
                '/settings/company/address/new',
                '/settings/company/address/edit',
                '/settings/company/locations/new',
                '/settings/company/locations/edit/:id'],
            component: SettingsCompanyView,
            hasAccess: (user, organization) => user && user.isOrganizationAdmin && organization.status !== Organization.status.inactive
        },
        billing: {
            name: 'Billing',
            icon: <BillingIcon />,
            paths: ['/settings/billing',
                '/settings/billing/history',
                '/settings/billing/payment',
                '/settings/billing/plan',
                '/settings/billing/details',
                '/settings/billing/payment/update-card'],
            component: SettingsBillingView,
            hasAccess: (user, organization) => user && user.isBillingUser && organization && !organization.isDemo,
            isDefault: user => user && !user.isOrganizationAdmin && user.isBillingUser
        }
    };

    constructor(props) {
        super(props, App.pages.settings);

        this.state = {
            orgSettings: null,
            categories: null,
            attributes: null,
            jobs: null,
            courses: null,
            integration: null,
            employees: null,
            managers: null,
            groups: null,
            billingDetails: null,
            billingPlans: null,
            billingInvoices: null,
            showAddEditAttributeDialog: false,
            addAttributeCallback: null,
            showAddEditCategoryDialog: false,
            addCategoryCallback: null,
            categoryError: null,
            attributeError: null,
            courseError: null,
            showLanding: (!Utils.checkLocalStorageOption('hideSettingsLanding') || this.hasQuery('showLanding=true')) && props.user && props.user.isOrganizationAdmin && props.organization && !props.organization.isDemo,
            newCategory: null,
            newAttribute: null,
            showBillingPlans: false,
            showBillingDetails: false,
            showCreditCardDetails: false,
            adminError: null,
            errorSnackbar: null
        };
    }

    onViewChange(location) {
        if (this.pathEquals('/settings')) this.resetErrors();
        if (this.hasQuery('showLanding=true')) this.setState({ showLanding: true });
    }

    resetErrors() {
        this.setState({ categoryError: null, attributeError: null });
    }

    onShowNewAttributeDialog(e, value, addAttributeCallback) {
        const newAttribute = new SettingsAttribute();
        newAttribute.name = value;
        this.setState({ showAddEditAttributeDialog: true, attributeError: null, addAttributeCallback, newAttribute });
    }

    onCancelAddEditAttribute(e) {
        this.setState({ showAddEditAttributeDialog: false, attributeError: null });
    }

    onShowNewCategoryDialog(e, value, addCategoryCallback) {
        const newCategory = new SettingsAttributeCategory();
        newCategory.name = value;
        this.setState({ showAddEditCategoryDialog: true, categoryError: null, addCategoryCallback, newCategory });
    }

    onCancelAddEditCategory(e) {
        this.setState({ showAddEditCategoryDialog: false, categoryError: null });
    }

    onDeleteVacancy(e, job) {
        const { jobs } = this.state;

        this.context.setLoading('addjob', true);

        return new Promise((resolve, reject) => {
            axios
                .post(Data.apiBasePath + '/Job', job.toApiDto(), {
                    withCredentials: true
                })
                .then(response => {
                    jobs.addVacancy(job.jobDefinitionId, response.data);
                    resolve();
                })
                .catch(e => {
                    //this.setState({ jobError: ErrorModel.parseServerError(e) });
                    reject(e);
                })
                .finally(() => {
                    this.context.setLoading('addjob', false);
                });
        });
    }

    onLoadAttributes(updateCache, searchText, pageSize, orderBy) {
        // option for some api use list all with this api
        const pagingOptions = {pageNumber: 1, searchText, orderBy, pageSize: pageSize || SettingsAttributeList.defaultPageSize};

        this.context.setLoading('attributes', true);

        // get setting attributes data then set to attributes state
        SettingsAttributeList
            .getWithPaging(this.context, pagingOptions).then(attributes => {
                this.setState({ attributes });
            })
            .catch(error => {})
            .finally(() => this.context.setLoading('attributes', false));
    }

    onLoadAttributesPaging(pageNumber, pageSize, searchText, orderBy) {
        this.context.setLoading('attributes', true);

        SettingsAttributeList
            .getWithPaging(this.context, {pageNumber: pageNumber, searchText, orderBy, pageSize: pageSize || SettingsAttributeList.defaultPageSize})
            .then(attributes => {
                this.setState({ attributes });
            })
            .catch(error => {})
            .finally(() => this.context.setLoading('attributes', false));
    }

    onLoadAttributesPageSize(pageNumber, pageSize, searchText, orderBy) {
        this.context.setLoading('attributes', true);

        SettingsAttributeList
        .getWithPaging(this.context, {pageNumber: pageNumber, searchText, orderBy, pageSize: pageSize || SettingsAttributeList.defaultPageSize})
        .then(attributes => {
            this.setState({ attributes });
        })
        .catch(error => {})
        .finally(() => this.context.setLoading('attributes', false));
    }

    onLoadCategoriesPaging(pageNumber, pageSize, searchText, orderBy) {
        this.context.setLoading('categories', true);

        SettingsAttributeCategoryList
            .getWithPaging(this.context, {pageNumber: pageNumber, searchText: searchText, orderBy, pageSize: pageSize || SettingsAttributeCategoryList.defaultPageSize})
            .then(categories => {
                this.setState({ categories });
            })
            .catch(error => {})
            .finally(() => this.context.setLoading('categories', false));
    }

    onLoadCategoriesPageSize(pageNumber, pageSize, searchText, orderBy) {
        this.context.setLoading('categories', true);

        SettingsAttributeCategoryList
        .getWithPaging(this.context, {pageNumber: pageNumber, searchText: searchText, orderBy, pageSize: pageSize || SettingsAttributeCategoryList.defaultPageSize})
        .then(categories => {
            this.setState({ categories });
        })
        .catch(error => {})
        .finally(() => this.context.setLoading('categories', false));
    }

    onLoadCategories(updateCache, searchText, pageSize, orderBy) {
        this.context.setLoading('categories', true);

        const pagingOptions = {pageNumber: 1, searchText, orderBy, pageSize: pageSize || SettingsAttributeCategoryList.defaultPageSize};

        SettingsAttributeCategoryList
            .getWithPaging(this.context, pagingOptions).then(categories => {
                this.setState({ categories });
            })
            .catch(error => {})
            .finally(() => this.context.setLoading('categories', false));
    }

    onLoadIntegration(updateCache, searchText, pageSize, orderBy) {
        this.context.setLoading('integration', true);


        const pagingOptions = {pageNumber: 1, searchText, orderBy, pageSize: pageSize || SettingsIntegrationList.defaultPageSize};

        SettingsIntegrationList
            .getWithPaging(this.context, pagingOptions).then(integration => {
                this.setState({ integration });
            })
            .catch(error => {})
            .finally(() => this.context.setLoading('integration', false));
    }
    
    onLoadTrainingCourse(searchText, pageSize, orderBy, integrationId) {
        this.context.setLoading('courses', true);

        const pagingOptions = {pageNumber: 1, orderBy, searchText: searchText, integrationId, pageSize: pageSize || SettingsCourseList.defaultPageSize};

        // get setting courses data then set to courses state
        SettingsCourseList
            .getWithPaging(this.context, pagingOptions).then(courses => {
                this.setState({ courses });
            })
            .catch(error => {})
            .finally(() => this.context.setLoading('courses', false));
    }

    onLoadCoursesPaging(pageNumber, pageSize, searchText, orderBy, integrationId) {
        this.context.setLoading('courses', true);

        SettingsCourseList
            .getWithPaging(this.context, {pageNumber: pageNumber, orderBy, searchText: searchText, integrationId, pageSize: pageSize || SettingsCourseList.defaultPageSize})
            .then(courses => {
                this.setState({ courses });
            })
            .catch(error => {})
            .finally(() => this.context.setLoading('courses', false));
    }

    onLoadCoursesPageSize(pageNumber, pageSize, searchText, orderBy, integrationId) {
        this.context.setLoading('courses', true);

        SettingsCourseList
            .getWithPaging(this.context, {pageNumber: pageNumber, orderBy, searchText: searchText, integrationId, pageSize: pageSize || SettingsCourseList.defaultPageSize})
            .then(courses => {
                this.setState({ courses });
            })
            .catch(error => {})
            .finally(() => this.context.setLoading('courses', false));
    }

    onSaveCourseRecurrence(id, recurrence) {
        this.context.setLoading('courses', true);

        const updateData = {
            id,
            recurrence,
        }

        return new Promise((resolve, reject) => {
            axios
                .put(Data.apiBasePath + '/Integration/Course/UpdateCourse', updateData, {
                    withCredentials: true
                })
                .then(response => {
                    const { id, recurrence } = response.data?.integrationCourse;
                    const { courses } = this.state;
                    const updateCourses = courses?.courses.map((course) => {
                        if (course.id === id) course.recurrence = recurrence;
                        return course;
                    });

                    this.setState({courses: {...this.state.courses, courses: updateCourses}});
                    // this.onLoadTrainingCourse(true);
                    resolve();
                })
                .catch(e => {
                    this.setState({ courseError: ErrorModel.parseServerError(e) });
                    reject(e);
                })
                .finally(() => {
                    this.context.setLoading('courses', false);
                });
        });
    }

    onLoadJobs(updateCache, searchText, pageSize) {
        this.context.setLoading('jobs', true);

        const pagingOptions = {pageNumber: 1, pageSize: pageSize || SettingsJobList.defaultPageSize, searchText: searchText};

        // get setting job data then set to jobs state
        SettingsJobList
            .getWithPaging(this.context, pagingOptions).then(jobs => {
                this.setState({ jobs });
            })
            .catch(error => {})
            .finally(() => this.context.setLoading('jobs', false));
    }

    onLoadJobsPaging(pageNumber, pageSize, searchText) {
        this.context.setLoading('jobs', true);

        SettingsJobList
            .getWithPaging(this.context, {pageNumber: pageNumber, pageSize: pageSize || SettingsJobList.defaultPageSize, searchText: searchText})
            .then(jobs => {
                this.setState({ jobs });
            })
            .catch(error => {})
            .finally(() => this.context.setLoading('jobs', false));
    }

    onLoadJobsPageSize(pageNumber, pageSize, searchText) {
        this.context.setLoading('jobs', true);

        SettingsJobList
        .getWithPaging(this.context, {pageNumber: pageNumber,  searchText: searchText, pageSize: pageSize || SettingsJobList.defaultPageSize})
        .then(jobs => {
            this.setState({ jobs });
        })
        .catch(error => {})
        .finally(() => this.context.setLoading('jobs', false));
    }

    onLoadEmployees(updateCache, searchText, pageSize, status, orderBy) {
        this.context.setLoading('employees', true);

        // get setting employees data then set to employees state
        SettingsEmployeeList
            .getWithPaging(this.context, { pageNumber: 1, searchText, orderBy, status, pageSize: pageSize || SettingsEmployeeList.defaultPageSize})
            .then(employees => {
                this.setState({ employees });
            })
            .catch(error => {})
            .finally(() => this.context.setLoading('employees', false));
    }

    onLoadEmployeesPaging(pageNumber, pageSize, searchText, status, orderBy) {
        this.context.setLoading('employees', true);

        SettingsEmployeeList
            .getWithPaging(this.context, {pageNumber: pageNumber, searchText, status, orderBy, pageSize: pageSize || SettingsEmployeeList.defaultPageSize})
            .then(employees => {
                this.setState({ employees });
            })
            .catch(error => {})
            .finally(() => this.context.setLoading('employees', false));
    }

    onLoadEmployeesPageSize(pageNumber, pageSize, searchText, status, orderBy) {
        this.context.setLoading('employees', true);

        // get setting employees data then set to employees state
        SettingsEmployeeList
            .getWithPaging(this.context, {pageNumber: pageNumber, searchText, status, orderBy, pageSize: pageSize || SettingsEmployeeList.defaultPageSize})
            .then(employees => {
                this.setState({ employees });
            })
            .catch(error => {})
            .finally(() => this.context.setLoading('employees', false));
    }

    onLoadManagers(updateCache) {
        this.context.setLoading('managers', true);

        SettingsManagerList
            .get(this.context, updateCache).then(managers => {
                this.setState({ managers });
            })
            .catch(error => {})
            .finally(() => this.context.setLoading('managers', false));
    }

    onLoadGroups(updateCache) {
        this.context.setLoading('groups', true);

        SettingsGroupList
            .get(this.context, updateCache).then(groups => {
                this.setState({ groups });
            })
            .catch(error => {})
            .finally(() => this.context.setLoading('groups', false));
    }

    handleNewAttribute(attr, returnToList) {
        const { addAttributeCallback } = this.state;

        this.onCancelAddEditAttribute();
        if (addAttributeCallback) addAttributeCallback(attr);
    }

    onSaveAttribute(attribute, returnToList, attributeSearch, categorySearch) {
        const { attributes, addAttributeCallback } = this.state;

        this.context.setLoading('savingAttribute', true);

        return new Promise((resolve, reject) => {
            if (attribute.attributeId) {
                axios
                    .put(Data.apiBasePath + '/AttributeDefinition', attribute.toApiDto(), {
                        withCredentials: true
                    })
                    .then(response => {
                        const updatedAttr = attributes.update(response.data);
                        this.handleNewAttribute(updatedAttr, returnToList);
                        this.onLoadCategories(true, categorySearch);
                        this.onLoadAttributes(true, attributeSearch);
                        resolve();
                    })
                    .catch(e => {
                        this.setState({ attributeError: ErrorModel.parseServerError(e) });
                        reject(e);
                    })
                    .finally(() => {
                        this.context.setLoading('savingAttribute', false);
                    });
            }
            else {
                axios
                    .post(Data.apiBasePath + '/AttributeDefinition', attribute.toApiDto(), {
                        withCredentials: true
                    })
                    .then(response => {
                        const newAttr = attributes.add(response.data);
                        this.handleNewAttribute(newAttr, returnToList);
                        this.onLoadCategories(true, categorySearch);
                        this.onLoadAttributes(true, attributeSearch);
                        resolve();
                    })
                    .catch(e => {
                        this.setState({ attributeError: ErrorModel.parseServerError(e) });
                        reject(e);
                    })
                    .finally(() => {
                        this.context.setLoading('savingAttribute', false);
                    });
            }
        });
    }

    handleNewCategory(cat) {
        const { addCategoryCallback } = this.state;

        this.onCancelAddEditCategory();
        if (addCategoryCallback) addCategoryCallback(cat);
    }

    onSaveCategory(category, categorySearch) {
        const { categories, addCategoryCallback } = this.state;

        this.context.setLoading('savingCategory', true);

        return new Promise((resolve, reject) => {
            if (category.categoryId) {
                axios
                    .put(Data.apiBasePath + '/AttributeDefinition/AttributeCategory', {
                        attributeCategoryId: category.categoryId,
                        name: category.name
                    }, {
                        withCredentials: true
                    })
                    .then(response => {
                        const updatedCat = categories.updateCategory(response.data);
                        this.handleNewCategory(updatedCat);
                        this.onLoadCategories(true, categorySearch);
                        resolve();
                    })
                    .catch(e => {
                        this.setState({ categoryError: ErrorModel.parseServerError(e) });
                        reject(e);
                    })
                    .finally(() => {
                        this.context.setLoading('savingCategory', false);
                    });
            }
            else {
                axios
                    .post(Data.apiBasePath + '/AttributeDefinition/AttributeCategory', {
                        name: category.name
                    }, {
                        withCredentials: true
                    })
                    .then(response => {
                        const newCat = categories.addCategory(response.data);
                        this.handleNewCategory(newCat);
                        this.onLoadCategories(true, categorySearch);
                        resolve();
                    })
                    .catch(e => {
                        this.setState({ categoryError: ErrorModel.parseServerError(e) });
                        reject(e);
                    })
                    .finally(() => {
                        this.context.setLoading('savingCategory', false);
                    });
            }
        });
    }

    onHasUser(user) {
        if (user && user.isOrganizationAdmin) {
            if (this.hasQuery('showLanding=true')) this.setState({ showLanding: true });

            this.context.setLoading('settings', true);

            OrganizationSettings.get(this.context).then(orgSettings => {
                this.setState({ orgSettings });
            })
                .catch(e => {
                    this.setState({ adminError: ErrorModel.parseServerError(e) });
                    //Todo: handle error
                    //alert('error 4553565: ' + error);
                })
                .finally(() => this.context.setLoading('settings', false));
        }
    }

    onCompanyNameChange(name) {
        const { organization } = this.props;
        organization.name = name;
        this.updateTitle();
    }

    onLoadBillingDetails(updateCache) {
        const { user } = this.props;

        if (user.isBillingUser) {
            this.context.setLoading('billing', true);

            BillingSettings
                .get(this.context, updateCache).then(billingDetails => {
                    if (billingDetails.dataReceived) this.setState({ billingDetails });
                })
                .catch(error => {})
                .finally(() => this.context.setLoading('billing', false));
        }
    }

    onLoadBillingInvoices(updateCache) {
        const { user } = this.props;

        if (user.isBillingUser) {
            this.context.setLoading('billingInvoices', true);

            BillingInvoices
                .get(this.context, updateCache).then(billingInvoices => {
                    if (billingInvoices.dataReceived) this.setState({ billingInvoices });
                })
                .catch(error => {})
                .finally(() => this.context.setLoading('billingInvoices', false));
        }
    }

    onLoadBillingPlans(updateCache) {
        const { user } = this.props;

        if (user.isBillingUser) {
            this.context.setLoading('billingPlans', true);

            BillingPlans
                .get(this.context, updateCache).then(billingPlans => {
                    this.setState({ billingPlans });
                })
                .catch(error => {})
                .finally(() => this.context.setLoading('billingPlans', false));
        }
    }

    reload() {
        this.onLoadBillingDetails(true);
        this.onLoadBillingInvoices(true);
    }

    onShowBillingPlans() {
        this.setState({ showBillingPlans: true });
    }

    onCloseBillingPlansDialog() {
        const { history } = this.props;
        this.setState({ showBillingPlans: false }, s => this.reload());
        history.push('/settings/billing');
    }

    onShowBillingDetails() {
        this.setState({ showBillingDetails: true });
    }

    onCloseBillingDetailsDialog() {
        const { history } = this.props;
        this.setState({ showBillingDetails: false }, s => this.reload());
        history.push('/settings/billing');
    }

    onShowCreditCardDetails() {
        this.setState({ showCreditCardDetails: true });
    }

    onCloseCreditCardDetailsDialog() {
        const { history } = this.props;
        this.setState({ showCreditCardDetails: false }, s => this.reload());
        history.push('/settings/billing/payment');
    }

    onInvoiceDownload(e, invoice) {
        this.context.setLoading('downloadingInvoice', true);

        Data.call('get', '/Organization/Invoices/' + invoice.invoiceId, { responseType: 'blob' })
            .then(response => {
                const blob = new Blob([response.data], { type: response.headers['content-type'] });

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', invoice.invoiceId);
                document.body.appendChild(link);
                link.click();

            })
            .catch(e => {
                this.setState({ errorSnackbar: 'Failed to download invoice' });
            })
            .finally(() => this.context.setLoading('downloadingInvoice', false));
    }

    getPageProps(page) {
        const { billingDetails, billingInvoices } = this.state;

        if (page === Settings.subPages.company) return { onCompanyNameChange: this.onCompanyNameChange.bind(this) };
        else if (page === Settings.subPages.competencies) return {
            onSaveAttribute: this.onSaveAttribute.bind(this),
            onSaveCategory: this.onSaveCategory.bind(this)
        }
        else if (page === Settings.subPages.billing) return {
            onLoadBillingDetails: this.onLoadBillingDetails.bind(this),
            onLoadBillingInvoices: this.onLoadBillingInvoices.bind(this),
            billingDetails,
            billingInvoices,
            onShowBillingPlans: this.onShowBillingPlans.bind(this),
            onShowBillingDetails: this.onShowBillingDetails.bind(this),
            onShowCreditCardDetails: this.onShowCreditCardDetails.bind(this),
            onInvoiceDownload: this.onInvoiceDownload.bind(this)
        };
        return {};
    }

    onHideLandingView(e) {
        Utils.setLocalStorageOption('hideSettingsLanding', 'true');
        this.setState({ showLanding: false });
    }

    onDataChanged(change) {
        const { onChange } = this.props;
        if (onChange) onChange(change);
    }

    render() {
        if (!this.verifyAccess()) return this.renderAccessDenied();
        
        const { organization, user, theme, location, onChangeCurrentUserFromSettings, onDemoSwitch, onContactUs, landingEnabled, onNavigation, onBlockNavigation, onAllowNavigation } = this.props;
        let { orgSettings, courses, integration, categories, attributes, jobs, employees, managers, groups, billingPlans, adminError } = this.state;
        const { showAddEditCategoryDialog, showAddEditAttributeDialog, categoryError, attributeError, showLanding, newCategory, newAttribute, showBillingPlans, showBillingDetails, showCreditCardDetails, billingDetails, errorSnackbar } = this.state;
        const root = this.props.location.pathname === '/settings';

        return landingEnabled && showLanding && root ?
            <SettingsLandingView
                {...this.props}
                allowDemoSwitch={organization && (organization.isDemo || (user && user.isOrganizationAdmin && user.hasDemoEmployee && organization.demoOrgStatus !== Organization.status.resetting) && theme.isBreakpointUp('md'))}
                onHide={this.onHideLandingView.bind(this)}
                onDemoSwitch={onDemoSwitch}
                showNameInGreeting={user && user.isValidFirstName} />
            :
            <View
                className='klayo-settings'
                theme={theme}
                fullWidth={false}
                scrollToTopOnMount={true}
                sx={{
                    padding: { xs: '56px 0px', sm: '56px 0px' },
                    minHeight: '100vh',
                    background: { xs: 'var(--white-wh-900)', md: '#f8f8fe' }
                }}>

                {adminError && <Alert severity='error'>{adminError}</Alert>}

                {!adminError &&
                    <Switch>
                        {Object.values(Settings.subPages).filter(i => user && organization && i.hasAccess(user, organization)).map((page, index) => (
                            <Route
                                key={index}
                                exact={true}
                                path={[...page.paths, user && page.isDefault && page.isDefault(user) ? '/settings' : '']}
                                render={props => React.createElement(page.component, {
                                    ...props,
                                    organization,
                                    theme,
                                    location,
                                    orgSettings,
                                    categories,
                                    attributes,
                                    jobs,
                                    courses,
                                    integration,
                                    employees,
                                    user,
                                    managers,
                                    groups,
                                    allowAssignAdminBilling: true,
                                    onLoadAttributes: this.onLoadAttributes.bind(this),
                                    onLoadAttributesPaging: this.onLoadAttributesPaging.bind(this),
                                    onLoadAttributesPageSize: this.onLoadAttributesPageSize.bind(this),
                                    onLoadCategoriesPaging: this.onLoadCategoriesPaging.bind(this),
                                    onLoadCategoriesPageSize: this.onLoadCategoriesPageSize.bind(this),
                                    onLoadCategories: this.onLoadCategories.bind(this),
                                    onLoadJobs: this.onLoadJobs.bind(this),
                                    onLoadJobsPaging: this.onLoadJobsPaging.bind(this),
                                    onLoadJobsPageSize: this.onLoadJobsPageSize.bind(this),
                                    onLoadIntegration: this.onLoadIntegration.bind(this),
                                    onLoadTrainingCourse: this.onLoadTrainingCourse.bind(this),
                                    onLoadCoursesPaging: this.onLoadCoursesPaging.bind(this),
                                    onLoadCoursesPageSize: this.onLoadCoursesPageSize.bind(this),
                                    onLoadEmployees: this.onLoadEmployees.bind(this),
                                    onLoadEmployeesPaging: this.onLoadEmployeesPaging.bind(this),
                                    onLoadEmployeesPageSize: this.onLoadEmployeesPageSize.bind(this),
                                    onSaveCourseRecurrence: this.onSaveCourseRecurrence.bind(this),
                                    onLoadManagers: this.onLoadManagers.bind(this),
                                    onLoadGroups: this.onLoadGroups.bind(this),
                                    onDataChanged: this.onDataChanged.bind(this),
                                    onShowNewCategoryDialog: this.onShowNewCategoryDialog.bind(this),
                                    onShowNewAttributeDialog: this.onShowNewAttributeDialog.bind(this),
                                    onBlockNavigation,
                                    onAllowNavigation,
                                    onNavigation,
                                    onContactUs,
                                    onChangeCurrentUserFromSettings,
                                    errorSnackbar,
                                    ...this.getPageProps(page)
                                })} />
                        ))}
                    </Switch>
                }

                {(showAddEditAttributeDialog) &&
                    <AddEditAttributeView
                        theme={theme}
                        orgSettings={orgSettings}
                        dialogMode={true}
                        error={attributeError}
                        location={location}
                        categories={categories}
                        attributes={attributes}
                        attribute={newAttribute}
                        onLoadCategories={this.onLoadCategories.bind(this)}
                        onLoadAttributes={this.onLoadAttributes.bind(this)}
                        onLoadJob={this.onLoadJobs.bind(this)}
                        onShowNewCategoryDialog={this.onShowNewCategoryDialog.bind(this)}
                        onBlockNavigation={onBlockNavigation}
                        onAllowNavigation={onAllowNavigation}
                        onNavigation={onNavigation}
                        onSave={this.onSaveAttribute.bind(this)}
                        onCancel={this.onCancelAddEditAttribute.bind(this)} />}

                {(showAddEditCategoryDialog) &&
                    <AddEditCategoryView
                        theme={theme}
                        dialogMode={true}
                        error={categoryError}
                        category={newCategory}
                        onSave={this.onSaveCategory.bind(this)}
                        onCancel={this.onCancelAddEditCategory.bind(this)} />}

                {showBillingPlans && <BillingPlansDialog
                    user={user}
                    organization={organization}
                    theme={theme}
                    billingPlans={billingPlans}
                    existingBillingDetails={billingDetails}
                    onLoadBillingDetails={this.onLoadBillingDetails.bind(this)}
                    onLoadBillingPlans={this.onLoadBillingPlans.bind(this)}
                    onContactUs={onContactUs}
                    onClose={this.onCloseBillingPlansDialog.bind(this)}
                />}

                {showBillingDetails && <BillingDetailsDialog
                    user={user}
                    organization={organization}
                    theme={theme}
                    existingBillingDetails={billingDetails}
                    onLoadBillingDetails={this.onLoadBillingDetails.bind(this)}
                    onLoadBillingPlans={this.onLoadBillingPlans.bind(this)}
                    onContactUs={onContactUs}
                    onClose={this.onCloseBillingDetailsDialog.bind(this)}
                />}

                {showCreditCardDetails && <CreditCardDetailsDialog
                    user={user}
                    organization={organization}
                    theme={theme}
                    onClose={this.onCloseCreditCardDetailsDialog.bind(this)}
                />}
            </View>;
    }
}