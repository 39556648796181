import { Data } from "../Data";
import { User } from "../users/User";

export class EmployeeSelectorList extends Data {

    static instance = null;

    constructor(options) {
        super({
            api: '/Employee/Search?searchText=' + options.search, callback: data => {
                options.resolve(this, false);
            }, ...options
        });
    }
    
    onDataReceived(data) {
        this.employees = [];
        data.employees.forEach((employee, index) => this.employees.push(new User({ data: { id: index, ...employee } })));
    }

    static get(context, search) {
        return new Promise((resolve, reject) => {
            if (EmployeeSelectorList.instance && search === '') resolve(EmployeeSelectorList.instance, true);
            else EmployeeSelectorList.instance = new EmployeeSelectorList({ resolve, reject, search, context });
        });
    }

    findMatch(attr) {
        return this.attributes.find(a => a.name === attr);
    }
}