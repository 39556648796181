import { Component } from "react";
import { Stack } from "@mui/material";
import { Alert } from "../../components/Alert";
import { EmployeeSelector } from "../../components/selectors/EmployeeSelector";
import { SettingsEmployee } from '../../data/settings/SettingsEmployee';
import { Dialog } from "../../components/dialogs/Dialog";

export class AssignEmployeeDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            employee: null,
            warnHasJob: false
        };
    }

    componentDidMount() {
        const { onLoadEmployees } = this.props;
        if (onLoadEmployees) onLoadEmployees();
    }

    onEmployeeChange(e, employee) {
        // run only when select data
        this.setState({ employee, warnHasJob: employee && employee.hasJob });
    }

    onEmployeeInputChange() {
        // run when input data & api fetching
        this.setState({ employee: null, warnHasJob: false });
    }

    onSave(e) {
        const { onSave, position } = this.props;
        const { employee } = this.state;
        if (onSave) onSave(e, position, employee);
    }

    render() {
        const { organization, user, theme, employees, onCancel, error } = this.props;
        const { employee, warnHasJob } = this.state;

        return <Dialog
                open={true}
                onClose={onCancel}
                theme={theme}
                fullWidth={true}
                title='Assign employee'
                maxWidth='sm'
                actions={[{
                    label: 'Select',
                    primary: true,
                    disabled: employee === null,
                    onClick: this.onSave.bind(this)
                }, {
                    label: 'Cancel',
                    onClick: onCancel
                }]}>
                    <Stack
                        direction="column"
                        spacing={4}
                    >
                        {error &&
                            <div><Alert
                                severity='error'>
                                {error}
                            </Alert>
                            </div>}

                        {warnHasJob &&
                            <div><Alert
                                severity='info'>
                                {employee.fullName} will be removed from their current position and assigned to this vacancy.
                            </Alert>
                            </div>}

                        <EmployeeSelector
                            label={null}
                            theme={theme}
                            multiple={false}
                            placeholder='Search employee'
                            showStatus={false}
                            initialItems={employees ? employees.employees : null}
                            getItemState={e => e.status === SettingsEmployee.status.inactive ? 'greyed' : null}
                            getItemSecondaryAction={e => e.status === SettingsEmployee.status.inactive ? e.status.label : null}
                            onChange={this.onEmployeeChange.bind(this)}
                            onInputChange={this.onEmployeeInputChange.bind(this)}
                        />
                    </Stack>
            </Dialog>
    }
}