import { FormControlLabel, Radio } from "@mui/material";
import { Table } from "./Table";

export class VacancyTable extends Table {

    static columns = {
        name: {
            field: 'name',
            label: 'Name',
        },
        select: {
            field: 'name',
            label: 'Your selection',
        },
        reportsTo: {
            field: 'reportsTo',
            label: 'Reports to',
        },
        reports: {
            field: 'reports',
            label: 'Reports',
        }
    };

    static defaultProps = {
        ...Table.defaultProps,
        columns: [
            { type: VacancyTable.columns.select },
            { type: VacancyTable.columns.reportsTo },
            { type: VacancyTable.columns.reports }
        ],
        noRowsMessage: 'No rows',
        rowHasAction: false,
        selectable: true
    }

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            sortModel: this.props.sortModel ? this.props.sortModel : [{
                field: 'name',
                sort: 'desc',
            }],
            selectedVacancy: props.selected
        };
    }

    getRowId(a) {
        return this.tableId + '_' + a.jobId;
    }

    onVacancySelect(e, selectedVacancy) {
        const { onVacancySelect } = this.props;
        this.setState({ selectedVacancy: selectedVacancy });
        if (onVacancySelect) onVacancySelect(e, selectedVacancy);
    }

    onSave(e) {
        const { onSave, position } = this.props;
        const { manager } = this.state;
        if (onSave) onSave(e, position, manager);
    }

    initColumns() {
        const { columns, sortable } = this.props;

        this.columns = [];

        columns.forEach(c => {
            if (this.hasColumn(c)) {

                if (c.type === VacancyTable.columns.name) this.columns.push({
                    field: 'name',
                    headerName: c.label || c.type.label,
                    sortable: sortable,
                    width: 200,
                    filterable: false,
                    disableReorder: true,
                    valueGetter: params => params.row.fullName
                });

                if (c.type === VacancyTable.columns.select) this.columns.push({
                    field: 'name',
                    headerName: c.label || c.type.label,
                    sortable: sortable,
                    width: 200,
                    filterable: false,
                    disableReorder: true,
                    valueGetter: params => params.row.fullName,
                    renderCell: params => <FormControlLabel
                        disableTypography={true}
                        className='klayo_vacancytable_select'
                        value={params.row.jobId}
                        onChange={e => this.onVacancySelect(e, params.row)}
                        checked={this.state.selectedVacancy ? this.state.selectedVacancy.jobId === params.row.jobId : false}
                        control={<Radio />}
                        label={params.row.fullName} />
                });

                if (c.type === VacancyTable.columns.reports) this.columns.push({
                    field: 'reports',
                    sortable: sortable,
                    headerName: c.label || c.type.label,
                    flex: true,
                    filterable: false,
                    disableReorder: true,
                    width: 50,
                    type: 'number',
                    valueGetter: params => params.row && !params.row.isCreatable ? params.row.numberOfTeamMembers : ''
                });

                if (c.type === VacancyTable.columns.reportsTo) this.columns.push({
                    field: 'reportsTo',
                    sortable: sortable,
                    headerName: c.label || c.type.label,
                    flex: true,
                    filterable: false,
                    disableReorder: true,
                    valueGetter: params => params.row.manager ? params.row.manager.fullName : '',
                    renderCell: params => params.row.manager ?
                        <div className='klayo_employeetable_pic klayo_employeetable_picname'>
                            <div>
                                <div className='klayo_employeetable_name'>{params.row.manager.fullName}</div>
                                <div className='klayo_employeetable_job'>{params.row.manager.jobTitle}</div>
                            </div>
                        </div> : params.row.isCreatable ? '' : 'Unassigned'
                });
            }
        });
    }
};