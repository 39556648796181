import { Table } from "./Table";
import { format } from "date-fns";
import { Tooltip as CustomTooltip } from '../Tooltip';

export class PracticalLogTable extends Table {

    static columns = {
        date: {
            field: 'date',
            label: 'Date',
        },
        user: {
            field: 'user',
            label: 'User',
        },
        notes: {
            field: 'notes',
            label: 'Notes',
        },
    };

    static defaultProps = {
        ...Table.defaultProps,
        columns: [
            { type: PracticalLogTable.columns.date },
            { type: PracticalLogTable.columns.user },
            { type: PracticalLogTable.columns.notes },
        ],
        noRowsMessage: 'No change log available for this practical',
    }

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            sortModel: this.props.sortModel ? this.props.sortModel : [{
                field: 'date',
                sort: 'desc',
            }]
        };
    }

    renderCreatedAt(data) {
        const { createdDate } = data;
        const string = format(new Date(createdDate), 'dd MMM yyyy hh:mm aaa');
        return <CustomTooltip title={string}>
            <div>{string}</div>
        </CustomTooltip>
    }

    initColumns() {
        const { columns, sortable } = this.props;

        this.columns = [];

        columns.forEach(c => {
            if (this.hasColumn(c)) {

                if (c.type === PracticalLogTable.columns.date) this.columns.push({
                    field: 'date',
                    headerName: c.label || c.type.label,
                    sortable: sortable,
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    filterable: false,
                    disableReorder: true,
                    width: 180,
                    valueGetter: params => params.row.createdDate,
                    renderCell: params => this.renderCreatedAt(params.row)
                });

                if (c.type === PracticalLogTable.columns.user) this.columns.push({
                    field: 'user',
                    headerName: c.label || c.type.label,
                    sortable: sortable,
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    filterable: false,
                    disableReorder: true,
                    width: 200,
                    valueGetter: params => params.row.updatedByEmployeeFullName,
                    renderCell: params => <div>{params.row.updatedByEmployeeFullName}</div>
                });

                if (c.type === PracticalLogTable.columns.notes) this.columns.push({
                    field: 'notes',
                    headerName: c.label || c.type.label,
                    sortable: sortable,
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    filterable: false,
                    disableReorder: true,
                    flex: 1,
                    valueGetter: params => params.row.notes,
                    renderCell: params => <div>{params.row.notes}</div>
                });
            }
        });
    }
};