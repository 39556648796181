import { Stack, Typography } from '@mui/material';
import { React, Component } from 'react';
import { ReactComponent as AttributesIcon } from '../resources/images/icons-attribute.svg';
import { Placeholder } from './Placeholder';

export class TablePlaceholder extends Component {

    static defaultProps = {
        text: 'No rows',
        icon: <AttributesIcon />
    };

    render() {
        return <Placeholder
            className='klayo-tableplaceholder'
            sx={{ top: { xs: '0', md: '-56px' } }}
            {...this.props} />
    }
}