import styled from '@emotion/styled';
import { Box, ListItemText, Menu, MenuItem, Stack, Tab, Tabs } from "@mui/material";
import { ConfirmationDialog, Delayed } from "klayowebshared";
import { Link } from "react-router-dom";
import { AppContext } from '../../common/AppContext';
import { ErrorModel } from '../../common/models/ErrorModel';
import { Button } from "../../components/Button";
import { Paper } from '../../components/Paper';
import { AttributeTable } from "../../components/table/AttributeTable";
import { CategoryTable } from "../../components/table/CategoryTable";
import { TablePlaceholder } from "../../components/TablePlaceholder";
import { TextField } from "../../components/TextField";
import { ViewComponent } from "../../components/ViewComponent";
import { TableSearchDebounceTime } from '../../constants';
import { Data } from '../../data/Data';
import { SettingsAttribute } from "../../data/settings/SettingsAttribute";
import { SettingsAttributeCategory } from "../../data/settings/SettingsAttributeCategory";
import { SettingsAttributeCategoryList } from '../../data/settings/SettingsAttributeCategoryList';
import { SettingsAttributeList } from '../../data/settings/SettingsAttributeList';
import { ReactComponent as AddIcon } from '../../resources/images/icons-add.svg';
import { ReactComponent as AttributesIcon } from '../../resources/images/icons-attribute.svg';
import { ReactComponent as CategoriesIcon } from '../../resources/images/icons-departments.svg';
import { ReactComponent as SearchIcon } from '../../resources/images/icons-search.svg';
import { getSortByApiValue } from '../../utilities';
import { AddEditAttributeView } from "./AddEditAttributeView";
import { AddEditCategoryView } from "./AddEditCategoryView";
import { AttributeInformDialog } from './AttributeInformDialog';

const axios = require('axios').default;
export class SettingsAttributesView extends ViewComponent {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            ...ViewComponent.state,
            attributeSearch: null,
            categorySearch: null,
            actionTarget: null,
            actionCategory: null,
            actionAttribute: null,
            attributeOrderByConvertList: [
                { tableFieldName: 'attribute', apiFieldName: 'name' },
                { tableFieldName: 'numJobs', apiFieldName: 'numberOfJobAttributes' },
                { tableFieldName: 'numEmployees', apiFieldName: 'numberOfEmployeeAttributes' },
            ],
            categoryOrderByConvertList: [],
            attributeSortModel: [{
                field: 'attribute',
                sort: 'asc',
            }],
            categorySortModel: [{
                field: 'name',
                sort: 'asc',
            }],
            categoryError: null,
            attributeError: null,
            deletingCategory: null,
            deletingAttribute: null,
            isInformDialogShow: false,
            error: null,
            newAttribute: new SettingsAttribute(),
            newCategory: new SettingsAttributeCategory(),
            attributePaginationModel: {
                page: 0,
                pageSize: SettingsAttributeList.defaultPageSize
            },
            categoriesPaginationModel: {
                page: 0,
                pageSize: SettingsAttributeCategoryList.defaultPageSize
            },
        };

        this.debounceTimer = null;
    }

    componentDidMount() {
        const { onLoadAttributes, onLoadCategories } = this.props;
        const { categorySortModel, attributeSortModel, attributeOrderByConvertList, categoryOrderByConvertList } = this.state;
        const [{ field, sort }] = attributeSortModel;
        const attributeOrderBy = getSortByApiValue(attributeOrderByConvertList, field, sort);
        const [model] = categorySortModel;
        const categoryOrderBy = getSortByApiValue(categoryOrderByConvertList, model?.field, model?.sort);

        onLoadAttributes(false, null, null, attributeOrderBy);
        onLoadCategories(false, null, null, categoryOrderBy);
    }

    onAttributeSearch(e) {
        const { onLoadAttributes } = this.props;
        const { attributePaginationModel: { pageSize }, attributeSortModel, attributeOrderByConvertList } = this.state;
        const [{ field, sort }] = attributeSortModel;
        const orderBy = getSortByApiValue(attributeOrderByConvertList, field, sort);
        clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(() => {
            onLoadAttributes(true, e.target.value, pageSize, orderBy);
        }, TableSearchDebounceTime);
        const newModel = { page: 0, pageSize }
        this.setState({ attributeSearch: e.target.value, attributePaginationModel: newModel });
    }

    onAttributesPaginationModelChange(model) {
        const { page, pageSize } = model;
        this.setState({ attributePaginationModel: model })
        const { onLoadAttributesPageSize } = this.props;
        const { attributeSearch, attributeSortModel, attributeOrderByConvertList } = this.state;
        const [{ field, sort }] = attributeSortModel;
        const orderBy = getSortByApiValue(attributeOrderByConvertList, field, sort);
        onLoadAttributesPageSize(page + 1, pageSize, attributeSearch, orderBy);
    }

    onAttributesSortModelChange(sortModel) {
        const { onLoadAttributes } = this.props;
        const { attributeSearch, attributePaginationModel: { pageSize }, attributeOrderByConvertList } = this.state;
        const [{ field, sort }] = sortModel;
        const orderBy = getSortByApiValue(attributeOrderByConvertList, field, sort);
        onLoadAttributes(true, attributeSearch, pageSize, orderBy);
        const newModel = { page: 0, pageSize };
        this.setState({ attributeSortModel: sortModel, attributePaginationModel: newModel });
    }

    onCategorySearch(e) {
        const { onLoadCategories } = this.props;
        const { categoriesPaginationModel: { pageSize }, categorySortModel, categoryOrderByConvertList } = this.state;
        const [{ field, sort }] = categorySortModel;
        const orderBy = getSortByApiValue(categoryOrderByConvertList, field, sort);
        clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(() => {
            onLoadCategories(true, e.target.value, pageSize, orderBy);
        }, TableSearchDebounceTime);
        const newModel = { page: 0, pageSize }
        this.setState({ categorySearch: e.target.value, categoriesPaginationModel: newModel });
    }

    onCategoriesPaginationModelChange(model) {
        const { page, pageSize } = model;
        this.setState({ categoriesPaginationModel: model })
        const { onLoadCategoriesPageSize } = this.props;
        const { categorySearch, categorySortModel, categoryOrderByConvertList } = this.state;
        const [{ field, sort }] = categorySortModel;
        const orderBy = getSortByApiValue(categoryOrderByConvertList, field, sort);
        onLoadCategoriesPageSize(page + 1, pageSize, categorySearch, orderBy);
    }

    onCategoriesSortModelChange(sortModel) {
        const { onLoadCategories } = this.props;
        const { categorySearch, categoryOrderByConvertList, categoriesPaginationModel: { pageSize } } = this.state;
        const [{ field, sort }] = sortModel;
        const orderBy = getSortByApiValue(categoryOrderByConvertList, field, sort);
        onLoadCategories(true, categorySearch, pageSize, orderBy);
        const newModel = { page: 0, pageSize };
        this.setState({ categorySortModel: sortModel, categoriesPaginationModel: newModel });
    }

    onViewChange(location) {
        if (this.pathEquals('/settings/competencies') || this.pathEquals('/settings/competencies/categories')) this.resetErrors();
    }

    resetErrors() {
        this.setState({ categoryError: null, attributeError: null, errorSnackbar: null, newAttribute: new SettingsAttribute(), newCategory: new SettingsAttributeCategory() });
    }

    onModeChange(e, showCategories) {
        this.setState({ showCategories });

        if (showCategories) this.loadCategories();
    }

    onCancelAddEditAttribute(e) {
        const { history, onCancelAddEditAttribute } = this.props;
        const { showAddEditAttributeDialog } = this.state;

        this.setState({ attributeError: null, newCategory: new SettingsAttributeCategory() });

        if (!showAddEditAttributeDialog) history.push('/settings/competencies');
        if (onCancelAddEditAttribute) onCancelAddEditAttribute();
    }

    onSaveAttribute(attribute, returnToList) {
        const { history, onSaveAttribute } = this.props;
        const { categorySearch, attributeSearch} = this.state;
        const isAddingNew = attribute.attributeId === null || attribute.attributeId === undefined;
        if (onSaveAttribute) onSaveAttribute(attribute, returnToList, attributeSearch, categorySearch).then(() => {
            const successMessage = isAddingNew ? 'Competency created succesfully' : 'Competency updated succesfully';
            if (returnToList) this.onCancelAddEditAttribute(null);
            this.setState({ successSnackbar: successMessage });
            if (!returnToList) {
                this.setState({ attributeError: null, newAttribute: new SettingsAttribute(), successSnackbar: successMessage }, history.push('/settings/competencies/new'));
            }
        }).catch(e => {
            this.setState({ attributeError: ErrorModel.parseServerError(e) });
        });
    }

    onCancelAddEditCategory(e) {
        const { history, onCancelAddEditCategory } = this.props;
        const { showAddEditCategoryDialog } = this.state;

        this.setState({ categoryError: null, newCategory: new SettingsAttributeCategory() });

        if (!showAddEditCategoryDialog) history.push('/settings/competencies/categories');
        if (onCancelAddEditCategory) onCancelAddEditCategory();
    }

    onSaveCategory(categoryId, name) {
        const { onSaveCategory } = this.props;
        const { categorySearch } = this.state;
        if (onSaveCategory) onSaveCategory(categoryId, categorySearch).then(() => {
            this.onCancelAddEditCategory();
            this.setState({ categoryError: null, newCategory: new SettingsAttributeCategory(), successSnackbar: 'Category created' });
        }).catch(e => {
            this.setState({ categoryError: ErrorModel.parseServerError(e) });
        });
    }

    isPath(path) {
        return this.props.location.pathname.startsWith(path);
    }

    onCategoryAction(e, category) {
        //if (this.locationMenu) this.locationMenu.open(e.target);
        this.setState({ actionTarget: e.target, actionCategory: category });
    }

    onAttributeAction(e, attribute) {
        //if (this.locationMenu) this.locationMenu.open(e.target);
        this.setState({ actionTarget: e.target, actionAttribute: attribute });
    }

    onEditCategory() {
        const { actionCategory } = this.state;
        this.onCloseActionMenu();

        this.props.history.push('/settings/competencies/categories/edit/' + actionCategory.categoryId);
    }

    onDeleteCategory() {
        const { actionCategory } = this.state;

        if (actionCategory.isInUsed) this.setState({ deletingCategory: actionCategory });
        else this.deleteCategory(actionCategory);

        this.onCloseActionMenu();
    }

    onDeleteCategoryConfirmed() {
        const { deletingCategory } = this.state;
        this.deleteCategory(deletingCategory);
        this.setState({ deletingCategory: null });
    }

    onCancelDeleteCategory() {
        this.setState({ deletingCategory: null });
    }

    onEditAttribute() {
        const { actionAttribute } = this.state;
        this.onCloseActionMenu();
        this.props.history.push('/settings/competencies/edit/' + actionAttribute.attributeId);
    }

    onDeleteAttribute() {
        const { actionAttribute } = this.state;
        if (actionAttribute.isInUsed) this.setState({ deletingAttribute: actionAttribute });
        else this.deleteAttribute(actionAttribute);
        this.onCloseActionMenu();
    }

    onDeleteAttributeConfirmed() {
        const { deletingAttribute } = this.state;
        this.deleteAttribute(deletingAttribute);
        this.setState({ deletingAttribute: null });
    }

    onCancelDeleteAttribute() {
        this.setState({ deletingAttribute: null });
    }

    onCloseActionMenu() {
        this.setState({ actionTarget: null, actionCategory: null, actionAttribute: null });
    }

    onCloseAttributeInformDialog() {
        this.setState({ isInformDialogShow: false });
    }

    onOpenAttributeInformDialog() {
        this.setState({ isInformDialogShow: true });
    }

    deleteAttribute(attribute) {
        const { attributes, onLoadAttributes } = this.props;
        const { attributeSearch } = this.state;
        this.context.setLoading('deleting', true);

        axios
            .delete(Data.apiBasePath + '/AttributeDefinition', {
                data: {
                    attributeDefinitionId: attribute.attributeId
                },
                withCredentials: true
            })
            .then(response => {
                attributes.delete(attribute);
                this.setState({ successSnackbar: "Competency deleted successfully." });
                if (onLoadAttributes) onLoadAttributes(true, attributeSearch);
            })
            .catch(e => {
                this.setState({ errorSnackbar: ErrorModel.parseServerError(e) });
            })
            .finally(() => {
                this.context.setLoading('deleting', false);
            });
    }

    deleteCategory(category) {
        const { categories, onLoadAttributes, onLoadCategories } = this.props;
        const { categorySearch, attributeSearch } = this.state;

        this.context.setLoading('deleting', true);

        axios
            .delete(Data.apiBasePath + '/AttributeDefinition/AttributeCategory', {
                data: {
                    attributeCategoryId: category.categoryId,
                    isForcing: true
                },
                withCredentials: true
            })
            .then(response => {
                categories.deleteCategory(category);
                this.setState({ successSnackbar: "Competency category deleted successfully." });
                if (onLoadAttributes) onLoadAttributes(true, attributeSearch);
                if (onLoadCategories) onLoadCategories(true, categorySearch);
            })
            .catch(e => {
                this.setState({ categoryError: ErrorModel.parseServerError(e) });
            })
            .finally(() => {
                this.context.setLoading('deleting', false);
            });
    }

    onAttributeSelect(e, attr) {
        this.props.history.push('/settings/competencies/edit/' + attr.attributeId);
    }

    onCategorySelect(e, cat) {
        this.props.history.push('/settings/competencies/categories/edit/' + cat.categoryId);
    }

    render() {
        const { organization, user, theme, location, orgSettings, history, onShowNewAttributeDialog, onShowNewCategoryDialog, categories, attributes, onNavigation, onBlockNavigation, onAllowNavigation } = this.props;
        const { attributeSortModel, categorySortModel, attributeSearch, categorySearch, actionTarget, categoryError, attributeError, deletingCategory, deletingAttribute, newAttribute, newCategory, isInformDialogShow, attributePaginationModel, categoriesPaginationModel } = this.state;

        const path = location.pathname;
        const showCategories = path.startsWith('/settings/competencies/categories');
        const editMode = path.includes('edit');

        const editingAttribute = path.startsWith('/settings/competencies/edit/');
        const showAddEditAttributeView = path.startsWith('/settings/competencies/new') || editingAttribute;

        const editingCategory = path.startsWith('/settings/competencies/categories/edit/');
        const showAddEditCategoryView = path.startsWith('/settings/competencies/categories/new') || editingCategory;
        const showRoot = !showAddEditAttributeView && !showAddEditCategoryView;

        let editCategory = null;
        let editCategoryId = null;
        if (editingCategory) editCategoryId = path.replace('/settings/competencies/categories/edit/', '');
        if (categories) editCategory = categories.getCategory(editCategoryId);

        let editAttribute = null;
        let editAttributeId = null;
        if (editingAttribute) editAttributeId = path.replace('/settings/competencies/edit/', '');
        if (attributes) editAttribute = attributes.get(editAttributeId);

        let categoryFilterItems = [{ id: 'search', columnField: 'name', operatorValue: 'contains', value: categorySearch }];
        let attributeFilterItems = [{ id: 'search', columnField: 'attribute', operatorValue: 'contains', value: attributeSearch }];

        return (
            <div>
                {showRoot && <Paper
                    theme={theme}
                    padding={{ xs: '40px 24px', md: '56px 64px!important' }}
                    borderFromBreakpoint='md'>
                    <StyledAttributeTitle>Competencies</StyledAttributeTitle>

                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            centered={true}
                            value={showCategories ? 'categories' : 'competencies'}
                            variant='fullWidth'
                            sx={{ width: '100%' }}>
                            <Tab
                                label="Competencies"
                                to={'/settings/competencies'}
                                component={Link}
                                value='competencies' />
                            <Tab
                                label="Categories"
                                to={'/settings/competencies/categories'}
                                component={Link}
                                value='categories' />
                        </Tabs>
                    </Box>

                    {!showCategories ?
                        <div>
                            <StyledAttributeSection
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={2}
                            >
                                <TextField
                                    value={attributeSearch}
                                    dense={true}
                                    placeholder='Search competencies'
                                    disabled={user === null}
                                    autoComplete={false}
                                    leadingIcon={<SearchIcon />}
                                    fullWidth={true}
                                    onChange={this.onAttributeSearch.bind(this)}
                                    sx={{ maxWidth: { md: '300px' } }} />
                                <Button
                                    path={'/settings/competencies/new'}
                                    size='md'
                                    theme={theme}
                                    variant='filled'
                                    showLabelFromBreakpoint='md'
                                    startIcon={<AddIcon />}
                                    label='New competency' />
                            </StyledAttributeSection>
                            <Delayed>
                                <AttributeTable
                                    disableSelection={false}
                                    minHeight='300px'
                                    rowHasAction={true}
                                    theme={theme}
                                    showHeaderFromBreakpoint='md'
                                    filterMode='server'
                                    filterItems={attributeFilterItems}
                                    hideFirstLastBorder={true}
                                    dense={true}
                                    paginationMode='server'
                                    totalCount={this.props.attributes?.totalCount}
                                    paginationModel={attributePaginationModel}
                                    onPaginationModelChange={this.onAttributesPaginationModelChange.bind(this)}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    onAttributeSelect={this.onAttributeSelect.bind(this)}
                                    onAttributeAction={this.onAttributeAction.bind(this)}
                                    onSortModelChange={this.onAttributesSortModelChange.bind(this)}
                                    sortingMode="server"
                                    sortModel={attributeSortModel}
                                    paper={false}
                                    columns={[
                                        { type: AttributeTable.columns.attribute, label: 'Competency', visibleFromBreakpoint: 'xs' },
                                        { type: AttributeTable.columns.numJobs, visibleFromBreakpoint: 'md' },
                                        { type: AttributeTable.columns.numEmployees, visibleFromBreakpoint: 'md' },
                                        { type: AttributeTable.columns.hasTrainingCourses, visibleFromBreakpoint: 'md' },
                                        { type: AttributeTable.columns.actions }
                                    ]}
                                    rows={attributes ? attributes.attributes : null}
                                    noRowsComponent={<TablePlaceholder text='Click the New competency button to create your first one' icon={<AttributesIcon />} />} />
                            </Delayed>
                            <Menu
                                open={actionTarget !== null}
                                anchorEl={actionTarget}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                onClose={this.onCloseActionMenu.bind(this)}
                            >
                                <MenuItem onClick={this.onEditAttribute.bind(this)}>
                                    <ListItemText>Edit competency</ListItemText>
                                </MenuItem>
                                <MenuItem onClick={this.onDeleteAttribute.bind(this)}>
                                    <ListItemText>Delete competency</ListItemText>
                                </MenuItem>
                            </Menu>
                        </div>
                        :
                        <div>
                            <StyledAttributeSection
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={2}
                            >
                                <TextField
                                    value={categorySearch}
                                    dense={true}
                                    placeholder='Search categories'
                                    disabled={user === null}
                                    autoComplete={false}
                                    fullWidth={true}
                                    leadingIcon={<SearchIcon />}
                                    onChange={this.onCategorySearch.bind(this)}
                                    sx={{ maxWidth: { md: '300px' } }} />
                                <Button
                                    path={'/settings/competencies/categories/new'}
                                    size='md'
                                    theme={theme}
                                    variant='filled'
                                    startIcon={<AddIcon />}
                                    showLabelFromBreakpoint='md'
                                    label='New category' />
                            </StyledAttributeSection>
                            <CategoryTable
                                theme={theme}
                                showHeaderFromBreakpoint='md'
                                minHeight='300px'
                                hideFirstLastBorder={true}
                                filterMode='server'
                                filterItems={categoryFilterItems}
                                dense={true}
                                paginationMode='server'
                                totalCount={this.props.categories?.totalCount}
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                paginationModel={categoriesPaginationModel}
                                onPaginationModelChange={this.onCategoriesPaginationModelChange.bind(this)}
                                onCategorySelect={this.onCategorySelect.bind(this)}
                                onCategoryAction={this.onCategoryAction.bind(this)}
                                onSortModelChange={this.onCategoriesSortModelChange.bind(this)}
                                sortingMode="server"
                                sortModel={categorySortModel}
                                paper={false}
                                rows={categories ? categories.categories : null}
                                noRowsComponent={<TablePlaceholder text='Click the New category button to add a category' icon={<CategoriesIcon />} />} />

                            <Menu
                                open={actionTarget !== null}
                                anchorEl={actionTarget}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                onClose={this.onCloseActionMenu.bind(this)}
                            >
                                <MenuItem onClick={this.onEditCategory.bind(this)}>
                                    <ListItemText>Edit category</ListItemText>
                                </MenuItem>
                                <MenuItem onClick={this.onDeleteCategory.bind(this)}>
                                    <ListItemText>Delete category</ListItemText>
                                </MenuItem>
                            </Menu>

                        </div>}

                </Paper>}

                {showAddEditAttributeView &&
                    <AddEditAttributeView
                        className={'klayo__add-edit-attribute-setting'}
                        dialogMode={false}
                        organization={organization}
                        orgSettings={orgSettings}
                        editMode={editMode}
                        location={location}
                        theme={theme}
                        error={attributeError}
                        onShowNewCategoryDialog={onShowNewCategoryDialog}
                        onShowNewAttributeDialog={onShowNewAttributeDialog}
                        attribute={editMode ? editAttribute : newAttribute}
                        attributeId={editAttributeId}
                        onBlockNavigation={onBlockNavigation}
                        onAllowNavigation={onAllowNavigation}
                        onNavigation={onNavigation}
                        onOpenDialog={this.onOpenAttributeInformDialog.bind(this)}
                        onSave={this.onSaveAttribute.bind(this)}
                        onCancel={this.onCancelAddEditAttribute.bind(this)}
                        {...this.props}
                    />}

                {showAddEditCategoryView &&
                    <AddEditCategoryView
                        editMode={editMode}
                        theme={theme}
                        dialogMode={false}
                        category={editMode && editCategory ? new SettingsAttributeCategory(editCategory) : newCategory}
                        categoryId={editCategoryId}
                        error={categoryError}
                        onBlockNavigation={onBlockNavigation}
                        onAllowNavigation={onAllowNavigation}
                        onNavigation={onNavigation}
                        onSave={this.onSaveCategory.bind(this)}
                        onCancel={this.onCancelAddEditCategory.bind(this)} />}

                {deletingCategory &&
                    <ConfirmationDialog
                        theme={theme}
                        title='Delete?' question='This category is currently used by competencies. Are you sure you want to delete this category?'
                        cancelButton='No'
                        acceptButton='Yes'
                        acceptDanger={true}
                        onCancel={this.onCancelDeleteCategory.bind(this)}
                        onAccept={this.onDeleteCategoryConfirmed.bind(this)} />}

                {deletingAttribute &&
                    <ConfirmationDialog
                        theme={theme}
                        title='Delete competency' question='This competency is being used. Are you sure you want to delete it?'
                        cancelButton='Cancel'
                        acceptButton='Delete competency'
                        acceptDanger={true}
                        onCancel={this.onCancelDeleteAttribute.bind(this)}
                        onAccept={this.onDeleteAttributeConfirmed.bind(this)} />}

                {isInformDialogShow && <AttributeInformDialog
                    onClose={this.onCloseAttributeInformDialog.bind(this)}
                    theme={theme}
                    {...this.props}
                />}

                {this.renderSnackbar()}

            </div>
        );
    }
}

const StyledAttributeSection = styled(Stack)`
    padding: 40px 0px 24px 0px;
    
    @media (max-width: 600px) {
        padding: 32px 0px 24px 0px;
    }
`

const StyledAttributeTitle = styled.h1`
    @media (max-width: 600px) {
        margin-bottom: 32px !important;
    }
`