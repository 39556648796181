import { React, Component } from 'react';
import { ReactComponent as TreeIcon } from '../resources/images/icons-view-tree.svg';
import { ReactComponent as ListIcon } from '../resources/images/icons-view-list.svg';
import { ToggleButton } from './ToggleButton';

export class ListTreeToggleButton extends Component {

    constructor(props) {
        super(props);

        this.state = {
            treeMode: false
        };
    }

    onChange(e, mode) {
        const { onChange } = this.props;
        if (mode === null) return;
        if(onChange) onChange(mode);
    }

    render() {
        const { value, disabled } = this.props;
        const { treeMode } = this.state;

        return <ToggleButton
            options={[
                {
                    value: false,
                    icon: <ListIcon />,
                    tooltip: 'List view',
                    disabled: disabled
                }, {
                    value: true,
                    icon: <TreeIcon />,
                    tooltip: 'Tree view',
                    disabled: disabled
                }
            ]}
            value={value}
            disabled={disabled}
            onChange={this.onChange.bind(this)}
        />;
    }
}