import { Component } from "react";
import { Button } from "../../components/Button";
import { Alert } from "klayowebshared";
import { Stack, Step, StepLabel, Stepper, Fade, Divider, Box, Tab, Tabs, Link, Menu, MenuItem, ListItemText } from "@mui/material";
import { TextField } from "../../components/TextField";
import { LocationSelector } from "../../components/selectors/LocationSelector";
import { Data } from '../../data/Data';
import { ErrorModel } from '../../common/models/ErrorModel';
import { AppContext } from '../../common/AppContext';
import { JobSelector } from "../../components/selectors/JobSelector";
import { ReactComponent as RightIcon } from '../../resources/images/icons-arrow-right.svg';
import { ReactComponent as LeftIcon } from '../../resources/images/icons-arrow-left.svg';
import { ReactComponent as UserIcon } from '../../resources/images/icons-users-1.svg';
import { Utils } from "klayowebshared";
import { PaperOrDialog } from "../../components/PaperOrDialog";
import { TooltipIcon } from "../../components/TooltipIcon";
import { EditTemplate } from "klayowebshared";
import { SettingsGroup } from "../../data/settings/SettingsGroup";
import { PillButtons } from "../../components/PillButtons";
import { EmployeeSelector } from "../../components/selectors/EmployeeSelector";
import { GroupMemberSelectorList } from "../../data/filters/GroupMemberSelectorList";
import { EmployeeTable } from "../../components/table/EmployeeTable";
import { MultiSelector } from "../../components/selectors/MultiSelector";
import { GroupMembersDialog } from "./GroupMembersDialog";
import { Placeholder } from "../../components/Placeholder";
import { SettingsJobList } from "../../data/settings/SettingsJobList";
import { PAGE_SIZE_FOR_ADD_EDIT } from "../../constants";
import { cloneDeep } from "lodash";

export class AddEditGroupView extends Component {
    static contextType = AppContext;

    static defaultProps = {
        allowEmptyMembers: true,
        allowEmptyManagers: true
    };

    constructor(props) {
        super(props);

        this.existingGroup = new SettingsGroup(props.group);

        this.state = {
            group: props.group,
            groupLoaded: !props.editMode,
            actionEmployee: null,
            jobs: null,
            jobsSearchList: null,
            jobsSearch: null,
            jobSelected: null,
            error: props.error,
            step: 0,
            hasEdit: false,
            saving: false,
            manualManagerMemberOverlap: this.getManagerMemberOverlap(this.existingGroup),
            ruleBasedMembersCount: null,
            updatingRuleBasedMembersCount: false,
            ruleBasedMembers: null,
            showRuleBasedEmployeesDialog: false
        };
    }

    componentDidMount() {
        const { onLoadJobs, group } = this.props;
        this.loadDetails();
        this.loadJobs();
        onLoadJobs();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.error !== prevProps.error) this.setState({ error: this.props.error });
        if (prevProps.jobs !== this.props.jobs) this.forceUpdate();
    }

    loadDetails() {
        const { group } = this.state;
        const groupId = group ? group.groupId : this.props.groupId;

        if (!groupId) return;

        this.context.setLoading('loading', true);

        Data.get(this.context, '/Group/' + groupId)
            .then(response => {
                const group = new SettingsGroup(response.data.group);
                this.existingGroup = new SettingsGroup(response.data.group);

                this.setState({ group, jobSelected: group.jobDefinitionIds, groupLoaded: true, saving: false, manualManagerMemberOverlap: this.getManagerMemberOverlap(group) }, () => {
                    if (group && group.isRuleBasedSelection()) this.getRuleBasedEmployeeCount();
                });
            })
            .catch(e => {
                this.setState({ error: ErrorModel.parseServerError(e) });
            })
            .finally(() => {
                this.context.setLoading('loading', false);
            });
    }

    loadJobs(searchText) {
        this.context.setLoading('jobs', true);

        const pagingOptions = searchText ?  {pageNumber: 1, pageSize: PAGE_SIZE_FOR_ADD_EDIT, searchText: searchText} : {pageSize: 100000};

        // get setting job data then set to jobs state
        if (searchText) {
            SettingsJobList
                .getWithPaging(this.context, pagingOptions).then(jobs => {
                    this.setState({ jobsSearchList: jobs, jobsSearch: searchText });
                })
                .catch(error => {})
                .finally(() => this.context.setLoading('jobs', false));
        } else {
            SettingsJobList
                .getWithPaging(this.context, pagingOptions).then(jobs => {
                    const jobsSearchList = { ...jobs, jobs: jobs.jobs.slice(0, PAGE_SIZE_FOR_ADD_EDIT - 1)}

                    // job state need to be separated from search job state because it was used to take selected job in multiselect
                    this.setState({ jobs, jobsSearchList, jobsSearch: searchText });
                })
                .catch(error => {})
                .finally(() => this.context.setLoading('jobs', false));
        }
    }

    onSearchJobs(searchText) {
        this.loadJobs(searchText);
    }

    onJobsBlur() {
        const { jobsSearch } = this.state;

        if (jobsSearch) this.loadJobs(jobsSearch);
    }

    onBack() {
        this.setState({ step: 0 });
    }

    onNavigation(e, callback) {
        const { onNavigation } = this.props;
        return onNavigation && onNavigation(e, callback);
    }

    onCancel(e, source) {
        this.onNavigation(e, this.stopBlockingNavAndClose.bind(this));
    }

    stopBlockingNavAndClose(e) {
        const { onCancel, onBlockNavigation } = this.props;
        if (onBlockNavigation) onBlockNavigation(false, 'Group');
        if (onCancel) onCancel(e);
    }

    onNameChange(e) {
        const { group } = this.state;
        group.name = e.target.value;
        this.setState({ group });
    }

    onDescriptionChange(e) {
        const { group } = this.state;
        group.description = e.target.value;
        this.setState({ group });
    }

    isNameValid(group) {
        return (group && group.name && group.name.length > 2) === true;
    }

    isValid(group, step) {
        const { allowEmptyMembers, allowEmptyManagers } = this.props;

        if (!group || !this.isNameValid(group)) return false;
        if (!allowEmptyMembers && group.isManualSelection() && group.members.length < 1) return false;
        if (group.isRuleBasedSelection() && !this.isRuleValid(group)) return false;

        if (step === 1) {
            if (!allowEmptyManagers && group.managers.length < 1) return false;
            if (group.managers.some(m => !m.groupLevelType || !m.groupNotificationType)) return false;
        }
        return true;
    }

    isRuleValid(group) {
        return (group.jobDefinitionIds.length > 0 || group.includeAllJobDefinitions === true) && (group.locationIds.length > 0 || group.includeAllLocations === true);
    }

    isNameValid(group) {
        return (group && group.name && group.name.length > 2) === true;
    }

    onTabChange(e, newStep) {
        this.setState({ step: newStep, error: null });
    }

    onSave() {
        const { onSave, onBlockNavigation, editMode } = this.props;
        const { group, step } = this.state;

        if (!editMode && step === 0 && this.isValid(group, 0)) this.setState({ step: 1, error: null });
        if (onSave && (editMode || step === 1)) {
            if (onBlockNavigation) onBlockNavigation(false, 'Group');
            this.setState({ saving: true });
            onSave(group, () => {
                this.setState({ saving: false });
            });
        }
    }

    onMemberSelectionTypeChange(e, memberSelectionType) {
        const { group } = this.state;
        group.memberSelectionType = memberSelectionType;
        this.setState({ group, manualManagerMemberOverlap: this.getManagerMemberOverlap(group) });
    }

    onJobsChange(e, jobs, isAll) {
        const { group, jobsSearchList } = this.state;

        //save only Job ID
        group.includeAllJobDefinitions = isAll === true;
        group.jobDefinitionIds = isAll ? [] : jobs.map(j => j.jobDefinitionId);

        this.setState({ group, jobSelected: group.jobDefinitionIds }, () => this.onRuleChange(e));
    }

    onLocationsChange(e, locations, isAll) {
        const { group } = this.state;
        group.includeAllLocations = isAll === true;
        group.locationIds = isAll ? [] : locations.map(j => j.locationId);
        this.setState({ group }, () => this.onRuleChange(e));
    }

    onRuleChange(e) {
        const { group } = this.state;
        this.getRuleBasedEmployeeCount();
    }

    getRuleBasedEmployeeCount() {
        const { group } = this.state;
        this.context.setLoading('calculateMembers', true);

        this.setState({ updatingRuleBasedMembersCount: true })

        group.loadRuleBasedMembersCount(this.context, true)
            .then(ruleBasedMembersCount => {
                this.setState({ ruleBasedMembersCount, updatingRuleBasedMembersCount: false });
            })
            .catch(e => {
                this.setState({ error: ErrorModel.parseServerError(e), ruleBasedMembersCount: null, updatingRuleBasedMembersCount: false });
            })
            .finally(() => this.context.setLoading('calculateMembers', false));
    }

    onEditStateChange(hasEdit) {
        this.setState({ hasEdit });
    }

    onMembersChange(e, employee) {
        if (!employee) return;
        const { group } = this.state;

        group.addMember(employee);
        this.setState({ group, manualManagerMemberOverlap: this.getManagerMemberOverlap(group) });
    }

    onRemoveMember(e, employee) {
        if (!employee) return;
        const { group } = this.state;

        group.removeMember(employee);
        this.setState({ group, manualManagerMemberOverlap: this.getManagerMemberOverlap(group) });
    }

    onManagerChange(e, employee) {
        if (!employee) return;
        const { group } = this.state;

        group.addManager(employee);
        this.setState({ group, manualManagerMemberOverlap: this.getManagerMemberOverlap(group) }, () => this.onRuleChange(e));
    }

    onRemoveManager(e, employee) {
        if (!employee) return;
        const { group } = this.state;

        this.onCloseActionMenu();

        group.removeManager(employee);
        this.setState({ group, manualManagerMemberOverlap: this.getManagerMemberOverlap(group) }, () => this.onRuleChange(e));
    }

    onManagerGroupLevelTypeChange(e, manager) {
        if (!manager) return;
        manager.groupLevelType = e.target.value.id;
        this.forceUpdate();
    }

    onManagerGroupNotificationTypesChange(e, manager) {
        if (!manager) return;
        manager.groupNotificationType = e.target.value.id;
        this.forceUpdate();
    }

    getManagerMemberOverlap(group) {
        if(!group) return;
        return group.managers.filter(m => group.members.some(e => m.employeeId === e.employeeId));
    }

    renderManagerMemberOverlapAlert() {
        const { group, manualManagerMemberOverlap } = this.state;

        const overlap = group && group.isRuleBasedSelection() ? group.ruleBasedMembersCount.managerMemberOverlap : manualManagerMemberOverlap;

        if (!overlap || overlap.length === 0) return;

        return <Alert severity='warning'><b>{overlap.map(m => m.fullName).join(", ")}</b> {overlap.length === 1 ? 'is also a member' : 'are also members'} of this group. Any group member that is also given ‘manage’ access to that group, will not be able to manage themselves, nor receive their own notifications.</Alert>
    }

    onViewRuleBasedEmployees(e) {
        const { group } = this.state;

        this.context.setLoading('viewMembers', true);

        group.loadRuleBasedMembers(this.context, true)
            .then(ruleBasedMembers => {
                this.setState({ ruleBasedMembers });
            })
            .catch(e => {
                this.setState({ error: ErrorModel.parseServerError(e), ruleBasedMembers: null });
            })
            .finally(() => this.context.setLoading('viewMembers', false));

        this.setState({ showRuleBasedEmployeesDialog: true, ruleBasedMembers: null });
    }

    onCloseRuleBasedEmployeesDialog(e) {
        this.setState({ showRuleBasedEmployeesDialog: false });
    }

    onAccessEmployeeAction(e, Employee) {
        this.setState({ actionTarget: e.target, actionEmployee: Employee });
    }

    onCloseActionMenu() {
        this.setState({ actionTarget: null, actionEmployee: null });
    }

    onAssignAsInstructor(isInstructor) {
        const { actionEmployee, group } = this.state;
        const managers = cloneDeep(group.managers);
        this.onCloseActionMenu();
        
        if (group) {
            const editedManagerIndex = managers.findIndex((e) => e.employeeId === actionEmployee.employeeId);

            managers[editedManagerIndex].isInstructor = isInstructor;
            group.managers = [...managers];
        }
    }

    render() {
        const { organization, theme, user, orgSettings, editMode, locations, onAllowNavigation, onBlockNavigation } = this.props;
        const { error, group, actionEmployee, actionTarget, groupLoaded, jobs, jobSelected, jobsSearchList, step, ruleBasedMembers, ruleBasedMembersCount, updatingRuleBasedMembersCount, showRuleBasedEmployeesDialog, saving, search, hasEdit } = this.state;

        return <EditTemplate
            theme={theme}
            name='Group'
            onAllowNavigation={onAllowNavigation}
            onBlockNavigation={onBlockNavigation}
            onEditStateChange={this.onEditStateChange.bind(this)}
            detectEdit={!saving}
            compare={{
                existing: this.existingGroup,
                editing: group,
                members: [
                    { name: 'name' },
                    { name: 'description' },
                    { name: 'members', detectEdit: (existing, editing) => !existing.compareMemberSelection(editing) },
                    { name: 'managers', detectEdit: (existing, editing) => !existing.compareManagers(editing) }
                ]
            }}>
            <PaperOrDialog
                theme={theme}
                dialogToBreakpoint='md'
                disabledToBreakpoint='md'
                paperPadding='60px'
                title={editMode ? 'Group details' : 'Create group'}
                titleSx={{ fontSize: { xs: '20px', md: '30px' }, fontWeight: 'bold' }}
                titleSize={1}
                backButton={editMode}
                onBack={this.onCancel.bind(this)}>

                <Stack
                    direction="column"
                    spacing={4}
                >
                    {error && <Alert
                        severity='error'
                        sx={{ width: '100%' }}>{error}</Alert>}

                    {!editMode ? <Stepper activeStep={step}>
                        <Step
                            onClick={this.onBack.bind(this)}
                            sx={{ cursor: step !== 0 ? 'pointer' : 'default' }}>
                            <StepLabel>Group information</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>Group access</StepLabel>
                        </Step>
                    </Stepper> :
                        <div>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs
                                    centered={true}
                                    value={step}
                                    onChange={this.onTabChange.bind(this)}
                                    variant='fullWidth'
                                    sx={{ width: '100%' }}>
                                    <Tab
                                        label="Information"
                                        value={0} />
                                    <Tab
                                        label="Access"
                                        value={1} />
                                </Tabs>
                            </Box>
                        </div>}

                    {step === 0 &&
                        <Fade in={true}>
                            <div>
                                <Stack
                                    direction="column"
                                    spacing={4}
                                >
                                    <div>
                                        <h2>Group details</h2>
                                        <Stack
                                            direction="column"
                                            spacing={4}
                                            sx={{ padding: '16px 0' }}>
                                            <TextField
                                                label='Group name (required)'
                                                value={group ? group.name : null}
                                                clearable={false}
                                                autoComplete={false}
                                                onChange={this.onNameChange.bind(this)}
                                                validationMethod={() => this.isNameValid(group)}
                                                helperText='The name must be unique'
                                                validationText='Please enter a valid group name (more than 2 characters)'
                                                loading={!groupLoaded}
                                                disabled={saving}
                                                sx={{ width: '100%' }} />

                                            <TextField
                                                label="Description"
                                                autoComplete={false}
                                                multiline={true}
                                                clearable={false}
                                                minRows={3}
                                                sx={{ width: '100%' }}
                                                value={group ? group.description : null}
                                                disabled={saving}
                                                loading={!groupLoaded}
                                                onChange={this.onDescriptionChange.bind(this)}
                                            />
                                        </Stack>
                                    </div>
                                    <div><Divider /></div>
                                    <div>
                                        <h2>Group members</h2>
                                        <Stack
                                            direction="column"
                                            spacing={4}
                                            sx={{ padding: '16px 0' }}>

                                            {!editMode &&
                                                <Stack
                                                    spacing={2}
                                                    alignItems='end'
                                                    direction="row">
                                                    <PillButtons
                                                        label='Member selection'
                                                        user={user}
                                                        value={group ? group.memberSelectionType : SettingsGroup.memberSelectionType.manual}
                                                        organization={organization}
                                                        onChange={this.onMemberSelectionTypeChange.bind(this)}
                                                        pages={{
                                                            standard: { name: SettingsGroup.memberSelectionType.manual.name, value: SettingsGroup.memberSelectionType.manual, tooltip: null },
                                                            parent: { name: SettingsGroup.memberSelectionType.ruleBased.name, value: SettingsGroup.memberSelectionType.ruleBased, tooltip: null }
                                                        }}
                                                        disabled={saving}
                                                        sx={{ alignSelf: 'flex-start' }} />
                                                    <TooltipIcon
                                                        text='Manually choose each person to be a member of this group, or configure the group based on one or more rules to dynamically assign members.'
                                                        placement='right'
                                                        sx={{ paddingBottom: '2px' }} />
                                                </Stack>
                                            }

                                            {(!group || group.isManualSelection()) &&
                                                <Fade in={true}>
                                                    <div>
                                                        <Stack
                                                            spacing={2}
                                                            direction="column">

                                                            <EmployeeSelector
                                                                theme={theme}
                                                                hasNoneItem={false}
                                                                multiple={false}
                                                                label='Members'
                                                                clearable={false}
                                                                clearOnSelection={true}
                                                                dense={false}
                                                                clearResultsOnSelection={true}
                                                                excludeItems={group ? group.members : null}
                                                                //initialItems={managers ? managers.employees : null}
                                                                placeholder='Search and add employees'
                                                                dataList={GroupMemberSelectorList}
                                                                disabled={saving}
                                                                onChange={this.onMembersChange.bind(this)}
                                                            />

                                                            {this.renderManagerMemberOverlapAlert()}

                                                            {group && group.members && group.members.length > 0 &&
                                                                <EmployeeTable
                                                                    pagination={false}
                                                                    showFooter={false}
                                                                    rowHasAction={true}
                                                                    theme={theme}
                                                                    showHeaderFromBreakpoint='md'
                                                                    //filterItems={filterItems}
                                                                    hideFirstLastBorder={true}
                                                                    dense={true}
                                                                    columns={[
                                                                        { type: EmployeeTable.columns.fullName },
                                                                        { type: EmployeeTable.columns.jobTitle },
                                                                        { type: EmployeeTable.columns.removeAction, tooltip: 'Remove member' }
                                                                    ]}
                                                                    sortModel={[{
                                                                        field: 'fullName',
                                                                        sort: 'asc',
                                                                    }]}
                                                                    onRemove={this.onRemoveMember.bind(this)}
                                                                    paper={false}
                                                                    disabled={saving}
                                                                    rows={group.members} />
                                                            }
                                                        </Stack>
                                                    </div>
                                                </Fade>
                                            }

                                            {(group && group.isRuleBasedSelection()) &&
                                                <Fade in={true}>
                                                    <div>
                                                        <Stack
                                                            direction="column"
                                                            spacing={2}
                                                            sx={{ padding: '16px 0' }}>

                                                            <MultiSelector
                                                                onChange={this.onJobsChange.bind(this)}
                                                                hasAllItem={true}
                                                                allItemLabel='All jobs'
                                                                values={jobs ? jobs.jobs.filter(j => group.jobDefinitionIds.includes(j.jobDefinitionId)) : null}
                                                                // values={jobs ? jobs.jobs.filter(j => jobSelected.includes(j.jobDefinitionId)) : null}
                                                                allSelected={group.includeAllJobDefinitions}
                                                                disabled={saving}
                                                                renderSelector={props =>
                                                                    <JobSelector
                                                                        label='Jobs (required)'
                                                                        placeholder='Search and select jobs'
                                                                        items={jobsSearchList ? jobsSearchList.jobs : null}
                                                                        onInputChange={this.onSearchJobs.bind(this)}
                                                                        onBlur={this.onJobsBlur.bind(this)}
                                                                        getItemLabel={job => job.name}
                                                                        compareItems={(a, b) => a.jobDefinitionId === b.jobDefinitionId}
                                                                        callApi={false}
                                                                        multiple={false}
                                                                        clearable={true}
                                                                        loading={!groupLoaded || !jobs}
                                                                        {...props} />
                                                                } />

                                                            <MultiSelector
                                                                onChange={this.onLocationsChange.bind(this)}
                                                                hasAllItem={true}
                                                                allItemLabel='All locations'
                                                                //values={group.locationIds}
                                                                values={locations ? locations.filter(j => group.locationIds.includes(j.locationId)) : null}
                                                                allSelected={group.includeAllLocations}
                                                                disabled={saving}
                                                                renderSelector={props =>
                                                                    <LocationSelector
                                                                        label='Locations (required)'
                                                                        placeholder='Search and select locations'
                                                                        items={locations ? locations : null}
                                                                        callApi={false}
                                                                        multiple={false}
                                                                        compareItems={(a, b) => a.locationId === b.locationId}
                                                                        getItemLabel={location => location.name}
                                                                        loading={!groupLoaded || !locations}
                                                                        {...props}
                                                                    />
                                                                } />

                                                            {ruleBasedMembersCount && this.isRuleValid(group) && this.renderManagerMemberOverlapAlert()}

                                                            {ruleBasedMembersCount && this.isRuleValid(group) &&
                                                                <Alert severity='info' sx={{ width: '100%' }}>
                                                                    <Stack direction='row' justifyContent='space-between' sx={{ width: '100%' }}>
                                                                        <div>{updatingRuleBasedMembersCount ? 'Updating...' : Utils.pluralize(ruleBasedMembersCount.totalEmployees, 'employee') + ' ' + Utils.isOrAre(ruleBasedMembersCount.totalEmployees) + ' matching your criteria'}</div>
                                                                        {!updatingRuleBasedMembersCount && ruleBasedMembersCount.totalEmployees !== 0 && <div><Link onClick={this.onViewRuleBasedEmployees.bind(this)}>View employees</Link></div>}
                                                                    </Stack>
                                                                </Alert>}
                                                        </Stack>

                                                        {showRuleBasedEmployeesDialog && ruleBasedMembers &&
                                                            <GroupMembersDialog
                                                                theme={theme}
                                                                open={true}
                                                                employees={ruleBasedMembers.employees}
                                                                onCancel={this.onCloseRuleBasedEmployeesDialog.bind(this)} />}
                                                    </div>
                                                </Fade>
                                            }

                                        </Stack>
                                    </div>
                                </Stack>
                            </div>
                        </Fade>
                    }

                    {step === 1 &&
                        <Fade in={true}>
                            <div>
                                <Stack
                                    direction="column"
                                    spacing={2}
                                >
                                    <div className='klayo-instruction klayo-instruction-dark'>Select one or more employees you want to give access to this group.</div>

                                    <Stack
                                        spacing={4}
                                        direction="column">
                                        <div>
                                            <EmployeeSelector
                                                theme={theme}
                                                hasNoneItem={false}
                                                multiple={false}
                                                label={null}
                                                clearable={false}
                                                clearOnSelection={true}
                                                clearResultsOnSelection={true}
                                                disabled={saving}
                                                excludeItems={group ? group.managers : null}
                                                //initialItems={managers ? managers.employees : null}
                                                placeholder='Search and add employees'
                                                dataList={GroupMemberSelectorList}
                                                onChange={this.onManagerChange.bind(this)}
                                            />
                                        </div>

                                        {group && group.managers && group.managers.length > 0 &&
                                            <Stack
                                                spacing={2}
                                                direction="column">
                                                {this.renderManagerMemberOverlapAlert()}
                                                <EmployeeTable
                                                    pagination={false}
                                                    showFooter={false}
                                                    rowHasAction={true}
                                                    theme={theme}
                                                    showHeaderFromBreakpoint='md'
                                                    disabled={saving}
                                                    //filterItems={filterItems}
                                                    hideFirstLastBorder={true}
                                                    dense={false}
                                                    columns={[
                                                        { type: EmployeeTable.columns.profilePicFullNameJobTitle },
                                                        { type: EmployeeTable.columns.groupLevelType, groupLevelTypes: orgSettings.groupLevelTypes },
                                                        { type: EmployeeTable.columns.groupNotificationType, groupNotificationTypes: orgSettings.groupNotificationTypes },
                                                        { type: EmployeeTable.columns.removeAction, tooltip: 'Remove member' }
                                                    ]}
                                                    sortModel={[{
                                                        field: 'profilePicFullName',
                                                        sort: 'asc',
                                                    }]}
                                                    onRemove={this.onRemoveManager.bind(this)}
                                                    onGroupLevelTypeChange={this.onManagerGroupLevelTypeChange.bind(this)}
                                                    onGroupNotificationTypesChange={this.onManagerGroupNotificationTypesChange.bind(this)}
                                                    onAccessEmployeeAction={this.onAccessEmployeeAction.bind(this)}
                                                    paper={false}
                                                    rows={group.managers} />
                                            </Stack>
                                        }

                                        {!group || !group.managers || group.managers.length === 0 &&
                                            <Placeholder
                                                text='Use the selector above to search and add employees'
                                                icon={<UserIcon />}
                                                sx={{ margin: '64px 0 32px 0!important' }} />}
                                    </Stack>
                                </Stack>
                            </div>
                        </Fade>
                    }

                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >

                        <Button
                            onClick={this.onCancel.bind(this)}
                            disabled={saving}
                            label='Cancel' />
                        <Stack
                            direction="row"
                            spacing={2}>
                            {step > 0 && !editMode && <Button
                                variant='outlined'
                                onClick={this.onBack.bind(this)}
                                disabled={saving}
                                startIcon={<LeftIcon />}
                                label='Back' />}
                            <Button
                                variant='filled'
                                disabled={!this.isValid(group, step) || !hasEdit || saving || !groupLoaded}
                                onClick={this.onSave.bind(this)}
                                endIcon={!editMode && step === 0 ? <RightIcon /> : null}
                                label={editMode ? 'Save' : step === 0 ? 'Continue' : 'Create'} />
                        </Stack>
                    </Stack>

                    {actionEmployee &&
                        <Menu
                            open={actionTarget !== null}
                            anchorEl={actionTarget}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            onClose={this.onCloseActionMenu.bind(this)}
                        >
                            <MenuItem onClick={(e) => this.onRemoveManager(e, actionEmployee)}>
                                <ListItemText>Remove member</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={() => this.onAssignAsInstructor(!actionEmployee.isInstructor)}>
                                <ListItemText>{actionEmployee.isInstructor ? 'Unassign as Instructor' : 'Assign as Instructor'}</ListItemText>
                            </MenuItem>
                        </Menu>
                    }
                </Stack>
            </PaperOrDialog>
        </EditTemplate>
    }
}