import { React } from 'react';
import { Box, Grid, MenuItem } from '@mui/material';
import { GapAttributeList } from '../../data/attributes/GapAttributeList';
import { AbstractProfileSection } from './AbstractProfileSection';
import { AddEditAttributeDialog } from './AddEditAttributeDialog';
import { AttributeActionMenu } from '../../components/AttributeActionMenu';
import { AttributeTable } from '../../components/table/AttributeTable';
import { AttributeTableSearchBar } from '../../components/table/AttributeTableSearchBar';
import { AbstractAttributeList } from '../../data/attributes/AbstractAttributeList';
import { ReactComponent as PendingIcon } from '../../resources/images/icons-pending.svg';
import { ReactComponent as UserIcon } from '../../resources/images/icons-user.svg';
import { Placeholder } from '../../components/Placeholder';
import { Utils } from '../../common/Utils';

export class ProfileGapsSection extends AbstractProfileSection {

    constructor(props) {
        super(props);

        const { organization } = this.props;
        this.filters = GapAttributeList.getFilterItems(organization && organization.showProficiency);
    }

    onAddGapAction() {
        super.openAddEditAttributeDialog(AddEditAttributeDialog.mode.add);
    }

    renderActionMenu() {
        const { employee, profile, organization, onAddChangeAction, onProficiencyChangeAction, onFeedbackAction } = this.props;
        const { actionTarget, actionAttribute } = this;

        const gotoTrainingMenuItem = 
            <MenuItem>
                Go to training
            </MenuItem>;
        if (!actionAttribute || !actionTarget) return null;

        if (actionAttribute.isNeedsFeedback()) return (
            <div>
                <MenuItem
                    onClick={e => onFeedbackAction(e, actionAttribute)}
                    disabled={employee === null || employee.hasJob === false || (profile && (profile.hasApprover === false))}>
                    Provide feedback
                </MenuItem>
            </div>
        );
        else if ((organization && actionAttribute.isLowProficiency() && organization.showProficiency) || (actionAttribute.hasExpiryDate && actionAttribute.expiryDate === null)) return (
            <div>
                <MenuItem
                    onClick={e => onProficiencyChangeAction(e, actionAttribute)}
                    disabled={employee === null || employee.hasJob === false || (profile && (profile.hasApprover === false))}>
                    Request changes
                </MenuItem>
            </div>
        );
        else if (actionAttribute.isMissing()) return (
            <div>
                <MenuItem
                    onClick={e => onAddChangeAction(e, actionAttribute)}
                    disabled={employee === null || employee.hasJob === false || (profile && (profile.hasApprover === false))}>
                    Add competency
                </MenuItem>
                {actionAttribute.trainingCourses && actionAttribute.trainingCourses.length !== 0 && gotoTrainingMenuItem}
            </div>
        );

        return null;
    }

    rowHasAction(attr) {
        const { organization } = this.props;
        if (!attr) return false;

        if (attr.hasValidationRequest() && !attr.isNeedsFeedback()) return false;
        return attr.isMissing() || (organization && organization.showProficiency && attr.isLowProficiency()) || attr.isNeedsFeedback();
    }

    onHighlightRowComplete() {
        Utils.removeLocationHash();
    }

    render() {
        const { organization, user, employee, theme, profile, readOnly, location, onShowAttributeDetails, treeMode, onChangeViewMode } = this.props;
        const { filter, search } = this.state;

        const highlightAttr = location.hash ? location.hash.substring(1) : null;

        let filterItems = [];
        if (filter && filter.label !== AbstractAttributeList.allFilter.label) filterItems.push({ id: 'filter', columnField: 'reasonLabel', operatorValue: 'contains', value: filter.label });
        if (search && search !== '') filterItems.push({ id: 'search', columnField: 'attribute', operatorValue: 'contains', value: search });

        const rows = profile && profile.gapAttributes ? treeMode ? profile.gapAttributes.treeAttributes : profile.gapAttributes.flatAttributes : null;

        return (
            <Box>
                {employee && employee.hasJob && <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12} sx={{ marginBottom: '6px' }}>
                            <AttributeTableSearchBar
                                user={employee}
                                addButton={false}
                                theme={theme}
                                filters={this.filters}
                                treeMode={treeMode}
                                onChangeViewMode={onChangeViewMode}
                                showSearchFromBreakpoint='md'
                                onFilterChange={this.onFilterChange.bind(this)}
                                onSearch={this.onSearch.bind(this)}
                                disabled={!rows || rows.length === 0}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <AttributeTable
                                rows={rows}
                                tree={treeMode}
                                theme={theme}
                                minHeight='300px'
                                dense={true}
                                columns={[
                                    { type: AttributeTable.columns.summary },
                                    { type: AttributeTable.columns.attribute, label: 'Job Competency' },
                                    { type: AttributeTable.columns.category, visibleFromBreakpoint: 'xl' },
                                    { type: AttributeTable.columns.attributeType },
                                    { type: AttributeTable.columns.requiredProficiency },
                                    { type: AttributeTable.columns.currentProficiency, label: readOnly ? 'Proficiency' : 'My Proficiency' },
                                    { type: AttributeTable.columns.reason },
                                    { type: AttributeTable.columns.actions }
                                ]}
                                filterItems={filterItems}
                                filterOperator='and'
                                highlightRow={highlightAttr}
                                onHighlightRowComplete={this.onHighlightRowComplete.bind(this)}
                                onAttributeAction={this.onAttributeAction.bind(this)}
                                onAttributeSelect={onShowAttributeDetails}
                                rowHasSelect={attr => attr.hasValidationRequest()}
                                rowNoActionIcon={<PendingIcon className='klayo-attrtable_pendingicon' />}
                                readOnly={readOnly}
                                rowHasAction={this.rowHasAction.bind(this)}
                                showProficiency={organization && organization.showProficiency}
                                noRowsMessage={{ primary: 'No gaps, looking good!', secondary: 'Any competency gaps that need to be addressed will show up here' }}
                                noResultsMessage={{ primary: 'No search results', secondary: 'We couldn’t find any results for your search' }}
                                showHeaderFromBreakpoint='md'
                            />
                        </Grid>
                    </Grid>
                </>}

                {employee && !employee.hasJob && <Placeholder
                    text={{ primary: 'Nothing to show yet', secondary: <div>This section requires having a job assigned to { user && user.isMe(employee) ? 'you' : 'the employee' }.{user && user.isOrganizationAdmin && <div>Go to Settings &gt; Employees and add a job to your employee details.</div>}</div> }}
                    icon={<UserIcon />}
                    sx={{ height: '100vh', maxHeight: '40vh' }} />}

                <AttributeActionMenu
                    ref={this.setMenuRef}
                    onClose={this.closeActionMenus.bind(this)}
                    onRender={this.renderActionMenu.bind(this)}
                />
            </Box>
        );
    }
}