export class SettingsIntegration {
  constructor(data) {

      if (data) {
          this.id = data.id;
          this.name = data.name;
          this.abbreviation = data.abbreviation;
          this.organizationId = data.organizationId;
          this.integrationSource = data.integrationSource;
          this.createdDate= data.createdDate;
          this.lastUpdatedDate = data.lastUpdatedDate;
          this.url = data.url;
      }
  }
}