import { AttributeDefinition } from "./AttributeDefinition";
import { AbstractAttributeList } from "./AbstractAttributeList";

export class AttributeDefinitionSingleList extends AbstractAttributeList {

    static instance = null;

    constructor(options) {
        super({
            api: '/AttributeDefinition?includeParentAttributes=false', callback: data => {
                options.resolve(this, false);
            }, ...options
        });
    }

    onDataReceived(data) {
        data.attributeDefinitions.forEach((attr, index) => {
            if(!this.findMatch(attr.name)) this.attributes.push(new AttributeDefinition(index, attr));
        });
    }

    static get(context) {
        return new Promise((resolve, reject) => {
            if (AttributeDefinitionSingleList.instance) resolve(AttributeDefinitionSingleList.instance, true);
            else AttributeDefinitionSingleList.instance = new AttributeDefinitionSingleList({ resolve, reject, context });
        });
    }

    findMatch(attr) {
        return this.attributes.find(a => a.attribute === attr);
    }
}