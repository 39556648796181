import { Fade, LinearProgress } from '@mui/material';
import { Component } from 'react';

export class LoadingBar extends Component {

    constructor(props) {
        super(props);

        this.state = { 
            loading: this.props.loading ? this.props.loading : false,
            loadingReason: null
         }
    }

    render() {
        const { loading } = this.state;
        const { sx } = this.props;

        return (
            <Fade
                in={loading === true}
                sx={sx}
            ><LinearProgress className='klayo-loadingbar'/></Fade>
        );
    }

    setLoading(loading) {
        this.setState({ loading });
    }
}