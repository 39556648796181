import { Data } from "../Data";
import { SettingsGroup } from "./SettingsGroup";

export class SettingsGroupList extends Data {

    static instance = null;

    constructor(options) {
        super({
            api: '/Group/List', callback: data => {
                options.resolve(this, false);
            }, ...options
        });
    }
    
    onDataReceived(data) {
        this.groups = [];
        data.groups.forEach((g, index) => this.groups.push(new SettingsGroup(g)));
    }

    add(group) {
        this.groups = [...this.groups];
        this.groups.push(group);
    }

    update(group) {
        const groups = [...this.groups];
        const index = groups.indexOf(this.get(group.groupId));
        if (index !== -1) groups.splice(index, 1, group);
        this.groups = [...groups];
    }

    get(id) {
        return this.groups.find(l => l.id === id || l.groupsId === id);
    }

    delete(group) {
        const groups = [...this.groups];
        const index = groups.indexOf(group);
        if (index !== -1) groups.splice(index, 1);
        this.groups = [...groups];
    }

    static get(context, updateCache) {
        return new Promise((resolve, reject) => {
            if (SettingsGroupList.instance && updateCache !== true) resolve(SettingsGroupList.instance, true);
            else SettingsGroupList.instance = new SettingsGroupList({ resolve, reject, context });
        });
    }
}