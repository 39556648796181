import { Utils } from "klayowebshared";
import { SettingsEmployee } from "./SettingsEmployee";
import { SettingsGroupMemberList } from "./SettingsGroupMemberList";
import { SettingsGroupMemberCount } from "./SettingsGroupMemberCount";

export class SettingsGroup {

    static memberSelectionType = {
        manual: {
            index: 0,
            name: 'Manual',
            value: 'manual'
        },
        ruleBased: {
            index: 1,
            name: 'Rule based',
            value: 'rule-based'
        }
    };

    constructor(data) {

        this.members = new Array();
        this.managers = new Array();
        this.jobDefinitionIds = new Array();
        this.locationIds = new Array();
        this.ruleBasedMembersCount = {};
        this.ruleBasedMembers = {};

        if (data) {
            this.id = data.groupId;
            this.groupId = data.groupId;
            this.name = data.name;
            this.description = data.description;
            this.numberOfEmployees = data.numberOfEmployees;
            this.numberOfManagers = data.numberOfManagers;

            this.memberSelectionType = data.memberSelectionType ?  data.memberSelectionType : data.isManualMemberSelection ? SettingsGroup.memberSelectionType.manual : SettingsGroup.memberSelectionType.ruleBased;
            if (data.groupEntities) {
                if (this.memberSelectionType === SettingsGroup.memberSelectionType.manual/*data.groupEntities.some(e => e.objectType === 'Employee')*/) {
                    //this.memberSelectionType = SettingsGroup.memberSelectionType.manual;
                    data.groupEntities.forEach(e => this.members.push(new SettingsEmployee({ employeeId: e.objectId, fullName: e.objectName, jobTitle: e.objectExtraInfo1 })));
                }
                else {
                    //this.memberSelectionType = SettingsGroup.memberSelectionType.ruleBased;
                    this.jobDefinitionIds = data.groupEntities.filter(e => e.objectType === 'JobDefinition').map(e => e.objectId);
                    this.locationIds = data.groupEntities.filter(e => e.objectType === 'Location').map(e => e.objectId);

                    this.includeAllJobDefinitions = !data.groupEntities.some(e => e.objectType === 'JobDefinition');
                    this.includeAllLocations = !data.groupEntities.some(e => e.objectType === 'Location');
                }
            }

            if (data.managers) data.managers.forEach(m => {
                this.managers.push(new SettingsEmployee({ ...m.employee, ...m }));
            });
        }
        else {
            this.memberSelectionType = SettingsGroup.memberSelectionType.manual;
        }

        this.activeEmployeeId = null;
    }

    addMember(employee) {
        if (this.members.some(m => m.employeeId === employee.employeeId)) return;
        this.members = [...this.members];
        this.members.push(employee);
        this.numberOfEmployees = this.members.length;
    }

    removeMember(employee) {
        const members = [...this.members];
        const index = members.indexOf(employee);
        if (index !== -1) members.splice(index, 1);
        this.members = [...members];
        this.numberOfEmployees = this.members.length;
    }

    addManager(employee) {
        if (this.managers.some(m => m.employeeId === employee.employeeId)) return;
        this.managers = [...this.managers];
        this.managers.push(employee);
        this.numberOfManagers = this.managers.length;
    }

    removeManager(employee) {
        const managers = [...this.managers];
        const index = managers.indexOf(employee);
        if (index !== -1) managers.splice(index, 1);
        this.managers = [...managers];
        this.numberOfManagers = this.managers.length;
    }

    isManualSelection() {
        return this.memberSelectionType === SettingsGroup.memberSelectionType.manual;
    }

    isRuleBasedSelection() {
        return this.memberSelectionType === SettingsGroup.memberSelectionType.ruleBased;
    }

    compareMemberSelection(other) {
        if (this.memberSelectionType !== other.memberSelectionType) return false;
        if (this.isManualSelection()) return this.compareMembers(other);
        else {
            if (!(this.includeAllJobDefinitions === other.includeAllJobDefinitions)) return false;
            if (this.includeAllLocations !== other.includeAllLocations) return false;
            if (!Utils.compareList(this.jobDefinitionIds, other.jobDefinitionIds, (j1, j2) => j1 === j2)) return false;
            if (!Utils.compareList(this.locationIds, other.locationIds, (l1, l2) => l1 === l2)) return false;
            return true;
        }
    }

    compareMembers(other) {
        return Utils.compareList(this.members, other.members, (m1, m2) => m1.employeeId === m2.employeeId);
    }

    compareManagers(other) {
        return Utils.compareList(this.managers, other.managers, (m1, m2) => m1.employeeId === m2.employeeId && m1.groupLevelType === m2.groupLevelType && m1.groupNotificationType === m2.groupNotificationType && m1.isInstructor === m2.isInstructor);
    }

    loadRuleBasedMembers(context, updateCache) {
        return new Promise((resolve, reject) => {
            if (this.ruleBasedMembers && updateCache !== true) resolve(this.ruleBasedMembers);
            else {
                this.ruleBasedMembers = new SettingsGroupMemberList({ params: this.getRuleBasedUrlParams(), resolve, reject, context });
                this.numberOfEmployees = this.ruleBasedMembers.employees.length;
            }
        });
    }

    loadRuleBasedMembersCount(context, updateCache) {
        return new Promise((resolve, reject) => {
            if (this.ruleBasedMembersCount && updateCache !== true) resolve(this.ruleBasedMembersCount);
            else {
                this.ruleBasedMembersCount = new SettingsGroupMemberCount({ params: this.getRuleBasedUrlParams(), resolve, reject, context });
                this.numberOfEmployees = this.ruleBasedMembersCount.totalEmployees;
            }
        });
    }

    getRuleBasedUrlParams() {
        const params = new URLSearchParams();

        this.jobDefinitionIds.forEach(id => params.append("JobDefinitionIds", id));
        this.locationIds.forEach(id => params.append("LocationIds", id));
        this.managers.forEach(m => params.append("ManagerIds", m.employeeId));

        return params.toString();
    }

    toApiDto() {
        return {
            groupId: this.groupId,
            activeEmployeeId: this.activeEmployeeId,
            name: this.name,
            description: this.description,
            employeeIds: this.isManualSelection() ? this.members.map(e => e.employeeId) : null,
            jobDefinitionIds: this.isRuleBasedSelection() && this.jobDefinitionIds.length > 0 ? this.jobDefinitionIds : null,
            locationIds: this.isRuleBasedSelection() && this.locationIds.length > 0 ? this.locationIds : null,
            includeAllJobDefinitions: this.includeAllJobDefinitions === true,
            includeAllLocations: this.includeAllLocations === true,
            managers: this.managers.map(m => {
                return { employeeId: m.employeeId, levelTypeId: m.groupLevelType, notificationTypeId: m.groupNotificationType, isInstructor: m.isInstructor };
            })
        }
    }
}