import React, { Component } from 'react';
import { AppBar, Drawer, Toolbar, Box, MenuItem, ListItemIcon, ListItemText, Divider, Stack, Collapse, List, IconButton } from '@mui/material';
import { AppContext } from '../common/AppContext';
import { AppMenu } from './AppMenu';
import { Button } from './Button';
import { ReactComponent as CloseIcon } from '../resources/images/icons-close.svg';
import { ReactComponent as ChevronDownIcon } from '../resources/images/icons-chevron-down.svg';
import { ReactComponent as Logo } from '../resources/images/logo.svg';
import { ReactComponent as BellIcon } from '../resources/images/icons-bell.svg';
import { AppNav } from './AppNav';
import { ProfilePicture } from 'klayowebshared';
import { EventBus } from '../common/EventBus';
import { ListItem } from '@mui/material';

export class AppDrawer extends Component {
    static contextType = AppContext;

    static defaultProps = {
        anchor: 'left',
        transitionDuration: 200,
        drawerWidth: '276px'
    }

    constructor(props) {
        super(props);

        this.state = {
            userButton: null,
            profilePicUpdated: false,
            unreadNotifications: props.unreadNotifications,
            itemState: {
                collapsed: [],
                childItems: []
            }
        };

        this.navRef = React.createRef();
    }

    componentDidMount() {
        this.context.eventBus.on(EventBus.event.profilePictureChanged, e => {
            this.setState({ profilePicUpdated: true });
        });
    }

    onClose(e) {
        const { theme } = this.props;
        const isDesktop = theme.isBreakpointUp('lg');

        if (!isDesktop) this.props.onClose(e);
    }

    onOpenDevDialog(e) {
        this.onUserMenuClose(e);
        this.onClose(e);

        const { onOpenDevDialog } = this.props;
        if (onOpenDevDialog) this.onNavigation(e, onOpenDevDialog);
    }

    onNavigation(e, callback) {
        const { onNavigation } = this.props;
        return onNavigation && onNavigation(e, callback);
    }

    onSignOut(e) {
        this.onUserMenuClose(e);
        this.onClose(e);

        const { onSignOut } = this.props;
        if (onSignOut) this.onNavigation(e, onSignOut);
    }

    onLogoClick(e) {
        const { onLogoClick } = this.props;
        if (onLogoClick) this.onNavigation(e, onLogoClick);
    }

    onShowSwitchUserDialog(e) {
        this.onUserMenuClose(e);
        this.onClose(e);

        const { onShowSwitchUserDialog } = this.props;
        if (onShowSwitchUserDialog) this.onNavigation(e, onShowSwitchUserDialog);
    }

    onOpenSettings(e) {
        this.onClose(e);
        this.props.onOpenSettings(e);
    }

    onDemoSwitch(e, demo) {
        this.onUserMenuClose(e);

        const { onDemoSwitch } = this.props;
        if (onDemoSwitch) this.onNavigation(e, e => onDemoSwitch(e, demo));
    }

    onUserButton(e) {
        this.setState({ userButton: e.currentTarget });
    }

    onUserMenuClose(e) {
        this.setState({ userButton: null });
    }

    onNotificationReceived(notif, numUnread) {
        this.setUnreadNotifications(numUnread);
    }

    setUnreadNotifications(numUnread) {
        this.setState({ unreadNotifications: numUnread });
    }

    updateNav() {
        if(this.navRef && this.navRef.current) this.navRef.current.updateNav();
    }

    render() {
        const { user, organization, theme, location, transitionDuration, navItems, defaultNavItem, open, anchor, getChildNavItems, onShowNotifications, onSwitchUser, onDevOptions, onSettings, onSignOut, drawerWidth, onNavigation, onDemoSwitch, allowDemoSwitch, allowSwitchUser, allowSettings, devMode } = this.props;
        const { userButton, profilePicUpdated, unreadNotifications, itemState } = this.state;

        const rootPath = location.pathname.split('/')[1];
        const currentPage = rootPath === '' ? defaultNavItem : navItems.find(page => page.slug === rootPath);
        const isDesktop = theme.isBreakpointUp('lg');

        return <Drawer
            className='klayo-appdrawer klayo-drawer'
            open={open}
            anchor={anchor}
            hideBackdrop={true}
            variant={isDesktop ? 'permanent' : 'temporary'}
            transitionDuration={isDesktop ? 0 : { enter: transitionDuration, exit: transitionDuration }}
            onClose={this.onClose.bind(this)}
            sx={{
                '& .MuiDrawer-paper': {
                    width: { lg: drawerWidth },
                    minWidth: { lg: drawerWidth },
                    height: { lg: 'auto' },
                    bottom: { lg: '0' }
                }
            }}>
            <Box sx={{
                width: { xs: '100vw', lg: drawerWidth },
                height: { lg: '100%' }
            }} >
                <Stack
                    justifyContent='space-between'
                    sx={{ height: '100%' }}>
                    <Toolbar>
                        <Box sx={{ flexGrow: 1 }}>
                            <Box
                                sx={{ display: { xs: 'none', lg: 'block' } }}
                                onClick={this.onLogoClick.bind(this)}>
                                <Logo
                                    className="klayo-appdrawer_logo"
                                    width='auto'
                                    height='100%'
                                    alt="Klayo Logo" />
                            </Box>
                        </Box>
                        <Box>
                            {user &&
                                <Button
                                    className='klayo-appbar_notificationbutton klayo-appbar_button'
                                    size='sm'
                                    aria-label="Show 2 new notifications"
                                    icon={<BellIcon />}
                                    badgeProps={{
                                        badgeContent: unreadNotifications,
                                        invisible: unreadNotifications === null,
                                        variant: "dot",
                                        color: 'error',
                                        max: 99
                                    }}
                                    color="inherit"
                                    tooltip={'Unread notifications: ' + (unreadNotifications ? unreadNotifications : 0)}
                                    onClick={onShowNotifications}
                                    sx={{ display: { xs: 'none', lg: 'flex' } }}
                                />}
                            <Button
                                className='klayo-appdrawer_closebutton'
                                autoFocus
                                variant="simple"
                                size='sm'
                                icon={<CloseIcon />}
                                onClick={this.onClose.bind(this)}
                                sx={{ display: { xs: 'block', lg: 'none' } }} />
                        </Box>
                    </Toolbar>

                    <div className='klayo-appdrawer-menuwrapper' style={{ flexGow: 1 }}>
                        <AppNav
                            ref={this.navRef}
                            location={location}
                            user={user}
                            organization={organization}
                            defaultItem={defaultNavItem}
                            items={navItems}
                            currentPage={currentPage}
                            open={true}
                            drawerMode={true}
                            devMode={devMode}
                            showIcons={false/*!isDesktop*/}
                            allowSwitchUser={allowSwitchUser}
                            allowSettings={allowSettings}
                            getChildNavItems={getChildNavItems}
                            onSwitchUser={this.onShowSwitchUserDialog.bind(this)}
                            onDevOptions={this.onOpenDevDialog.bind(this)}
                            onSettings={this.onOpenSettings.bind(this)}
                            onSignOut={this.onSignOut.bind(this)}
                            allowDemoSwitch={allowDemoSwitch}
                            onDemoSwitch={onDemoSwitch}
                            onNavigation={onNavigation}
                            itemState={itemState}
                            onDrawerClose={this.onClose.bind(this)}
                        />

                        {!isDesktop && <Divider />}

                        <AppMenu
                            organization={organization}
                            open={!isDesktop || userButton !== null}
                            drawerMode={!isDesktop}
                            anchorEl={isDesktop ? userButton : null}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 12,
                            }}
                            devMode={devMode}
                            showIcons={isDesktop}
                            allowSwitchUser={allowSwitchUser}
                            allowSettings={false}
                            onClose={this.onUserMenuClose.bind(this)}
                            onSwitchUser={this.onShowSwitchUserDialog.bind(this)}
                            onDevOptions={this.onOpenDevDialog.bind(this)}
                            onSettings={this.onOpenSettings.bind(this)}
                            onSignOut={this.onSignOut.bind(this)}
                            allowDemoSwitch={allowDemoSwitch}
                            onDemoSwitch={this.onDemoSwitch.bind(this)}
                            onNavigation={onNavigation}
                        />
                    </div>

                    {isDesktop && <div>
                        <ListItem
                            className='klaro-appdrawer_usermenuitem'
                            onClick={this.onUserButton.bind(this)}>
                            <ListItemIcon><ProfilePicture size='xs' user={user} updateCache={profilePicUpdated} /></ListItemIcon>
                            <ListItemText primary={user ? user.fullName : ''} secondary={organization ? organization.isDemo ? 'Demo Company' : organization.name : ''} />
                            <ChevronDownIcon />
                        </ListItem>
                    </div>
                    }

                </Stack>
            </Box>
        </Drawer>
    }
}