import { User } from "../users/User";
import { AbstractAttribute } from "./AbstractAttribute";
export default class AttributeSummary extends AbstractAttribute {
    
    constructor(index, data) {
        super(index, data)

        this.id = index;
        this.rowId = index;
        this.attributeName = data.attributeName;
        this.averageProficiency = data.averageProficiency;
        this.totalNumberOfEmployees = data.totalNumberOfEmployees;

        this.employees = [];
        data.employees.forEach((employee, index) => this.employees.push(new User({ data: { id: index, ...employee } })));
    }
}