import { React, Component } from 'react';
import { LinearProgress, Box, Stack } from '@mui/material';
import { Utils } from '../common/Utils';
import { Theme } from 'klayowebshared';
import { Tooltip } from './Tooltip';
import { AttributeProgressBarLabel } from './AttributeProgressBarLabel';
import { Skeleton } from '@mui/material';

export class AttributeProgressBar extends Component {

    static defaultProps = {
        showLabel: true,
        barHeight: 18,
        gapBarHeight: 18,
        singleRow: false,
        color: Theme.getStyleVar('--neon-blue-nb-300'),
        showFraction: true,
        showPercent: true
    }

    static calculatePercent(met, total) {
        return total !== 0 ? (met / total * 100) : null;
    }

    render() {
        const { sx, met, total, showLabel, color, label, barHeight, gapBarHeight, onClick, singleRow, showFraction, showPercent, loading } = this.props;
        const percent = AttributeProgressBar.calculatePercent(met, total);

        const styles = {
            height: barHeight,
            borderRadius: barHeight / 2,
            backgroundColor: Theme.getStyleVar('--grey-gr-100')
        };

        return (
            <Box className={'klaro_attr-progress' + (singleRow ? ' klaro_attr-progress_singlerow' : '')} sx={{ width: '100%', ...sx }}>
                <Stack
                    direction={singleRow ? 'row' : 'column'}
                    justifyContent='space-between'
                    alignItems='center'>
                    {showLabel && <AttributeProgressBarLabel {...this.props} />}
                    {loading ?
                        <Skeleton width='100%' height={barHeight} variant='rounded'/> :
                        <Tooltip title={label + ': ' + (percent !== null && total !== 0 ? met + '/' + total + ' (' + Utils.formatPercent(percent) + ')' : 'n/a')}>
                            <LinearProgress
                                sx={{
                                    ...styles, width: '100%', '& .MuiLinearProgress-bar': { backgroundColor: color, borderRadius: styles.borderRadius },
                                    cursor: onClick ? 'pointer' : 'default'
                                }}
                                variant="determinate"
                                value={loading ? -10 : percent}
                                onClick={onClick} />
                        </Tooltip>}
                </Stack>
            </Box>
        );
    }
}
