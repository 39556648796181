import { Table } from "./Table";

export class TrainingAttributesTable extends Table {

    static columns = {
        attributes: {
          field: 'attributes',
          label: 'Competencies',
        },
    };

    static defaultProps = {
        ...Table.defaultProps,
        columns: [TrainingAttributesTable.columns.attributes],
        noRowsMessage: 'No rows, yet',
    }

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
        };
    }

    onRowClick(params, e) {
        const { onAttributeSelect } = this.props;
        if (onAttributeSelect) onAttributeSelect(e, params.row);
    }

    initColumns() {
        const { columns, sortable } = this.props;

        this.columns = [];

        columns.forEach(c => {
            if (this.hasColumn(c)) {

              if (c.type === TrainingAttributesTable.columns.attributes) this.columns.push({
                field: 'attributes',
                headerName: c.label || c.type.label,
                sortable: sortable,
                filterable: false,
                disableReorder: true,
                flex: 1,
                valueGetter: params => params.row.name,
                cellRenderer: params => (
                    <div>{params.row.name}</div>
                )
              });
            }
        });
    }
};
