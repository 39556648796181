import React from 'react';
import ReactDOM from 'react-dom';
import './resources/css/app.css';
import './resources/css/colors.css';
import 'inter-ui/inter.css';
import './resources/css/fonts.css';
import App from './App';
import reportWebVitals from './common/ReportWebVitals';
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { MsalConsumer, MsalProvider, MsalAuthenticationTemplate, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { msalConfig } from './common/AuthConfig';
import { BrowserRouter } from 'react-router-dom';
import { AuthLoadingView } from './views/other/AuthLoadingView';
import { NotLoggedInView } from './views/other/NotLoggedInView';

import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import { Utils } from './common/Utils';


/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);

/*msalInstance.addEventCallback(message => {
    if (message.eventType === EventType.LOGIN_SUCCESS) {
        //Utils.deleteCookie('Impersonate');
        //var domain = new URL(window.app.env.API_URL).hostname;
        //document.cookie = `DemoMode=; expires = Thu, 01 Jan 1970 00:00:00 GMT; domain=${domain};path=/;`; //samesite=none;`;
    }
});*/

ReactDOM.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <MsalConsumer>
                {(msalContext) => (
                    <MsalAuthenticationTemplate
                        interactionType={InteractionType.Redirect}
                        errorComponent={props => (<NotLoggedInView {...props} msal={msalContext} />)}
                        loadingComponent={AuthLoadingView}>
                        <AuthenticatedTemplate>
                            <BrowserRouter>
                                <App msal={msalContext} />
                            </BrowserRouter>
                        </AuthenticatedTemplate>
                        <UnauthenticatedTemplate>
                            <NotLoggedInView msal={msalContext} />
                        </UnauthenticatedTemplate>
                    </MsalAuthenticationTemplate>)}
            </MsalConsumer>
        </MsalProvider>
    </React.StrictMode>,
    document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
