export const PAGE_SIZE_FOR_ADD_EDIT = 15;

export const TableSearchDebounceTime = 500;

export const PARTICIPANT_MARKING = {
	None: {
		value: 0,
		label: 'Not assessed',
	},
	Pass: {
		value: 1,
		label: 'Satisfactory',
	},
	Failed: {
		value: 2,
		label: 'Not yet satisfactory',
	}
}

export const IN_PROGRESS = 'In progress';
export const PENDING_CONFIRMATION = 'Pending confirmation';
export const COMPLETED = 'Completed';

export const PRACTICAL_STATUS = {
	0: {
		value: 0,
		label: 'In progress',
	},
	1: {
		value: 1,
		label: 'Pending confirmation',
	},
	2: {
		value: 2,
		label: 'Completed',
	},
}

export const PRACTICAL_TYPE = {
	NONE: 0,
	INSTRUCTOR: 1,
	PARTICIPANT: 2,
	BOTH: 3,
}

export const INTEGRATION_STATUS = {
	ACTIVE: 1,
	PAUSE: 2,
	ISSUE: 3,
};

export const TRAINING_FILTER_DEFAULT_VALUE = {
	ALL: {
		value: 'all',
		label: 'Show All'
	},
}

export const EMPLOYEE_FILTER_STATUS = {
	ALL: {
		value: 'all',
		label: 'Show All'
	},
	ADDED: {
		value: 0,
		label: 'Added',
	},
	INVITED: {
		value: 1,
		label: 'Invited',
	},
	ACTIVE: {
		value: 2,
		label: 'Active',
	},
	INACTIVE: {
		value: 3,
		label: 'Inactive',
	},
}

export const COURSE_RECURRENCE = {
	NONE: {
		index: 0,
		value: 0,
		label: 'None',
		filterLabel: 'None'
	},
	THREE_MONTHS: {
		index: 1,
		value: 1,
		label: '3 months',
		filterLabel: '3 months'
	},
	SIX_MONTHS: {
		index: 2,
		value: 2,
		label: '6 months',
		filterLabel: '6 months'
	},
	TWELVE_MONTHS: {
		index: 3,
		value: 3,
		label: '12 months',
		filterLabel: '12 months'
	},
	TWENTY_FOUR_MONTHS: {
		index: 4,
		value: 4,
		label: '24 months',
		filterLabel: '24 months'
	},
	THIRTY_SIX_MONTHS: {
		index: 5,
		value: 5,
		label: '36 months',
		filterLabel: '36 months'
	},
};

export const TRAINING_ATTRIBUTE_DIALOG_TYPE = {
	DETAIL: 1,
	WARNING: 2,
};
