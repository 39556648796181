import { DataGridPro } from '@mui/x-data-grid-pro';
import { AbstractDialog } from "../../components/AbstractDialog";
import { Button } from "../../components/Button";
import { Dialog } from '../../components/dialogs/Dialog';

export class JobClassiciationsDialog extends AbstractDialog {

    componentDidMount() {
        this.setLoading('classifications', !this.props.classifications);
    }

    componentDidUpdate(prevProps) {
        if (this.props.classifications !== prevProps.classifications) this.setLoading('classifications', false);
    }

    render() {
        const { onClose, theme, attribute, classifications } = this.props;
        const { open } = this.state;

        const columns = [
            { field: 'classification', headerName: 'Classification', flex: true },
            { field: 'employees', headerName: 'Employees', width: 100, type: 'number' }
        ];

        return (
            <Dialog
                theme={theme}
                open={open}
                onClose={onClose}
                fullWidth={true}
                maxWidth='xs'
                title='Job classifications'
                actions={[{
                    label: 'Close',
                    primary: true,
                    onClick: onClose
                }]}>
                {super.renderLoadingBar()}
                <DataGridPro
                    rows={classifications ? Object.entries(classifications).map(([key, c]) => c) : []}
                    columns={columns}
                    pagination={false}
                    columnHeaderHeight={0}
                    disableMultipleRowSelection={true}
                    hideFooterRowCount={true}
                    disableRowSelectionOnClick={true}
                    className='klaro_attrppl_table'
                    sx={{ border: 'none!important' }}
                />
            </Dialog>
        );
    }
}
