import { CountrySelectorList } from '../../data/filters/CountrySelectorList';
import { AppContext } from '../../common/AppContext';
import { AbstractFilterSelect } from './AbstractFilterSelect';
export class CountrySelector extends AbstractFilterSelect {

    static contextType = AppContext;

    static defaultProps = {
        ...AbstractFilterSelect.defaultProps,
        label: 'Country',
        listType: 'Countries',
        initialSearch: true,
        maxResults: 9999,
        callApi: false,
        multiple: false,
        getItemId: c => c ? c.twoLetterISO : null
    }

    constructor(props) {
        super(props);

        this.dataList = CountrySelectorList;
    }

    findValueItem(items, value) {
        return items ? items.find(c => c.name === value) : null;
    }

    getItemLabel(item) {
        const { getItemLabel } = this.props;

        if(getItemLabel) return getItemLabel(item);
        return item.name;
    }

    getList(dataList) {
        return dataList.countries;
    }
}