import { React } from 'react';
import { Box, Grid, SvgIcon, MenuItem, Divider, Stack } from '@mui/material';
import { EmployeeAttributeList } from '../../data/attributes/EmployeeAttributeList';
import { AbstractProfileSection } from './AbstractProfileSection';
import { AttributeActionMenu } from '../../components/AttributeActionMenu';
import { AttributeTable } from '../../components/table/AttributeTable';
import { ReactComponent as PendingIcon } from '../../resources/images/icons-pending.svg';
import { AttributeTableSearchBar } from '../../components/table/AttributeTableSearchBar';
import { AbstractAttributeList } from '../../data/attributes/AbstractAttributeList';
import { Utils } from '../../common/Utils';
import { Dialog } from '../../components/dialogs/Dialog';
import { GoToTrainingDialog } from './GoToTrainingDialog';
import { cloneDeep } from 'lodash';
import { trimToLowercase } from '../../utilities';

export class ProfileAttributesSection extends AbstractProfileSection {

    constructor(props) {
        super(props);

        this.state = {
            ...AbstractProfileSection.state,
            showAddDisabledNotice: false,
            showGoToTraining: false,
            goToTrainingRowSelected: null,
        };

        this.filters = EmployeeAttributeList.getFilterItems();
    }

    renderActionMenu() {
        const { user, organization, employee, treeMode, onDeleteAttribute, onFeedbackAction, onProficiencyChangeAction, profile, onAddChangeAction, isInstructorViewing, onEditAttribute } = this.props;
        const { actionTarget, actionAttribute } = this;
        // check to hide go to training if trainingCourses has 1 course that not have any url
        const trainingUrl = actionAttribute && actionAttribute?.trainingCourses?.length === 1 ?
            actionAttribute.trainingCourses[0].enrollmentUrl ?
                actionAttribute.trainingCourses[0].enrollmentUrl :
                actionAttribute.trainingCourses[0].courseUrl :
            true;

        if (!actionAttribute || !actionTarget || actionAttribute.isParent)
            return null;

        //TODO:
        const gotoTrainingMenuItem =
            <MenuItem
                onClick={e => this.onOpenGoToTraining(e, actionAttribute)}
            >
                Go to training
            </MenuItem>;
        // instructor ability
        if (isInstructorViewing && !this.isSelfView()) {
            return <div>
                <MenuItem
                    onClick={e => onEditAttribute(e, actionAttribute)}>
                    Edit competency
                </MenuItem>
                <MenuItem
                    onClick={e => onDeleteAttribute(e, actionAttribute)}>
                    Remove competency
                </MenuItem>
            </div>
        }

        if (actionAttribute.isApproved()) {
            if ((organization && organization.showProficiency) || (actionAttribute.hasExpiryDate)) return (
                <div>
                    {!actionAttribute.isParent && <MenuItem
                        onClick={e => onProficiencyChangeAction(e, actionAttribute)}
                        disabled={employee === null || employee.hasJob === false || (profile && (profile.hasApprover === false))}>
                        Request changes
                    </MenuItem>}
                    {!actionAttribute.isParent && <Divider />}
                    {actionAttribute.trainingCourses && actionAttribute.trainingCourses.length !== 0 && trainingUrl && gotoTrainingMenuItem}
                    <MenuItem
                        onClick={e => onDeleteAttribute(e, actionAttribute)}>
                        Delete
                    </MenuItem>
                </div>
            );
            else return (
                <div>
                    <MenuItem onClick={e => onDeleteAttribute(e, actionAttribute)}>Delete</MenuItem>
                </div>
            );
        }
        else if (actionAttribute.isNeedsFeedback()) return (
            <div>
                <MenuItem
                    onClick={e => onFeedbackAction(e, actionAttribute)}
                    disabled={employee === null || employee.hasJob === false || (profile && (profile.hasApprover === false))}>
                    Provide feedback
                </MenuItem>
                {actionAttribute.trainingCourses && actionAttribute.trainingCourses.length !== 0 && trainingUrl && gotoTrainingMenuItem}
                <Divider />
                <MenuItem onClick={e => onDeleteAttribute(e, actionAttribute)}>
                    Delete
                </MenuItem>
            </div>
        )
        else if (actionAttribute.isDeleted) return (
            <div>
                <MenuItem
                    onClick={e => onAddChangeAction(e, actionAttribute)}
                    disabled={employee === null || employee.hasJob === false || (profile && (profile.hasApprover === false))}>
                    Add competency
                </MenuItem>
            </div>
        );
    }
    isSelfView() {
        const { user, employee } = this.props;
        return user.employeeId === employee.employeeId;
    }

    rowHasAction(attr) {
        // instructor ability
        const { isInstructorViewing, user, employee } = this.props;
        return (!attr.isUnderReview() && this.isSelfView()) || (isInstructorViewing && !this.isSelfView());
    }

    onHighlightRowComplete() {
        Utils.removeLocationHash();

        this.props.location.hash = null;
        this.forceUpdate();
    }

    onAddAttributeClick(e) {
        const { onAddAttributeClickCb } = this.props;
        const { employee, profile, history } = this.props;
        if (employee === null || employee.hasJob === false || profile === null || profile.hasApprover === false) this.setState({ showAddDisabledNotice: true });
        else {
            history.push('#add');
            if (onAddAttributeClickCb) onAddAttributeClickCb();
        }
    }

    onCloseAddDisabledNotice(e) {
        this.setState({ showAddDisabledNotice: false });
    }

    onCloseGoToTraining(e) {
        this.setState({ showGoToTraining: false });
    }

    onOpenGoToTraining(e, row) {
        let hasOneIntegration = false;
        let integrationCourseUrl = null;
        let integrationEnrollmentUrl = null;

        if (row && row.trainingCourses && row.trainingCourses.length === 1) {
            integrationCourseUrl = row.trainingCourses[0].courseUrl;
            integrationEnrollmentUrl = row.trainingCourses[0].enrollmentUrl;
            hasOneIntegration = true;
        }

        if (hasOneIntegration) {
            window.open(integrationEnrollmentUrl ? integrationEnrollmentUrl : integrationCourseUrl, "_blank");

            return;
        }

        this.setState({ showGoToTraining: true, goToTrainingRowSelected: row });
    }

    checkStringContains(string, keyword) {
        const str = trimToLowercase(keyword || '');
        return String(string || '').toLowerCase().includes(str);
    }

    render() {
        const { organization, user, employee, theme, profile, readOnly, location, onShowAttributeDetails, treeMode, onChangeViewMode, isInstructorViewing, isTeamView } = this.props;
        const { filter, search, showAddDisabledNotice, showGoToTraining, goToTrainingRowSelected } = this.state;

        const highlightAttr = location.hash ? location.hash.substring(1) : null;

        let filterItems = [];
        if (filter && filter.label !== AbstractAttributeList.allFilter.label) filterItems.push({ id: 'filter', columnField: 'statusLabel', operatorValue: 'contains', value: filter.label });
        if (search !== '') filterItems.push({ id: 'search', columnField: 'attribute', operatorValue: 'contains', value: search });

        const rows = profile && profile.employeeAttributes ? treeMode ? profile.employeeAttributes.treeAttributes : profile.employeeAttributes.flatAttributes : null;
        const isSelfView = user?.employeeId === employee?.employeeId;
        const showAddAction = (!readOnly && !isInstructorViewing) || (isInstructorViewing && !isSelfView)

        const filterLabel = !filter?.label || filter?.label === AbstractAttributeList.allFilter.label ? '' : filter?.label;
        let filterRows = cloneDeep(rows);
        if (filterLabel) {
            filterRows = filterRows?.filter((row) => {
                if (treeMode && row?.isParent) {
                    const childrenMatch = row?.childAttributes
                        ?.some(child => child?.statusLabel === filter?.label)
                    return childrenMatch
                } else {
                    return row?.statusLabel === filter?.label
                }
            })
        }
        if (search) {
            filterRows = filterRows?.filter((row) => {
                if (treeMode && row?.isParent) {
                    const childrenMatch = row?.childAttributes
                        ?.some(child => this.checkStringContains(child?.attributeName, search))
                    return childrenMatch || this.checkStringContains(row?.attributeName, search)
                } else {
                    return this.checkStringContains(row?.attributeName, search)
                }
            })
        }
        return (
            <Box>
                <Grid container spacing={2}>

                    <Grid item xs={12} md={12} lg={12} sx={{ marginBottom: '6px' }}>
                        <AttributeTableSearchBar
                            user={employee}
                            theme={theme}
                            filters={this.filters}
                            addActionDisabled={false}
                            showAddAction={showAddAction}
                            treeMode={treeMode}
                            onChangeViewMode={onChangeViewMode}
                            showSearchFromBreakpoint='md'
                            onFilterChange={this.onFilterChange.bind(this)}
                            onSearch={this.onSearch.bind(this)}
                            onAddAttributeClick={this.onAddAttributeClick.bind(this)}
                            disabled={!rows || rows.length === 0}
                        /></Grid>

                    <Grid item xs={12} md={12} lg={12}>
                        <AttributeTable
                            // rows={rows}
                            rows={filterRows}
                            tree={treeMode}
                            minHeight='300px'
                            theme={theme}
                            dense={true}
                            columns={treeMode ? [
                                { type: AttributeTable.columns.summary },
                                { type: AttributeTable.columns.attribute, label: 'Competency' },
                                //{ type: AttributeTable.columns.attributeType },
                                { type: AttributeTable.columns.category, visibleFromBreakpoint: 'xl' },
                                { type: AttributeTable.columns.currentProficiency, label: readOnly ? 'Proficiency' : 'My Proficiency' },
                                { type: AttributeTable.columns.status, visibleFromBreakpoint: 'md' },
                                { type: AttributeTable.columns.actions }
                            ] : [
                                { type: AttributeTable.columns.summary },
                                { type: AttributeTable.columns.lastUpdate, visibleFromBreakpoint: 'md' },
                                { type: AttributeTable.columns.attribute, label: 'Competency' },
                                //{ type: AttributeTable.columns.attributeType },
                                { type: AttributeTable.columns.category, visibleFromBreakpoint: 'xl' },
                                { type: AttributeTable.columns.currentProficiency, label: readOnly ? 'Proficiency' : 'My Proficiency', visibleFromBreakpoint: 'md' },
                                { type: AttributeTable.columns.status, visibleFromBreakpoint: 'md' },
                                { type: AttributeTable.columns.actions }
                            ]}
                            filterItems={filterItems}
                            filterOperator='and'
                            highlightRow={highlightAttr}
                            onHighlightRowComplete={this.onHighlightRowComplete.bind(this)}
                            onAttributeAction={this.onAttributeAction.bind(this)}
                            onAttributeSelect={onShowAttributeDetails}
                            onCloseGoToTraining={this.onCloseGoToTraining.bind(this)}
                            readOnly={readOnly}
                            rowHasAction={this.rowHasAction.bind(this)}
                            rowNoActionIcon={<PendingIcon className='klayo-attrtable_pendingicon' />}
                            showProficiency={organization && organization.showProficiency}
                            rowHasSelect={attr => !attr.isDeleted}
                            noRowsMessage={{ primary: 'No competencies available', secondary: !readOnly && employee && employee.hasJob && profile && profile.hasApprover ? (showAddAction ? 'Use Add competency button to create your first competency' : null) : null }}
                            noResultsMessage={{ primary: 'No search results', secondary: 'We couldn’t find any results for your search' }}
                            showHeaderFromBreakpoint='md'
                            sortModel={[{
                                field: 'attribute',
                                sort: 'asc',
                            }]}
                        />
                    </Grid>
                </Grid>
                <AttributeActionMenu
                    ref={this.setMenuRef}
                    onClose={this.closeActionMenus.bind(this)}
                    onRender={this.renderActionMenu.bind(this)}
                />

                {showAddDisabledNotice &&
                    <Dialog
                        open={true}
                        theme={theme}
                        title='Feature not available'
                        onClose={this.onCloseAddDisabledNotice.bind(this)}
                        maxWidth='sm'
                        actions={[{
                            label: 'Close',
                            primary: true,
                            onClick: this.onCloseAddDisabledNotice.bind(this)
                        }]}>You can’t add any competencies because you haven’t been assigned a manager yet. Please contact your system administrator for assistance.</Dialog>}

                {showGoToTraining &&
                    <GoToTrainingDialog
                        attribute={goToTrainingRowSelected}
                        theme={theme}
                        onClose={this.onCloseGoToTraining.bind(this)}
                        organization={organization}
                    />
                }
            </Box>
        );
    }
}