import { React, Component } from 'react';
import { Stack, Grid } from '@mui/material';
import { TextField } from '../TextField';
import { ReactComponent as SearchIcon } from '../../resources/images/icons-search.svg';
import { SelectMenu } from '../SelectMenu';
import { ListTreeToggleButton } from '../ListTreeToggleButton';
import { Button } from '../Button';
import { FailedToNegotiateWithServerError } from '@microsoft/signalr/dist/esm/Errors';

export class AttributeTableSearchBar extends Component {

    static defaultProps = {
        showViewModeToggle: true,
        showAddAction: false,
        addActionDisabled: true,
        treeMode: FailedToNegotiateWithServerError,
        searchPlaceholder: 'Search competencies',
        disabledSearch: false,
        disabledFilter: false
    }

    constructor(props) {
        super(props);

        this.state = {
            filter: Object.entries(this.props.filters)[0][1],
            search: ''
        };
    }

    onFilterChange(e) {
        this.setState({ filter: e.target.value }, () => {
            this.props.onFilterChange(e.target.value);
        });
    }

    onSearch(e) {
        this.setState({ search: e.target.value }, () => {
            this.props.onSearch(this.state.search);
        });
    }

    clearSearch(e) {
        this.setState({ search: '' }, () => {
            this.props.onSearch(this.state.search);
        });
    }

    render() {
        const { user, filters, theme, sx, showViewModeToggle, searchPlaceholder, showAddAction, addActionDisabled, onAddAttributeClick, showSearchFromBreakpoint, disabled, treeMode, onChangeViewMode, otherFilters, disabledSearch, disabledFilter } = this.props;
        const { search, filter } = this.state;

        return <Grid container spacing={2} sx={sx} justifyContent="space-between">
            <Grid item xs='auto' sm='auto' md='auto' sx={{ flexGrow: { xs: '1', sm: '1', md: '1' } }}>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    justifyContent='start'
                    spacing={2}
                    sx={{ maxWidth: { md: otherFilters ? '770px' : '500px' } }}>
                    <TextField
                        value={search}
                        dense={true}
                        placeholder={searchPlaceholder}
                        disabled={user === null || disabled || disabledSearch}
                        autoComplete={false}
                        leadingIcon={<SearchIcon />}
                        fullWidth={true}
                        onChange={this.onSearch.bind(this)}
                        sx={{
                            maxWidth: { md: '300px' },
                            width: { xs: '100%', sm: '100%' },
                            [theme.breakpoints.down(showSearchFromBreakpoint)]: {
                                display: 'none'
                            },
                            [theme.breakpoints.up(showSearchFromBreakpoint)]: {
                                display: 'block',
                                width: '100%',
                                marginRight: '12px'
                            }
                        }} />

                    <SelectMenu
                        value={filter}
                        firstValueDefault={true}
                        dense={true}
                        disabled={user === null || disabled || disabledFilter}
                        items={Object.entries(filters)}
                        onChange={this.onFilterChange.bind(this)}
                        sx={{
                            maxWidth: { md: '220px' },
                            width: { xs: '100%', sm: '100%' },
                            [theme.breakpoints.down(showSearchFromBreakpoint)]: {
                                margin: '0!important'
                            }
                        }} />

                    {otherFilters}
                </Stack>
            </Grid>

            {showAddAction &&
                <Grid item xs={12} sm={12} md={2} order={{ xs: 2, sm: 2, md: 3 }} sx={{ maxWidth: { md: '160px!important' } }}>
                    <Button
                        onClick={onAddAttributeClick}
                        size='md'
                        variant='filled'
                        disabled={addActionDisabled}
                        label='Add competency'
                        sx={{ width: '100%' }} />
                </Grid>}

            {showViewModeToggle &&
                <Grid item order={{ md: 3 }} justifyContent='end' sx={{ width: '87px' }}>
                    {showViewModeToggle && <ListTreeToggleButton
                        value={treeMode}
                        disabled={user === null || disabled}
                        onChange={onChangeViewMode} />}
                </Grid>}
        </Grid>
    }
}