import { React, Component } from 'react';
import { AbstractAttribute } from '../../data/attributes/AbstractAttribute';
import { Autocomplete } from '@mui/material';
import { TextField } from '../TextField';
import { SelectMenu } from '../SelectMenu';

export class SortSelector extends Component {

    static sort = {
        ascending: {
            label: 'Sort A - Z',
            method: 'asc'
        },
        descending: {
            label: 'Sort Z - A',
            method: 'desc'
        }
    };

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.value !== this.props.value) this.setState({ value: this.props.value });
    }


    onChange(e) {
        const { onChange } = this.props;

        this.setState({ value: e.target.value }, state => {
            if (onChange) onChange(e, e.target.value);
        });
    }

    render() {
        const { sx, disabled } = this.props;
        const { value } = this.state;

        return (
            <SelectMenu
                value={value}
                placeholder='Sort'
                firstValueDefault={true}
                clearable={false}
                items={Object.entries(SortSelector.sort)}
                disabled={disabled}
                onChange={this.onChange.bind(this)}
                sx={sx}
            />);
    }
}