import { DialogActions, DialogContent, DialogTitle, InputLabel, MenuItem, Stack, Select as MuiSelect, FormControl, Grid } from '@mui/material';
import { Component, React } from 'react';
import { ProfilePicture } from '../../components/ProfilePicture';
import { AppContext } from '../../common/AppContext';
import { SwitchUserList } from '../../data/users/SwitchUserList';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { AbstractDialog } from '../../components/AbstractDialog';
import { Button } from '../../components/Button';
import { Dialog } from '../../components/dialogs/Dialog';
import { SelectMenu } from '../../components/SelectMenu';
import { OrganizationSelector } from '../../components/selectors/OrganizationSelector';
import { EmployeeTable } from '../../components/table/EmployeeTable';
import { TextField } from '../../components/TextField';
import { ReactComponent as SearchIcon } from '../../resources/images/icons-search.svg';
import { trimToLowercase } from '../../utilities';

export class SwitchUserDialog extends AbstractDialog {

    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            users: null,
            orgs: null,
            selectedOrg: props.user && props.organization ? { index: -1, label: props.organization.isDemo ? props.user.organizationName + ' (DEMO)' : props.user.organizationName } : null,
            switching: false,
            userSearch: null,
            tableHeight: this.calcTableSize()
        };

        window.addEventListener('resize', e => {
            this.setState({ tableHeight: this.calcTableSize() });
        });
    }

    componentDidMount() {
        this.loadUsers();
    }

    calcTableSize() {
        return window.innerHeight - 400;
    }

    loadUsers() {
        this.setLoading('employees', true);

        SwitchUserList.get(this.context)
            .then(r => {
                this.allUsers = r.data.users;
                this.allUsers.map((u, index) => u.id = index);

                let orgs = this.allUsers.map(u => u.organizationName).filter((v, i, a) => a.indexOf(v) === i).sort((a, b) => {
                    if (a.endsWith('(DEMO)') && !b.endsWith('(DEMO)')) return 1;
                    if (!a.endsWith('(DEMO)') && b.endsWith('(DEMO)')) return -1;
                    return a.endsWith('(DEMO)') - b.endsWith('(DEMO)') || a.localeCompare(b);
                });
                this.setState({ orgs, users: this.filterUsers(this.state.selectedOrg.label || this.props.user.organizationName) });
            })
            .catch(error => {
                //Todo: handle error
                //alert('error 56564: ' + error);
            })
            .finally(() => this.setLoading('employees', false));
    }

    filterUsers(org) {
        return this.allUsers.filter(u => u.organizationName === org);
    }

    onOrgChange(e) {
        const org = e.target.value.label;
        this.setState({ selectedOrg: org, users: this.filterUsers(org) });
    }

    onUserSelect(e, user) {
        this.setLoading('switching', true);
        this.setState({ switching: true }, state => {
            this.props.onSwitchUser(e, user);
        });
    }

    onUserSearch(e) {
        this.setState({ userSearch: e.target.value });
    }

    render() {
        const { organization, theme, user, onClose } = this.props;
        const { switching, users, orgs, selectedOrg, userSearch, tableHeight } = this.state;

        let filterItems = [{ id: 'search', columnField: 'profilePicFullNameJobTitle', operatorValue: 'contains', value: userSearch }];

        let filteredUser = users ? users?.filter(u => {
          const keyword = trimToLowercase(userSearch);
          const userName = trimToLowercase(u?.fullName);
          return userName?.includes(keyword);
        }) : null;

        return (
            <Dialog
                open={true}
                theme={theme}
                onClose={onClose}
                fullWidth
                maxWidth='md'
                className='klaro-switchuserdialog'
                title={<Stack direction="row" spacing={2} justifyContent="space-between" sx={{ padding: 0 }}>
                    <div>Switch user</div>
                    {user && user.loggedInUser &&
                        <Button
                            label={'Switch to ' + user.loggedInUser.basicInformation.fullName}
                            tooltip={'Switch to logged in user: ' + user.loggedInUser.basicInformation.fullName + ', ' + user.loggedInUser.basicInformation.organizationName}
                            disabled={switching}
                            size='md'
                            onClick={e => this.onUserSelect(e, null)}
                            sx={{ marginRight: '12px' }} />}
                </Stack>}
                actions={[{
                    label: 'Close',
                    variant: 'filled',
                    onClick: onClose,
                    disabled: switching
                }]}>
                {this.renderLoadingBar()}

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ marginBottom: '16px'}}>
                    <TextField
                        className='klaro-switchuserdialog_search'
                        value={userSearch}
                        label='Search'
                        placeholder='Search user'
                        fullWidth={true}
                        disabled={!users || switching || user === null}
                        autoComplete={false}
                        leadingIcon={<SearchIcon />}
                        onChange={this.onUserSearch.bind(this)} />
                    {user && user.canChangeOrg &&
                        <OrganizationSelector
                            className='klaro-switchuserdialog_orgselect'
                            value={selectedOrg}
                            items={orgs}
                            fullWidth={true}
                            sx={{ width: '100%', marginBottom: '20px' }}
                            onChange={this.onOrgChange.bind(this)}
                            disabled={!users || switching || (user && !user.canChangeOrg)} />
                    }
                </Stack>

                <div className='klaro-switchuserdialog_table'>
                    <EmployeeTable
                        pagination={false}
                        showHeader={false}
                        showFooter={false}
                        theme={theme}
                        filterItems={filterItems}
                        columns={[
                            { type: EmployeeTable.columns.profilePicFullNameJobTitle },
                            { type: EmployeeTable.columns.userDetails, visibleFromBreakpoint: 'md' }
                        ]}
                        sortModel={[{ field: 'userDetails', sort: 'desc' }, { field: 'profilePicFullNameJobTitle', sort: 'asc' }]}
                        // rows={users}
                        rows={filteredUser}
                        paper={false}
                        autoHeight={false}
                        disabled={switching}
                        hideFirstLastBorder={false}
                        onEmployeeSelect={this.onUserSelect.bind(this)}
                        sx={{ height: { xs: tableHeight + 'px', md: '400px' } }}
                    />
                </div>
            </Dialog >
        )
    }
}