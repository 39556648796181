import styled from "@emotion/styled";
import { Box, ListItemText, Menu, MenuItem, Stack, Tab, Tabs } from "@mui/material";
import { ConfirmationDialog, Delayed } from "klayowebshared";
import App from '../../App';
import { AppContext } from "../../common/AppContext";
import { ErrorModel } from "../../common/models/ErrorModel";
import { Button } from "../../components/Button";
import { Paper } from "../../components/Paper";
import { Snackbar } from "../../components/Snackbar";
import { InstructorPracticalTable } from "../../components/table/InstructorPracticalTable";
import { ParticipantPracticalTable } from "../../components/table/ParticipantPracticalTable";
import { TextField } from "../../components/TextField";
import { View } from "../../components/View";
import { PAGE_SIZE_FOR_ADD_EDIT, PRACTICAL_STATUS, PRACTICAL_TYPE, TableSearchDebounceTime } from "../../constants";
import { Data } from "../../data/Data";
import { InstructorPracticalLists } from "../../data/practical/InstructorPracticalList";
import { participantPracticalList } from "../../data/practical/participantPracticalList";
import { Practical } from "../../data/practical/Practical";
import { ReactComponent as AddIcon } from '../../resources/images/icons-add.svg';
import { ReactComponent as SearchIcon } from '../../resources/images/icons-search.svg';
import { getSortByApiValue } from "../../utilities";
import { AbstractView } from '../AbstractPage';
import { AddEditPracticalView } from "./AddEditPracticalView";
import { PracticalConfirmationDialog } from "./PracticalConfirmationDialog";
const axios = require('axios').default;

export class Practicals extends AbstractView {
    static contextType = AppContext;

    constructor(props) {
        super(props, App.pages.practicals);

        this.state = {
            search: null,
            practical: null,
            instructorPracticals: null,
            participantPracticals: null,
            instructorSearch: null,
            participantSearch: null,
            instructorOrderByConvertList: [
                { tableFieldName: 'practicalDate', apiFieldName: 'practicalDate' },
                { tableFieldName: 'name', apiFieldName: 'name' },
                { tableFieldName: 'numberOfAttributes', apiFieldName: 'numberOfAttributes' },
                { tableFieldName: 'numberOfParticipants', apiFieldName: 'numberOfParticipants' },
                { tableFieldName: 'numberOfAcknowledgedParticipants', apiFieldName: 'numberOfAcknowledgedParticipants' },
                { tableFieldName: 'status', apiFieldName: 'status' },
            ],
            participantOrderByConvertList: [
                { tableFieldName: 'practicalDate', apiFieldName: 'practicalDate' },
                { tableFieldName: 'name', apiFieldName: 'name' },
                { tableFieldName: 'numberOfAttributes', apiFieldName: 'numberOfAttributes' },
                { tableFieldName: 'numberOfParticipants', apiFieldName: 'numberOfParticipants' },
                { tableFieldName: 'status', apiFieldName: 'status' },
            ],
            instructorSortModel: [{
                field: 'practicalDate',
                sort: 'desc',
            }],
            participantSortModel: [{
                field: 'practicalDate',
                sort: 'desc',
            }],
            instructorPaginationModel: {
                page: 0,
                pageSize: InstructorPracticalLists.defaultPageSize
            },
            participantPaginationModel: {
                page: 0,
                pageSize: participantPracticalList.defaultPageSize
            },
            showParticipant: false,
            mode: null,
            isDuplicateMode: false,
            showTabs: false,
            isPracticalsDialogShow: true,
            successMessage: null,
            actionTarget: null,
            actionPractical: null,
            deletingPractical: null,
            sendingNotification: null,
        }

        this.debounceTimer = null;
    }

    componentDidMount() {
        const { user } = this.props;
        this.onSetPracticalMode(user);
        this.setState({ showTabs: (user?.isInstructor && user?.isParticipant) === true });
        super.componentDidMount();
    }

    componentDidUpdate(prevProps, prevState) {
        const { user } = this.props;
        const { mode } = this.state;
        if (prevProps.user !== user) {
            this.onSetPracticalMode(user);
            this.setState({ showTabs: (user?.isInstructor && user?.isParticipant) === true });
        }
        if (prevState.mode !== mode) {
            if (mode === PRACTICAL_TYPE.INSTRUCTOR && user?.isInstructor) this.onLoadInstructorPractical();
            if (mode === PRACTICAL_TYPE.PARTICIPANT && user?.isParticipant) this.onLoadParticipantPractical();
        }
        super.componentDidUpdate(prevProps, prevState);
    }

    onSearch(e) {
        const { mode, instructorSortModel, participantSortModel, instructorOrderByConvertList, participantOrderByConvertList } = this.state;
        const keyword = e.target.value;

        if (mode === PRACTICAL_TYPE.INSTRUCTOR) {
            const { instructorPaginationModel: { pageSize } } = this.state
            const newModel = { page: 0, pageSize }
            this.setState({ instructorSearch: keyword, instructorPaginationModel: newModel });
            const [{ field, sort }] = instructorSortModel;
            const orderBy = getSortByApiValue(instructorOrderByConvertList, field, sort);
            clearTimeout(this.debounceTimer);
            this.debounceTimer = setTimeout(() => {
                this.onLoadInstructorPractical(null, pageSize, keyword.trim(), orderBy);
            }, TableSearchDebounceTime);
        }

        if (mode === PRACTICAL_TYPE.PARTICIPANT) {
            const { participantPaginationModel: { pageSize } } = this.state
            const newModel = { page: 0, pageSize }
            this.setState({ participantSearch: keyword, participantPaginationModel: newModel });
            const [{ field, sort }] = participantSortModel;
            const orderBy = getSortByApiValue(participantOrderByConvertList, field, sort);
            clearTimeout(this.debounceTimer);
            this.debounceTimer = setTimeout(() => {
                this.onLoadParticipantPractical(null, pageSize, keyword.trim(), orderBy);
            }, TableSearchDebounceTime);
        }
    }

    onInstructorPaginationModelChange(model) {
        const { page, pageSize } = model;
        this.setState({ instructorPaginationModel: model })
        const { instructorSearch, instructorSortModel, instructorOrderByConvertList } = this.state;
        // handle sort model
        const [{ field, sort }] = instructorSortModel;
        const orderBy = getSortByApiValue(instructorOrderByConvertList, field, sort);
        this.onLoadInstructorPractical(page + 1, pageSize, instructorSearch, orderBy);
    }


    onInstructorSortModelChange(sortModel) {
        const { instructorSearch, instructorPaginationModel: { pageSize }, instructorOrderByConvertList } = this.state;
        // handle sort model
        const [{ field, sort }] = sortModel;
        const orderBy = getSortByApiValue(instructorOrderByConvertList, field, sort);
        this.onLoadInstructorPractical(null, pageSize, instructorSearch, orderBy);
        const newModel = { page: 0, pageSize }
        this.setState({ instructorSortModel: sortModel, instructorPaginationModel: newModel });
    }

    onLoadInstructorPractical(pageNumber, pageSize, searchText, orderBy) {
        this.context.setLoading('practical', true);
        const {instructorSortModel, instructorOrderByConvertList} = this.state;
        const [{ field, sort }] = instructorSortModel;
        const orderByDefault = getSortByApiValue(instructorOrderByConvertList, field, sort);
        const pagingOptions = { pageNumber: pageNumber || 1, pageSize: pageSize || InstructorPracticalLists.defaultPageSize, searchText: searchText, orderBy: orderBy || orderByDefault };

        InstructorPracticalLists
            .getWithPaging(this.context, pagingOptions).then(practicals => {
                this.setState({ instructorPracticals: practicals });
            })
            .catch(error => { })
            .finally(() => this.context.setLoading('practical', false));
    }

    onParticipantSortModelChange(sortModel) {
        const { participantSearch, participantPaginationModel: { pageSize }, participantOrderByConvertList } = this.state;
        // handle sort model
        const [{ field, sort }] = sortModel;
        const orderBy = getSortByApiValue(participantOrderByConvertList, field, sort);
        this.onLoadParticipantPractical(null, pageSize, participantSearch, orderBy);
        const newModel = { page: 0, pageSize }
        this.setState({ participantSortModel: sortModel, participantPaginationModel: newModel });
    }

    onParticipantPaginationModelChange(model) {
        const { page, pageSize } = model;
        this.setState({ participantPaginationModel: model })
        const { participantSearch, participantSortModel, participantOrderByConvertList } = this.state;
        // handle sort model
        const [{ field, sort }] = participantSortModel;
        const orderBy = getSortByApiValue(participantOrderByConvertList, field, sort);
        this.onLoadParticipantPractical(page + 1, pageSize, participantSearch, orderBy);
    }


    onLoadParticipantPractical(pageNumber, pageSize, searchText, orderBy) {
        this.context.setLoading('practical', true);
        const {participantSortModel, participantOrderByConvertList} = this.state;
        const [{ field, sort }] = participantSortModel;
        const orderByDefault = getSortByApiValue(participantOrderByConvertList, field, sort);
        const pagingOptions = { pageNumber: pageNumber || 1, pageSize: pageSize || PAGE_SIZE_FOR_ADD_EDIT, searchText: searchText, orderBy: orderBy || orderByDefault };

        participantPracticalList
            .getWithPaging(this.context, pagingOptions).then(practicals => {
                this.setState({ participantPracticals: practicals });
            })
            .catch(error => { })
            .finally(() => this.context.setLoading('practical', false));
    }

    onPracticalModeChange(e, mode) {
        const instructorPaginationModel = { page: 0, pageSize: InstructorPracticalLists.defaultPageSize }
        const participantPaginationModel = { page: 0, pageSize: participantPracticalList.defaultPageSize }
        this.setState({ mode, instructorPaginationModel, participantPaginationModel });
    }

    onCloseParticipantConfirmDialog() {
        const { history } = this.props;
        this.setState({ practical: null });
        history.push('/practicals');
    }

    onInstructorPracticalSelect(e, row) {
        const { history } = this.props;
        history.push(`/practicals/edit/${row.id}`);
        this.setState({ practical: row, isPracticalsDialogShow: true, isDuplicateMode: false });
    }

    onEditPractical(actionPractical) {
        const { history } = this.props;
        history.push(`/practicals/edit/${actionPractical.id}`);
        this.setState({
            practical: actionPractical,
            isDuplicateMode: false,
            isPracticalsDialogShow: true,
            actionTarget: null,
            actionPractical: null
        });
    }

    onCancelDeletePractical() {
        this.setState({ deletingPractical: null });
    }

    onDeletePracticalConfirmed() {
        const { deletingPractical } = this.state;
        this.deletePractical(deletingPractical);
        this.setState({ deletingPractical: null });
    }

    onDeletePractical(actionPractical) {
        this.setState({ deletingPractical: actionPractical });
        this.onCloseActionMenu();
    }

    deletePractical(actionPractical) {
        const { instructorPracticals } = this.state;
        this.context.setLoading('practical', true);
        axios
            .delete(Data.apiBasePath + `/Practical/${actionPractical.id}`, {
                withCredentials: true
            })
            .then((res) => {
                instructorPracticals.practicals = instructorPracticals.practicals.filter(p => p.id !== actionPractical.id);
                this.setState({ instructorPracticals, successMessage: res?.data?.message });
            })
            .catch(e => {
                this.setState({ error: ErrorModel.parseServerError(e) });
            })
            .finally(() => {
                this.context.setLoading('practical', false);
            });
    }

    onCancelSendNotification() {
        this.setState({ sendingNotification: null });
    }

    onSendNotificationConfirmed() {
        const { sendingNotification } = this.state;
        this.sendNotification(sendingNotification);
        this.setState({ sendingNotification: null });
    }

    onSendNotification(actionPractical) {
        this.setState({ sendingNotification: actionPractical })
        this.onCloseActionMenu();
    }

    sendNotification(actionPractical) {
        axios
            .put(Data.apiBasePath + `/Practical/${actionPractical.id}/sendnotifications`, {
                withCredentials: true
            })
            .then(() => {
                this.onLoadInstructorPractical();
                this.setState({ successMessage: 'Notifications have been sent successfully!'})
            })
            .catch(e => {
                this.setState({ error: ErrorModel.parseServerError(e) });
            })
            .finally(() => {
                this.context.setLoading('practical', false);
            });
    }

    onParticipantConfirm(practical) {
        this.context.setLoading('participantConfirm', true);
        this.onCloseParticipantConfirmDialog();
        axios
            .put(Data.apiBasePath + `/Practical/${practical.id}/acknowledge`, {
                withCredentials: true
            })
            .then(() => {
                this.onLoadParticipantPractical();
            })
            .catch(e => {
                this.setState({ error: ErrorModel.parseServerError(e) });
            })
            .finally(() => {
                this.context.setLoading('participantConfirm', false);
            });
    }

    onDuplicatePractical(actionPractical) {
        const { history } = this.props;
        history.push(`/practicals/new`);
        this.onCloseActionMenu();
        this.setState({ practical: actionPractical, isPracticalsDialogShow: true, isDuplicateMode: true });
    }

    onDuplicateModeSave() {
        this.setState({ isDuplicateMode: false });
    }

    onInstructorPracticalAction(e, practical) {
        this.setState({ actionTarget: e.target, actionPractical: practical });
    }

    onParticipantPracticalSelect(e, row) {
        const { history } = this.props;
        history.push(`/practicals/confirmation/${row.id}`);
        this.setState({ practical: row });
    }

    onSnackBarClose() {
        this.setState({ successMessage: null, error: null });
    }

    onAddEditSuccess(message) {
        const { instructorSearch } = this.state;
        this.setState({ successMessage: message });
        this.onLoadInstructorPractical(null, null, instructorSearch)
    }

    onCloseActionMenu() {
        this.setState({ actionTarget: null, actionPractical: null });
    }

    onSetPracticalMode(user) {
        if (user?.isParticipant) {
            this.setState({ mode: PRACTICAL_TYPE.PARTICIPANT });
            this.onLoadParticipantPractical();
        }
        if (user?.isInstructor) {
            this.setState({ mode: PRACTICAL_TYPE.INSTRUCTOR });
            this.onLoadInstructorPractical();
        }
    }

    onNewPracticalClick() {
        this.setState({ isDuplicateMode: false })
    }

    render() {
        const { instructorSortModel, participantSortModel, instructorSearch, participantSearch, actionPractical, actionTarget, isDuplicateMode, successMessage, error, practical, mode, showTabs, instructorPracticals, participantPracticals, deletingPractical, sendingNotification, instructorPaginationModel, participantPaginationModel } = this.state;
        const { theme, location, history } = this.props;

        const path = location.pathname;
        const editMode = path.includes('/practicals/edit');
        const showAddEditPracticalView = path.startsWith('/practicals/new') || path.startsWith('/practicals/edit');
        const showParticipantConfirmation = path.startsWith('/practicals/confirmation/');

        return (
            <View theme={theme} sx={{ margin: 'auto' }} className='klayo_pracicals-container' scrollToTopOnMount={true}>
                {!showAddEditPracticalView && <PracticalSectionStyled className="klayo_practical-section">

                    <Paper
                        theme={theme}
                        padding={{ xs: '46px 24px', md: '56px 64px' }}
                        borderFromBreakpoint='md'>
                        <h1>Practicals</h1>

                        {showTabs && (
                            <Box sx={{ borderBottom: 0.5, borderColor: 'divider' }}>
                                <Tabs
                                    centered={true}
                                    value={mode}
                                    onChange={this.onPracticalModeChange.bind(this)}
                                    variant='fullWidth'
                                    sx={{ width: '100%' }}>
                                    <Tab
                                        label="As an instructor"
                                        value={PRACTICAL_TYPE.INSTRUCTOR} />
                                    <Tab
                                        label="As a participant"
                                        value={PRACTICAL_TYPE.PARTICIPANT} />
                                </Tabs>
                            </Box>
                        )}

                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                            sx={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)', padding: '40px 0px 24px 0px' }}
                        >
                            <TextField
                                value={mode === PRACTICAL_TYPE.INSTRUCTOR ? instructorSearch : participantSearch}
                                dense={true}
                                placeholder='Search practical'
                                disabled={false}
                                fullWidth={true}
                                autoComplete={false}
                                leadingIcon={<SearchIcon />}
                                onChange={this.onSearch.bind(this)}
                                sx={{ maxWidth: { md: '300px' } }}
                            />

                            {mode === PRACTICAL_TYPE.INSTRUCTOR && <Button
                                path={'/practicals/new'}
                                size='md'
                                onClick={this.onNewPracticalClick.bind(this)}
                                theme={theme}
                                variant='filled'
                                showLabelFromBreakpoint='md'
                                startIcon={<AddIcon />}
                                label='New practical' />}

                        </Stack>
                        <Delayed>
                            {mode === PRACTICAL_TYPE.INSTRUCTOR ? (
                                <InstructorPracticalTable
                                    sortable={true}
                                    rowHasAction={true}
                                    minHeight='300px'
                                    theme={theme}
                                    filterMode='server'
                                    hideFirstLastBorder={true}
                                    dense={true}
                                    paginationMode='server'
                                    totalCount={instructorPracticals?.totalCount}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    selectable={true}
                                    onSortModelChange={this.onInstructorSortModelChange.bind(this)}
                                    onPracticalSelect={this.onInstructorPracticalSelect.bind(this)}
                                    onPracticalsAction={this.onInstructorPracticalAction.bind(this)}
                                    sortingMode="server"
                                    sortModel={instructorSortModel}
                                    paginationModel={instructorPaginationModel}
                                    onPaginationModelChange={this.onInstructorPaginationModelChange.bind(this)}
                                    paper={false}
                                    rows={instructorPracticals && instructorPracticals.practicals && instructorPracticals.practicals.length > 0 ? instructorPracticals.practicals : []} 
                                    searchText={instructorSearch}
                                    />
                            ) : mode === PRACTICAL_TYPE.PARTICIPANT ? (
                                <ParticipantPracticalTable
                                    sortable={true}
                                    rowHasAction={true}
                                    minHeight='300px'
                                    theme={theme}
                                    filterMode='server'
                                    hideFirstLastBorder={true}
                                    dense={true}
                                    paginationMode='server'
                                    totalCount={participantPracticals?.totalCount}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    selectable={true}
                                    onSortModelChange={this.onParticipantSortModelChange.bind(this)}
                                    onPracticalSelect={this.onParticipantPracticalSelect.bind(this)}
                                    sortingMode="server"
                                    sortModel={participantSortModel}
                                    paginationModel={participantPaginationModel}
                                    onPaginationModelChange={this.onParticipantPaginationModelChange.bind(this)}
                                    paper={false}
                                    searchText={participantSearch}
                                    rows={participantPracticals && participantPracticals.practicals && participantPracticals.practicals.length > 0 ? participantPracticals.practicals : []} />
                            ) : <></>}
                        </Delayed>
                    </Paper>
                </PracticalSectionStyled>}

                {actionPractical && <Menu
                    open={actionTarget !== null}
                    anchorEl={actionTarget}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    onClose={this.onCloseActionMenu.bind(this)}
                >
                    <MenuItem onClick={() => this.onEditPractical(actionPractical)}>
                        <ListItemText>{Practical.isCompleted(actionPractical) ? 'View' : 'Edit'}</ListItemText>
                    </MenuItem>

                    {PRACTICAL_STATUS[actionPractical.status].label !== 'Completed' && (
                        <MenuItem onClick={() => this.onSendNotification(actionPractical)}>
                            <ListItemText>{PRACTICAL_STATUS[actionPractical.status].label === 'Pending confirmation' ? 'Resend notifications' : 'Send notifications'}</ListItemText>
                        </MenuItem>
                    )}

                    <MenuItem onClick={() => this.onDuplicatePractical(actionPractical)}>
                        <ListItemText>Duplicate</ListItemText>
                    </MenuItem>

                    {PRACTICAL_STATUS[actionPractical.status].label !== 'Completed' && PRACTICAL_STATUS[actionPractical.status].label !== 'Pending confirmation' && (
                        <MenuItem onClick={() => this.onDeletePractical(actionPractical)}>
                            <ListItemText>Delete</ListItemText>
                        </MenuItem>
                    )}

                </Menu>}
                {showParticipantConfirmation && (<PracticalConfirmationDialog
                    open={true}
                    onClose={this.onCloseParticipantConfirmDialog.bind(this)}
                    onConfirm={this.onParticipantConfirm.bind(this)}
                    practical={practical}
                    participantPracticals={participantPracticals && participantPracticals.practicals && participantPracticals.practicals.length > 0 ? participantPracticals.practicals : []}
                    theme={theme}
                    {...this.props}
                />)}
                {showAddEditPracticalView &&
                    <AddEditPracticalView
                        className='klayo__add-edit-employee-setting'
                        practical={(editMode || isDuplicateMode) && practical ? new Practical(practical) : new Practical()}
                        editMode={editMode}
                        onCancel={() => history.push('/practicals')}
                        onLoadInstructorPractical={this.onLoadInstructorPractical.bind(this)}
                        onAddEditSuccess={this.onAddEditSuccess.bind(this)}
                        isDuplicateMode={isDuplicateMode}
                        onDuplicateModeSave={this.onDuplicateModeSave.bind(this)}
                        theme={theme}
                        {...this.props}
                    />}
                {successMessage &&
                    <Snackbar
                        open={true}
                        duration={3000}
                        type='success'
                        onClose={this.onSnackBarClose.bind(this)}
                        message={successMessage}
                    />}
                {error &&
                    <Snackbar
                        open={true}
                        duration={3000}
                        type='error'
                        onClose={this.onSnackBarClose.bind(this)}
                        message={error}
                    />}
                {deletingPractical &&
                    <ConfirmationDialog
                        theme={theme}
                        title='Delete practical' question='Are you sure you want to delete this practical?'
                        cancelButton='Cancel'
                        acceptButton='Delete'
                        acceptDanger={true}
                        onCancel={this.onCancelDeletePractical.bind(this)}
                        onAccept={this.onDeletePracticalConfirmed.bind(this)}
                    />}
                {sendingNotification && <ConfirmationDialog
                    theme={theme}
                    title='Send confirmation notifications' question='Send a notification to all participants to confirm their participation.'
                    cancelButton='Cancel'
                    acceptButton='Send notifications'
                    acceptDanger={false}
                    onCancel={this.onCancelSendNotification.bind(this)}
                    onAccept={this.onSendNotificationConfirmed.bind(this)}
                />}
            </View>
        )
    }
}

const PracticalSectionStyled = styled.div`
	&.klayo_practical-section {

	}
`;