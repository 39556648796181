import { Card, CardContent } from '@mui/material';
import React, { Component } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as CertificateIcon } from '../resources/images/icons-certificate.svg';
import { CompanyIntegrationStatus } from './CompanyIntegrationStatus';
import { KLAYO_COLORS } from '../themes';
import IntegrationAbbreviation from '../views/settings/IntegrationAbbreviation';

export class CompanyIntegrationCard extends Component {

    constructor(props) {
        super(props);
    }
    
    render() {
        const { organizationIntegration, organization } = this.props;
        const { name, description, status, lastSyncDate, isLiveSync } = organizationIntegration;

        return (
            <StyledCompanyIntegrationCard className='klayo_company-integration-card'>
                <CardContent>
                    <div className='klayo_company-integration-content'>
                        <CertificateIcon className='certification-icon'/>
                        <div className='klayo_company-integration-sub-content'>
                            <p className='title'>
                                {name} {organization && organization.hasMultipleIntegrations && organizationIntegration.abbreviation && (
                                    <IntegrationAbbreviation 
                                        data={organizationIntegration} 
                                        type={IntegrationAbbreviation.dataType.integration.value}
                                    />
                                )}
                            </p>
                            <p className='description'>{description}</p>
                        </div>
                    </div>
                    <CompanyIntegrationStatus status={status} lastSyncDate={lastSyncDate} isLiveSync={isLiveSync} {...this.props}/>
                </CardContent>
            </StyledCompanyIntegrationCard>
        )
    }
}

const StyledCompanyIntegrationCard = styled(Card)`
    &.klayo_company-integration-card {
        width: 100%;
        border-radius: 16px;
        border: 1px solid ${KLAYO_COLORS.NeonBlueNB050};
        box-shadow: none;
        margin-top: 24px;
        
        .MuiCardContent-root {
            display: flex;
            justify-content: space-between;
            padding: 24px;
        }

        .klayo_company-integration-content {
            display: flex;
            gap: 24px;

            .certification-icon {
                color: ${KLAYO_COLORS.NeonBlueNB200};
            }
        }

        .klayo_company-integration-sub-content {
            .title {
                color: ${KLAYO_COLORS.GreyGR700};
                margin: 0px;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: -0.1px;
            }

            .description {
                color: ${KLAYO_COLORS.GreyGR500};
                margin: 0px;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                margin-right: 26px;
            }
        }
    }
`;
