import { React, Component } from 'react';
import { Snackbar } from './Snackbar';

export class NotificationPopup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };
    }

    onClose(e) {
        this.close();
    }

    open(mode, open) {
        this.setState({ open: open === true || open === undefined, mode });
    }

    close() {
        this.setState({ open: false });
    }

    onNotificationReceived(notif) {
        if(notif.isNew) this.setState({ open: true, message: 'Notification: ' + notif.primaryText + ': ' + notif.secondaryText });
    }

    render() {
        const { open, message } = this.state;

        return <Snackbar
                open={open}
                duration={6000}
                onClose={this.onClose.bind(this)}
                message={message}>
            </Snackbar>
    }
}