import { React, Component } from 'react';
import { Box, Toolbar, AppBar as MuiAppBar, Stack } from '@mui/material';
import { AppContext } from '../common/AppContext';
import { Button } from './Button';
import { AppNav } from './AppNav';
import { ReactComponent as Logo } from '../resources/images/logo.svg';
import { ReactComponent as BellIcon } from '../resources/images/icons-bell.svg';
import { ReactComponent as MenuIcon } from '../resources/images/icons-menu.svg';
import { EventBus } from '../common/EventBus';
import { Tooltip } from './Tooltip';

export class AppBar extends Component {
    static contextType = AppContext;

    static defaultProps = {
        allowThemeChange: false,
        showNavigation: false
    }

    constructor(props) {
        super(props);

        this.state = {
            userButton: null,
            showActions: true,
            unreadNotifications: this.props.unreadNotifications,
            profilePicUpdated: false
        };
    }

    componentDidMount() {
        this.context.eventBus.on(EventBus.event.profilePictureChanged, e => {
            this.setState({ profilePicUpdated: true });
        });
    }

    onUserButton(e) {
        this.setState({ userButton: e.target });
    }

    onUserMenuClose(e) {
        this.setState({ userButton: null });
    }

    onNavigation(e, callback) {
        const { onNavigation } = this.props;
        return onNavigation && onNavigation(e, callback);
    }

    onSignOut(e) {
        this.onUserMenuClose(e);

        const { onSignOut } = this.props;
        if (onSignOut) this.onNavigation(e, onSignOut);
    }

    onLogoClick(e) {
        const { onLogoClick } = this.props;
        if (onLogoClick) this.onNavigation(e, onLogoClick);
    }

    onShowSwitchUserDialog(e) {
        this.onUserMenuClose(e);

        const { onShowSwitchUserDialog } = this.props;
        if (onShowSwitchUserDialog) this.onNavigation(e, onShowSwitchUserDialog);
    }

    onNotificationReceived(notif, numUnread) {
        this.setUnreadNotifications(numUnread);
    }

    setUnreadNotifications(numUnread) {
        this.setState({ unreadNotifications: numUnread });
    }

    onSwitchTheme() {
        this.setState({ userButton: null }, () => {
            this.props.onSwitchTheme(this.props.themeName === 'v1' ? 'v2' : 'v1');
        });
    }

    onOpenDevDialog(e) {
        this.onUserMenuClose(e);

        const { onOpenDevDialog } = this.props;
        if (onOpenDevDialog) this.onNavigation(e, onOpenDevDialog);
    }

    onOpenSettings(e) {
        this.onUserMenuClose(e);

        const { onOpenSettings } = this.props;
        if (onOpenSettings) this.onNavigation(e, onOpenSettings);
    }

    onDemoSwitch(e, demo) {
        this.onUserMenuClose(e);

        const { onDemoSwitch } = this.props;
        if (onDemoSwitch) this.onNavigation(e, e => onDemoSwitch(e, demo));
    }

    onMenuButtonClick(e) {
        this.props.onMenuButtonClick(e);
    }

    render() {
        const { user, organization, theme, showNavigation, showActions, onShowNotifications, onMenuButtonClick, themeName, allowThemeChange, navItems, defaultNavItem, location, onDemoSwitch, onNavigation, sx } = this.props;
        const { userButton, unreadNotifications, profilePicUpdated } = this.state;

        const profilePicUrl = user && user.getProfilePictureUrl ? user.getProfilePictureUrl() : null;

        return (
            <MuiAppBar
                className='klaro-appbar'
                position='fixed'
                elevation={0}
                sx={{
                    ...sx,
                    background: 'var(--white-wh-900)',
                    borderBottom: '1px solid var(--neon-blue-nb-050)',
                    padding: {
                        xs: '0 16px 0 10px',
                        sm: '0 16px 0 10px',
                        md: '0 40px',
                        lg: '0 40px'
                    }
                }}>

                <Toolbar className='klaro-appbar_toolbar'>

                    <Button
                        className='klaro-appbar_menubutton klayo-appbar_button'
                        icon={<MenuIcon />}
                        size='sm'
                        // tooltip='Menu'
                        onClick={this.onMenuButtonClick.bind(this)}
                        sx={{ flexGrow: 1, md: { display: 'none' } }}
                    />

                    <Box
                        className='klaro-appbar_logocontainer'
                        onClick={this.onLogoClick.bind(this)}
                        sx={{
                            height: {
                                xs: '24px!important',
                                sm: '32px!important'
                            }
                        }}>
                        <Logo
                            className="klaro-appbar_logo"
                            width='auto'
                            height='100%'
                            alt="Klayo Logo" />
                        {organization && organization.isDemo && <Tooltip title='Demo mode' placement='right'><div className='klaro-appbar_logo_demo'>Demo Company</div></Tooltip>}
                    </Box>

                    <Box sx={{ flexGrow: 1 }}>
                        {showNavigation && <AppNav
                            sx={{ flexGrow: 1, [theme.breakpoints.down('md')]: { display: 'none' } }}
                            location={location}
                            user={this.props.user}
                            defaultItem={defaultNavItem}
                            onNavigation={onNavigation}
                            items={navItems} />}
                    </Box>

                    {showActions && (<Box>
                        <Stack direction='row' spacing={2}>
                            {user &&
                                <Button
                                    className='klayo-appbar_notificationbutton klayo-appbar_button'
                                    size='sm'
                                    aria-label="Show 2 new notifications"
                                    icon={<BellIcon />}
                                    badgeProps={{
                                        badgeContent: unreadNotifications,
                                        invisible: unreadNotifications === null,
                                        variant: "dot",
                                        color: 'error',
                                        max: 99
                                    }}
                                    color="inherit"
                                    tooltip={'Unread notifications: ' + (unreadNotifications ? unreadNotifications : 0)}
                                    onClick={onShowNotifications}
                                />}
                        </Stack>
                    </Box>)}
                </Toolbar>
            </MuiAppBar>
        );
    }
}