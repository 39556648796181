import { AvatarGroup } from '@mui/material';
import { React, Component } from 'react';
import { ProfilePicture } from './ProfilePicture';

export class ProfilePictureGroup extends Component {

    static defaultProps = {
        max: 5,
        size: 'xs',
    }

    onUserClick(e, user) {
        if(this.props.onUserClick) this.props.onUserClick(e, user);
    }

    render() {
        const { max, size, users } = this.props;

        return (
            <AvatarGroup max={max} sx={{ '& .MuiAvatar-root': { width: ProfilePicture.size[size], height: ProfilePicture.size[size], fontSize: ProfilePicture.fontSize[size] }}}>
                {users.map((e, index) => (<ProfilePicture key={index} user={e} size={size} onClick={event => this.onUserClick(event, e)} sx={{ zIndex: index}} />))}
            </AvatarGroup>
        );
    }
}