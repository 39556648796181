import { React, Component } from 'react';
import { SettingsAttribute } from '../../data/settings/SettingsAttribute';
import { RepeatingSelect } from './RepeatingSelect';

export class ExpiryNotificationSelector extends Component {

    static defaultProps = {
    }
    constructor(props) {
        super(props);
    }

    render() {
        const { theme } = this.props;

        return (
            <RepeatingSelect
                theme={theme}
                {...this.props}
                defaultItem={SettingsAttribute.notificationPeriods.none}
                items={SettingsAttribute.notificationPeriods}
                itemComparator={(e1, e2) => e1.index === e2.index} />
        );

    }
}