import { JobAttribute } from "./JobAttribute";
import { AbstractAttributeList } from "./AbstractAttributeList";
import { AbstractAttribute } from "./AbstractAttribute";

export class JobAttributeList extends AbstractAttributeList {

    constructor(options) {
        super(options);

        options.data.forEach((attr, index) => this.attributes.push(new JobAttribute(index, attr)));
        this.initAttributesLists();
    }

    initAttributesLists() {
        this.attributes.forEach(a => {
            if (a.isParent) {
                a.childAttributes.forEach(c => {
                    const attrDef = this.getByDefinition(c.attributeDefinitionId);
                    if (attrDef) {
                        c.required = attrDef.required;
                        c.mandatory = attrDef.mandatory;
                        c.categories = attrDef.categories;
                        c.currentProficiency = attrDef.currentProficiency;
                        c.requiredProficiency = attrDef.requiredProficiency;
                        c.type = attrDef.type;
                    }
                })
            }
        });

        super.initAttributesLists();
    }

    getByDefinition(defId) {
        return this.attributes.find(a => a.attributeDefinitionId === defId);
    }
}