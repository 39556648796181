import { Component } from "react";
import { Paper } from '../../components/Paper';
import { Link } from "react-router-dom";
import { Dialog, Box, AppBar, Toolbar, DialogContent, Stack, Divider } from "@mui/material";
import { ViewComponent } from "../../components/ViewComponent";
import { AppContext } from '../../common/AppContext';
import { Button } from "../../components/Button";
import { ReactComponent as CloseIcon } from '../../resources/images/icons-close.svg';
import { ReactComponent as Logo } from '../../resources/images/logo.svg';
import { TextField } from "../../components/TextField";
import { Alert } from "../../components/Alert";
import { Utils } from "../../common/Utils";
import { ErrorModel } from '../../common/models/ErrorModel';
import { Data } from '../../data/Data';
import { ReactComponent as PlaneIcon } from '../../resources/images/icons-plane.svg';

import axios from "axios";
import { ConfirmationBox } from "../../components/ConfirmationBox";

export class ContactUsDialog extends ViewComponent {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            firstName: props.user ? props.user.firstName : '',
            lastName: props.user ? props.user.lastName : '',
            email: props.user ? props.user.email : '',
            phone: '',
            company: props.organization ? props.organization.name : '',
            numUsersRequired: '',
            message: '',
            error: null,
            sending: false,
            sent: false
        };
    }

    onFirstNameChange(e) {
        this.setState({ firstName: e.target.value });
    }

    onLastNameChange(e) {
        this.setState({ lastName: e.target.value });
    }

    onEmailChange(e) {
        this.setState({ email: e.target.value });
    }

    onPhoneChange(e) {
        this.setState({ phone: e.target.value });
    }

    onCompanyChange(e) {
        this.setState({ company: e.target.value });
    }

    onNumUsersChange(e) {
        this.setState({ numUsersRequired: e.target.value });
    }

    onMessageChange(e) {
        this.setState({ message: e.target.value });
    }

    validate() {
        const { firstName, lastName, email, phone, company, numUsersRequired, message, error } = this.state;

        if (!firstName) { this.setError('Please enter a first name.'); return false }
        else if (!lastName) { this.setError('Please enter a first name.'); return false }
        else if (!Utils.isValidEmail(email)) { this.setError('Please enter an email address.'); return false }
        else if (!company) { this.setError('Please enter a company name.'); return false }
        else if (!phone) { this.setError('Please enter a phone number.'); return false }
        else if (!numUsersRequired) { this.setError('Please enter the number of required users.'); return false }
        return true;
    }

    setError(error) {
        this.setState({ error });
    }

    onSend(e) {
        if (!this.validate()) return;

        this.setState({ error: null, sending: true });
        this.context.setLoading('sending', true);

        const { firstName, lastName, email, phone, company, numUsersRequired, message, error, sending } = this.state;

        axios.post(Data.apiBasePath + '/Organization/ContactUs', {
            'firstName': firstName,
            'lastName': lastName,
            'email': email,
            'phone': phone,
            'companyName': company,
            'numberOfUserRequired': numUsersRequired,
            'message': message
        }, {
            withCredentials: true
        })
            .then(response => {
                this.setState({ sent: true, error: null, sending: false });
            })
            .catch(e => {
                this.setState({ error: ErrorModel.parseServerError(e) });
            })
            .finally(() => {
                this.context.setLoading('sending', false);
                this.setState({ sending: false });
            });
    }

    render() {
        const { organization, user, onClose } = this.props;
        const { firstName, lastName, email, phone, company, numUsersRequired, message, error, sending, sent } = this.state;

        return <Dialog
            className='klayo-billingplandialog'
            onClose={onClose}
            open={true}
            fullScreen={true}>
            <AppBar
                className='klaro_employeeprofile-appbar'
                elevation={0}>
                <Toolbar>
                    <Logo className="klaro-appbar_logo" alt="Klayo Logo" />
                    <Box sx={{ flexGrow: 1 }}>
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Button
                            autoFocus
                            label='Close'
                            variant='outlined'
                            size='md'
                            startIcon={<CloseIcon />}
                            onClick={onClose} />
                    </Box>
                </Toolbar>
            </AppBar>
            <DialogContent sx={{ marginTop: '64px', backgroundColor: '#F8F8FE' }}>
                <div className='klayo-billingplandialog_content'>

                    {sent && 
                        <ConfirmationBox
                            icon={<PlaneIcon />}
                            heading='Got it!'
                            message='We’ll be in touch shortly'
                            action='Close'
                            onAction={onClose} />}

                    {!sent &&
                        <Stack direction='column' spacing={2}>
                            <h1>Get in touch for a customised plan</h1>
                            <Stack spacing={5}>

                                {error && <Alert severity='error'>{error}</Alert>}

                                <Stack
                                    direction='row'
                                    spacing={4}
                                    justifyContent='space-between'>
                                    <TextField
                                        label='First name'
                                        value={firstName}
                                        autoFocus={true}
                                        clearable={false}
                                        autoComplete={false}
                                        onChange={this.onFirstNameChange.bind(this)}
                                        sx={{ width: '100%' }} />
                                    <TextField
                                        label='Last name'
                                        value={lastName}
                                        clearable={false}
                                        autoComplete={false}
                                        onChange={this.onLastNameChange.bind(this)}
                                        sx={{ width: '100%' }} />
                                </Stack>

                                <Stack
                                    direction='row'
                                    spacing={4}
                                    justifyContent='space-between'>
                                    <TextField
                                        label='Email'
                                        value={email}
                                        clearable={false}
                                        autoComplete={false}
                                        onChange={this.onEmailChange.bind(this)}
                                        sx={{ width: '100%' }} />
                                    <TextField
                                        label='Phone'
                                        value={phone}
                                        clearable={false}
                                        autoComplete={false}
                                        onChange={this.onPhoneChange.bind(this)}
                                        sx={{ width: '100%' }} />
                                </Stack>

                                <Stack
                                    direction='row'
                                    spacing={4}
                                    justifyContent='space-between'>
                                    <TextField
                                        label='Company name'
                                        value={company}
                                        clearable={false}
                                        autoComplete={false}
                                        onChange={this.onCompanyChange.bind(this)}
                                        sx={{ width: '100%' }} />
                                    <TextField
                                        label='Number of users required'
                                        value={numUsersRequired}
                                        clearable={false}
                                        autoComplete={false}
                                        onChange={this.onNumUsersChange.bind(this)}
                                        sx={{ width: '100%' }} />
                                </Stack>


                                <Stack
                                    direction='row'
                                    spacing={4}
                                    justifyContent='space-between'>
                                    <TextField
                                        label='Optional message'
                                        multiline={true}
                                        minRows={4}
                                        value={message}
                                        clearable={false}
                                        autoComplete={false}
                                        onChange={this.onMessageChange.bind(this)}
                                        sx={{ width: '100%' }} />
                                </Stack>


                                <div style={{ textAlign: 'center', padding: '30px' }}>
                                    <Button
                                        label='Send details'
                                        variant='filled'
                                        disabled={sending}
                                        onClick={this.onSend.bind(this)} />
                                </div>
                            </Stack>
                        </Stack>
                    }
                </div>
            </DialogContent>
        </Dialog >
    }
}