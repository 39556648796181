
import { Divider, Stack } from "@mui/material";
import { Component } from "react";
import { Button } from "../../components/Button";
import { Alert } from "../../components/Alert";
import { TextField } from "../../components/TextField";
import { BillingDetails } from "./BillingDetails";
import { BillingContact } from "./BillingContact";
import { BillingSelectionCard } from "../../components/BillingSelectionCard";
import { EwayField } from "../../components/billing/EwayField";
import { Utils } from "../../common/Utils";

export class CreditCardPayment extends Component {

    static ewayInjected = false;

    static defaultProps = {
        disabled: false,
        visible: false
    }

    constructor(props) {
        super(props);

        this.state = {
            nameValid: false,
            cardValid: false,
            expiryValid: false,
            cvcValid: false,
            ewayInjcted: false,
            attemptId: props.attemptId
        };
    }

    componentDidMount() {
        EwayField.injectEwayScript(() => {
            this.setState({ ewayInjcted: true });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.attemptId !== this.props.attemptId) {
            EwayField.resetEwayFields();

            this.setState({
                attemptId: this.props.attemptId,
                nameValid: false,
                cardValid: false,
                expiryValid: false,
                cvcValid: false
            });
        }
    }

    onNameChange(fieldValid, secureFieldCode) {
        const { onChange } = this.props;
        this.setState({ nameValid: fieldValid });
        if (onChange) onChange(this.state, secureFieldCode);
    }

    onCardChange(fieldValid, secureFieldCode) {
        const { onChange } = this.props;
        this.setState({ cardValid: fieldValid });
        if (onChange) onChange(this.state, secureFieldCode);
    }

    onExpiryChange(fieldValid, secureFieldCode) {
        const { onChange } = this.props;
        this.setState({ expiryValid: fieldValid });
        if (onChange) onChange(this.state, secureFieldCode);
    }

    onCVCChange(fieldValid, secureFieldCode) {
        const { onChange } = this.props;
        this.setState({ cvcValid: fieldValid });
        if (onChange) onChange(this.state, secureFieldCode);
    }

    render() {
        const { disabled, visible } = this.props;
        const { ewayInjcted, attemptId } = this.state;

        return <div style={{ display: visible === true ? 'block' : 'none' }}>
            {ewayInjcted &&
                <Stack spacing={5}>
                    <Stack
                        direction='row'
                        spacing={4}
                        justifyContent='space-between'>
                        <EwayField
                            label='Name on card'
                            type='name'
                            attemptId={attemptId}
                            onChange={this.onNameChange.bind(this)}
                            sx={{ width: '100%' }} />
                        <EwayField
                            label='Card number'
                            type='card'
                            attemptId={attemptId}
                            onChange={this.onCardChange.bind(this)}
                            sx={{ width: '100%' }} />
                    </Stack>
                    <Stack
                        direction='row'
                        spacing={4}
                        justifyContent='space-between'>
                        <EwayField
                            label='Expiry'
                            type='expiry'
                            attemptId={attemptId}
                            onChange={this.onExpiryChange.bind(this)}
                            sx={{ width: '380px' }} />

                        <EwayField
                            label='CVC / CVV'
                            type='cvn'
                            attemptId={attemptId}
                            onChange={this.onCVCChange.bind(this)}
                            sx={{ width: '200px' }} />
                    </Stack>
                </Stack>}
        </div>
    }
}