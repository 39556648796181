import { BillingPlan } from "../billing/BillingPlan";
import { BillingCreditCardDetails } from "../billing/BillingCreditCardDetails";
import { Data } from "../Data";

export class BillingSettings extends Data {

    static instance = null;

    constructor(options) {
        super({
            api: '/Organization/Admin/Billing', callback: data => {
                options.resolve(this, false);
            }, ...options
        });
    }

    onDataReceived(data) {
        this.billingDetails = data.billingDetails.billingDetail;
        this.selectedPlan = this.billingDetails.selectedPlan;
        this.creditCardDetails = data.billingDetails.creditCardDetails ? new BillingCreditCardDetails(data.billingDetails.creditCardDetails[0]) : null;
        this.contactDetails = this.billingDetails.contact;
        this.planName = this.selectedPlan.planName;
        this.planGroupName = this.selectedPlan.planGroupName;
        this.isCustomPlan = this.planGroupName === 'Custom' || this.planName === 'Custom';
        this.planGroupId = this.selectedPlan.billingPlanGroupId;
        this.paymentMethod = this.selectedPlan.planPaymentMethod ? Object.values(BillingPlan.paymentMethod)[this.selectedPlan.planPaymentMethod] : BillingPlan.paymentMethod.none;
        this.planEmployeeCap = this.selectedPlan.planEmployeeCap;
        this.currentCycleEndDate = new Date(this.billingDetails.currentCycle.endDate);
        this.planPricePerCycleInCents = this.selectedPlan.planPricePerCycleInCents;
        this.currentNumberOfEmployeesInOrg = this.billingDetails.currentNumberOfEmployeesInOrg;
        this.cycle = this.selectedPlan.planCycle ? Object.values(BillingPlan.cycle)[this.selectedPlan.planCycle] : BillingPlan.cycle.none;
        this.organizationName = this.billingDetails.organizationName;
    }

    static get(context, updateCache) {
        return new Promise((resolve, reject) => {
            if (BillingSettings.instance && updateCache !== true) resolve(BillingSettings.instance, true);
            else BillingSettings.instance = new BillingSettings({ resolve, reject, context });
        });
    }
}