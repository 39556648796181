import { Box } from '@mui/material';
import React, { Component } from 'react';
import * as d3 from "d3";
import { AbstractChart } from './AbstractChart';
import { Utils } from '../../common/Utils';

export class AttributeBubbleChart extends AbstractChart {

    static defaultProps = {
        ...AbstractChart.defaultProps,
        height: 270,
        bubbleColor: '#4d5ef7',
        bubbleTextColor: 'white',
        bubbleSizeMax: { xs: 56, sm: 56, md: 56, lg: 56, xl: 56 },
        //bubbleSizeMax: { xs: 80, sm: 80, md: 80, lg: 80, xl: 80 },
        top: null,
        maxFontSize: 16
    }

    constructor(props) {
        super(props);

        this.state = {
            ...this.state
        };

        this.svgRef = React.createRef();
    }

    onBubbleMouseOver(e, d) {
        const { attributes, onAttributeSelect, bubbleColor, bubbleTextColor } = this.props;
        this.tooltip.style("opacity", 1);

        this.tooltip.html('<div><div class="klayo_bubblechart_tooltip_attribute">' + d.name + '</div><div class="klayo_bubblechart_tooltip_employees">Employees: ' + d.attribute.employees.length + '</div></div>');

        this.node.style("fill", d2 => d === d2 ? 'white' : bubbleColor);
        this.text.style("color", d2 => d === d2 ? '#333' : bubbleTextColor);
    }

    onBubbleMouseMove(e, d) {
        // this.tooltip.style("left", (d3.pointer(e)[0] - 20) + "px")
        //     .style("top", (d3.pointer(e)[1]) + "px");

        this.tooltip.style("left", (e.clientX + 10) + "px")
            .style("top", (e.clientY + 10) + "px");
    }

    onBubbleMouseLeave(e, d) {
        const { attributes, onAttributeSelect, bubbleColor, bubbleTextColor } = this.props;
        this.tooltip.style("opacity", 0);

        this.node.style("fill", bubbleColor);
        this.text.style("color", bubbleTextColor);
    }

    onBubbleClick(e, d) {
        const { attributes, onAttributeSelect, bubbleColor, bubbleTextColor } = this.props;
        onAttributeSelect(null, d.attribute);
    }

    componentDidMount() {
        super.componentDidMount();

        this.updateDimensions((width, height) => {
            this.forceUpdate();
        });
    }


    onDragStarted(e, d) {
        /*if (!e.active) this.simulation.restart();
        d.fx = d.x;
        d.fy = d.y;*/
    }

    onDragged(e, d) {
        /*d.fx = e.x;
        d.fy = e.y;*/
    }

    onDragEnded(e, d) {
        /*if (!e.active) this.simulation.alphaTarget(.03);
        d.fx = null;
        d.fy = null;*/
    }

    renderChart() {
        if (!this.svgRef.current) return;

        const { theme, top, attributes, maxFontSize, onAttributeSelect, bubbleColor, bubbleTextColor, bubbleSizeMax } = this.props;
        const { width, height } = this.state;
        const data = [];

        const currentBreakpoint = theme ? theme.getCurrentBreakpoint()[0] : 'lg';

        if (attributes) {
            let items = Object.values(attributes);
            if(top) {
                items = Object.values(attributes).sort((a, b) => a.employees.length < b.employees.length);
                items = items.slice(0, top);
            }
            //data.sort(() => 0.5 - Math.random());

            const counts = Object.values(items).map(a => a.employees.length);
            let max = Math.max(...counts);
            let min = Math.min(...counts);

            Object.values(items).forEach(a => {
                data.push({
                    name: a.attributeName,
                    attribute: a,
                    value: a.employees.length
                });
            });

            //data.sort(() => 0.5 - Math.random());

            // Size scale for countries
            const size = d3.scaleLinear()
                .domain([top ? min : 0, max])
                .range([top ? 32 : 7, bubbleSizeMax[currentBreakpoint]])  // circle will be between 7 and 55 px wide


            var xScale = d3.scaleLinear().domain([min, max]).range([0, width]);

            this.tooltip = d3.select(this.containerRef.current)
                .append("div")
                .style("opacity", 0)
                .attr("class", "klayo_bubblechart_tooltip")
                .style("background-color", "white")
                .style("border", "solid")
                .style("border-width", "2px")
                .style("border-radius", "5px")
                .style("padding", "5px")

            this.svg = d3.select(this.svgRef.current);

            this.node = this.svg.append("g")
                .selectAll("circle")
                .data(data)
                .join("circle")
                .attr("class", "node")
                .attr("r", d => size(d.value))
                .attr("cx", width / 2)
                .attr("cy", height / 2)
                .style("fill", d => bubbleColor)
                .style("fill-opacity", 0.9)
                .style("stroke", d => bubbleColor)
                .style("stroke-width", 2)
                .style("cursor", "pointer")
                .on("mouseover", this.onBubbleMouseOver.bind(this))
                .on("mousemove", this.onBubbleMouseMove.bind(this))
                .on("click", this.onBubbleClick.bind(this))
                .on("mouseleave", this.onBubbleMouseLeave.bind(this))
                .call(d3.drag() // call specific function when circle is dragged
                    .on("start", this.onDragStarted.bind(this))
                    .on("drag", this.onDragged.bind(this))
                    .on("end", this.onDragEnded.bind(this)));

            this.text = this.svg.append("g").selectAll('foreignObject')
                .data(data)
                .enter()
                .append("foreignObject")
                .attr("class", d => "klayo_bubblechart_bubble klayo_bubblechart_bubble" + (Math.ceil(size(d.value) / 10.0) * 10))
                .attr('width', d => size(d.value) * 2 + 'px')
                .attr('height', d => size(d.value) * 2 + 'px')
                .style('font-size', d => Math.min((size(d.value) / 3), maxFontSize) + 'px')
                .style("transform", d => 'translate(-' + parseInt(size(d.value)) + 'px, -' + parseInt(size(d.value)) + 'px)')
                .style("pointer-events", 'none')
                .style("color", d => bubbleTextColor)
                .style("opacity", d => size(d.value) > 20 ? 1 : 0)
                .html(d => top ? '<div class="klayo_bubblechart_bubble_inner"><div class="klayo_bubblechart_bubblelabel" style="display:-webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;">' + Utils.truncate(d.name, 60) + '</div></div>'
                : '<div class="klayo_bubblechart_bubble_inner"><div class="klayo_bubblechart_bubblelabel" style="line-height:' + parseInt(Math.min((size(d.value) / 3)+1, maxFontSize+1)  + 'px;height:' + (parseInt(Math.min((size(d.value) / 3), maxFontSize) ) * 2) + 1) + 'px;">' + Utils.truncate(d.name, 15) + '</div></div>');

            this.simulation = d3.forceSimulation(data)
                .force('charge', d3.forceManyBody().strength(5))
                .force("center", d3.forceCenter().x(width / 2).y(height / 2)) // Attraction to the center of the svg area
                .force("collide", d3.forceCollide().strength(.2).radius(d => size(d.value) + 3).iterations(1)) // Force that avoids circle overlapping*/
                .force('y', d3.forceY().y(d => {
                    if(theme && theme.isBreakpointUp('sm')) return 0;
                    return d.y;
                }));

            this.simulation
                .nodes(data)
                .on("tick", d => {
                    for (let i = 0; i < 10; i++) this.simulation.tick();

                    this.node
                        .attr("cx", d => d.x)
                        .attr("cy", d => d.y);

                    this.text
                        .attr("x", d => d.x)
                        .attr("y", d => d.y);
                });
        }
    }

    render() {
        const { sx, attributes, onAttributeSelect } = this.props;
        const { width, height } = this.state;

        const svgWidth = width ? width - 20 : null;
        const svgHeight = height ? height - 50 : null;

        if (this.svg) {
            this.tooltip.remove();
            this.svg.selectAll("*").remove();
        }
        this.renderChart();

        return (
            <Box ref={this.containerRef} className='klayo_bubblechart' sx={sx}>
                <div style={{ overflow: 'hidden', height: '100%', display:'flex', alignItems:'center' }}>
                    <svg
                        ref={this.svgRef}
                        viewBox={'0 0 ' + width + ' ' + height}
                        style={{
                            width: svgWidth, height: svgHeight, overflow: 'visible'
                        }}
                    >
                    </svg>
                </div>
            </Box>
        );
    }
}