import { InputLabel, Skeleton } from "@mui/material";
import React, { Component } from "react";

export class EwayField extends Component {

    static ewayPublicApiKey = 'epk-DC890758-B015-47C2-A4D2-9B117CB713FB';

    constructor(props) {
        super(props);

        this.state = {
            ewayInit: window.eWAY,
            ewayFieldLoaded: false,
            focus: false
        };

        this.ewayFieldRef = React.createRef();
        this.secureFieldCode = null;
    }

    componentDidMount() {
        if (!window.eWAY) this.initEway();
        else this.initEwaySecureField();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.attemptId !== this.props.attemptId) {
            this.iframe.remove();
            this.initEwaySecureField();
        }
    }

    initEwaySecureField() {
        const { type, attemptId, onChange } = this.props;

        let style = 'line-height: 1; height: 42px; border-radius: 28px; border: 1px solid #9ea2fa; color: #000; padding: 11px 16px; font-family: Inter, sans-serif; font-size: 16px; box-shadow:none;';
        if (type === 'expiry') style += 'padding: 9px 16px';

        if (window.eWAY) {
            window.eWAY.setupSecureField({
                publicApiKey: EwayField.ewayPublicApiKey,
                fieldDivId: 'eway-secure-field-' + attemptId + '_' + type,
                autocomplete: true,
                fieldType: type,
                styles: style
            }, event => {
                this.setState({ focus: event.valueHasFocus });

                if (event.fieldValid) {
                    this.secureFieldCode = event.secureFieldCode;
                }

                if (onChange) onChange(event.valueIsValid, event.secureFieldCode);
            });

            if (this.ewayFieldRef.current) {
                this.iframe = this.ewayFieldRef.current.querySelector('iframe');
                this.iframe.addEventListener('load', e => {
                    this.setState({ ewayFieldLoaded: true });
                });
            }

            this.setState({ ewayInit: true });
        }
    }

    initEway() {
        if (window.eWAY) return;
        else EwayField.injectEwayScript(() => this.initEwaySecureField());
    }

    static injectEwayScript(onload) {
        if (window.eWAY) {
            onload();
            return;
        }

        let script = document.getElementById('eway-script');
        if (script) {
            if (onload) setTimeout(t => onload(), 100);
            return;
        }

        script = document.createElement("script");
        script.id = 'eway-script';
        if (onload) script.onload = onload;
        script.src = "https://secure.ewaypayments.com/scripts/eWAY.min.js";
        script.setAttribute("data-init", "false");
        document.body.appendChild(script);
    }

    static saveAllFields() {
        //window.eWAY.saveAllFields(() => {}, 2000); 
    }

    static resetEwayFields() {
        window.eWAYUtils.setVar('activeFields', [])
        window.eWAYUtils.setVar('pageId', window.eWAYUtils.generateUUID());
    }

    onFocus(e) {
        this.setState({ focus: true });
    }

    render() {
        const { type, attemptId, label, sx } = this.props;
        const { focus, ewayInit } = this.state;

        return <div
            className={'klayo-ewayfield klayo-ewayfield-' + type + (focus ? ' klayo-ewayfield--focus' : '')}
            style={sx}>
            <InputLabel sx={{ marginBottom: '6px' }}>{label}</InputLabel>
            <div
                id={'eway-secure-field-' + attemptId + '_' + type}
                className='klayo-ewayfield-ewayfield'
                ref={this.ewayFieldRef}
                onMouseDown={this.onFocus.bind(this)}
                style={{ display: window.eWAY ? 'block' : 'none' }}>
            </div>
            {!window.eWAY && <Skeleton height='42px' />}
            <div id={'eway-secure-field-' + type + '-styles'} style={{ background: 'red' }}>
            </div>
        </div>
    }
};