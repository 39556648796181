import { Utils } from "../../common/Utils";
import { AbstractAttribute } from "./AbstractAttribute";
export class EmployeeAttribute extends AbstractAttribute {

    static status = {
        review: {
            label: 'Under review'
        },
        feedback: {
            label: 'Needs feedback'
        },
        approved: {
            label: 'Approved'
        },
        declined: {
            label: 'Declined'
        }
    };

    constructor(index, data) {
        super(index, data);

        this.lastUpdate = data.lastUpdateDate;
        this.attributeName = data.attributeDefinitionName;
        this.attribute = data.attributeDefinitionName;
        this.category = data.attributeDefinitionCategory;
        this.currentProficiency = data.proficiencyValue;
        this.currentExpiryDate = Utils.parseApiDate(data.expiryDate);
        this.status = Object.values(AbstractAttribute.status)[data.validationRequestState] || null;
        this.statusLabel = this.status ? this.status.label : '';
        this.employeeAttributeId = data.employeeAttributeId;
        this.validated = data.validated;
        this.requestedProficiency = data.validationRequest ? data.validationRequest.proficiencyValue : 0;
        this.requestedExpiryDate = data.validationRequest ? Utils.parseApiDate(data.validationRequest.expiryDate) : null;
        this.proficiency = data.proficiencyValue;
        this.expiryDate = this.currentExpiryDate;
        this.validationRequest = data.validationRequest;
        this.validationRequestId = data.validationRequest ? data.validationRequest.employeeAttributeValidationRequestId : null;
        this.trainingCourses = data.trainingCourses;
        this.notifications = [];
        this.pendingExpiryDate = data.pendingExpiryDate ? data.pendingExpiryDate : (this.requestedExpiryDate || this.currentExpiryDate);
        this.validationRequestState = isNaN(data.validationRequestState) ? data.validationRequestState : Object.values(AbstractAttribute.status)[data.validationRequestState];
    }
}