import { Data } from "../Data";
import { Practical } from "./Practical";

export class participantPracticalList extends Data {

    static instance = null;
    static defaultPageSize = 50;

    constructor(options) {
        const { searchText, pageNumber, pageSize, orderBy, ...otherOptions } = options;
        const apiOptions = {
            api: `/Practical/participant`, callback: data => {
                options.resolve(this, false)
            }, ...otherOptions
        };
        
        // add params for api if provided
        const queryParams = [];
        if (searchText) queryParams.push(`searchText=${searchText}`);
        if (pageNumber) queryParams.push(`pageNumber=${pageNumber}`);
        if (pageSize) queryParams.push(`pageSize=${pageSize || participantPracticalList.defaultPageSize}`);
        if (orderBy) queryParams.push(`orderBy=${orderBy}`);

        if (queryParams.length > 0) {
            apiOptions.api += `?${queryParams.join('&')}`;
        }

        super(apiOptions);

        this.practicals = [];
        this.currentPage = 1;
        this.totalPages = null;
        this.totalCount = null;
        this.hasNext = false;
        this.hasPrevious = false;
    }

    onDataReceived(data) {
        // reformat data from api then assign to state
        const {currentPage, totalPages, totalCount, hasNext, hasPrevious} = data.practicals;
        this.currentPage = currentPage;
        this.totalPages = totalPages;
        this.totalCount = totalCount;
        this.hasNext = hasNext;
        this.hasPrevious = hasPrevious;

        data.practicals.data.forEach((p, index) => {
            const practical = new Practical(p);
            this.practicals.push(practical);
        });
    }

    // static get(context, updateCache) {
    //     return new Promise((resolve, reject) => {
    //         if (instructorPracticalLists.instance && updateCache !== true) resolve(instructorPracticalLists.instance, true);
    //         else instructorPracticalLists.instance = new instructorPracticalLists({ resolve, reject, context });
    //     });
    // }

    static getWithPaging(context, options) {
        const { searchText, pageNumber, pageSize, orderBy } = options;

        return new Promise((resolve, reject) => {
            participantPracticalList.instance = new participantPracticalList({searchText, pageNumber, pageSize, orderBy, resolve, reject, context});
        });
    }
}