import { Document } from "./Document";

export class DocumentList {

    constructor(data) {
        this.list = [];

        data.forEach(d => {
            this.list.push(new Document(d));
        });
    }

    getDocument(id) {
        return this.list.filter(e => e.documentId === id)[0];
    }

    delete(doc) {
        doc.pendingDelete = true;
    }
}