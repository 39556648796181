import { Data } from "../Data";

export class UpdateCreditCard extends Data {

    constructor(options) {
        super({
            api: '/Organization/Admin/Billing/CreditCard', postData: options.details, callback: data => {
                options.resolve(this, false);
            }, ...options
        });
    }

    static post(context, details) {
        return new Promise((resolve, reject) => new UpdateCreditCard({ resolve, reject, context, details, method: 'post' }));
    }
}