import { Link } from "@mui/material";
import { Utils } from "../../common/Utils";
import { DateLabel } from 'klayowebshared';
import { Table } from "./Table";

export class BillingHistoryTable extends Table {

    static columns = {
        date: {
            field: 'date',
            label: 'Date',
        },
        invoice: {
            field: 'invoice',
            label: 'Invoice',
        },
        billedTo: {
            field: 'billedTo',
            label: 'Billed to',
        },
        amount: {
            field: 'amount',
            label: 'Amount',
        },
        paid: {
            field: 'paid',
            label: 'Paid',
        },
        status: {
            field: 'status',
            label: 'Status',
        }
    };

    static defaultProps = {
        ...Table.defaultProps,
        columns: [
            { type: BillingHistoryTable.columns.date },
            { type: BillingHistoryTable.columns.invoice },
            { type: BillingHistoryTable.columns.billedTo },
            { type: BillingHistoryTable.columns.amount },
            { type: BillingHistoryTable.columns.paid },
            { type: BillingHistoryTable.columns.status },
        ],
        noRowsMessage: 'No rows',
        rowHasAction: false,
        selectable: true
    }

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            sortModel: this.props.sortModel ? this.props.sortModel : [{
                field: 'date',
                sort: 'desc',
            }]
        };
    }

    getRowId(a) {
        return this.tableId + '_' + a.invoiceId;
    }

    onClickDownloadLink(e, invoice) {
        const { onClickDownloadLink } = this.props;
        if(onClickDownloadLink) onClickDownloadLink(e, invoice);
    }

    initColumns() {
        const { columns, sortable } = this.props;

        this.columns = [];

        columns.forEach(c => {
            if (this.hasColumn(c)) {

                if (c.type === BillingHistoryTable.columns.date) this.columns.push({
                    field: 'date',
                    headerName: c.label || c.type.label,
                    sortable: sortable,
                    width: 200,
                    filterable: false,
                    disableReorder: true,
                    valueGetter: params => params.row.date,
                    renderCell: params => <DateLabel date={params.row.date} />
                });

                else if (c.type === BillingHistoryTable.columns.invoice) this.columns.push({
                    field: 'invoice',
                    headerName: c.label || c.type.label,
                    sortable: sortable,
                    width: 240,
                    filterable: false,
                    disableReorder: true,
                    valueGetter: params => params.row.invoiceNumber,
                    renderCell: params => <Link className='klayo-billingtable_invoicelink' onClick={e => this.onClickDownloadLink(e, params.row)} target='_blank'>{params.row.invoiceNumber}</Link>
                });

                else if (c.type === BillingHistoryTable.columns.amount) this.columns.push({
                    field: 'amount',
                    headerName: c.label || c.type.label,
                    sortable: sortable,
                    width: 120,
                    filterable: false,
                    disableReorder: true,
                    valueGetter: params => '$'+Utils.formatCurrency(params.row.amount, 2)
                });

                else if (c.type === BillingHistoryTable.columns.paid) this.columns.push({
                    field: 'paid',
                    headerName: c.label || c.type.label,
                    sortable: sortable,
                    width: 120,
                    filterable: false,
                    disableReorder: true,
                    valueGetter: params => '$'+Utils.formatCurrency(params.row.amountPaid, 2)
                });

                else if (c.type === BillingHistoryTable.columns.status) this.columns.push({
                    field: 'status',
                    headerName: c.label || c.type.label,
                    sortable: sortable,
                    width: 150,
                    filterable: false,
                    disableReorder: true,
                    valueGetter: params => params.row.status.label
                });
            }
        });
    }
};