import { SettingsEmployee } from "./SettingsEmployee";
import { Data } from "../Data";

export class SettingsGroupMemberList extends Data {
    constructor(options) {
        super({
            api: '/Group/ListEmployeesByJobDefinitionsAndLocations?' + options.params, callback: data => {
                options.resolve(this, false);
            }, ...options
        });

        this.managerMemberOverlap = [];
        this.employees = [];
    }

    onDataReceived(data) {
        data.employees.forEach(e => this.employees.push(new SettingsEmployee(e)));
        data.overlapEmployees.forEach(e => this.managerMemberOverlap.push(new SettingsEmployee(e)));
    }
}