import { SettingsJob } from "./SettingsJob";
import { Data } from "../Data";
import { SettingsEmployee } from "./SettingsEmployee";
import uniqueId from 'lodash.uniqueid';

export class SettingsJobList extends Data {

    static instance = null;
    static defaultPageSize = 100000;

    constructor(options) {
        const { searchText, pageNumber, pageSize, orderBy, ...otherOptions } = options;
        const apiOptions = {
            api: `/JobDefinition/List`, callback: data => {
                options.resolve(this, false)
            }, ...otherOptions
        };
        
        // add params for api if provided
        const queryParams = [];
        if (searchText) queryParams.push(`searchText=${searchText}`);
        if (pageNumber) queryParams.push(`pageNumber=${pageNumber}`);
        if (pageSize) queryParams.push(`pageSize=${pageSize || SettingsJobList.defaultPageSize}`);
        if (orderBy) queryParams.push(`orderBy=${orderBy}`);

        if (queryParams.length > 0) {
            apiOptions.api += `?${queryParams.join('&')}`;
        }

        super(apiOptions);

        this.jobs = [];
        this.positions = [];
        this.currentPage = 1;
        this.totalPages = null;
        this.totalCount = null;
        this.hasNext = false;
        this.hasPrevious = false;
    }

    onDataReceived(data) {
        // reformat data from api then assign to state
        const {currentPage, totalPages, totalCount, hasNext, hasPrevious} = data.jobDefinitions;

        this.currentPage = currentPage;
        this.totalPages = totalPages;
        this.totalCount = totalCount;
        this.hasNext = hasNext;
        this.hasPrevious = hasPrevious;

        data.jobDefinitions.data.forEach((j, index) => {
            const job = new SettingsJob(j);
            job.jobHierarchy = [ job.jobDefinitionId ];
            this.jobs.push(job);

            j.jobs.forEach((position, index) => {
                const employee = new SettingsEmployee(position.employee);
                employee.jobId = position.jobId;
                employee.numberOfTeamMembers = position.numberOfTeamMembers;
                employee.jobHierarchy = [ job.jobDefinitionId, employee.jobId ];
                employee.job = job;
                if(position.employee === null) {
                    employee.rowId = uniqueId('vacancy-');
                    employee.isVacancy = true;
                    employee.fullName = 'Vacancy';
                }

                if(position.manager) employee.setManager(position.manager);
                
                this.positions.push(employee);
            });
        });
    }

    get(id) {
        return this.jobs.find(l => l.id === id || l.jobDefinitionId === id);
    }

    getPosition(id) {
        return this.positions.find(l => l.id === id || l.jobId === id);
    }

    getByName(name) {
        return this.jobs.find(l => l.name === name);
    }

    update(job) {
        const jobs = [...this.jobs];
        const index = jobs.indexOf(this.get(job.jobDefinitionId));
        if (index !== -1) jobs.splice(index, 1, job);
        this.jobs = [...jobs];
    }

    updatePosition(position) {
        const positions = [...this.positions];
        const index = positions.indexOf(this.get(position.jobId));
        if (index !== -1) positions.splice(index, 1, position);
        this.positions = [...positions];
    }

    replacePosition(oldPosition, newPosition) {
        // const positions = [...this.positions];
        const index = this.positions.indexOf(this.getPosition(oldPosition.jobId));

        const employeePosition = this.positions.findIndex((pos) => pos?.employeeId === newPosition.employeeId);
        let updated = null;
        if (employeePosition > -1) {
          updated = this.positions[employeePosition];
          updated.fullName = 'Vacancy';
          updated.email = null;
          updated.firstName = null;
          updated.lastName = null;
          updated.isVacancy = true;
          updated.employeeId = null;
        }

        oldPosition.fullName = newPosition.fullName;
        oldPosition.firstName = newPosition.firstName;
        oldPosition.lastName = newPosition.lastName;
        oldPosition.isVacancy = false;
        oldPosition.hasProfilePicture = newPosition.hasProfilePicture;
        
        oldPosition.employeeId = newPosition.employeeId; //

        if (index !== -1) this.positions.splice(index, 1, oldPosition);
        return [updated, oldPosition]
    }

    vacatePosition(position) {
        const positions = this.positions;
        const index = positions.indexOf(this.get(position.jobId));

        position.fullName = 'Vacancy';
        position.email = null;
        position.firstName = null;
        position.lastName = null;
        position.isVacancy = true;
        position.employeeId = null;

        if (index !== -1) positions.splice(index, 1, position);

    }

    updatePositionCount() {
        this.jobs.forEach(j => j.numberOfPositions = this.positions.filter(p => p.job.jobDefinitionId === j.jobDefinitionId/* && p.isDummy !== true*/).length);
    }

    /*addDummyPosition(job) {
        const dummy = new SettingsEmployee();
        dummy.jobId = uniqueId('Dummy ');
        dummy.jobHierarchy = [ job.jobDefinitionId, dummy.jobId ];
        dummy.job = job;
        dummy.isDummy = true;
        dummy.fullName = dummy.jobId;
        this.addPosition(dummy);
        job.hasDummy = true;
    }

    deleteDummyPosition(job) {
        this.positions = this.positions.filter(p => !(p.job.jobDefinitionId === job.jobDefinitionId && p.isDummy));
        job.hasDummy = false;
    }*/

    add(job) {
        this.jobs = [...this.jobs];
        this.jobs.push(job);
    }

    addPosition(position) {
        this.positions = [...this.positions];
        this.positions.push(position);
    }

    addVacancy(jobId, vacancyDto) {
        const job = this.get(jobId);
        const vacancy = SettingsJobList.createVacancy();
        vacancy.id = vacancyDto.job.jobId;
        vacancy.jobId = vacancyDto.job.jobId;
        vacancy.jobHierarchy = [ job.jobDefinitionId, vacancy.jobId ];
        vacancy.job = new SettingsJob(vacancyDto.job);
        this.addPosition(vacancy);
        this.updatePositionCount();
        return [vacancy, job]; //
    }

    static createVacancy() {
        const vacancy = new SettingsEmployee();
        vacancy.rowId = uniqueId('vacancy-');
        vacancy.fullName = 'Vacancy';
        vacancy.isVacancy = true;
        return vacancy;
    }

    delete(job) {
        const jobs = [...this.jobs];
        const index = jobs.indexOf(job);
        if (index !== -1) jobs.splice(index, 1);
        this.jobs = [...jobs];
    }

    deletePosition(position) {
        const positions = [...this.positions];
        const findIndex = positions?.findIndex(p => p?.rowId === position?.rowId);
        if (findIndex > -1) positions.splice(findIndex, 1);
        this.positions = [...positions];
        this.updatePositionCount();
    }

    static get(context, updateCache) {
        return new Promise((resolve, reject) => {
            if (SettingsJobList.instance && updateCache !== true) resolve(SettingsJobList.instance, true);
            else SettingsJobList.instance = new SettingsJobList({ resolve, reject, context });
        });
    }

    static getWithPaging(context, options) {
        const { searchText, pageNumber, pageSize, orderBy } = options;

        return new Promise((resolve, reject) => {
            SettingsJobList.instance = new SettingsJobList({searchText, pageNumber, pageSize, orderBy, resolve, reject, context});
        });
    }
}