import { DialogActions, DialogContent, FormGroup, FormControl, FormControlLabel, Stack, OutlinedInput, InputAdornment, InputLabel, IconButton, Divider } from '@mui/material';
import { AppContext } from '../../common/AppContext';
import { AbstractDialog } from '../../components/AbstractDialog';
import { Utils } from '../../common/Utils';
import { DevComponentsDialog } from './DevComponentsDialog';
import { Button } from '../../components/Button';
import { Dialog } from '../../components/dialogs/Dialog';
import { Tooltip } from '../../components/Tooltip';
import { TextField } from '../../components/TextField';
import { ReactComponent as SwitchUserIcon } from '../../resources/images/icons-user-switch.svg';
import { ReactComponent as CopyIcon } from '../../resources/images/icons-copy.svg';
import { DatePicker } from '../../components/DatePicker';
import { Switch } from 'klayowebshared';
import { Data } from '../../data/Data';
import { Environment } from 'klayowebshared';
import { Alert } from '../../components/Alert';
import { DetailsGrid } from '../../components/DetailsGrid';
import { DetailsGridRow } from '../../components/DetailsGridRow';
import { ControlLabel } from 'klayowebshared';
import { App } from '../../App';

export class DevDialog extends AbstractDialog {

    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            mimicApiDown: Utils.getDevOption('devOpts_MimicApiDown'),
            showDeviceBreakpoints: Utils.getDevOption('devOpts_ShowDeviceBreakpoints'),
            impersonating: props.user && props.user.impersonating,
            showComponents: false,
            newFeatures: Utils.getDevOption('devOpts_NewFeatures'),
            accessToken: null,
            environment: Environment.isProduction ? 'Production' : Environment.isDev ? 'Development' : Environment.isTest ? 'Test' : 'Local'
        };
    }

    componentDidMount() {
        if (!this.props.user) Data.getAccessToken(this.context)
            .then(accessToken => this.setState({ accessToken }))
            .catch(error => {
            });
    }

    onShowToken() {
        alert('onShowToken');
    }

    onSetMimicApiDown(e, value) {
        Utils.setDevOption('devOpts_MimicApiDown', value);
        this.setState({ mimicApiDown: value }, s => window.location.reload());
    }

    onSetShowDeviceBreakpoints(e, value) {
        Utils.setDevOption('devOpts_ShowDeviceBreakpoints', value);
        this.setState({ showDeviceBreakpoints: value }, s => window.location.reload());
    }

    onSetNewFeatures(e, value) {
        Utils.setDevOption('devOpts_NewFeatures', value);
        this.setState({ newFeatures: value }, s => window.location.reload());
    }

    onImpersonatingClick(e) {
        const { onSwitchUser } = this.props;
        const { impersonating } = this.state;

        this.setLoading('switching', true);

        this.setState({ impersonating: !impersonating }, s => {
            if (!impersonating) this.props.onShowSwitchUserDialog(e);
            else {
                onSwitchUser(e, null);
                this.setLoading('switching', false);
            }
        });
    }

    onCopyAccessToken() {
        navigator.clipboard.writeText(this.props.user.accessToken);
    }

    onCopyUserEmail() {
        navigator.clipboard.writeText(this.context.msal.accounts[0].username);
    }

    onCopyEnvironment() {
        navigator.clipboard.writeText(this.state.environment);
    }

    onCopyVersion() {
        navigator.clipboard.writeText(process.env.REACT_APP_VERSION);
    }

    onCopyLoginDomain() {
        navigator.clipboard.writeText(this.context.msal.accounts[0].environment);
    }

    onCopyDetails() {
        const { organization, user } = this.props;
        navigator.clipboard.writeText(App.getTestDetails(organization, user));
    }

    onShowComponents() {
        this.setState({ showComponents: true });
    }

    onCloseComponents() {
        this.setState({ showComponents: false });
    }

    render() {
        const { user, theme, onClose } = this.props;
        const { mimicApiDown, impersonating, showComponents, showDeviceBreakpoints, newFeatures, accessToken, environment } = this.state;

        let account = null;
        let tokenExp = null;
        if (this.context.msal) {
            account = this.context.msal.accounts[0];

            tokenExp = new Date(0);
            tokenExp.setUTCSeconds(account.idTokenClaims.exp);
        }

        return (
            <Dialog
                title='Developer settings'
                theme={theme}
                open={true}
                onClose={onClose}
                fullWidth
                maxWidth='sm'
                actions={[{
                    label: 'Close',
                    variant: 'filled',
                    onClick: onClose
                }]}>
                {this.renderLoadingBar()}

                <Stack direction="column" spacing={4} sx={{ marginTop: '10px' }}>

                    <Alert severity='info' sx={{ marginTop: '-16px' }}>These options are available {user && user.isDeveloper ? <span>because the user has <b>developer permissions</b></span> : <span>in <b>development environment</b></span>}</Alert>

                    <Stack direction='row' justifyContent='space-between' spacing={2}>
                        <TextField
                            label='App version'
                            clearable={false}
                            readonly={true}
                            value={process.env.REACT_APP_VERSION}
                            endAdornment={
                                <InputAdornment position="end">
                                    <Button
                                        onClick={this.onCopyVersion.bind(this)}
                                        edge="end"
                                        tooltip='Copy version'
                                        icon={<CopyIcon />}
                                    />
                                </InputAdornment>} />

                        <TextField
                            label='Environment'
                            clearable={false}
                            value={environment}
                            endAdornment={
                                <InputAdornment position="end">
                                    <Button
                                        onClick={this.onCopyEnvironment.bind(this)}
                                        edge="end"
                                        tooltip='Copy environment'
                                        icon={<CopyIcon />}
                                    />
                                </InputAdornment>}
                            mx={{ width: '240px' }}
                        />
                    </Stack>

                    <Divider />


                    {account && <Stack direction='row' justifyContent='space-between' spacing={2}>
                        <TextField
                            label='User email'
                            clearable={false}
                            value={account.username}
                            endAdornment={
                                <InputAdornment position="end">
                                    <Button
                                        onClick={this.onCopyUserEmail.bind(this)}
                                        edge="end"
                                        tooltip='Copy email'
                                        icon={<CopyIcon />}
                                    />
                                </InputAdornment>}
                        />

                        <TextField
                            label='Login domain'
                            clearable={false}
                            value={account.environment}
                            readonly={true}
                            endAdornment={
                                <InputAdornment position="end">
                                    <Button
                                        onClick={this.onCopyLoginDomain.bind(this)}
                                        edge="end"
                                        tooltip='Copy login domain'
                                        icon={<CopyIcon />}
                                    />
                                </InputAdornment>}
                        />

                    </Stack>}
                    
                    <Button
                        label='Copy to clipboard'
                        size='md'
                        startIcon={impersonating ? null : <SwitchUserIcon />}
                        disabled={!user}
                        onClick={this.onCopyDetails.bind(this)} />

                    <Stack direction='row' justifyContent='space-between' spacing={2}>
                        <TextField
                            label='Access token'
                            clearable={false}
                            value={user ? user.accessToken : '' + accessToken}
                            readonly={true}
                            endAdornment={
                                <InputAdornment position="end">
                                    <Button
                                        onClick={this.onCopyAccessToken.bind(this)}
                                        edge="end"
                                        tooltip='Copy access token'
                                        icon={<CopyIcon />}
                                    />
                                </InputAdornment>}
                        />

                        <DatePicker
                            label='Expires'
                            theme={theme}
                            value={tokenExp}
                            inputFormat='yyy-MM-dd hh:mm:ss'
                            readOnly={true}
                            mx={{ width: '240px' }}
                        />
                    </Stack>

                    <Divider />

                    <Stack direction='column' spacing={2}>
                        {impersonating && <Alert severity='warning'><div>Impersonating: {user.fullName}, {user.loggedInUser.basicInformation.organizationName}</div></Alert>}

                        <Button
                            label={impersonating ? 'Stop impersonating ' : 'Switch user'}
                            size='md'
                            startIcon={impersonating ? null : <SwitchUserIcon />}
                            disabled={!impersonating && user && !user.canChangeUser}
                            onClick={this.onImpersonatingClick.bind(this)} />
                    </Stack>

                    <Stack direction='row' spacing={6} sx={{ display: 'none' }}>
                        <div>
                            <b>Logged in user</b>
                            <DetailsGrid spacing={2}>
                                <DetailsGridRow label='Name' fontSize='14px'>
                                    <TextField
                                        value={user.loggedInUser.basicInformation.firstName}
                                        dense={true}
                                        clearable={false} />
                                </DetailsGridRow>
                                <DetailsGridRow label='Can change user?' fontSize='14px'>{user && user.loggedInUser.permissions.canChangeUser ? 'true' : 'false'}</DetailsGridRow>
                                <DetailsGridRow label='Can change org?' fontSize='14px'>{user && user.loggedInUser.permissions.canChangeOrg ? 'true' : 'false'}</DetailsGridRow>
                                <DetailsGridRow label='Admin user?' fontSize='14px'>{user && user.loggedInUser.permissions.isOrganizationAdmin ? 'true' : 'false'}</DetailsGridRow>
                                <DetailsGridRow label='Billing user?' fontSize='14px'>{user && user.loggedInUser.permissions.isBillingUser ? 'true' : 'false'}</DetailsGridRow>
                            </DetailsGrid>
                        </div>
                        <div>
                            <b>Active user</b>
                            {impersonating &&
                                <DetailsGrid spacing={2}>
                                    <DetailsGridRow label='Name' fontSize='14px'>
                                        <TextField
                                            value={user.fullName}
                                            dense={true}
                                            clearable={false} />
                                    </DetailsGridRow>
                                    <DetailsGridRow label='Can change user?' fontSize='14px'>{user && user.activeUser.permissions.canChangeUser ? 'true' : 'false'}</DetailsGridRow>
                                    <DetailsGridRow label='Can change org?' fontSize='14px'>{user && user.activeUser.permissions.canChangeOrg ? 'true' : 'false'}</DetailsGridRow>
                                    <DetailsGridRow label='Admin user?' fontSize='14px'>{user && user.activeUser.permissions.isOrganizationAdmin ? 'true' : 'false'}</DetailsGridRow>
                                    <DetailsGridRow label='Billing user?' fontSize='14px'>{user && user.activeUser.permissions.isBillingUser ? 'true' : 'false'}</DetailsGridRow>
                                </DetailsGrid>}
                            <Button
                                label={impersonating ? 'Stop impersonating ' : 'Impersonate a user'}
                                size='md'
                                startIcon={impersonating ? null : <SwitchUserIcon />}
                                disabled={!impersonating && user && !user.canChangeUser}
                                onClick={this.onImpersonatingClick.bind(this)} />
                        </div>
                    </Stack>

                    <Divider />

                    <Switch label="Enable 1.1 features"
                        checked={newFeatures}
                        onChange={this.onSetNewFeatures.bind(this)}
                        sx={{ display: 'none' }} />

                    <Switch label="Mimic API Down"
                        checked={mimicApiDown}
                        onChange={this.onSetMimicApiDown.bind(this)} />

                    <Switch
                        label='Show device breakpoints'
                        checked={showDeviceBreakpoints}
                        onChange={this.onSetShowDeviceBreakpoints.bind(this)} />

                    {1 === 2 && <Button label='View components' sx={{ placeSelf: 'start' }} onClick={this.onShowComponents.bind(this)} />}
                </Stack>
                {showComponents && <DevComponentsDialog onClose={this.onCloseComponents.bind(this)} />}
            </Dialog >
        )
    }
}