import { Table } from "./Table";
import { ReactComponent as MoreVertIcon } from '../../resources/images/icons-more-vertical.svg';
import { DataGridPro, GridActionsCellItem } from '@mui/x-data-grid-pro';
import { SettingsGroup } from "../../data/settings/SettingsGroup";

export class GroupsTable extends Table {

    static columns = {
        name: {
            field: 'name',
            label: 'Group',
        },
        memberCount: {
            field: 'memberCount',
            label: 'Employees',
        },
        managerCount: {
            field: 'managerCount',
            label: 'Access',
        },
        actions: {
            field: 'actions',
            label: 'Actions',
        },
    };

    static defaultProps = {
        ...Table.defaultProps,
        columns: [
            { type: GroupsTable.columns.name },
            { type: GroupsTable.columns.actions }
        ],
        noRowsMessage: 'Click the New group button to add a group',
        rowHasAction: null
    }

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            sortModel: this.props.sortModel ? this.props.sortModel : [{
                field: 'name',
                sort: 'asc',
            }]
        };
    }

    getRowId(g) {
        return this.tableId + '_' + g.groupId;
    }

    onRowClick(params, e) {
        const { onGroupSelect } = this.props;
        if (onGroupSelect) onGroupSelect(e, params.row);
    }

    initColumns() {
        const { columns, sortable, onGroupAction, disabled } = this.props;

        this.columns = [];

        columns.forEach(c => {
            if (this.hasColumn(c)) {

                if (c.type === GroupsTable.columns.name) this.columns.push({
                    field: 'name',
                    sortable: sortable,
                    headerName: c.label || c.type.label,
                    flex: true,
                    filterable: false,
                    disableReorder: true,
                    valueGetter: params => params.row.name + (params.row.memberSelectionType === SettingsGroup.memberSelectionType.ruleBased ? ' (' + params.row.memberSelectionType.name.toLowerCase() + ')' : ''),
                });
            }

            if (this.hasColumn(c)) {
                if (c.type === GroupsTable.columns.memberCount) this.columns.push({
                    field: 'memberCount',
                    sortable: sortable,
                    headerName: c.label || c.type.label,
                    filterable: false,
                    disableReorder: true,
                    width: 120,
                    type: 'number',
                    valueGetter: params => params.row.numberOfEmployees,
                });
            }

            if (this.hasColumn(c)) {
                if (c.type === GroupsTable.columns.managerCount) this.columns.push({
                    field: 'managerCount',
                    sortable: sortable,
                    headerName: c.label || c.type.label,
                    filterable: false,
                    disableReorder: true,
                    width: 120,
                    type: 'number',
                    valueGetter: params => params.row.numberOfManagers,
                });
            }

            if (c.type === GroupsTable.columns.actions) this.columns.push({
                field: 'actions',
                type: 'actions',
                filterable: false,
                disableReorder: true,
                align: 'right',
                getActions: params => [
                    <GridActionsCellItem
                        icon={<MoreVertIcon />}
                        disabled={disabled}
                        onClick={e => this.props.onGroupAction(e, params.row)}
                    />
                ]
            });
        });
    }
};