import { React, Component } from 'react';
import { SelectMenu } from '../SelectMenu';
import { Button } from '../Button';
import { ReactComponent as DeleteIcon } from '../../resources/images/icons-delete.svg';
import { ReactComponent as AddIcon } from '../../resources/images/icons-add.svg';
import { Box, Stack } from '@mui/material';

export class RepeatingSelect extends Component {

    static defaultProps = {
        maxRows: 4
    }

    constructor(props) {
        super(props);

        this.state = {
            values: props.values && props.values.length !== 0 ? props.values : [props.defaultItem]
        };
    }

    onAddRow() {
        const { defaultItem, onChange } = this.props;
        const { values } = this.state;

        values.push(defaultItem);
        this.setState({ values });

        if (onChange) onChange(values);
    }

    updateValue(index, newValue) {
        const { values } = this.state;
        values[index] = newValue;
        this.forceUpdate();
    }

    onDeleteRow(index) {
        const { onChange } = this.props;
        const { values } = this.state;

        values.splice(index, 1);
        this.forceUpdate();

        if (onChange) onChange(values);
    }

    onRowChange(index, newValue) {
        const { onChange } = this.props;
        const { values } = this.state;

        this.updateValue(index, newValue);

        if (onChange) onChange(values);
    }

    renderRow(value, index) {
        const { theme, defaultItem, items, maxRows, itemComparator, selectSx } = this.props;
        const { values } = this.state;

        return <Stack
            key={index}
            className='klayo-repeatingselector_row'
            direction='row'
            spacing={1}
            sx={{ height: { xs: '66px', md: '42px' } }}>

            {theme.isBreakpointUp('md') &&
                <Box
                    className='klayo-repeatingselector_row_label'
                    sx={{
                        display: { xs: 'none', md: 'block' }
                    }}>Notification</Box>
            }

            <SelectMenu
                value={value}
                defaultValue={defaultItem}
                clearable={false}
                label={theme.isBreakpointDown('md') ? 'Notification' : null}
                //firstValueDefault={true}
                //dense={true}
                items={Object.entries(items)}   //.filter((i1, key) => itemComparator ? !values.find(i2 => itemComparator(i1[1], i2)) : true)
                onChange={e => this.onRowChange(index, e.target.value)}
                disableItem={i1 => values.find(i2 => itemComparator(i1, i2))}
                sx={{ width: '100%', minWidth: '150px', ...selectSx }} />

            <Stack
                direction='row'
                spacing={1}
                alignItems='center'
                sx={{ paddingTop: { xs: '24px', md: '0' } }}>

                {values.length > 1 && <Button
                    onClick={e => this.onDeleteRow(index)}
                    size='sm'
                    icon={<DeleteIcon />} />}

                {index === values.length - 1 && value !== defaultItem &&
                    <Button
                        onClick={this.onAddRow.bind(this)}
                        disabled={index >= maxRows - 1}
                        size='sm'
                        icon={<AddIcon />} />}
            </Stack>
        </Stack>
    }

    render() {
        const { defaultItem, sx } = this.props;
        const { values } = this.state;

        return (
            <Stack
                className='klayo-repeatingselector'
                direction='column'
                spacing={{ xs: 3, md: 4 }}
                sx={sx}>
                {Object.values(values).map((v, index) => this.renderRow(v, index))}
            </Stack>

        );

    }
}