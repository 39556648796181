import { DataGridPro } from '@mui/x-data-grid-pro';
import { React, Component } from 'react';
import { SectionLoader } from './SectionLoader';
import { DateLabel } from 'klayowebshared';
import { Stack, List, ListItem, ListItemText } from '@mui/material';
import { ReactComponent as BellIcon } from '../resources/images/icons-bell.svg';
import { Placeholder } from './Placeholder';

export class NotificationList extends Component {

    static defaultProps = {
        stackedLayout: true,
        noUpdatesMessage: 'No updates yet!'
    }

    onNotificationClick(e, notif) {
        if (!notif.isRead()) {
            this.props.notifications.setAsRead(notif, false);
        }

        if (this.props.onNotificationClick) this.props.onNotificationClick(e, notif);
    }

    renderTable() {
        const { user, notifications, noUpdatesMessage } = this.props;

        const columns = [{
            field: 'name',
            sortable: false,
            filterable: false,
            disableReorder: true,
            headerName: 'Summary',
            flex: 1,
            renderCell: params => (<div>
                <div className='klaro_notificationlist_primarytext' style={{ fontWeight: params.row.isRead() ? 400 : 500 }}>{params.row.primaryText}</div>
                <div className='klaro_notificationlist_secondarytext'>{params.row.secondaryText}</div>
            </div>),
        }, {
            field: 'date',
            sortable: false, filterable: false,
            disableReorder: true,
            headerName: 'Date',
            align: 'right',
            renderCell: params => {
                return (<DateLabel date={params.row.date} />);
            },
            width: 150
        }];

        return !user || !notifications ?
            <SectionLoader />
            : notifications && notifications.items.length > 0 ? <DataGridPro
                rows={notifications !== null ? notifications.items : []}
                className='klaro_notificationlist'
                columns={columns}
                pageSize={4}
                columnHeaderHeight={0}
                hideFooter={true}
                pagination={false}
                disableMultipleRowSelection={true}
                disableRowSelectionOnClick={true}
                hideFooterRowCount={true}
                autoHeight={true}
                onRowClick={(params, e) => this.onNotificationClick(e, params.row)}
                components={{
                    NoRowsOverlay: () => (
                        <Stack height='200px' alignItems='center' justifyContent='center'>
                            {noUpdatesMessage}
                        </Stack>
                    )
                }} /> : <Placeholder text='There are no notifications to show' />;
    }

    renderList() {
        const { user, notifications, group, sx } = this.props;
        const items = notifications ? group ? notifications.items.filter(n => n.groupId === group.groupId || (n.alsoInOtherGroupIds && n.alsoInOtherGroupIds.includes(group.groupId))) : notifications.items : null;

        return !user || !notifications ?
            <SectionLoader />
            : notifications && items.length > 0 ?
                <List sx={{ padding: { xs: '0 8px!important', sm: '0 8px!important', md: '0 16px!important' }, ...sx }}>
                    {items.map((notif, index) => (
                        <ListItem key={index} alignItems="flex-start" sx={{ cursor: 'pointer' }} onClick={e => this.onNotificationClick(e, notif)}>
                            <ListItemText
                                disableTypography={true}
                                primary={
                                    <Stack>
                                        <div className='klaro_notificationlist_date'><DateLabel date={notif.date} /></div>
                                        <div className='klaro_notificationlist_primarytext' style={{ fontWeight: notif.isRead() ? 400 : 600 }}>{notif.primaryText}</div>
                                    </Stack>
                                }
                                secondary={<div className='klaro_notificationlist_secondarytext'>{notif.secondaryText} </div>} />
                        </ListItem>
                    ))}
                </List>
                : <Placeholder icon={<BellIcon />} text='There are no notifications to show' />
    }

    render() {
        return this.props.stackedLayout ? this.renderList() : this.renderTable();
    }
}