import { Component } from "react";
import { Paper } from '../../components/Paper';
import { Link } from "react-router-dom";
import { Dialog, Box, AppBar, Toolbar, DialogContent, Stack, Divider, Zoom } from "@mui/material";
import { ViewComponent } from "../../components/ViewComponent";
import { AppContext } from '../../common/AppContext';
import { Button } from "../../components/Button";
import { Alert } from "../../components/Alert";
import { ReactComponent as CloseIcon } from '../../resources/images/icons-close.svg';
import { ReactComponent as BackIcon } from '../../resources/images/icons-arrow-left.svg';
import { ReactComponent as Logo } from '../../resources/images/logo.svg';
import { BillingGroupCard } from "../../components/BillingGroupCard";
import { BillingPlan } from "../../data/billing/BillingPlan";
import { PillButtons } from "../../components/PillButtons";
import { CreditCardPayment } from "./CreditCardPayment";
import { InvoicePayment } from "./InvoicePayment";
import { Utils } from "../../common/Utils";
import { ErrorModel } from "../../common/models/ErrorModel";
import { SubmitBilling } from "../../data/settings/SubmitBilling";
import { BillingSelectionCard } from "../../components/BillingSelectionCard";
import { BillingContact } from "./BillingContact";
import { BillingDetails } from "./BillingDetails";
import { ReactComponent as ClapIcon } from '../../resources/images/icons-clap.svg';
import { ConfirmationBox } from "../../components/ConfirmationBox";
import { EwayField } from "../../components/billing/EwayField";

export class BillingPlansDialog extends ViewComponent {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.existingGroup = this.getExistingGroup(props.billingPlans, props.existingBillingDetails);

        this.state = {
            selectedGroup: null,
            selectedPlan: null,
            step: 0,
            paymentMethod: props.existingBillingDetails && props.existingBillingDetails.paymentMethod !== BillingPlan.paymentMethod.none ? props.existingBillingDetails.paymentMethod : BillingPlan.paymentMethod.creditCard,
            billingCycle: props.existingBillingDetails && props.existingBillingDetails.cycle !== BillingPlan.cycle.none ? props.existingBillingDetails.cycle : BillingPlan.cycle.monthly,
            error: null,
            submitting: false,
            submitted: false,
            billingContact: {},
            billingDetails: {},
            isNewPlan: this.isNewPlan(props.existingBillingDetails),
            allowChange: props.existingBillingDetails ? !props.existingBillingDetails.isCustomPlan : true,
            creditCardDetails: {},
            ewaySecureFieldCode: null,
            creditCardAttemptId: 0
        };
    }

    componentDidMount() {
        const { onLoadBillingPlans, onLoadBillingDetails } = this.props;
        onLoadBillingPlans();
        onLoadBillingDetails();
    }

    componentDidUpdate(prevProps, prevState) {
        super.componentDidUpdate(prevProps, prevState);

        if (this.props.billingPlans !== prevProps.billingPlans || this.props.existingBillingDetails !== prevProps.existingBillingDetails) {

            if (this.props.existingBillingDetails && this.props.billingPlans) {
                this.existingGroup = this.getExistingGroup(this.props.billingPlans, this.props.existingBillingDetails);
                this.setState({ isNewPlan: this.isNewPlan(this.props.existingBillingDetails) });
            }
        }
    }

    isNewPlan(existingBillingDetails) {
        if (!existingBillingDetails) return true;
        const { organization } = this.props;
        if (!organization) return true;

        return (/*organization.isTrial || */existingBillingDetails.cycle === BillingPlan.cycle.none || existingBillingDetails.paymentMethod === BillingPlan.paymentMethod.none) === true;
    }

    getExistingGroup(billingPlans, existingBillingDetails) {
        if (!billingPlans || !existingBillingDetails) return null;
        return billingPlans.groups.find(g => g.planGroupId === existingBillingDetails.planGroupId);
    }

    onGroupSelect(e, selectedGroup) {
        const { onContactUs } = this.props;
        const { paymentMethod, billingCycle } = this.state;

        if (selectedGroup.isCustom) onContactUs(e, '/settings/billing');
        else this.setState({ selectedGroup, selectedPlan: selectedGroup.getPlan(paymentMethod, billingCycle), error: null });
    }

    onNextStep(e) {
        const { isNewPlan, step, billingContact, billingDetails } = this.state;
        if (isNewPlan) {
            if(step === 1) {
                if (!this.validateBillingContactDetails(billingContact)) return false;
                if (!this.validateBillingDetails(billingDetails)) return false;
            }
            this.setState({ step: step + 1, error: null });
        }
        else this.onSubmit(e);
    }

    onPrevStep(e) {
        const { step } = this.state;
        this.setState({ step: step - 1, error: null});
    }

    onPaymentMethodChange(e, paymentMethod) {
        const { selectedGroup, billingCycle } = this.state;
        const newBillingCycle = paymentMethod === BillingPlan.paymentMethod.creditCard ? BillingPlan.cycle.monthly : billingCycle === BillingPlan.cycle.monthly ? BillingPlan.cycle.quarterly : billingCycle;
        this.setState({ paymentMethod, selectedPlan: selectedGroup ? selectedGroup.getPlan(paymentMethod, newBillingCycle) : null, billingCycle: newBillingCycle, error: null });
    }

    onBillingCycleChange(e, billingCycle) {
        const { selectedGroup, paymentMethod } = this.state;
        this.setState({ billingCycle, selectedPlan: selectedGroup ? selectedGroup.getPlan(paymentMethod, billingCycle) : null });
    }

    onResetGroup(e) {
        this.setState({ selectedGroup: null, selectedPlan: null, step: 0, error: null });
    }

    onBillingContactChange(e, billingContact) {
        this.setState({ billingContact });
    }

    onBillingDetailsChange(e, billingDetails) {
        this.setState({ billingDetails });
    }

    onCreditCardChange(creditCardDetails, ewaySecureFieldCode) {
        this.setState({ creditCardDetails, ewaySecureFieldCode });
    }

    unBlurAndSubmit(e) {
        //Eqay fields only fire event callback on blur
        setTimeout(t => this.onSubmit(e), 500);
    }

    onSubmit(e) {
        const { isNewPlan, selectedPlan, billingContact, billingDetails, creditCardDetails, ewaySecureFieldCode, paymentMethod } = this.state;

        if (isNewPlan) {
            if (paymentMethod === BillingPlan.paymentMethod.invoice && !this.validateInvoiceDetails({ billingContact, billingDetails })) return false;
            if (paymentMethod === BillingPlan.paymentMethod.creditCard) {
                if (!this.validateCreditCardDetails({ creditCardDetails, billingContact, billingDetails })) return false;
            }
        }

        this.context.setLoading('submitting', true);
        this.setState({ error: null, submitting: true });

        if (isNewPlan) {
            SubmitBilling.post(this.context, {
                billingPlanId: selectedPlan.billingPlanId,
                securedFieldsCode: ewaySecureFieldCode,
                contactFirstName: billingContact.firstName,
                contactLastName: billingContact.lastName,
                contactEmail: billingContact.email,
                contactPhone: billingContact.phone,
                companyName: billingDetails.company,
                street1: billingDetails.billingAddress,
                country: billingDetails.country.name,
                city: billingDetails.city,
                state: billingDetails.state,
                postalCode: billingDetails.zipCode
            })
                .then(n => { this.setState({ error: null, submitted: true }); })
                .catch(e => {
                    const error = new ErrorModel(ErrorModel.Severity.FAIL, e);
                    let newState = { error: error.message };
                    if (error.details && error.details.creditCardTransactionAttempted) newState = { ...newState, creditCardDetails: {}, creditCardAttemptId: Utils.generateUniqueId() };
                    this.setState(newState);
                })
                .finally(() => {
                    this.context.setLoading('submitting', false);
                    this.setState({ submitting: false });
                });
        }
        else {
            SubmitBilling.put(this.context, {
                billingPlanId: selectedPlan.billingPlanId
            })
                .then(n => { this.setState({ error: null, submitted: true }); })
                .catch(e => {
                    const error = ErrorModel.parseServerError(e);
                    this.setState({ error, creditCardAttemptId: Utils.generateUniqueId() });
                })
                .finally(() => {
                    this.context.setLoading('submitting', false);
                    this.setState({ submitting: false });
                });
        }
    }

    validateInvoiceDetails(invoiceDetails) {
        const { billingContact, billingDetails } = invoiceDetails;
        if (!this.validateBillingContactDetails(billingContact)) return false;
        if (!this.validateBillingDetails(billingDetails)) return false;
        return true;
    }

    validateBillingContactDetails(billingContact) {
        if (!billingContact.firstName) { this.setError('Please enter a first name.'); return false }
        else if (!billingContact.lastName) { this.setError('Please enter a last name.'); return false }
        else if (!Utils.isValidEmail(billingContact.email)) { this.setError('Please enter an email address.'); return false }
        else if (!billingContact.phone) { this.setError('Please enter a phone number.'); return false }
        return true;
    }

    validateBillingDetails(billingDetails) {
        if (!billingDetails.company) { this.setError('Please enter a company name.'); return false }
        else if (!billingDetails.billingAddress) { this.setError('Please enter a billing address.'); return false }
        else if (!billingDetails.country) { this.setError('Please enter a country.'); return false }
        else if (!billingDetails.city) { this.setError('Please enter a city.'); return false }
        else if (!billingDetails.state) { this.setError('Please enter a state.'); return false }
        else if (!billingDetails.zipCode) { this.setError('Please enter a zip code.'); return false }
        return true;
    }

    validateCreditCardDetails(ccDetails) {
        const { creditCardDetails, billingContact, billingDetails } = ccDetails;
        const { nameValid, cardValid, expiryValid, cvcValid } = creditCardDetails;

        if (nameValid !== true) { this.setError('Please enter the name on the credit card.'); return false }
        if (cardValid !== true) { this.setError('Please enter a valid card number.'); return false }
        if (expiryValid !== true) { this.setError('Please enter a valid card expiry.'); return false }
        if (cvcValid !== true) { this.setError('Please enter a valid CVC / CVV.'); return false }
        if (!this.validateBillingContactDetails(billingContact)) return false;
        if (!this.validateBillingDetails(billingDetails)) return false;
        return true;
    }

    setError(error) {
        this.setState({ error });
        if (error) window.scrollTo(0, 0);
    }

    render() {
        const { organization, user, billingPlans, onClose } = this.props;
        const { selectedGroup, selectedPlan, step, paymentMethod, billingCycle, error, submitting, submitted, isNewPlan, allowChange, creditCardAttemptId, billingContact, billingDetails } = this.state;

        return <Dialog
            className='klayo-billingplandialog'
            onClose={onClose}
            open={true}
            fullScreen={true}>
            <AppBar
                className='klaro_employeeprofile-appbar'
                elevation={0}>
                <Toolbar>
                    <Logo className="klaro-appbar_logo" alt="Klayo Logo" />
                    <Box sx={{ flexGrow: 1 }}>
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Button
                            autoFocus
                            label='Close'
                            variant='outlined'
                            size='md'
                            startIcon={<CloseIcon />}
                            onClick={onClose} />
                    </Box>
                </Toolbar>
            </AppBar>
            <DialogContent sx={{ marginTop: '64px', backgroundColor: '#F8F8FE' }}>
                <div className='klayo-billingplandialog_content'>

                    {submitted &&
                        <ConfirmationBox
                            icon={<ClapIcon />}
                            heading='You’re set!'
                            message='Your account is ready for action'
                            action='Close'
                            onAction={onClose} />}

                    {!submitted &&
                        <div>
                            {(step === 0 || selectedGroup === null) &&
                                <Stack direction='column' spacing={2}>
                                    <h1>Choose the perfect plan for you</h1>

                                    {error && <Alert severity='error' scrollTo={true}>{error}</Alert>}

                                    <h2>Our plans</h2>
                                    <Stack direction='column' spacing={5} sx={{ marginTop: '30px!important' }}>

                                        {billingPlans && billingPlans.groups.filter(g => g.name !== 'Enterprise').map((group, key) =>
                                            <BillingGroupCard
                                                key={key}
                                                group={group}
                                                selected={selectedGroup === group}
                                                existing={this.existingGroup && this.existingGroup.planGroupId === group.planGroupId}
                                                disabled={!allowChange || this.existingGroup && this.existingGroup.planGroupId === group.planGroupId || !group.isAllowed}
                                                onClick={this.onGroupSelect.bind(this)} />
                                        )}

                                        <div style={{ textAlign: 'center' }}>
                                            <Button
                                                label='Choose plan'
                                                variant='filled'
                                                disabled={selectedGroup === null || !allowChange}
                                                onClick={this.onNextStep.bind(this)} />
                                        </div>
                                    </Stack>

                                    <div>
                                        <Divider sx={{ margin: '40px 0' }} />
                                        <h2>Need more?</h2>
                                        {billingPlans &&
                                            <BillingGroupCard
                                                group={billingPlans.enterprisePlan}
                                                onClick={this.onGroupSelect.bind(this)} />
                                        }
                                    </div>
                                </Stack>
                            }

                            {step === 1 && selectedGroup && selectedGroup !== BillingPlan.group.enterprise &&
                                <Stack direction='column' spacing={4}>
                                    <h1>Billing details</h1>
                                    {error && <Alert severity='error' scrollTo={true}>{error}</Alert>}

                                    <BillingContact
                                        user={user}
                                        organization={organization}
                                        billingContact={billingContact}
                                        onChange={this.onBillingContactChange.bind(this)}
                                        disabled={submitting} />

                                    <Divider sx={{ margin: '30px 0 0 0' }} />

                                    <BillingDetails
                                        user={user}
                                        organization={organization}
                                        billingDetails={billingDetails}
                                        onChange={this.onBillingDetailsChange.bind(this)}
                                        disabled={submitting} />

                                    <Stack direction='row' justifyContent='center' spacing={2} sx={{ paddingTop: '32px' }}>
                                        <Button
                                            label='Back'
                                            variant='outlined'
                                            startIcon={<BackIcon />}
                                            onClick={this.onPrevStep.bind(this)}
                                            disabled={submitting} />

                                        <Button
                                            label='Continue'
                                            variant='filled'
                                            onClick={this.onNextStep.bind(this)}
                                            disabled={submitting} />
                                    </Stack>
                                </Stack>
                            }

                            {step === 2 && selectedGroup && selectedGroup !== BillingPlan.group.enterprise &&
                                <Stack direction='column' spacing={4}>
                                    <h1>Select your payment method</h1>
                                    <h2>Payment details</h2>

                                    {error && <Alert severity='error' scrollTo={true}>{error}</Alert>}

                                    {isNewPlan &&
                                        <PillButtons
                                            label='Payment method'
                                            value={paymentMethod}
                                            items={[BillingPlan.paymentMethod.creditCard, BillingPlan.paymentMethod.invoice]}
                                            onChange={this.onPaymentMethodChange.bind(this)}
                                            sx={{ alignSelf: 'flex-start' }} />
                                    }

                                    <CreditCardPayment
                                        onResetGroup={this.onResetGroup.bind(this)}
                                        onChange={this.onCreditCardChange.bind(this)}
                                        disabled={true}
                                        attemptId={creditCardAttemptId}
                                        visible={paymentMethod === BillingPlan.paymentMethod.creditCard} />

                                    <InvoicePayment
                                        onResetGroup={this.onResetGroup.bind(this)}
                                        billingCycle={billingCycle}
                                        onBillingCycleChange={this.onBillingCycleChange.bind(this)}
                                        visible={paymentMethod === BillingPlan.paymentMethod.invoice} />


                                    <Zoom in={true}>
                                        <div>
                                            <BillingSelectionCard
                                                onResetGroup={this.onResetGroup.bind(this)}
                                                group={selectedGroup}
                                                plan={selectedPlan} />
                                        </div>
                                    </Zoom>

                                    <Stack direction='row' justifyContent='center' spacing={2} sx={{ paddingTop: '32px' }}>
                                        <Button
                                            label='Back'
                                            variant='outlined'
                                            startIcon={<BackIcon />}
                                            onClick={this.onPrevStep.bind(this)}
                                            disabled={submitting} />
                                        <Button
                                            label={paymentMethod === BillingPlan.paymentMethod.creditCard ? 'Pay now' : 'Submit request'}
                                            variant='filled'
                                            onClick={paymentMethod === BillingPlan.paymentMethod.creditCard ? this.unBlurAndSubmit.bind(this) : this.onSubmit.bind(this)}
                                            disabled={submitting} />
                                    </Stack>
                                </Stack>}
                        </div>}
                </div>
            </DialogContent>
        </Dialog >
    }
}