import { Component } from "react";
import { FormControl, FormLabel, RadioGroup as MuiRadioGroup, FormControlLabel, Radio, Stack, FormHelperText } from '@mui/material';
import { ReactComponent as CheckedIcon } from '../resources/images/icons-check-circle-fill.svg';

export class RadioGroup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: props.value
        };
    }

    onChange(e) {
        const { onChange } = this.props;
        this.setState({ value: e.target.value });
        if (onChange) onChange(e);
    }

    render() {
        const { items, onChange, label, disabled } = this.props;
        const { value } = this.state;

        return <FormControl className='klayo-radiogroup'>
            <FormLabel>{label}</FormLabel>
            <MuiRadioGroup
                value={value}
                onChange={this.onChange.bind(this)}
            >
                <Stack spacing={1}>
                    {items && items.map(i =>
                        <div className={'klayo-radiogroup_item klayo-radiogroup_item_' + i.value + '=' + value + (value == i.value ? ' klayo-radiogroup_item--selected' : '') + (i.disabled === true || disabled ? ' klayo-radiogroup_item--disabled' : '')}>
                            <FormControlLabel
                                value={i.value}
                                control={<Radio
                                    helperText={i.helperText}
                                    checkedIcon={<CheckedIcon />}
                                    disabled={i.disabled === true || disabled} />}
                                label={<div>
                                    {i.label}
                                    {i.helperText && <FormHelperText disableTypography={true}>{i.helperText}</FormHelperText>}
                                </div>}
                                disableTypography={true} />

                        </div>
                    )}
                </Stack>
            </MuiRadioGroup>
        </FormControl>
    }
};