import React, { Component } from 'react';
import { Box, TextField as MuiTextField, InputAdornment, IconButton, Input, InputLabel } from '@mui/material';
import { TextField } from './TextField';
import { ReactComponent as AttachIcon } from '../resources/images/icons-attachment.svg';
import { ReactComponent as RemoveIcon } from '../resources/images/icons-remove.svg';
import { Tooltip } from './Tooltip';

export class FileUpload extends Component {

    static defaultProps = {
        allowedMimeTypes: ['*/*'],
        maxFileSize: 10240000,
        errorMimeType: 'Please upload a valid file',
        errorFileSize: 'Please upload a valid file',
        hasRemove: false,
        multiple: false
    }

    constructor(props) {
        super(props);

        this.state = {
            file: this.props.file,
            error: null
        };

        this.inputRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.file !== this.props.file) this.setState({ file: this.props.file });
    }

    onChange(e) {
        this.filesChange(e, e.target.files);
    }

    filesChange(e, files) {
        const { onMultipleFilesSelected } = this.props;

        this.fileChange(e, files[0], files.length > 1);
        if(files.length > 1 && onMultipleFilesSelected) onMultipleFilesSelected(e, [...files].slice(1));
    }

    fileChange(e, file, multiple) {
        const { allowedMimeTypes, maxFileSize, errorMimeType, errorFileSize, onFileChange, onError } = this.props;

        if (!allowedMimeTypes.includes(file.type)) {
            this.setState({ error: errorMimeType });
            if(onError) onError(errorMimeType);
            return;
        }

        if (file.size > maxFileSize) {
            this.setState({ error: errorFileSize });
            if(onError) onError(errorFileSize);
            return;
        }

        this.setState({ file: file, error: null });
        if(onError) onError(null);

        if (onFileChange) onFileChange(e, file, multiple);
    }

    selectFile(e) {
        this.inputRef.current.click(e);
    }

    dropFile(e) {
        this.filesChange(e, e.dataTransfer.files);

    }

    render() {
        const { id, className, disabled, label, placeholder, onRemoveFile, allowedMimeTypes, sx, helperText, multiple } = this.props;
        const { file, error } = this.state;

        return (
            <Box className={'klayo-singlefileupload' + (className ? +' ' + className : '')}>
                <Input
                    id={id + '_file'}
                    inputProps={{
                        ref: this.inputRef,
                        accept: allowedMimeTypes.join(', '),
                        multiple
                    }}
                    onChange={this.onChange.bind(this)}
                    type="file"
                    value=''
                    style={{ display: 'none' }}
                />

                <TextField
                    id={id}
                    placeholder={placeholder}
                    label={label}
                    disabled={disabled}
                    autoComplete={false}
                    sx={sx}
                    helperText={error || helperText}
                    error={error !== null}
                    value={file ? file.name : ''}
                    endAdornment={(
                        <InputAdornment position="end">
                            <InputLabel htmlFor={id + '_file'}>
                                <Tooltip title="Choose a file from your device">
                                    <IconButton
                                        aria-label="Choose a file from your device"
                                        component="span"
                                    >
                                        <AttachIcon />
                                    </IconButton>
                                </Tooltip>
                            </InputLabel>
                            {onRemoveFile && file && (
                                <Tooltip title="Remove">
                                    <IconButton
                                        aria-label="Remove"
                                        component="span"
                                        onClick={e => onRemoveFile(e, file)}
                                    >
                                        <RemoveIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </InputAdornment>
                    )}
                />
            </Box>
        );
    }
}