import { Grid, Typography } from '@mui/material';
import { React, Component } from 'react';
import { AbstractAttribute } from '../data/attributes/AbstractAttribute';
import { Theme } from 'klayowebshared';
import { AttributeProgressBar } from './AttributeProgressBar';
import { AttributeProgressBarLabel } from './AttributeProgressBarLabel';

export class AttributeProgressOverview extends Component {

    static defaultProps = {
        progressBarProps: {
            showFraction: false,
            showPercent: true,
        },
        mandatoryBarColor: '#3dbd35',
        requiredBarColor: '#2196f3',
        desirableBarColor: '#f9b800',
        spacing: '32px'
    }

    render() {
        const { title, onMandatoryClick, onRequiredClick, onDesireableClick, singleRowProgressBar, progressBarProps, mandatoryBarColor, requiredBarColor, desirableBarColor, spacing, loading } = this.props;
        const { mandatoryMet, mandatoryTotal, requiredMet, requiredTotal, desirableMet, desirableTotal } = this.props;

        return <div>
            <div className='klaro_attr-progressoverrview'>
                {singleRowProgressBar &&
                    <div className='klaro_attr-progressoverrview_labels'>
                        <div>
                            <AttributeProgressBarLabel
                                label={AbstractAttribute.type.mandatory.label}
                                met={mandatoryMet}
                                total={mandatoryTotal}
                                onClick={onMandatoryClick}
                                singleRow={true}
                                loading={loading} />
                        </div>
                        <div style={{ marginTop: spacing}}>
                            <AttributeProgressBarLabel
                                label='Required'
                                met={requiredMet}
                                total={requiredTotal}
                                onClick={onRequiredClick}
                                singleRow={true}
                                loading={loading} />
                        </div>
                        <div style={{ marginTop: spacing}}>
                            <AttributeProgressBarLabel
                                label='Desirable'
                                met={desirableMet}
                                total={desirableTotal}
                                onClick={onDesireableClick}
                                singleRow={true}
                                loading={loading} />
                        </div>
                    </div>}
                <div className='klaro_attr-progressoverrview_bars'>
                    <div>
                        <AttributeProgressBar
                            color={mandatoryBarColor}
                            label={AbstractAttribute.type.mandatory.label}
                            met={mandatoryMet}
                            total={mandatoryTotal}
                            onClick={onMandatoryClick}
                            singleRow={singleRowProgressBar}
                            showLabel={!singleRowProgressBar}
                            barHeight={12}
                            progressBarProps
                            loading={loading} />
                    </div>
                        <div style={{ marginTop: spacing}}>
                        <AttributeProgressBar
                            color={requiredBarColor}
                            label='Required'
                            met={requiredMet}
                            total={requiredTotal}
                            onClick={onRequiredClick}
                            singleRow={singleRowProgressBar}
                            showLabel={!singleRowProgressBar}
                            barHeight={12}
                            progressBarProps
                            loading={loading} />
                    </div>
                        <div style={{ marginTop: spacing}}>
                        <AttributeProgressBar
                            color={desirableBarColor}
                            label='Desirable'
                            met={desirableMet}
                            total={desirableTotal}
                            onClick={onDesireableClick}
                            singleRow={singleRowProgressBar}
                            showLabel={!singleRowProgressBar}
                            barHeight={12}
                            progressBarProps
                            loading={loading} />
                    </div>
                </div>
            </div>
        </div>
    }
}