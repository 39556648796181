import React, { Component } from 'react';
import { AppContext } from '../common/AppContext';
import { LoadingBar } from './LoadingBar';
import { LoadingModel } from '../common/models/LoadingModel';

export class AbstractDialog extends Component {

    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            open: this.props.open ? this.props.open : false,
            loadingModel: new LoadingModel()
        };

        this.loadingModel = new LoadingModel();
        this.loadingBarRef = React.createRef();
    }

    setLoading(event, loading) {
        this.loadingModel.setLoading(event, loading);
        if (this.loadingBar) this.loadingBar.current.setLoading(this.loadingModel.isLoading);
    }

    renderLoadingBar() {
        return (<LoadingBar ref={this.loadingBarRef} />);
    }

    open(open) {
        this.setState({ open: open !== undefined ? open : true });
    }

    close() {
        this.setState({ open: false });
    }
}