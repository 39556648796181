import { Component, React } from 'react';
import { Button } from '../../components/Button';
import { Dialog } from '../../components/dialogs/Dialog';
import { ReactComponent as CloseIcon } from '../../resources/images/icons-close.svg';
import styled from '@emotion/styled';
import { KLAYO_COLORS } from '../../themes';

export class AttributeInformDialog extends Component {

    constructor(props) {
        super(props);
    }

	onAttributeSelect(e, attribute) {
        const { history } = this.props;
        history.push('/settings/competencies/edit/' + attribute.attributeDefinitionId);
    }

    render() {
        const { theme, onClose } = this.props;
		
        return (
            <StyledAttributeInformDialog
                className='klayo__attribute-inform-dialog'
                open={true}
                theme={theme}
                onClose={onClose}
                fullWidth
                maxWidth='sm'
                actions={[{
                    label: 'Close',
                    variant: 'filled',
                    onClick: onClose
                }]}>
				<div className='klayo__attribute-inform-dialog-title-section'>
               		<h1>Expiry date linked to training</h1>

					<Button
						className='klayo__attribute-inform-dialog-close-btn'
						icon={<CloseIcon />}
						onClick={onClose}
						sx={{ display: { xs: 'block', md: 'none' } }} 
					/>
				</div>
				<h2> 
					The expiry date cannot be turned off while there is training linked that is recurrent
				</h2>
            </StyledAttributeInformDialog>
        )
    }
}

const StyledAttributeInformDialog = styled(Dialog)`
	&.klayo__attribute-inform-dialog {

		h1 {
			color: ${KLAYO_COLORS.GreyGR900};
			font-family: Inter;
			font-size: 24px;
			font-style: normal;
			font-weight: 600;
			line-height: 32px; /* 133.333% */
			letter-spacing: -0.5px;
			margin-bottom: 0px;
			margin-top: 0px;
		}

		h2 {
			color: ${KLAYO_COLORS.GreyGR900};
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px; /* 150% */
			letter-spacing: -0.1px;
			margin-top: 0px;
			margin-bottom: 24px;
		}

		.MuiDialogContent-root {
			padding: 40px 40px 0px 40px;
		}

		.MuiPaper-root {
			max-width: 640px;
		}

		.klayo__attribute-inform-dialog-title-section {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 32px;

			.klayo__attribute-inform-dialog-close-btn {
				display: none !important;
				padding: 4px;
			}

			.MuiButtonBase-root {
				width: 20px;
				height: 20px;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			svg {
				width: 20px;
				height: 20px;
			}
		}

		@media (max-width: 900px) {
			h1 {
				color: ${KLAYO_COLORS.GreyGR900};
				font-family: Inter;
				font-size: 20px;
				font-style: normal;
				font-weight: 600;
				line-height: 28px; /* 140% */
				letter-spacing: -0.3px;
				margin-bottom: 0px;
				margin-top: 0px;		
			}

			h2 {
				color: ${KLAYO_COLORS.GreyGR900};
				font-family: Inter;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px; /* 150% */
				letter-spacing: -0.1px;
				margin-top: 0px;
				margin-bottom: 0px;
			}

			.klayo__attribute-inform-dialog-title-section {
				margin-bottom: 20px;

				.klayo__attribute-inform-dialog-close-btn {
					display: flex !important;
				}
			}

			.MuiDialogContent-root {
				padding: 24px 24px 0px 24px;
			}

			.MuiPaper-root {
				margin: auto;
				max-width: 375px;
				height: 90vh;
				max-height: 698px;
			}

			.MuiDialogActions-root {
				width: auto;
				position: absolute;
				bottom: 0px;
				width: calc(100% - 48px);
			}
		}
	}
`