import { Badge, Tooltip } from "@mui/material";
import React, { Component } from "react";
import { ReactComponent as TickIcon } from '../resources/images/icons-check-circle-fill.svg';
import { ReactComponent as WarningIcon } from '../resources/images/icons-warning-circle-fill.svg';

export class ComplianceBadge extends Component {

    render() {
        const { isCompliant, children, anchorOrigin } = this.props;

        return <Badge
            badgeContent={isCompliant ?
                <Tooltip title='Compliant' placement='top'>
                    <div className='klayo_profilepic_compliantbadge'>
                        <TickIcon />
                    </div>
                </Tooltip> :
                <Tooltip title='Non-compliant' placement='top'>
                    <div className='klayo_profilepic_noncompliantbadge'>
                        <WarningIcon />
                    </div>
                </Tooltip>}
            anchorOrigin={anchorOrigin}>
            {children}
        </Badge>
    }
};