import { EmployeeAttribute } from "./EmployeeAttribute";
import { AbstractAttributeList } from "./AbstractAttributeList";
import { AbstractAttribute } from "./AbstractAttribute";

export class EmployeeAttributeList extends AbstractAttributeList {

    constructor(options) {
        super(options);

        options.data.forEach((attr, index) => this.attributes.push(new EmployeeAttribute(index, attr)));
        this.attributes.sort((a, b) => new Date(b.lastUpdate) - new Date(a.lastUpdate));
        this.initAttributesLists();
    }

    initAttributesLists() {
        this.attributes.forEach(a => {
            if (a.isParent) {
                a.childAttributes.forEach(c => {
                    let approved = 0;
                    const attrDef = this.getByDefinition(c.attributeDefinitionId);
                    if (attrDef) {
                        c.categories = attrDef.categories;
                        c.lastUpdate = attrDef.lastUpdate;
                        c.status = attrDef.status;
                        c.statusLabel = attrDef.statusLabel;
                        c.proficiency = attrDef.proficiency;
                        c.expiryDate = attrDef.expiryDate;
                        c.currentExpiryDate = attrDef.currentExpiryDate;
                        c.requestedExpiryDate = attrDef.requestedExpiryDate;
                        c.currentProficiency = attrDef.currentProficiency;
                        c.requestedProficiency = attrDef.requestedProficiency;
                        c.employeeAttributeId = attrDef.employeeAttributeId;
                        c.validationRequestId = attrDef.validationRequestId;
                        if (c.status === AbstractAttribute.status.approved) approved++;
                    }
                    else {
                        c.isDeleted = true;
                        c.statusLabel = 'Missing';
                    }

                    //a.status = approved === a.childAttributes.length ? AbstractAttribute.status.approved : AbstractAttribute.status.review;
                    //a.statusLabel = a.status.label;
                })
            }
        });

        super.initAttributesLists();
    }

    static getFilterItems() {
        return { all: { ...AbstractAttributeList.allFilter }, approved: { ...AbstractAttribute.status.approved }, feedback: { ...AbstractAttribute.status.feedback }, review: { ...AbstractAttribute.status.review } };//, declined: { ...AbstractAttribute.status.declined } };
    }

    getByDefinition(defId) {
        return this.attributes.find(a => a.attributeDefinitionId === defId);
    }
}