import { Box } from '@mui/material';
import { React, Component } from 'react';
import { Theme } from 'klayowebshared';
import { Tooltip } from '../Tooltip';

export class CategoryDonutChart extends Component {

    //Todo: exten DonutChart

    static defaultProps = {
        strokeWidth: 30,
        firstColor: Theme.getStyleVar('--dodger-blue-db-500'),
        colors: [Theme.getStyleVar('--grey-gr-300'), Theme.getStyleVar('--lime-green-lg-600'), Theme.getStyleVar('--gold-gd-700'), Theme.getStyleVar('--torch-red-tr-500')]
    }

    constructor(props) {
        super(props);

        this.state = {
            radius: 0,
            circumference: 0,
            width: 400,
            height: 400,
            hoverCategory: null,
            tooltipOffset: { x: 0, y: 0 }
        };

        this.divElement = null;
        //this.circleRefs = [];
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions.bind(this));
        this.updateDimensions();
    }


    updateDimensions() {
        const width = this.divElement ? this.divElement.clientWidth : 400;
        const height = this.divElement ? this.divElement.clientHeight : 400;

        const radius = Math.min(width, height) / 2 - this.props.strokeWidth;
        const circumference = 2 * Math.PI * radius;
        this.setState({ radius, circumference, width, height });
    }

    /*setCircleElement(index, cateogry, el) {
        if (this.circleRefs[index] || !el) return;

        this.circleRefs[index] = el;

        el.addEventListener('mouseenter', e => {
            if (this.state.hoverCategory !== cateogry) this.setState({ hoverCategory: cateogry, tooltipOffset: { x: e.offsetX, y: e.offsetY } });
        });
        el.addEventListener('mouseleave', e => this.setState({ hoverCategory: null }));
    }*/

    onSliceMouseEnter(e, cateogry) {
        if (this.state.hoverCategory !== cateogry) this.setState({ hoverCategory: cateogry, tooltipOffset: { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY } });
    }

    onSliceMouseLeave(e, cateogry) {
        this.setState({ hoverCategory: null });
    }

    render() {
        const { sx, strokeWidth, categories, firstColor, colors } = this.props;
        const { radius, circumference, hoverCategory, tooltipOffset } = this.state;

        let orderedCategories = null;
        let total = 0, offset = 0;

        if (categories) {
            orderedCategories = Object.values(categories).sort((a, b) => a.employees - b.employees).reverse();
            const catCounts = orderedCategories.map(a => a.employees);

            total = catCounts.reduce((a, b) => a + b, 0);
        }

        return (
            <Box ref={divElement => this.divElement = divElement} className='klayo_attributedonut' sx={{ ...sx, display: 'flex', minWidth: '200px', minHeight: '200px' }} >

                {hoverCategory &&
                    <Tooltip
                        open={true}
                        title={hoverCategory ? hoverCategory.category + ': ' + hoverCategory.employees : 'Category'}
                        componentsProps={{
                            popper: { sx: { pointerEvents: 'none' } }
                        }} >
                        <Box sx={{ position: 'absolute', pointerEvents: 'none', marginLeft: tooltipOffset.x + 'px!important', marginTop: tooltipOffset.y + 'px' }}></Box>
                    </Tooltip>}

                <svg xmlns='http://www.w3.org/2000/svg' style={{ width: '100%', height: '100%' }}>
                    {orderedCategories && orderedCategories.map((cateogry, index) => {
                        const ofTotal = cateogry.employees / total;

                        const arc = ofTotal * circumference;

                        const markeup = (<circle
                            //ref={el => this.setCircleElement(index, cateogry, el)}
                            onMouseEnter={e => this.onSliceMouseEnter(e, cateogry)}
                            onMouseLeave={e => this.onSliceMouseLeave(e, cateogry)}
                            key={index}
                            cx='50%'
                            cy='50%'
                            r={radius + 'px'}
                            style={{
                                fill: 'none',
                                cursor: 'pointer',
                                stroke: index === 0 ? firstColor : colors[index % 4],
                                strokeWidth: strokeWidth,// + (index * .5),
                                strokeDasharray: arc + ',10000',
                                strokeDashoffset: -offset,
                                pointerEvents: 'stroke',
                                transform: 'rotate(-90deg)', transformOrigin: 'center', transition: 'all 200ms ease-in-out'
                            }} />);

                        offset += arc;

                        return markeup;
                    })}
                    <text x='50%' y='45%' dominantBaseline='middle' textAnchor='middle' style={{ fontSize: '48px', fontWeight: '700', fill: Theme.getStyleVar('--grey-gr-900') }}>{orderedCategories ? orderedCategories.length : 0}</text>
                    <text x='50%' y='58%' dominantBaseline='middle' textAnchor='middle' style={{ fontSize: '20px', fontWeight: '500', fill: Theme.getStyleVar('--grey-gr-500') }}>{orderedCategories && orderedCategories.length === 1 ? 'category' : 'categories'}</text>
                </svg>
            </Box>
        );
    }
}