
export class LoadingModel {

    constructor() {
        this.loadingEvents = [];
        this.isLoading = false;
        this.debug = false;
    }

    resetAll() {
        this.loadingEvents = [];
        this.isLoading = false;
    }

    setLoading(event, loading) {
        if(loading) this.loadingEvents.push(event);
        else this.loadingEvents = this.loadingEvents.filter(e => e !== event)
        if(this.debug) console.log((loading ? 'Start': 'Stop')+' loading '+event+'. Waiting on '+this.loadingEvents.length+' events');
        this.isLoading = this.loadingEvents.length > 0;
    }
}