import { Box, Grid, Stack } from '@mui/material';
import React, { Component } from 'react';
import ReorderIcon from '@mui/icons-material/Reorder';
import GridViewIcon from '@mui/icons-material/GridView';
import { AttributeProgressOverview } from '../../components/AttributeProgressOverview';
import { EmployeeTable } from '../../components/table/EmployeeTable';
import { Paper } from '../../components/Paper';
import { EmployeeCard } from '../../components/EmployeeCard';
import { ToggleButton } from '../../components/ToggleButton';
import { Button } from '../../components/Button';
import { StatCard } from '../../components/StatCard';
import { ReactComponent as TeamMembersIcon } from '../../resources/images/icons-users.svg';
import { ReactComponent as ClassificationIcon } from '../../resources/images/icons-briefcase.svg';
import { ReactComponent as DownloadIcon } from '../../resources/images/icons-download.svg';
import { BreakpointTemplate } from 'klayowebshared';
import { Delayed } from 'klayowebshared';
import { SortSelector } from '../../components/selectors/SortSelector';
export class TeamPeopleSection extends Component {

    static defaultProps = {
        allowExport: true,
        heading: 'Team members'
    }

    constructor(props) {
        super(props);

        this.state = {
            listMode: false,
            sortModel: [{
                field: 'profilePicFullNameJobTitle',
                sort: props.sortOrder.method || 'asc',
            }],
            sortOrder: props.sortOrder
        };

        this.tableRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.sortOrder !== this.props.sortOrder) this.setState({ sortOrder: this.props.sortOrder });
    }

    onChangeViewMode(e, mode) {
        if (mode === null) return;
        this.setState({ listMode: mode });
    }

    onViewJobClassifications(e) {
        e.preventDefault();
        this.props.onViewAllClassifications(e);
        return false;
    }

    onSortModelChange(model, details, sortOrder, e) {
        const { onSortOrderChange } = this.props;
        this.setState({ sortModel: model });

        const newSortOrder = sortOrder ? sortOrder : Object.values(SortSelector.sort).find(s => s.method === model[0].sort);

        if (onSortOrderChange) onSortOrderChange(e, newSortOrder);
        if(this.tableRef && this.tableRef.current) this.tableRef.current.setSortModel(model);
    }

    onSortOrderChange(e, sortOrder) {
        const { onSortOrderChange } = this.props;

        this.onSortModelChange([{
            field: 'profilePicFullNameJobTitle',
            sort: sortOrder.method,
        }], null, sortOrder, e);
    }

    renderCard(employee, index, animate) {
        const { theme, onEmployeeSelect } = this.props;

        return <EmployeeCard
            key={index}
            animate={animate}
            theme={theme}
            employee={employee}
            onClick={e => onEmployeeSelect(e, employee)} />
    }

    render() {
        const { teamList, theme, onEmployeeSelect, allowExport, animate, onShowExportDialog, heading, loading } = this.props;
        const { listMode, sortModel, sortOrder } = this.state;

        const { numberOfMetMandatoryAttributes, totalNumberOfMandatoryAttributes, numberOfMetRequiredAttributes, totalNumberOfRequiredAttributes, numberOfMetDesirableAttributes, totalNumberOfDesirableAttributes } = teamList && teamList.summary ? teamList.summary.attributeStatistic : {};

        let sortedEmployees = teamList && teamList.employees.length !== 0 ? [...teamList.employees].sort((e1, e2) => EmployeeTable.comparator(sortModel, e1, e2)) : [];

        return <div>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6} lg={6}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={12} lg={12}>
                            <StatCard
                                icon={<TeamMembersIcon />}
                                title={heading}
                                stat={teamList && teamList.summary ? teamList.summary.totalNumberOfEmployees : ''}
                                loading={loading} />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <StatCard
                                icon={<ClassificationIcon />}
                                title='Job classifications'
                                stat={teamList && teamList.summary ? teamList.summary.numberOfJobClassifications : ''}
                                loading={loading} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Paper title='Job competencies overview' sx={{ height: '100%', boxSizing: 'border-box' }}>
                        {teamList && (<AttributeProgressOverview
                            singleRowProgressBar={true}
                            mandatoryMet={numberOfMetMandatoryAttributes}
                            mandatoryTotal={totalNumberOfMandatoryAttributes}
                            requiredMet={numberOfMetRequiredAttributes}
                            requiredTotal={totalNumberOfRequiredAttributes}
                            desirableMet={numberOfMetDesirableAttributes}
                            desirableTotal={totalNumberOfDesirableAttributes}
                            loading={loading} />)}
                    </Paper>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                        <BreakpointTemplate
                            theme={theme}
                            breakpoint='md'
                            forceTo={!listMode}
                            to={
                                <SortSelector
                                    dense={true}
                                    value={sortOrder}
                                    onChange={this.onSortOrderChange.bind(this)}
                                    disabled={loading}
                                    sx={{ width: '100%', maxWidth: '200px' }} />
                            }
                            from={
                                <div className='klayo_teampeople_rowcount'>{teamList ? teamList.employees.length + ' records' : ''}</div>
                            }
                            sx={{ width: '100%' }} />
                        <Stack direction="row" spacing={2} alignItems="center">
                            {allowExport && <Button
                                size='md'
                                label='Export'
                                theme={theme}
                                tooltip='Export your filtered team data'
                                showLabelFromBreakpoint='md'
                                onClick={onShowExportDialog}
                                disabled={(teamList && teamList.employees.length === 0) || loading}
                                startIcon={<DownloadIcon />} />}
                            <ToggleButton
                                options={[
                                    {
                                        value: false,
                                        icon: <GridViewIcon />,
                                        tooltip: 'Card view'
                                    }, {
                                        value: true,
                                        icon: <ReorderIcon />,
                                        tooltip: 'List view'
                                    }
                                ]}
                                value={listMode}
                                disabled={loading}
                                onChange={this.onChangeViewMode.bind(this)} />
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Box className={'klaro_teampeople klaro_teampeople--' + (listMode ? 'list' : 'cards')} sx={{ position: 'relative' }} >
                        {listMode &&
                            <Delayed>
                                <EmployeeTable
                                    theme={theme}
                                    ref={this.tableRef}
                                    rows={teamList ? teamList.employees : null}
                                    loading={loading}
                                    onEmployeeSelect={onEmployeeSelect}
                                    showHeaderFromBreakpoint='md'
                                    columns={[
                                        { type: EmployeeTable.columns.summary },
                                        { type: EmployeeTable.columns.profilePicFullNameJobTitle, visibleFromBreakpoint: 'md' },
                                        { type: EmployeeTable.columns.isCompliant, visibleFromBreakpoint: 'md' },
                                        { type: EmployeeTable.columns.jobAttributesCount, label: 'Job competencies', visibleFromBreakpoint: 'md' },
                                        { type: EmployeeTable.columns.gapAttributesCount, visibleFromBreakpoint: 'md' },
                                        { type: EmployeeTable.columns.averageProficiency, label: 'Job match overview', visibleFromBreakpoint: 'md' }
                                    ]}
                                    sortModel={sortModel}
                                    onSortModelChange={this.onSortModelChange.bind(this)}
                                    showProficiency={true} />
                            </Delayed>
                        }
                        {!listMode &&
                            <Grid
                                container
                                spacing={4}>
                                {loading ? Object.values([0, 1, 2, 3, 4, 5]).map((e, index) =>
                                    <Grid key={index} item xs={12} sm={12} md={6} lg={6} xl={3}>
                                        {this.renderCard(null, index, false)}
                                    </Grid>)
                                    : teamList && teamList.employees.length !== 0 ? <Delayed>{sortedEmployees.map((e, index) =>
                                        <Grid key={index} item xs={12} sm={12} md={6} lg={6} xl={3}>
                                            {this.renderCard(e, index, false)}
                                        </Grid>
                                    )}</Delayed> : (<Grid item xs={6} sm={6} md={12} lg={12}>
                                        No records found for the selected filter
                                    </Grid>)}
                            </Grid>
                        }
                    </Box>
                </Grid>
            </Grid>
        </div>
    }
}