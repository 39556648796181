import React, { Component } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { TextField } from "./TextField";
import { ReactComponent as CalendarIcon } from '../resources/images/icons-calendar-custom.svg';
import { Alert } from './Alert';
import styled from '@emotion/styled';
import { KLAYO_COLORS } from '../themes';
import { getDateFormatPattern } from '../utilities/formatDate';

export class DatePicker extends Component {

    static defaultProps = {
        allowEmpty: true,
        allowFuture: true,
        allowPast: true,
        showPastWarning: false,
        showPastWarningMessage: 'Please select a current or future date',
        // inputFormat: 'dd/MM/yyyy',
        // format: 'dd/MM/yyyy',
        readOnly: false
    }

    constructor(props) {
        super(props);

        this.ref = React.createRef();

        const valid = this.isValid(props.value);

        this.state = {
            error: props.value && this.props.disabled !== true ? !valid : props.error ?? false,
            errorMessage: props.errorMessage ?? 'Invalid date',
            value: props.value,
            isPast: valid && this.isDatePast(props.value),
            format: 'MM/dd/yyyy'
        };
    }

    componentDidMount() {
      const userLanguage = navigator.language || 'en';
      const findFormat = getDateFormatPattern(userLanguage)
      this.setState({format: findFormat});
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.value !== this.props.value) this.setState({ value: this.props.value, error: this.props.disabled !== true ? !this.isValid(this.props.value) : false });
        if (prevProps.error !== this.props.error) this.setState({ error: this.props.error });
        if (prevProps.errorMessage !== this.props.errorMessage) this.setState({ errorMessage: this.props.errorMessage });
        // allowEmpty change reset error
        if (prevProps.allowEmpty !== this.props.allowEmpty) this.onBlur();
    }

    /*onError(reason, value) {
        this.setState({ error: reason !== null });
        if (reason === null) this.fireChange(value);
    }*/

    onChange(value) {
        const { onChange } = this.props;
        const { error } = this.state;

        const valid = this.isValid(value);
        this.setState({ value, error: !valid, isPast: valid && this.isDatePast(value) });

        if (onChange) onChange(value, valid);
    }

    isDatePast(value) {
        if (value === null) return false;
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return value < today;
    }

    isValid(value) {
        const { allowFuture, allowPast } = this.props;
        if (value === null && this.props.allowEmpty) return true;

        if (!value || typeof value === 'string' || !value instanceof Date || isNaN(value)) {
            return false;
        }

        let year = value.getYear();
        if (year === NaN) {
            return false;
        }

        year += 1900;
        if (year < 1900 || year > 2050) {
            return false;
        }

        const month = value.getMonth();
        if (month === NaN) return false;
        if (month < 0 || month > 11) {
            return false;
        }

        const date = value.getDate();
        if (date === NaN) return false;
        if (date < 0 || date > 31) {
            return false;
        }

        const today = new Date();
        if (!allowFuture && value > today) return false;
        if (!allowPast && value < today) return false;

        value.setHours(0);
        value.setMinutes(0);

        return true;
    }

    fireChange(value) {
        const { onChange } = this.props;

        const year = value.getYear() + 1900;
        if (year < 1900 || year > 2050) return;

        const month = value.getMonth();
        if (month < 0 || month > 11) return;

        this.setState({ error: false });

        if (onChange) onChange(value);
    }

    onAccept() {
    }

    onBlur() {
        const { value } = this.state;
        const isValid = this.isValid(value)

        this.setState({ error: !isValid });

        if (this.props.onBlur) {
            this.props.onBlur(value, isValid);
        }
    }

    renderDatePicker(props) {
        const { theme } = this.props;
        // return theme && theme.isBreakpointDown('md') ? <MobileDatePicker {...props} format={this.state.format} /> : <DesktopDatePicker {...props} format={this.state.format} />
        return <MuiDatePicker {...props} format={this.state.format} />
    }

    render() {
        const { label, disabled, sx, inputFormat, readOnly, autoFocus, helperText, showPastWarning, showPastWarningMessage, format, maxDate, minDate } = this.props;
        const { value, error, errorMessage, isPast } = this.state;

        return (
            <DatePickerStyled className={`klayo_date-picker ${disabled ? 'picker-disabled' : ''}`}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    {label && <label className="date-picker-label">{label}</label>}

                    {this.renderDatePicker({
                        ref: this.ref,
                        inputFormat: inputFormat,
                        onChange: this.onChange.bind(this),
                        //onError={this.onError.bind(this)}
                        onAccept: this.onAccept.bind(this),
                        value: value,
                        disabled: disabled,
                        readOnly: readOnly,
                        format: format,
                        components: {
                            OpenPickerIcon: CalendarIcon
                        },
                        maxDate: maxDate,
                        minDate: minDate,
                        renderInput: ({ inputRef, inputProps, InputProps }) =>
                            <TextField
                                {...this.props}
                                inputRef={inputRef}
                                inputProps={inputProps}
                                autoComplete={false}
                                autoFocus={autoFocus}
                                clearable={false}
                                error={error}
                                helperText={error ? errorMessage : helperText}
                                InputProps={InputProps}
                                onBlur={this.onBlur.bind(this)}
                                sx={{ width: '200px', ...sx }} />
                    })}

                    {showPastWarning && isPast && <Alert severity='warning' sx={{ marginTop: '16px' }}>{showPastWarningMessage}</Alert>}

                </LocalizationProvider>
            </DatePickerStyled>
        );
    }
}

const DatePickerStyled = styled.div`
    &.klayo_date-picker {
        // max-width: 200px;

        .date-picker-label {
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            line-height: 20px;
            color: ${KLAYO_COLORS.GreyGR700};
            padding-left: 14px;
            padding-bottom: 5px;
            display: block;
        }

        .MuiInputBase-input {
            padding: 9.5px 14px;
        }

        .MuiOutlinedInput-root {
            border-radius: 20px;
        }

        .Mui-focused {
            .MuiOutlinedInput-notchedOutline {
                border-color: ${KLAYO_COLORS.NeonBlueNB400};
            }
        }

        .MuiOutlinedInput-notchedOutline {
            border-color: ${KLAYO_COLORS.NeonBlueNB200};
        }

        .MuiIconButton-root {
            color: ${KLAYO_COLORS.NeonBlueNB500};
        }
    }
    &.picker-disabled {
        .MuiTextField-root {
            .MuiInputBase-root {
                background-color: ${KLAYO_COLORS.GreyGR100};
            }
        }
        .MuiOutlinedInput-notchedOutline {
            border-color: ${KLAYO_COLORS.GreyGR300};
        }
        .MuiIconButton-root {
            color: ${KLAYO_COLORS.GreyGR400};
        }
    }
`;