export const getDateFormatPattern = (locale) => {
  const getPatternForPart = (part) => {
    switch (part.type) {
      case 'day':
        return 'd'.repeat(part.value.length);
      case 'month':
        return 'M'.repeat(part.value.length);
      case 'year':
        return 'y'.repeat(part.value.length);
      case 'literal':
        return part.value;
      default:
        return '';
    }
  };
  return new Intl.DateTimeFormat(locale).formatToParts(new Date())
    .map(getPatternForPart)
    .join('');
};