import { Data } from "../Data";
import { TeamData } from "../users/TeamData";
import { User } from "../users/User";

export class TeamFilterOptions extends Data {

    static instance = {};
    static filteredInstance = null;

    constructor(options) {
        super({
            api: options.groupId ? '/MyGroup/' + options.groupId + '/GetFilterOptions' : '/Teams/GetFilterOptions', callback: data => {
                options.resolve(this, false);
            }, ...options
        });
    }

    onDataReceived(data) {
        this.employees = [];
        data.employees.forEach(e => this.employees.push(new User({ data: { id: e.employeeId, ...e } })));

        this.jobs = data.jobs;
        this.locations = data.locations;
    }

    getEmployee(id) {
        return this.employees.find(a => a.employeeId === id);
    }

    sortEmployees(sortedIds) {
        this.employees.sort((a, b) => sortedIds.indexOf(a.employeeId) - sortedIds.indexOf(b.employeeId));
    }

    static get(context, groupId) {
        return new Promise((resolve, reject) => {
            const instKey = TeamData.getInstanceKey(groupId);            
            if (TeamFilterOptions.instance[instKey]) resolve(TeamFilterOptions.instance[instKey], true);
            else TeamFilterOptions.instance[instKey] = new TeamFilterOptions({ resolve, reject, context, groupId });
        });
    }
}