import { MenuItem, ListItemText, ListItemIcon, ListSubheader } from '@mui/material';
import React, { Component } from 'react';
import { Theme } from 'klayowebshared';
import { ReactComponent as DropIcon } from '../resources/images/icons-chevron-double.svg';
import { ReactComponent as SelectedIcon } from '../resources/images/icons-check.svg';
import { TextField } from './TextField';

export class MenuSelect extends Component {

    static defaultProps = {
        noSelectionError: false,
        groups: null,
        highlightSelection: true
    };

    constructor(props) {
        super(props);

        this.state = {
            isTouched: false,
        }

        this.ref = React.createRef();
    }

    onChange(e) {
        const { onChange } = this.props;
        if (e.target.value === undefined) return;

        if (onChange) onChange(e);

        this.setState({ isTouched: true });
    }

    onBlur(e) {
        this.setState({ isTouched: true });
    }

    renderGroup(group) {
        const { items } = this.props;
        return [<ListSubheader className='klayo-selectmenu_group'>{group}</ListSubheader>, items.filter(([key, filter]) => filter.group === group).map(([key, filter]) => this.renderItem(key, filter))];
    }

    getHelperText() {
        const { CustomHelperText, noSelectionError, value } = this.props;
        const { isTouched } = this.state;
        let textContent = '';

        if (noSelectionError && value === null && isTouched) {
            textContent = CustomHelperText ? CustomHelperText : 'Please select an option';
        }

        return textContent;
    }

    renderItem(filter) {
        const { dense, getItemLabel, disableItem, highlightSelection, value } = this.props;

        return <MenuItem
            className={'klayo-selectmenu_item ' + (dense ? 'klayo-selectmenu_item-dense ' : '')}
            key={filter?.value || filter?.id}
            selected={value === filter?.value}
            disabled={disableItem ? disableItem(filter) : false}
            value={filter?.value || filter?.id}
        >
            <ListItemText>{getItemLabel ? getItemLabel(filter) : filter.label}</ListItemText>
            {highlightSelection && <ListItemIcon><SelectedIcon /></ListItemIcon>}
        </MenuItem>
    }

    render() {
        const { label, items, placeholder, disabled, className, menuClassName, sx, dense, onChange, firstValueDefault, getItemLabel, clearable, disableItem, noSelectionError, groups } = this.props;
        const { isTouched } = this.state;
        let { value } = this.props;

        const defaultValue = this.props.defaultValue ? this.props.defaultValue : firstValueDefault && items ? items[0][1] : null;
        if (!value) value = defaultValue;

        const helperText = this.getHelperText();

        const find = items?.find(i => {
            return i?.value === value
        });

        return (
            <TextField
                className={'klayo-selectmenu' + (dense ? ' klayo-selectmenu-dense' : '') + (className ? ' ' + className : '') + (groups ? ' klayo-selectmenu-hasgroups' : '')}
                select
                label={label}
                dense={dense}
                value={value}
                error={noSelectionError && value === null && isTouched}
                helperText={helperText}
                clearable={clearable && !disabled}
                defaultValue={defaultValue}
                placeholder={placeholder}
                disabled={disabled}
                onChange={this.onChange.bind(this)}
                onBlur={this.onBlur.bind(this)}
                SelectProps={{
                    MenuProps: {
                        classes: { list: '.MuiMenu-list' + (menuClassName ? ` ${menuClassName} ` : '') + (groups ? ' klayo-selectmenu_list-hasgroups' : '') + (dense ? ' klayo-selectmenu_list-dense' : '') },
                    },
                    IconComponent: () => <DropIcon className='klayo-selectmenu_dropbutton' />,
                    autoWidth: false,
                    displayEmpty: true,
                    renderValue: value => <div className='klayo-selectmenu_value'>{value !== '' ?
                        (getItemLabel ? getItemLabel(value) : find?.label ?? value.label) : placeholder ? <div className='klayo-selectmenu_placeholder'>{placeholder}</div> : ''}</div>
                }}
                sx={{
                    ...sx, '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: Theme.getStyleVar('--neon-blue-nb-200'),
                        },
                        '&:hover fieldset': {
                            borderColor: Theme.getStyleVar('--neon-blue-nb-300'),
                        },
                        '&.Mui-focused fieldset': {
                            borderWidth: '2px',
                            borderColor: Theme.getStyleVar('--neon-blue-nb-500'),
                        },
                    }
                }}>
                {groups && groups.map(g => this.renderGroup(g))}

                {!groups && items && items.map((filter) => this.renderItem(filter))}
            </TextField>
        );
    }
}