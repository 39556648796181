import { React, Component } from 'react';
import { AbstractAttribute } from '../../data/attributes/AbstractAttribute';
import { Autocomplete } from '@mui/material';
import { TextField } from '../TextField';
import { SelectMenu } from '../SelectMenu';

export class AttributeTypeSelector extends Component {

    constructor(props) {
        super(props);

        this.showAllItem = { key: 'all', label: 'Show all' };

        this.state = {
            type: this.props.value
        };
    }

    onChange(e) {
        const { onChange } = this.props;

        this.setState({ type: e.target.value }, state => {
            onChange(e);
        });
    }

    getItemLabel(item) {
        return item.filterLabel;
    }

    render() {
        const { sx } = this.props;
        const { type } = this.state;

        return (
            <SelectMenu
                value={type}
                label='Competency type'
                placeholder='Select'
                firstValueDefault={true}
                items={Object.entries({ all: this.showAllItem, ...AbstractAttribute.type })}
                onChange={this.onChange.bind(this)}
                sx={sx}
            />);
    }
}