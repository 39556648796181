import styled from "@emotion/styled";
import { Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Utils } from '../../common/Utils';
import { PRACTICAL_STATUS } from "../../constants";
import { ReactComponent as AssessmentIcon } from '../../resources/images/icons-assessment.svg';
import { ReactComponent as MoreVertIcon } from '../../resources/images/icons-more-vertical.svg';
import { KLAYO_COLORS } from "../../themes";
import { Tooltip as CustomTooltip } from '../Tooltip';
import { Table } from "./Table";

export class InstructorPracticalTable extends Table {

    static columns = {
        dateTime: {
            field: 'practicalDate',
            label: 'Date',
        },
        practical: {
            field: 'name',
            label: 'Practical',
        },
        competencies: {
            field: 'numberOfAttributes',
            label: 'Competencies',
        },
        participants: {
            field: 'numberOfParticipants',
            label: 'Participants',
        },
        acknowledged: {
            field: 'numberOfAcknowledgedParticipants',
            label: 'Confirmed',
        },
        status: {
            field: 'status',
            label: 'Status',
        },
        action: {
            field: 'action',
            label: 'Actions',
        },
    };

    static defaultProps = {
        ...Table.defaultProps,
        columns: [
            { type: InstructorPracticalTable.columns.dateTime, visibleFromBreakpoint: 'lg' },
            { type: InstructorPracticalTable.columns.practical },
            { type: InstructorPracticalTable.columns.competencies, visibleFromBreakpoint: 'sm' },
            { type: InstructorPracticalTable.columns.participants, visibleFromBreakpoint: 'sm' },
            { type: InstructorPracticalTable.columns.acknowledged, visibleFromBreakpoint: 'lg' },
            { type: InstructorPracticalTable.columns.status, visibleFromBreakpoint: 'lg' },
            { type: InstructorPracticalTable.columns.action },
        ],
        noRowsMessage: 'Click + New practical to create your first practical',
        noResultsMessage: 'No practicals matched your search'
    }

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            sortModel: this.props.sortModel ? this.props.sortModel : [{
                field: 'practicalDate',
                sort: 'desc',
            }]
        };
    }

    onRowClick(params, e) {
        const { onPracticalSelect } = this.props;
        if (params.row && onPracticalSelect) onPracticalSelect(e, params.row);
    }

    renderDateField(data) {
        const { practicalDate, practicalTime } = data;
        const date = Utils.formatDate(practicalDate);
        const time = Utils.convertPracticalTime(practicalTime, true);
        const string = `${date} ${time}`.trim();
        return <CustomTooltip title={string}>
            <div>{string}</div>
        </CustomTooltip>
    }

    initColumns() {
        const { columns, sortable, onPracticalsAction } = this.props;

        this.columns = [];

        columns.forEach(c => {
            if (this.hasColumn(c)) {

                if (c.type === InstructorPracticalTable.columns.dateTime) this.columns.push({
                    field: 'practicalDate',
                    headerName: c.label || c.type.label,
                    sortable: sortable,
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    filterable: false,
                    disableReorder: true,
                    width: 180,
                    valueGetter: params => params.row.practicalDate,
                    renderCell: params => this.renderDateField(params.row)
                });

                if (c.type === InstructorPracticalTable.columns.practical) this.columns.push({
                    field: 'name',
                    headerName: c.label || c.type.label,
                    sortable: sortable,
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    filterable: false,
                    disableReorder: true,
                    minWidth: 160,
                    flex: 1,
                    valueGetter: params => params.row.name,
                    renderCell: params => <PracticalNameSection>
                        {params.row.name}
                        {params.row.isAssessment && (<Tooltip title='Assessment' placement='top' arrow>
                            <AssessmentIcon className='klayo_assessment-icon' />
                        </Tooltip>)}
                    </PracticalNameSection>
                });

                if (c.type === InstructorPracticalTable.columns.competencies) this.columns.push({
                    field: 'numberOfAttributes',
                    headerName: c.label || c.type.label,
                    sortable: sortable,
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    filterable: false,
                    disableReorder: true,
                    width: 130,
                    headerAlign: 'left',
                    align: 'left',
                    valueGetter: params => params.row.numberOfAttributes,
                });

                if (c.type === InstructorPracticalTable.columns.participants) this.columns.push({
                    field: 'numberOfParticipants',
                    headerName: c.label || c.type.label,
                    sortable: sortable,
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    filterable: false,
                    disableReorder: true,
                    width: 130,
                    headerAlign: 'left',
                    align: 'left',
                    valueGetter: params => params.row.numberOfParticipants,
                });

                if (c.type === InstructorPracticalTable.columns.acknowledged) this.columns.push({
                    field: 'numberOfAcknowledgedParticipants',
                    headerName: c.label || c.type.label,
                    sortable: sortable,
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    filterable: false,
                    disableReorder: true,
                    width: 130,
                    headerAlign: 'left',
                    align: 'left',
                    valueGetter: params => params.row.numberOfAcknowledgedParticipants,
                    renderCell: params => <div>{params.row.numberOfAcknowledgedParticipants} of {params.row.numberOfParticipants}</div>
                });

                if (c.type === InstructorPracticalTable.columns.status) this.columns.push({
                    field: 'status',
                    headerName: c.label || c.type.label,
                    sortable: sortable,
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    filterable: false,
                    disableReorder: true,
                    width: 170,
                    headerAlign: 'left',
                    align: 'left',
                    valueGetter: params => params.row.status,
                    renderCell: params => <div>{PRACTICAL_STATUS[params.row.status].label}</div>
                });

                if (c.type === InstructorPracticalTable.columns.action) this.columns.push({
                    field: 'action',
                    type: 'actions',
                    filterable: false,
                    disableReorder: true,
                    width: 50,
                    align: 'right',
                    valueGetter: params => params,
                    getActions: params => [
                        <GridActionsCellItem
                            icon={<MoreVertIcon />}
                            label="Actions"
                            onClick={e => onPracticalsAction(e, params.row)}
                        />
                    ]
                });
            }
        });
    }
};

const PracticalNameSection = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;

	.klayo_assessment-icon {
		color: ${KLAYO_COLORS.GreyGR400};

		:hover {
			color: ${KLAYO_COLORS.NeonBlueNB500};
		}
	}

`;