import { Stack } from '@mui/material';
import axios from 'axios';
import { ConfirmationDialog } from 'klayowebshared';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import App from '../../App';
import { ErrorModel } from '../../common/models/ErrorModel';
import { TeamFilterModel } from '../../common/models/TeamFilterModel';
import { Alert } from '../../components/Alert';
import { Button } from '../../components/Button';
import { PillTabs } from '../../components/PillTabs';
import { Placeholder } from '../../components/Placeholder';
import { SortSelector } from '../../components/selectors/SortSelector';
import { Snackbar } from '../../components/Snackbar';
import { TeamFilterChips } from '../../components/TeamFilterChips';
import { TeamFilterPanel } from '../../components/TeamFilterPanel';
import { ViewHeading } from '../../components/typography/ViewHeading';
import { View } from '../../components/View';
import { AttributeDefinitionList } from "../../data/attributes/AttributeDefinitionList";
import { Data } from '../../data/Data';
import { TeamData } from '../../data/users/TeamData';
import { TeamDataExport } from '../../data/users/TeamDataExport';
import { ReactComponent as FlterIcon } from '../../resources/images/icons-filter.svg';
import { ReactComponent as UsersIcon } from '../../resources/images/icons-users-1.svg';
import { AbstractView } from '../AbstractPage';
import { DeleteConfirmationDialog } from '../other/DeleteConfirmationDialog';
import { AddEditAttributeDialog } from '../profile/AddEditAttributeDialog';
import { EmployeeProfileDialog } from '../profile/EmployeeProfileDialog';
import { AttributePeopleListDialog } from './AttributePeopleListDialog';
import { InstructorAddEditAttributeDialog } from './InstructorAddEditAttributeDialog';
import { JobClassiciationsDialog } from './JobClassiciationsDialog';
import { TeamAttributesSection } from './TeamAttributesSection';
import { TeamGapsSection } from './TeamGapsSection';
import { TeamPeopleSection } from './TeamPeopleSection';

export class Team extends AbstractView {

    static defaultProps = {
        groupMode: false,
        basePath: '/team',
        basePathDepth: 1
    };

    static subPages = {
        people: {
            name: 'People',
            slug: '',
            paths: [
                '/',
                '/:id'
            ],
            component: TeamPeopleSection,
            hasAccess: user => user && (user.isTeamLead || user.hasGroup || user.isOrganizationAdmin),
            isDefault: () => true
        },
        competencies: {
            name: 'Competencies',
            slug: 'competencies',
            paths: [
                '/competencies',
                '/competencies/:id'
            ],
            component: TeamAttributesSection,
            hasAccess: user => user && (user.isTeamLead || user.hasGroup || user.isOrganizationAdmin)
        },
        gaps: {
            name: 'Gaps',
            slug: 'gaps',
            paths: [
                '/gaps',
                '/gaps/:id'
            ],
            component: TeamGapsSection,
            hasAccess: user => user && (user.isTeamLead || user.hasGroup || user.isOrganizationAdmin)
        }
    };

    constructor(props, context, page) {
        super(props, page ?? App.pages.team);

        this.state = {
            filterModel: TeamFilterModel.fromUrlParams(props.location.search),
            teamList: null,
            definitions: null,
            filterError: null,
            showExportDialog: false,
            sortOrder: SortSelector.sort.ascending,
            basePath: this.getBasePath(),
            loading: true,
            successMessage: null,
            addEditAttributeDialogMode: AddEditAttributeDialog.mode.details,
            editAttribute: null,
        };

        this.filterPanelRef = React.createRef();

        this.setAttrPeopleListDialogRef = attrPeopleListDialog => {
            this.attrPeopleListDialog = attrPeopleListDialog;
        };

        this.setDefinitiomDialogRef = definitiomDialog => {
            this.definitiomDialog = definitiomDialog;
        };

        this.setEmployeeRef = ref => {
            this.employeeRef = ref;
        }

        window.onpopstate = e => this.parseLocationParams();
    }

    getBasePath() {
        return this.props.basePath;
    }

    componentDidMount() {
        super.componentDidMount();

        //this.context.setLoading('definitions', true);
        AttributeDefinitionList.get(this.context).then(list => {
            this.setState({ definitions: list });
        })
            .catch(error => {
                //Todo: handle error
                //alert('error 4553565: ' + error);
            })
            .finally(() => this.context.setLoading('definitions', false));
    }

    getActiveTab() {
        const { location, basePathDepth } = this.props;

        const pathSplit = location.pathname.split('/');
        return pathSplit.length > basePathDepth + 1 ? pathSplit[basePathDepth + 1] === 'gaps' || pathSplit[basePathDepth + 1] === 'competencies' ? pathSplit[basePathDepth + 1] : '' : '';
    }

    parseLocationParams() {
        const { location, basePathDepth } = this.props;

        const pathSplit = location.pathname.split('/');
        const activeTab = this.getActiveTab();

        const teamList = this.state.teamList;
        if (teamList) {
            if (activeTab === '') {
                this.selectedEmployee = teamList.getEmployee(pathSplit[basePathDepth + 1]);
                if (this.definitiomDialog) this.definitiomDialog.open(pathSplit[basePathDepth + 1] === 'classifications');
            }
            else {
                if (pathSplit.length === basePathDepth + 3) {
                    const attr = teamList[activeTab].getAttribute(pathSplit[basePathDepth + 2]);
                    if (this.attrPeopleListDialog) this.attrPeopleListDialog.open(attr, activeTab);
                }
                else if (this.attrPeopleListDialog) this.attrPeopleListDialog.close();
            }
        }
    }

    onHasUser(user) {
        if (!this.verifyAccess()) return;
        this.loadDetails();
    }

    openFilterPanel(e) {
        this.filterButton = e.target;
        if (this.filterPanelRef) this.filterPanelRef.current.open(e.target);
    }

    onFilterPanelClose(e) {
        if (this.filterPanelRef) this.filterPanelRef.current.close();
    }

    onFilterPanelApply(e, filterModel) {
        if (this.filterPanelRef) this.filterPanelRef.current.close();
        this.setState({ filterModel: filterModel }, () => this.filter());
    }

    onFilterChipsChange(e, filterModel) {
        this.setState({ filterModel: filterModel }, () => this.filter());
    }

    filter() {
        const { user, groupMode } = this.props;
        const { groupId, filterModel } = this.state;
        if (user && !((!groupMode && user.isTeamLead) || (groupMode && user.hasGroup))) return;

        if (this.filterPanelRef) this.filterPanelRef.current.setFilterModel(filterModel);

        //this.setState({ filterError: null });
        //if(filterModel && filterModel.isFiltered())
        this.updateUrlParams();
        this.loadDetails();
    }

    loadDetails() {
        const { groupId } = this.state;

        this.context.setLoading('loadingTeam', true);
        this.setState({ teamList: null, filterError: null, loading: true });

        TeamData.get(this.context, this.state.filterModel, groupId)
            .then(teamList => {
                this.updateFilterModelObjects(teamList);
            }).catch(e => {
                this.setState({ filterError: ErrorModel.parseServerError(e), loading: false });
            })
            .finally(() => {
                this.context.setLoading('loadingTeam', false);
            });
    }

    updateUrlParams() {
        const { history, location } = this.props;
        history.push(location.pathname + '?' + this.state.filterModel.toUrlParams());
    }

    updateFilterModelObjects(teamList) {

        const updatedFilterModel = this.state.filterModel.clone();

        let updated = false;
        updatedFilterModel.peopleIds.forEach(id => {
            const employee = teamList.getEmployee(id);
            if (employee) TeamFilterModel.pushIfUnique(updatedFilterModel.people, employee);
            updated = true;
        });
        updatedFilterModel.peopleIds = [];

        updatedFilterModel.attributeIds.forEach(id => {
            let attribute = this.state.definitions.getAttribute(id);
            if (attribute) TeamFilterModel.pushIfUnique(updatedFilterModel.attributes, attribute);
            else {
                attribute = this.state.definitions.getAttribute(id);
                if (attribute) TeamFilterModel.pushIfUnique(updatedFilterModel.attributes, attribute);
            }
            updated = true;
        });
        updatedFilterModel.attributeIds = [];

        let updateState = { teamList, loading: false };
        if (updated) {
            updateState = { ...updateState, filterModel: updatedFilterModel };
        }

        this.setState({ loading: false, teamList }, () => this.parseLocationParams());
        //this.setState(updateState);

        /*this.setState(updateState, () => this.parseLocationParams());*/
    }

    onAttributeSelect(e, attr) {
        const { basePath } = this.state;
        this.setLocation(basePath + '/competencies/' + attr.attributeDefinitionId, true);
        if (this.attrPeopleListDialog) this.attrPeopleListDialog.open(attr, 'competencies');
    }

    onGapSelect(e, attr) {
        const { basePath } = this.state;
        this.setLocation(basePath + '/gaps/' + attr.attributeDefinitionId, true);
        if (this.attrPeopleListDialog) this.attrPeopleListDialog.open(attr, 'gaps');
    }

    onCloseAttributePeopleListDialog() {
        const { basePath } = this.state;

        const activeTab = this.getActiveTab();
        this.setLocation(activeTab === '' ? basePath : basePath + '/' + activeTab, true);
        if (this.attrPeopleListDialog) this.attrPeopleListDialog.close();
    }

    onEmployeeSelect(e, employee) {
        const { location } = this.props;
        const { basePath } = this.state;
        this.prevPath = location.pathname;
        this.setLocation(basePath + '/' + employee.employeeId);
    }

    onShowEmployeeAttributeDetails(e, attr) {
        const { basePath } = this.state;
        const employeeId = this.getEmployeeIdFromPath();
        this.setLocation(basePath + '/' + employeeId + '/competencies/' + attr.employeeAttributeId);
    }

    onCloseEmployeeAttributeDetails(e, attr) {
        const { basePath } = this.state;
        const { location } = this.props;
        const employeeId = this.getEmployeeIdFromPath();
        const pathnameSplit = location.pathname.split('/');
        const isGapSection = pathnameSplit.includes('gaps');
        this.setLocation(basePath + '/' + employeeId + (isGapSection ? '/gaps' : '/competencies'));
        this.setState({ editAttribute: null })
    }

    onCloseEmployeeProfileDialog() {
        const { basePath } = this.state;
        this.setLocation(this.prevPath ? this.prevPath : basePath);
    }

    onCloseClassificationsDialog() {
        const { basePath } = this.state;
        this.setLocation(this.prevPath ? this.prevPath : basePath);
        if (this.definitiomDialog) this.definitiomDialog.close();
    }

    onViewAllClassifications(e, employee) {
        const { basePath } = this.state;
        this.setLocation(basePath + '/classifications');
        if (this.definitiomDialog) this.definitiomDialog.open();
    }

    getEmployeeIdFromPath() {
        const { location, basePathDepth } = this.props;
        const pathSplit = location.pathname.split('/');
        return pathSplit.length >= basePathDepth + 2 ? pathSplit[basePathDepth + 1] : null;
    }

    getAttributeIdFromPath() {
        const { location, basePathDepth } = this.props;
        const pathSplit = location.pathname.split('/');
        return pathSplit.length === basePathDepth + 3 ? pathSplit[basePathDepth + 2] : null;
    }

    setLocation(path, useNative) {
        const { history } = this.props;
        const { filterModel } = this.state;

        if (useNative === true) window.history.pushState(null, null, path + '?' + filterModel.toUrlParams());
        else history.push(path + '?' + filterModel.toUrlParams());
    }

    onExport(e) {
        const { filterModel, groupId } = this.state;

        if (this.filterPanelRef) this.filterPanelRef.current.setFilterModel(filterModel);

        this.setState({ filterError: null });
        this.updateUrlParams();
        this.context.setLoading('export', true);

        TeamDataExport.get(this.context, filterModel, groupId)
            .then(teamList => {
                this.setState({ showExportDialog: false });
            }).catch(e => {
                this.setState({ filterError: ErrorModel.parseServerError(e) });
            })
            .finally(() => this.context.setLoading('export', false));
    }

    onShowExportDialog(e) {
        this.setState({ showExportDialog: true });
    }

    onCancelExportDialog(e) {
        this.setState({ showExportDialog: false });
    }

    /*onExport(e) {
        const { onExport } = this.props;
        this.setState({ showExportDialog: false });
        if (onExport) onExport(e);
    }*/

    onSortOrderChange(e, sortOrder) {
        this.setState({ sortOrder });
    }

    onCloseAddEditAttributeDialog(refresh, mode) {
        const { basePath, editAttribute } = this.state;
        const employeeId = this.getEmployeeIdFromPath();
        const isGapSection = editAttribute?.isGap && editAttribute?.isGap();
        this.props.history.push(basePath + '/' + employeeId + (isGapSection ? '/gaps' : '/competencies'));
        this.setState({ editAttribute: null })
        if (refresh && this.employeeRef) {
            this.employeeRef.reload();
        }
        if (mode) {
            this.setState({ successMessage: mode === 'add' ? 'Competency added' : 'Competency edited' })
        }
        const { onBlockNavigation } = this.props;
        if (onBlockNavigation) onBlockNavigation(false, 'Employee attribute');
    }

    onSnackBarClose() {
        this.setState({ successMessage: null, error: null });
    }

    onEditEmployeeAttribute(e, attr) {
        const { basePath } = this.state;
        const employeeId = this.getEmployeeIdFromPath();
        if (attr?.isParent) return;
        this.setState({ editAttribute: attr, })
        const section = (attr?.isGap && attr?.isGap()) ? '/gaps/' : '/competencies/';
        this.setLocation(basePath + '/' + employeeId + section + attr.employeeAttributeId + '#edit');
    }
    onDeleteAttribute(e, attr) {
        this.setState({ deletingAttribute: attr });
    }
    onAddAttributeClickCb() {
        this.setState({ addEditAttributeDialogMode: AddEditAttributeDialog.mode.add })
    }

    onFeedbackAction(e, attr) {
        this.openAddEditAttributeDialog(e, attr, AddEditAttributeDialog.mode.feedback);
    }

    onProficiencyChangeAction(e, attr) {
        this.openAddEditAttributeDialog(e, attr, AddEditAttributeDialog.mode.proficiencyChange);
    }

    onShowAttributeDetails(e, attr) {
        if (attr.isNeedsFeedback()) this.onFeedbackAction(e, attr);
        else this.openAddEditAttributeDialog(e, attr, AddEditAttributeDialog.mode.details);
    }

    onAddChangeAction(e, attr) {
        this.openAddEditAttributeDialog(e, attr, AddEditAttributeDialog.mode.add);
    }

    openAddEditAttributeDialog(e, attribute, mode) {
        this.setState({ editAttribute: attribute, addEditAttributeDialogMode: mode }, state => {
            window.location = this.props.location.pathname + '#' + mode.hash;
        });
    }

    closeDeleteDialog() {
        this.setState({ deletingAttribute: null, deleteAttributeError: null });
    }


    deleteAttribute() {
        const { user } = this.props;
        const { deletingAttribute } = this.state;

        axios.delete(Data.apiBasePath + '/EmployeeAttribute', {
            data: {
                employeeAttributeId: deletingAttribute.employeeAttributeId
            },
            withCredentials: true
        })
            .then(response => {
                user.profile.removeAttribute(deletingAttribute);
                this.setState({ deletingAttribute: null, deleteAttributeError: null });
                this.reload();
            })
            .catch(e => {
                this.setState({ deleteAttributeError: ErrorModel.parseServerError(e) });
            });
    }


    render() {
        if (!this.verifyAccess()) return this.renderAccessDenied();

        const { organization, user, theme, location, treeMode, onChangeViewMode, groupMode, basePathDepth, onBlockNavigation } = this.props;
        const { filterModel, loading, teamList, definitions, filterError, showExportDialog, sortOrder, basePath, group, groupId, editAttribute, successMessage, addEditAttributeDialogMode, deletingAttribute, deleteAttributeError } = this.state;

        const pathSplit = location.pathname.split('/');
        const newFilterModel = filterModel.clone();

        let selectedEmployee = null;
        let selectedEmployeeAttributeId = null;

        let activeTab = this.getActiveTab();
        const isInstructorViewing = teamList && teamList.isInstructor;
        const isNotInstructor = teamList && teamList.isInstructor === false;

        if (teamList && activeTab === '') {
            selectedEmployee = teamList.getEmployee(pathSplit[basePathDepth + 1]);
            selectedEmployeeAttributeId = pathSplit[basePathDepth + 3] || null;
        }


        const isProcessingCompetencies = location.pathname?.includes('/competencies');
        const isProcessingGaps = location.pathname?.includes('/gaps');
        const addOrEditCompetencies = (isProcessingCompetencies || isProcessingGaps)
            && (location.hash === '#add'
                || location.hash?.includes('#edit')
                || location.hash === '#details');

        const isSelfView = selectedEmployee?.employeeId === user?.employeeId;

        return <View theme={theme} scrollToTopOnMount={true}>
            <Stack direction='row' spacing={2} justifyContent='space-between'>
                <ViewHeading text={groupMode ? group ? group.name : null : 'My team'} />

                <div>
                    {user && ((!groupMode && user.isTeamLead) || (groupMode && user.hasGroup)) &&
                        <Button
                            className='klaro_team-filterbutton'
                            label='Filters'
                            theme={theme}
                            size='md'
                            variant='filled'
                            startIcon={<FlterIcon />}
                            showLabelFromBreakpoint='md'
                            onClick={this.openFilterPanel.bind(this)} />
                    }

                    <TeamFilterPanel
                        ref={this.filterPanelRef}
                        theme={theme}
                        groupId={groupId}
                        definitions={definitions}
                        filterModel={newFilterModel}
                        fullScreenToBreakpoint='md'
                        anchor={this.filterButton}
                        includeExpiry={true}
                        onApply={this.onFilterPanelApply.bind(this)}
                        onClose={this.onFilterPanelClose.bind(this)} />
                </div>
            </Stack>

            {filterError && <Alert severity='error' sx={{ marginBottom: '20px' }}>{filterError}</Alert>}

            {filterModel && filterModel.countFilters() > 0 &&
                <TeamFilterChips
                    filterModel={filterModel}
                    onChange={this.onFilterChipsChange.bind(this)}
                    theme={theme}
                    showSingleToBreakpoint='md'
                    sx={{ marginBottom: '26px' }} />
            }

            <PillTabs
                user={user}
                organization={organization}
                theme={theme}
                path={basePath + (activeTab !== '' ? '/' + activeTab : '')}
                basePath={basePath}
                pages={Team.subPages}
                params={newFilterModel.toUrlParams()}
                sx={{ marginBottom: '41px' }} />

            {user && ((!groupMode && user.isTeamLead) || (groupMode && user.hasGroup)) && <>
                <Switch>
                    <Route exact={false} path={[basePath + '/competencies', basePath + '/competencies/:id']}>
                        <TeamAttributesSection
                            heading={(groupMode ? 'Group' : 'Team') + ' competencies'}
                            organization={organization}
                            user={user}
                            theme={theme}
                            loading={loading}
                            attributes={teamList ? teamList.attributes : null}
                            onAttributeSelect={this.onAttributeSelect.bind(this)}
                            allowExport={false}
                            sortOrder={sortOrder}
                            onSortOrderChange={this.onSortOrderChange.bind(this)}
                            onShowExportDialog={this.onShowExportDialog.bind(this)} />
                    </Route>
                    <Route exact={false} path={[basePath + '/gaps', basePath + '/gaps/:id']}>
                        <TeamGapsSection
                            heading={(groupMode ? 'Group' : 'Team') + ' gaps'}
                            organization={organization}
                            user={user}
                            theme={theme}
                            loading={loading}
                            attributes={teamList ? teamList.gaps : null}
                            onAttributeSelect={this.onGapSelect.bind(this)}
                            allowExport={false}
                            sortOrder={sortOrder}
                            onSortOrderChange={this.onSortOrderChange.bind(this)}
                            onShowExportDialog={this.onShowExportDialog.bind(this)} />
                    </Route>
                    <Route exact={false} path={[basePath, basePath + '/:id', basePath + '/:id/:subnav']} render={props => {
                        return (<div>
                            <TeamPeopleSection
                                heading={(groupMode ? 'Group' : 'Team') + ' members'}
                                organization={organization}
                                user={user}
                                theme={theme}
                                loading={loading}
                                teamList={teamList}
                                onEmployeeSelect={this.onEmployeeSelect.bind(this)}
                                onViewAllClassifications={this.onViewAllClassifications.bind(this)}
                                onExport={this.onExport.bind(this)}
                                allowExport={true}
                                sortOrder={sortOrder}
                                onSortOrderChange={this.onSortOrderChange.bind(this)}
                                onShowExportDialog={this.onShowExportDialog.bind(this)} />
                        </div>)
                    }} />
                </Switch>

            </>}

            {user && !user.isTeamLead && !groupMode &&
                <Placeholder
                    text={{ primary: 'Nothing to show yet', secondary: <>This section requires having employees report to you.<br />Go to Settings and set one or more employees to report to you.</> }}
                    icon={<UsersIcon />}
                    sx={{ height: '100vh', maxHeight: '60vh' }} />}

            {selectedEmployee &&
                <EmployeeProfileDialog
                    ref={this.setEmployeeRef}
                    user={user}
                    location={location}
                    organization={organization}
                    theme={theme}
                    treeMode={treeMode}
                    onChangeViewMode={onChangeViewMode}
                    employee={selectedEmployee}
                    employeeAttribute={editAttribute}
                    attribute={selectedEmployeeAttributeId || editAttribute?.employeeAttributeId}
                    isInstructorViewing={isInstructorViewing}
                    isNotInstructor={isNotInstructor}
                    onClose={this.onCloseEmployeeProfileDialog.bind(this)}
                    // onShowAttributeDetails={this.onShowEmployeeAttributeDetails.bind(this)}
                    onShowAttributeDetails={isSelfView
                        ? this.onShowAttributeDetails.bind(this)
                        : this.onEditEmployeeAttribute.bind(this)}
                    onCloseAttributeDialog={this.onCloseEmployeeAttributeDetails.bind(this)}
                    basePath={basePath + '/' + selectedEmployee.employeeId}
                    onEditAttribute={this.onEditEmployeeAttribute.bind(this)}
                    isTeamView={true}
                    isSelfView={isSelfView}
                    addOrEditCompetencies={addOrEditCompetencies}
                    addEditAttributeDialogMode={addEditAttributeDialogMode}
                    onAddAttributeClickCb={this.onAddAttributeClickCb.bind(this)}
                    onAddChangeAction={this.onAddChangeAction.bind(this)}
                    onDeleteAttribute={this.onDeleteAttribute.bind(this)}
                    onFeedbackAction={this.onFeedbackAction.bind(this)}
                    onProficiencyChangeAction={this.onProficiencyChangeAction.bind(this)}
                />}

            {selectedEmployee && addOrEditCompetencies && !isSelfView && isInstructorViewing
                ? <InstructorAddEditAttributeDialog
                    theme={theme}
                    user={user}
                    orgHasProficiency={organization && organization.showProficiency}
                    employee={selectedEmployee}
                    location={location}
                    onClose={this.onCloseAddEditAttributeDialog.bind(this)}
                    attribute={editAttribute}
                    onBlockNavigation={onBlockNavigation}
                />
                : null}

            {deletingAttribute &&
                <DeleteConfirmationDialog
                    theme={theme}
                    error={deleteAttributeError}
                    onClose={this.closeDeleteDialog.bind(this)}
                    onAccept={this.deleteAttribute.bind(this)} />}
            {
                successMessage &&
                <Snackbar
                    open={true}
                    duration={3000}
                    type='success'
                    onClose={this.onSnackBarClose.bind(this)}
                    message={successMessage}
                />
            }

            {teamList &&
                <JobClassiciationsDialog
                    theme={theme}
                    ref={this.setDefinitiomDialogRef}
                    classifications={teamList.classificationsSummary}
                    onClose={this.onCloseClassificationsDialog.bind(this)} />}

            <AttributePeopleListDialog
                ref={this.setAttrPeopleListDialogRef}
                organization={organization}
                theme={theme}
                onClose={this.onCloseAttributePeopleListDialog.bind(this)}
                onEmployeeSelect={(e, employee) => {
                    this.prevPath = location.pathname;
                    this.onEmployeeSelect(e, employee);
                }} />

            {showExportDialog && <ConfirmationDialog
                title='Export'
                theme={theme}
                question='An Excel document will be generated and sent to your Klayo registered email address once it’s ready.'
                cancelButton='Cancel'
                acceptButton='Export'
                onCancel={this.onCancelExportDialog.bind(this)}
                onAccept={this.onExport.bind(this)} />}
        </View>
    }
}