import React, { Component } from 'react';
import { Dialog as MuiDialog, DialogTitle, DialogActions, Stack, DialogContent, Slide } from "@mui/material";
import { AbstractDialog } from "../AbstractDialog";
import { AppContext } from '../../common/AppContext';
import { Button } from "../Button";
import { ReactComponent as CloseIcon } from '../../resources/images/icons-close.svg';
import { BreakpointTemplate } from "klayowebshared";
import { ActionsContainer } from "klayowebshared";

export class Dialog extends AbstractDialog {

    static contextType = AppContext;

    static defaultProps = {
        topCloseButton: true
    }

    static Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    constructor(props) {
        super(props);
    }

    onBackdropClick(e) {
    }

    onClose(e, reason) {
        const { onClose } = this.props;
        if(reason === 'backdropClick') return;
        if(onClose) onClose(e, reason);
    }

    render() {
        const { open, theme, fixedActions, onClose, fullWidth, className, title, children, maxWidth, maxHeight, actions, justifyActions, disabledToBreakpoint, topCloseButton } = this.props;
        const disabled = disabledToBreakpoint && theme && theme.isBreakpointDown(disabledToBreakpoint);

        return <MuiDialog
            className={'klayo-dialog' + (className ? ' ' + className : '')}
            open={open}
            fullWidth={fullWidth || true}
            // fullScreen={theme.isBreakpointDown('md')}
            onBackdropClick={this.onBackdropClick.bind(this)}
            onClose={this.onClose.bind(this)}
            PaperProps={{
                sx: {
                    borderRadius: { xs: '0!important', sm: '0!important', md: '16px!important' }
                }
            }}
            scroll={fixedActions ? 'paper' : 'body'}
            maxWidth={maxWidth}
            TransitionComponent={Dialog.Transition}>

            {title &&
                <DialogTitle
                    sx={{
                        fontSize: { xs: '20px', md: '24px' },
                        lineHeight: { xs: '28px', md: '32px' },
                        padding: { xs: '26px', md: '40px 40px 32px 40px' },
                        letterSpacing: { xs: '-0.3px', md: '-0.5px' }
                    }}
                >   
                    <Stack direction='row' alignItems='center'>
                        <div style={{ flexGrow: 1 }}>{title}</div>

                        {topCloseButton &&
                            <Button
                                icon={<CloseIcon />}
                                onClick={onClose}
                                sx={{ display: { xs: 'block', md: 'none' } }} />
                        }
                    </Stack>
                </DialogTitle>}

            <DialogContent
                sx={{
                    padding: { xs: '24px', md: '40px' },
                    maxHeight: maxHeight ? maxHeight : 'unset',
                    fontSize: '16px'
                }}>
                <BreakpointTemplate
                    theme={theme}
                    disabledToBreakpoint={disabledToBreakpoint}>
                    {children}
                </BreakpointTemplate>
            </DialogContent>

            {(actions || disabled) &&
                <DialogActions
                    sx={{
                        padding: { xs: '24px', md: '40px' }
                    }}>
                    <ActionsContainer
                        actions={actions}
                        justifyActions={justifyActions}
                        disabled={disabled}
                        onClose={onClose} />
                </DialogActions>}
        </MuiDialog>
    }
}
