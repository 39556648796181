import { format } from 'date-fns';
import Cookies from 'js-cookie'
import uniqueId from 'lodash.uniqueid'
export class Utils {
    static formatDate(s, type) {
        const date = Date.parse(s);
        if (isNaN(date)) return '';

        if (Utils.isToday(date)) return 'Today';

        const opts = type === 'long' ? {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        }
            : {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
            };

        return new Intl.DateTimeFormat('en-GB', opts).format(date);
    }

    static isToday(date) {
        if (typeof date.getDate !== 'function') return false;
        const today = new Date();
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
    }

    static toApiDate(date) {
        if (!date) return null;
        const offset = date.getTimezoneOffset()
        const d = new Date(date.getTime() - (offset * 60 * 1000))
        return d.toISOString().split('T')[0]
    }

    static formatReadableDate(date) {
        if (!date) return null;
        var yyyy = date.getFullYear().toString();
        var mm = (date.getMonth() + 1).toString();
        var dd = date.getDate().toString();

        return dd + '/' + mm + '/' + yyyy;
    }

    static parseApiDate(date) {
        return date ? new Date(Date.parse(date)) : date;
    }

    static formatProficiency(prof, valid) {
        return prof && prof > 0 && (valid === undefined || valid) ? Utils.formatNumber(prof, 1) : 'n/a'
    }

    static formatProficiencyFraction(currentProf, requiredProf, valid) {
        return currentProf && currentProf > 0 && requiredProf && requiredProf > 0 && (valid === undefined || valid) ? Utils.formatProficiency(currentProf, valid) + ' / ' + Utils.formatProficiency(requiredProf, valid) : 'n/a';
    }

    static formatPercent(n) {
        if (n === null || isNaN(n)) return 'n/a';
        return Math.ceil(n) + '%';
    }

    static roundNumber(num, dec = 2) {
        return +(Math.round(num + "e+" + dec) + "e-" + dec);
    }

    static zeroPadNumber(num, zeros) {
        return String(num).padStart(zeros, '0');
    }

    static formatNumber(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    static formatCurrency(num, dec = 0) {
        return parseInt(num, 10).toLocaleString('en-US', { minimumFractionDigits: dec });
    }

    static setCookie(name, value, days = 7, path = '/') {
        const expires = days === -1 ? 'Thu, 01 Jan 1970 00:00:00 GMT' : new Date(Date.now() + days * 864e5).toUTCString()
        //document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=' + path + ';samesite=none';
        //document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=' + path + ';samesite=none; domain=localhost:9631';
        //document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=' + path + ';samesite=none; domain=localhost';

        //Cookies.set(name, value, { expires: days, path, sameSite: 'none' })

        //alert(document.cookie);
    }

    static getCookie(name) {
        return document.cookie.split('; ').reduce((r, v) => {
            const parts = v.split('=')
            return parts[0] === name ? decodeURIComponent(parts[1]) : r
        }, '')
    }

    static truncate(input, limit) {
        if (!input) return input;
        return input.length > limit ? input.substring(0, limit) + '...' : input;
    }

    static deleteCookie(name, path) {
        Cookies.remove(name, { path, sameSite: 'none' })
    }

    static getDevOption(key) {
        return window.localStorage.getItem(key) === 'true';
    }

    static setDevOption(key, value) {
        window.localStorage.setItem(key, value);
    }

    static getLocalStorageOption(key) {
        return window.localStorage.getItem(key);
    }

    static checkLocalStorageOption(key) {
        return window.localStorage.getItem(key) === 'true';
    }

    static setLocalStorageOption(key, value) {
        window.localStorage.setItem(key, value);
    }

    static removeLocationHash() {
        window.history.pushState("", document.title, window.location.pathname, window.location.search);
    }

    static setLocation(history, path, useNative) {
        if (useNative === true) window.history.pushState(null, null, path);
        else history.push(path);
    }

    static isValidEmail(email) {
        return email ? email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) !== null : false;
    }

    static isTouchDevice() {
        return (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0));
    }

    static generateUniqueId(prefix) {
        return uniqueId(prefix)
    }

    static convertPracticalTime(time, at) {
        if (!time) return '';
        const splits = String(time).trim().split(' ');
        const suffix = splits[1].toLowerCase();
        const timeSegments = splits[0].split(':');
        const hour = timeSegments[0];
        const minute = this.zeroPadNumber(timeSegments[1], 2);
        return `${at ? 'at ' : ''}${hour}:${minute} ${suffix}`;
    }

    static toDtoDate(date) {
      if (!date) return null;
      return format(new Date(date), "yyyy-MM-dd'T'00:00:00")
    }
}