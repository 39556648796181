import { Data } from "../Data";
import { BillingInvoice } from "./BillingInvoice";

export class BillingInvoices extends Data {

    static instance = null;

    constructor(options) {
        super({
            api: '/Organization/Invoices', callback: data => {
                options.resolve(this, false);
            }, ...options
        });

        this.invoices = [];
    }

    onDataReceived(data) {
        data.invoices.forEach(i => {
            const invoice = new BillingInvoice(i);
            this.invoices.push(invoice);
        });
    }

    static get(context, updateCache) {
        return new Promise((resolve, reject) => {
            if (BillingInvoices.instance && updateCache !== true) resolve(BillingInvoices.instance, true);
            else BillingInvoices.instance = new BillingInvoices({ resolve, reject, context });
        });
    }
}