import { Component } from "react";
import { Dialog } from "../../components/dialogs/Dialog";

export class TourVideoDialog extends Component {

    render() {
        const { theme, organization, user, onClose } = this.props;

        return <Dialog
            theme={theme}
            open={true}
            onClose={onClose}
            fullWidth={true}
            title='Product Tour'
            maxWidth='lg'
            actions={[{
                label: 'Close',
                primary: true,
                onClick: onClose
            }]}>
            <div style={{ padding: '56.44% 0px 0px', position: 'relative' }}>
                <iframe src="https://player.vimeo.com/video/717702690?h=493e7cfd3e&amp;autoplay=1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                    style={{ position: 'absolute', width: '100%', height: '100%', top: '0' }}
                    title="Klayo Demo">
                </iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
        </Dialog>
    }
}