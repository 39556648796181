
import { Data } from "../Data";
import { HubConnectionBuilder } from '@microsoft/signalr'
import { Notification } from "./Notification";
import { DashboardData } from "../DashboardData";

export class NotificationsConnection extends Data {

    static instance = null;

    constructor(options) {
        super(options);

        Data.getAccessToken(options.context).then(accessToken => {
            const connection = new HubConnectionBuilder()
                .withUrl(`${window.app.env.API_URL}/live/notifications`, {
                    accessTokenFactory: () => accessToken
                }) 
                .build();

            connection.on('receiveNotification', notif => {
                const json = JSON.parse(notif);
                this.onNotificationReceived(this.parseNotification(json), json.NumberOfUnreadNotification);
            });

            connection.start()
                .then(() => {
                    options.resolve(this, false);
                })
                .catch(e => {
                    options.reject(e);
                });
        }).catch(e => {
            options.reject(e);
        });
    }

    parseNotification(json) {        
        return new Notification(json.Notification, DashboardData.getModeFromIndex(json.NotificationType));
    }

    onNotificationReceived(notif, numUnread) {
    }

    static get(context) {
        return new Promise((resolve, reject) => {
            if (NotificationsConnection.instance) resolve(NotificationsConnection.instance);
            else NotificationsConnection.instance = new NotificationsConnection({ resolve, reject, context });
        });
    }
}
