
import { Component } from "react";
import { Popover, Stack } from '@mui/material';
import { AttributeSelector } from "./selectors/AttributeSelector";
import { AttributeComplianceSelector } from "./selectors/AttributeComplianceSelector";
import { AttributeTypeSelector } from "./selectors/AttributeTypeSelector";
import { PeopleSelector } from "./selectors/PeopleSelector";
import { LocationSelector } from "./selectors/LocationSelector";
import { JobSelector } from "./selectors/JobSelector";
import { AppContext } from '../common/AppContext';
import { TeamFilterOptions } from "../data/filters/TeamFilterOptions";
import { Button } from "./Button";
import { DatePicker } from "./DatePicker";
import { TooltipIcon } from "./TooltipIcon";
import { ReactComponent as InfoIcon } from '../resources/images/icons-info-circle.svg';
import { Dialog } from "./dialogs/Dialog";
import { Alert } from "klayowebshared";
import { LocationSelectorList } from "../data/filters/LocationSelectorList";
import { JobSelectorList } from "../data/filters/JobSelectorList";
import { PersonSelectorList } from "../data/filters/PersonSelectorList";
import styled from '@emotion/styled';
import { KLAYO_COLORS } from "../themes";

export class TeamFilterPanel extends Component {

    static contextType = AppContext;

    static defaultProps = {
        includeExpiry: true
    }


    constructor(props) {
        super(props);

        this.state = {
            filterModel: this.props.filterModel,
            open: false,
            anchor: null,
            initialItems: {
                people: [],
                jobs: [],
                location: []
            },
            error: null
        };
    }

    componentDidMount() {
        this.getInitialFilterOptions();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.groupId !== this.props.groupId) this.getInitialFilterOptions();
    }

    getInitialFilterOptions() {
        const { groupId } = this.props;
        this.context.setLoading('filterOptions', true);

        TeamFilterOptions.get(this.context, groupId)
            .then(options => {
                this.setState({
                    initialItems: {
                        people: options.employees,
                        jobs: options.jobs,
                        locations: options.locations
                    }
                });
            }).catch(error => {
                //Todo: handle error
                //alert('error 44534365: ' + error);
            })
            .finally(() => this.context.setLoading('filterOptions', false));
    }

    open(anchor) {
        this.setState({ open: true, anchor });
    }

    close() {
        this.setState({ open: false });
    }

    onAttributeChange(e, attributes) {
        const newFilterModel = this.state.filterModel.clone();
        newFilterModel.attributes = attributes;
        this.updateFilterModel(e, newFilterModel);
    }

    onAttributeTypeChange(e) {
        const newFilterModel = this.state.filterModel.clone();
        newFilterModel.attributeType = e.target.value;
        if (newFilterModel.attributeType.key === 'all') newFilterModel.attributeType = null;
        this.updateFilterModel(e, newFilterModel);
    }

    onComplianceChange(e) {
        const newFilterModel = this.state.filterModel.clone();
        newFilterModel.compliance = e.target.value;
        if (newFilterModel.compliance.key === 'all') newFilterModel.compliance = null;
        this.updateFilterModel(e, newFilterModel);
    }

    onPeopleChange(e, people) {
        const newFilterModel = this.state.filterModel.clone();
        newFilterModel.people = people;
        this.updateFilterModel(e, newFilterModel);
    }

    onJobsChange(e, jobs) {
        const newFilterModel = this.state.filterModel.clone();
        newFilterModel.jobs = jobs;
        this.updateFilterModel(e, newFilterModel);
    }

    onLocationsChange(e, locations) {
        const newFilterModel = this.state.filterModel.clone();
        newFilterModel.locations = locations;
        this.updateFilterModel(e, newFilterModel);
    }

    onExpireAfterChange(date, valid) {
        const newFilterModel = this.state.filterModel.clone();
        newFilterModel.expireAfter = date;
        this.updateFilterModel(null, newFilterModel);
    }

    onExpireBeforeChange(date, valid) {
        const newFilterModel = this.state.filterModel.clone();
        newFilterModel.expireBefore = date;
        this.updateFilterModel(null, newFilterModel);
    }

    updateFilterModel(e, newFilterModel) {
        let error = null;
        if (newFilterModel.expireAfter && newFilterModel.expireBefore && newFilterModel.expireAfter > newFilterModel.expireBefore) {
            error = 'Expiry on or after date must be before expiry before or on date';
        }

        //if (newFilterModel.attributes === null || newFilterModel.attributes.length === 0) newFilterModel.attributeType = null;
        this.setState({ filterModel: newFilterModel, error }, () => {
            if (!error) this.onFilterChange(e, newFilterModel);
        });
    }

    onFilterChange(e, filterModel) {
    }

    onClose(e) {
        this.setState({ filterModel: this.props.filterModel, error: null }, () => this.props.onClose(e, this.state.filterModel));
    }

    onApply(e) {
        this.props.onApply(e, this.state.filterModel);
    }

    setFilterModel(filterModel) {
        this.setState({ filterModel: filterModel.clone() });
    }

    renderContent() {
        const { theme, definitions, includeExpiry, groupId } = this.props;
        const { filterModel, initialItems, error } = this.state;

        return <div>

            <Stack
                spacing={3}>
                {error && <Alert severity='error'>{error}</Alert>}
                <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={4}
                >
                    <PeopleSelector
                        value={filterModel.people}
                        label='Employee'
                        placeholder='Select employee'
                        focusPlaceholder='Type to filter selection'
                        initialItems={initialItems.people}
                        onChange={this.onPeopleChange.bind(this)}
                        getData={search => groupId ? PersonSelectorList.get(this.context, search, groupId) : null}
                        sx={{ width: '100%', maxWidth: { xs: 'auto', md: '288px' } }} />

                    <LocationSelector
                        value={filterModel.locations}
                        placeholder='Select location'
                        focusPlaceholder='Type to filter selection'
                        initialItems={initialItems.locations}
                        onChange={this.onLocationsChange.bind(this)}
                        getData={search => groupId ? LocationSelectorList.get(this.context, search, groupId) : null}
                        sx={{ width: '100%', maxWidth: { xs: 'auto', md: '288px' } }} />
                </Stack>

                <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={4}
                >
                    <JobSelector
                        value={filterModel.jobs}
                        placeholder='Select job'
                        focusPlaceholder='Type to filter selection'
                        initialItems={initialItems.jobs}
                        onChange={this.onJobsChange.bind(this)}
                        getData={search => groupId ? JobSelectorList.get(this.context, search, groupId) : null}
                        sx={{ width: '100%', maxWidth: { xs: 'auto', md: '288px' } }} />
                    <AttributeComplianceSelector
                        value={filterModel.compliance}
                        onChange={this.onComplianceChange.bind(this)}
                        sx={{ width: '100%', maxWidth: { xs: 'auto', md: '288px' } }} />
                </Stack>

                <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={4}
                >
                    <AttributeSelector
                        label='Competency'
                        placeholder='Select competencies'
                        focusPlaceholder='Type to filter selection'
                        floatLabel={false}
                        definitions={definitions}
                        attribute={filterModel.attributes}
                        allowMultiple={true}
                        onChange={this.onAttributeChange.bind(this)}
                        sx={{ width: '100%', maxWidth: { xs: 'auto', md: '288px' } }} />

                    <AttributeTypeSelector
                        value={filterModel.attributeType}
                        onChange={this.onAttributeTypeChange.bind(this)}
                        sx={{ width: '100%', maxWidth: { xs: 'auto', md: '288px' } }} />
                </Stack>
                {includeExpiry && <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={4}
                >
                    <Stack
                        direction='column'
                        sx={{ width: '100%' }}>
                        <Label>Competency expiry is on or after</Label>
                        <Stack
                            direction='row'
                            spacing={1}
                            sx={{ width: '100%' }}>
                            <DatePicker
                                theme={theme}
                                // label='Competency expiry is on or after'
                                value={filterModel.expireAfter}
                                onChange={this.onExpireAfterChange.bind(this)}
                                error={error}
                                errorMessage={error ? null : 'Invalid date'}
                                sx={{ width: '100%', maxWidth: { xs: 'auto', md: '288px' } }} />
                            <TooltipIcon
                                text='Search for all competencies expiring ON or AFTER the selected date'
                                icon={<InfoIcon />}
                            />
                        </Stack>
                    </Stack>

                    <Stack
                        direction='column'
                        sx={{ width: '100%' }}>
                        <Label>Competency expiry before or on</Label>
                        <Stack
                            direction='row'
                            spacing={1}
                            sx={{ width: '100%' }}>
                            <DatePicker
                                theme={theme}
                                // label='Competency expiry before or on'
                                value={filterModel.expireBefore}
                                onChange={this.onExpireBeforeChange.bind(this)}
                                error={error}
                                errorMessage={error ? null : 'Invalid date'}
                                sx={{ width: '100%', maxWidth: { xs: 'auto', md: '288px' } }} />
                            <TooltipIcon
                                text='Search for all competencies expiring BEFORE or ON the selected date'
                                icon={<InfoIcon />}
                            />
                        </Stack>
                    </Stack>
                </Stack>}
            </Stack>
        </div>
    }

    render() {
        const { theme, definitions, includeExpiry, fullScreenToBreakpoint } = this.props;
        const { anchor, open, filterModel, initialItems, error } = this.state;

        /*const validSelection = (filterModel.people !== null && filterModel.people.length > 0)
            || (filterModel.jobs !== null && filterModel.jobs.length > 0)
            || (filterModel.attributes !== null && filterModel.attributes.length > 0)
            || (filterModel.locations !== null && filterModel.locations.length > 0)
            || (filterModel.compliance !== null)
            || (filterModel.attributeType !== null);*/

        return theme.isBreakpointUp(fullScreenToBreakpoint) ?
            <Popover
                className='klayo-teamfilterpanel'
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{
                    className: 'klayo-paper'
                }}
                open={open}
                onClose={this.onClose.bind(this)}
                anchorEl={anchor}
                sx={{ width: '100%', maxWidth: '640px' }}
            >
                <h2>Filters</h2>

                {this.renderContent()}

                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                    sx={{ marginTop: '41px!important' }}
                >
                    <div><Button
                        label='Cancel'
                        variant='outlined'
                        onClick={this.onClose.bind(this)}
                        sx={{ flex: '1 1 auto' }} /></div>
                    <div><Button
                        label='Apply'
                        variant='filled'
                        disabled={error}
                        autoFocus sx={{ flex: '1 1 auto' }}
                        onClick={this.onApply.bind(this)} /></div>
                </Stack>

            </Popover>
            : <Dialog
                open={open}
                theme={theme}
                title='Filters'
                onClose={this.onClose.bind(this)}
                fullWidth={true}
                actions={[{
                    label: 'Apply',
                    primary: true,
                    disabled: error,
                    onClick: this.onApply.bind(this)
                }, {
                    label: 'Cancel',
                    onClick: this.onClose.bind(this)
                }]}>
                {this.renderContent()}
            </Dialog>
    }
}

const Label = styled.label`
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        line-height: 20px;
        color: ${KLAYO_COLORS.GreyGR700};
        padding-left: 14px;
        padding-bottom: 5px;
        display: block;
`