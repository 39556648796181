import { AbstractAttributeList } from "./AbstractAttributeList";
import { Data } from "../Data";
import { FeedbackAttribute } from "./FeedbackAttribute";
import { Feedback } from "../../views/feedback/Feedback";

export class FeedbackAttributeList extends AbstractAttributeList {

    static instance = null;

    constructor(options) {
        super({
            api: options.groupId ? '/EmployeeValidationRequest/groupId?groupId='+options.groupId : '/EmployeeValidationRequest', callback: data => {
                options.resolve(this, false);
            }, ...options
        });
    }

    onDataReceived(data) {
        this.attributes = Object.assign([], this.attributes);
        data.validationRequests.forEach((attr, index) => this.attributes.push(new FeedbackAttribute(index, attr)));
    }

    static get(context, updateCache, groupId) {
        return new Promise((resolve, reject) => {
            if (FeedbackAttributeList.instance && updateCache !== true && !groupId) resolve(FeedbackAttributeList.instance, true);
            else FeedbackAttributeList.instance = new FeedbackAttributeList({ resolve, reject, context, groupId });
        });
    }

    searchMatch(attribute, search) {
        const searchLower = search.toLowerCase();
        return attribute.jobName.toLowerCase().indexOf(searchLower) !== -1
            || attribute.attribute.toLowerCase().indexOf(searchLower) !== -1
            || attribute.employeeFullName.toLowerCase().indexOf(searchLower) !== -1;
    }

    filter(search, filter) {
        let filtered = filter.label !== Feedback.filters.all.label ? this.attributes.filter(a => a.stateLabel === filter.label) : this.attributes;

        if (search !== null && search.length > 0) {
            filtered = filtered.filter(a => this.searchMatch(a, search));
        }
        return filtered;
    }
}