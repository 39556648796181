import { Data } from "../Data";

export class OrganizationSettings extends Data {

    static instance = null;

    constructor(options) {
        super({
            api: '/Organization/Admin', callback: data => {
                options.resolve(this, false);
            }, ...options
        });

        this.locations = [];
    }

    onDataReceived(data) {
        this.organizationId = data.organization.organizationId;
        this.name = data.organization.name;
        this.organizationIntegrations = data.organization.organizationIntegrations;
        this.showProficiency = data.organization.showProficiency;
        this.address = data.organization.address;
        this.groupLevelTypes = data.organization.levelTypes.map(l => {
            return { id: l.groupManagerLevelTypeId, label: l.name };
        });
        this.groupNotificationTypes = data.organization.notificationTypes.map(l => {
            return { id: l.groupManagerNotificationTypeId, label: l.name };
        });

        data.organization.locations.forEach(l => this.locations.push(l));
    }

    getLocation(id) {
        return this.locations.find(l => l.locationId === id);
    }

    updateLocation(location) {
        const locations = [...this.locations];
        const index = locations.indexOf(this.getLocation(location.locationId));
        if (index !== -1) locations.splice(index, 1, location);
        this.locations = [...locations];
    }

    addLocation(location) {
        this.locations = [...this.locations];
        this.locations.push(location);
    }

    deleteLocation(location) {
        const locations = [...this.locations];
        const index = locations.indexOf(location);
        if (index !== -1) locations.splice(index, 1);
        this.locations = [...locations];
    }

    deleteAddress(){
        this.address = null;
    }

    addAddress(address) {
        this.address = address;
    }

    static get(context) {
        return new Promise((resolve, reject) => {
            if (OrganizationSettings.instance) resolve(OrganizationSettings.instance, true);
            else OrganizationSettings.instance = new OrganizationSettings({ resolve, reject, context });
        });
    }
}