import { SettingsAttribute } from "./SettingsAttribute";
import { Data } from "../Data";

export class SettingsAttributeList extends Data {

    static instance = null;
    static defaultPageSize = 50;

    constructor(options) {
        const { searchText, pageNumber, pageSize, orderBy, ...otherOptions } = options;

        const apiOptions = {
            api: `/AttributeDefinition/Admin`, callback: data => {
                options.resolve(this, false)
            }, ...otherOptions
        };

         // add params for api if provided
         const queryParams = [];
         if (searchText) queryParams.push(`searchText=${searchText}`);
         if (pageNumber) queryParams.push(`pageNumber=${pageNumber}`);
         if (pageSize) queryParams.push(`pageSize=${pageSize || SettingsAttributeList.defaultPageSize}`);
         if (orderBy) queryParams.push(`orderBy=${orderBy}`);
 
         if (queryParams.length > 0) {
             apiOptions.api += `?${queryParams.join('&')}`;
         }

        super(apiOptions);

        this.attributes = [];
        this.currentPage = 1;
        this.totalPages = null;
        this.totalCount = null;
        this.hasNext = false;
        this.hasPrevious = false;
    }

    onDataReceived(data) {
        // reformat data from api then assign to state
        const {currentPage, totalPages, totalCount, hasNext, hasPrevious} = data.attributeDefinitions;

        this.currentPage = currentPage;
        this.totalPages = totalPages;
        this.totalCount = totalCount;
        this.hasNext = hasNext;
        this.hasPrevious = hasPrevious;

        data.attributeDefinitions.data.forEach((attr, index) => this.attributes.push(new SettingsAttribute(attr)));
    }

    get(id) {
        return this.attributes.find(l => l.attributeId === id || l.attributeId === id);
    }

    update(attribute) {
        const attributes = [...this.attributes];
        const index = attributes.indexOf(this.get(attribute.attributeId));
        const updatedAttr = new SettingsAttribute(attribute);
        if (index !== -1) attributes.splice(index, 1, updatedAttr);
        this.attributes = [...attributes];
        return updatedAttr;
    }

    add(attribute) {
        const newAttr = new SettingsAttribute(attribute);
        this.attributes = [...this.attributes];
        this.attributes.push(newAttr);
        return newAttr;
    }

    delete(attribute) {
        const attributes = [...this.attributes];
        const index = attributes.indexOf(attribute);
        if (index !== -1) attributes.splice(index, 1);
        this.attributes = [...attributes];
    }

    static get(context, updateCache) {
        return new Promise((resolve, reject) => {
            if (SettingsAttributeList.instance && updateCache !== true) resolve(SettingsAttributeList.instance, true);
            else SettingsAttributeList.instance = new SettingsAttributeList({ resolve, reject, context });
        });
    }

    static getWithPaging(context, options) {
        const { searchText, pageNumber, pageSize, orderBy } = options;

        return new Promise((resolve, reject) => {
            SettingsAttributeList.instance = new SettingsAttributeList({searchText, pageNumber, pageSize, orderBy, resolve, reject, context});
        });
    }
}