
import { Stack } from "@mui/material";
import { Component } from "react";
import { PillButtons } from "../../components/PillButtons";
import { BillingPlan } from "../../data/billing/BillingPlan";
import { TextField } from "../../components/TextField";
import { CountrySelector } from "../../components/selectors/CountrySelector";

export class BillingDetails extends Component {
    static defaultProps = {
        disabled: true
    }

    constructor(props) {
        super(props);

        this.state = this.getBillingDetails(props.billingDetails);
        this.onChange(null);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.billingDetails !== prevProps.billingDetails) {
            this.setState(this.getBillingDetails(this.props.billingDetails));
        }
    }

    getBillingDetails(billingDetails) {
        const { organization } = this.props;

        return {
            company: billingDetails && billingDetails.company ? billingDetails.company : organization ? organization.name : '',
            billingAddress: billingDetails && billingDetails.billingAddress ? billingDetails.billingAddress : '',
            country: billingDetails && billingDetails.country ? billingDetails.country : null,
            city: billingDetails && billingDetails.city ? billingDetails.city : '',
            state: billingDetails && billingDetails.state ? billingDetails.state : '',
            zipCode: billingDetails && billingDetails.zipCode ? billingDetails.zipCode : ''
        }
    }

    onCompanyChange(e) {
        this.setState({ company: e.target.value }, () => this.onChange(e));
    }

    onBillingAddressChange(e) {
        this.setState({ billingAddress: e.target.value }, () => this.onChange(e));
    }

    onCountryChange(e, country) {
        this.setState({ country }, () => this.onChange(e));
    }

    onCityChange(e) {
        this.setState({ city: e.target.value }, () => this.onChange(e));
    }

    onStateChange(e) {
        this.setState({ state: e.target.value }, () => this.onChange(e));
    }

    onZipCodeChange(e) {
        this.setState({ zipCode: e.target.value }, () => this.onChange(e));
    }

    onChange(e) {
        const { onChange } = this.props;
        if (onChange) onChange(e, this.state);
    }

    render() {
        const { disabled } = this.props;
        const { company, billingAddress, country, city, state, zipCode } = this.state;

        return <div>
            <h2>Billing details</h2>
            <Stack spacing={5}>

                <TextField
                    label='Company name'
                    value={company}
                    clearable={false}
                    autoComplete={false}
                    onChange={this.onCompanyChange.bind(this)}
                    disabled={disabled}
                    sx={{ width: '100%' }} />

                <TextField
                    label='Billing address'
                    value={billingAddress}
                    clearable={false}
                    autoComplete={false}
                    onChange={this.onBillingAddressChange.bind(this)}
                    disabled={disabled}
                    sx={{ width: '100%' }} />

                <CountrySelector
                    value={country}
                    clearable={false}
                    placeholder='Select your country'
                    autoComplete={false}
                    onChange={this.onCountryChange.bind(this)}
                    disabled={disabled}
                    sx={{ width: '100%' }} />

                <Stack
                    direction='row'
                    spacing={4}
                    justifyContent='space-between'>

                    <TextField
                        label='City'
                        value={city}
                        clearable={false}
                        autoComplete={false}
                        onChange={this.onCityChange.bind(this)}
                        disabled={disabled}
                        sx={{ width: '100%' }} />

                    <TextField
                        label='State'
                        value={state}
                        clearable={false}
                        autoComplete={false}
                        onChange={this.onStateChange.bind(this)}
                        disabled={disabled}
                        sx={{ width: '100%' }} />

                    <TextField
                        label='Zip code'
                        value={zipCode}
                        clearable={false}
                        autoComplete={false}
                        onChange={this.onZipCodeChange.bind(this)}
                        disabled={disabled}
                        sx={{ width: '100%' }} />
                </Stack>
            </Stack>
        </div>
    }
}