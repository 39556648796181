import { Component } from "react";
import { Stack } from "@mui/material";
import { TextField } from "../../components/TextField";
import { Button } from "../../components/Button";
import { MultiFileUpload } from "../../components/MultiFileUpload";
import { ProficiencySelector } from "../../components/selectors/ProficiencySelector";
import { DatePicker } from "../../components/DatePicker";
import { RadioGroup } from "../../components/RadioGroup";
import { AbstractAttribute } from "../../data/attributes/AbstractAttribute";
import { RequestActivityFeed } from "../../components/RequestActivityFeed";
import { Alert } from "../../components/Alert";
import { Utils } from "../../common/Utils";
import { Dialog } from "../../components/dialogs/Dialog";
import { DragDropFileUpload } from "../../components/DragDropFileUpload";
import { DocumentList } from "../../components/table/DocumentList";
import { EditTemplate } from "klayowebshared";
import { SettingsAttribute } from "../../data/settings/SettingsAttribute";

export class EditAttributeDialog extends Component {

    static defaultProps = {
        allowNeedsFeedback: true,
        orgHasProficiency: false,
        newFileUploadComponent: true
    }

    static allowedMimeTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain', 'text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
    static maxFileSize = 10240000;
    static errorMimeType = 'Please upload a valid evidence file (doc, docx, pdf, txt, csv, xls, xlsx, png, or jpeg).';
    static errorFileSize = 'Please upload a valid evidence file (10Mb or less)';

    constructor(props) {
        super(props);

        this.state = {
            allowEditProficiency: this.isEditProficiencyAllowed(props.attribute),
            allowEditExpiry: this.isEditExpiryAllowed(props.attribute),
            proficiencyRequired: this.isProficiencyRequired(props.attribute),
            expiryRequired: this.isExpiryRequired(props.attribute),
            error: null,
            expiryDateValid: true,
            proficiencyError: false,
            expiryError: false,
            hasEdit: false
        };

        this.existingAttribute = new SettingsAttribute(props.attribute);
    }

    componentDidMount() {
        const { attribute } = this.props;
        if (!attribute.isNew && !attribute.requestActivity) {
            this.onLoadPreviousAttributeActivity(attribute);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { attribute, onLoadPreviousAttributeActivity } = this.props;
        if (prevProps.attribute !== this.props.attribute && !attribute.isNew && !attribute.requestActivity) {
            this.onLoadPreviousAttributeActivity(attribute);
        }
    }

    isEditProficiencyAllowed(attr) {
        if (!attr) return false;
        return attr.validationRequestState !== AbstractAttribute.status.feedback;
    }

    isEditExpiryAllowed(attr) {
        if (!attr) return false;
        return attr.validationRequestState !== AbstractAttribute.status.feedback;
    }

    isProficiencyRequired(attr) {
        if (!attr || !attr.hasProficiency) return false;
        return this.isEditProficiencyAllowed(attr);
    }

    isExpiryRequired(attr) {
        if (!attr || !attr.hasExpiryDate) return false;
        return this.isEditExpiryAllowed(attr);
    }

    onSave(e) {
        const { onSave, attribute, onBlockNavigation } = this.props;

        if (!this.validate()) return;
        if (onBlockNavigation) onBlockNavigation(false, 'Employee attribute');
        this.setState({ proficiencyError: false, expiryError: false });
        if (onSave) onSave(e, attribute);
    }

    validate() {
        const { attribute } = this.props;
        const { expiryDateValid } = this.state;

        if (this.isProficiencyRequired(attribute) && !attribute.hasValidPendingProficiency()) { this.setState({ error: 'Please select the competency proficiency level', proficiencyError: true }); return false; }
        if (this.isExpiryRequired(attribute) && (!attribute.hasValidPendingExpiryDate())) { this.setState({ error: 'Please select the competency expiry date', expiryError: true, proficiencyError: false }); return false; }

        return true;
    }

    onCommentChange(e) {
        const { attribute } = this.props;
        attribute.comment = e.target.value;
        this.updateAttributeState(attribute);
    }

    onProficiencyChange(e, proficiency) {
        const { attribute } = this.props;
        attribute.pendingProficiency = proficiency;
        this.updateAttributeState(attribute);
    }

    onFilesChange(e, files) {
        const { attribute } = this.props;
        attribute.files = files;
        this.updateAttributeState(attribute);
    }

    onStatusChange(e) {
        const { attribute } = this.props;
        attribute.validationRequestState = Object.values(AbstractAttribute.status)[e.target.value];
        this.updateAttributeState(attribute);
    }

    onExpiryDateChange(date, valid) {
        const { attribute } = this.props;
        attribute.pendingExpiryDate = date;
        this.updateAttributeState(attribute, valid);
    }

    updateAttributeState(attribute, expiryDateValid) {
        this.setState({
            attribute,
            allowEditProficiency: this.isEditProficiencyAllowed(attribute),
            allowEditExpiry: this.isEditExpiryAllowed(attribute),
            proficiencyRequired: this.isProficiencyRequired(attribute),
            expiryRequired: this.isExpiryRequired(attribute),
            expiryDateValid: expiryDateValid || this.state.expiryDateValid
        });
    }

    onShowUploadComponent(e) {
        this.setState({ showUploadComponent: true });
    }

    onDeleteDocument(e, doc) {
        const { attribute } = this.props;
        attribute.deleteDocument(doc);
        this.updateAttributeState(attribute);

        this.forceUpdate();
    }

    onEditStateChange(hasEdit) {
        this.setState({ hasEdit });
    }

    onNavigation(e, callback) {
        const { onNavigation } = this.props;
        return onNavigation && onNavigation(e, callback);
    }

    onCancel(e, source) {
        const { onCancel } = this.props;
        const { hasEdit } = this.state;

        if(hasEdit) this.onNavigation(e, this.stopBlockingNavAndClose.bind(this));
        else if (onCancel) onCancel(e);
    }

    stopBlockingNavAndClose(e) {
        const { onCancel, onBlockNavigation } = this.props;
        if (onBlockNavigation) onBlockNavigation(false, 'Employee attribute');
        if (onCancel) onCancel(e);
    }

    onLoadPreviousAttributeActivity(attribute) {
        const { onLoadPreviousAttributeActivity } = this.props;

        if (onLoadPreviousAttributeActivity) onLoadPreviousAttributeActivity(attribute, loadedAttr => {
            this.existingAttribute = new SettingsAttribute(loadedAttr);
            this.setState({ attribute: loadedAttr });
        });
    }

    render() {
        const { user, employee, attribute, theme, onCancel, allowNeedsFeedback, orgHasProficiency, onDocumentClick, newFileUploadComponent, onAllowNavigation, onBlockNavigation } = this.props;
        const { allowEditProficiency, allowEditExpiry, proficiencyRequired, expiryRequired, error, proficiencyError, expiryError, hasEdit } = this.state;

        const existingDocs = attribute.documents && attribute.documents.list.length > 0;
        const loadedFeed = attribute.isNew || attribute.requestActivity;

        return <EditTemplate
            theme={theme}
            name='Employee attribute'
            onAllowNavigation={onAllowNavigation}
            onBlockNavigation={onBlockNavigation}
            onEditStateChange={this.onEditStateChange.bind(this)}
            detectEdit={true}
            compare={{
                existing: this.existingAttribute,
                editing: attribute,
                members: [
                    {
                        name: 'attribute',
                        detectEdit: (existing, editing) => {
                            return !editing.isSame(existing);
                        }
                    }]
            }}>
            <Dialog
                theme={theme}
                open={true}
                onClose={onCancel}
                fullWidth={true}
                maxWidth='sm'
                title='Edit competency'
                actions={[{
                    label: 'Save',
                    primary: true,
                    disabled: !hasEdit || !loadedFeed,
                    onClick: this.onSave.bind(this)
                }, {
                    label: 'Cancel',
                    onClick: this.onCancel.bind(this)
                }]}>
                <Stack spacing={5}>
                    {error && <Alert severity='error' scrollTo={true}>{error}</Alert>}

                    <TextField
                        label='Competency'
                        disabled={true}
                        clearable={false}
                        value={attribute.name}
                    />

                    <RadioGroup
                        label='Competency status'
                        items={[
                            {
                                value: AbstractAttribute.status.approved.index,
                                label: 'Approved'
                            },
                            {
                                value: AbstractAttribute.status.feedback.index,
                                label: 'Needs employee feedback'
                            },
                            {
                                value: AbstractAttribute.status.review.index,
                                label: 'Pending manager feedback',
                                helperText: !allowNeedsFeedback ? 'A reporting manager needs to be assigned to this employee' : null,
                                disabled: !allowNeedsFeedback
                            }]}
                        value={attribute && attribute.validationRequestState ? attribute.validationRequestState.index : null}
                        disabled={!loadedFeed}
                        onChange={this.onStatusChange.bind(this)}
                    />

                    {attribute && orgHasProficiency && attribute && attribute.hasProficiency &&
                        <ProficiencySelector
                            label={'Proficiency' + (proficiencyRequired ? ' (required)' : '')}
                            proficiency={attribute.validationRequestState === AbstractAttribute.status.feedback ? (attribute.requestedProficiency || attribute.currentProficiency) : attribute.pendingProficiency}
                            originalProficiency={attribute.currentProficiency || attribute.requestedProficiency}
                            forceShowOriginal={false}
                            disabled={!allowEditProficiency || !loadedFeed}
                            autoFocus={proficiencyError}
                            error={proficiencyError}
                            onChange={this.onProficiencyChange.bind(this)} />}

                    {attribute && attribute.hasExpiryDate &&

                        <Stack>
                            <DatePicker
                                theme={theme}
                                label={'Expiry date' + (expiryRequired ? ' (required)' : '')}
                                onChange={this.onExpiryDateChange.bind(this)}
                                value={attribute.validationRequestState === AbstractAttribute.status.feedback ?
                                    attribute.requestedExpiryDate || attribute.currentExpiryDate ? new Date(attribute.requestedExpiryDate || attribute.currentExpiryDate) : null :
                                    attribute.pendingExpiryDate ? new Date(attribute.pendingExpiryDate) : null}
                                allowPast={true}
                                showPastWarning={allowEditExpiry}
                                showPastWarningMessage='The selected expiry date is in the past. You can save it as is, but the competency will expire overnight.'
                                allowEmpty={false}
                                autoFocus={expiryError}
                                error={expiryError}
                                helperText={attribute.currentExpiryDate ? 'Current expiry: ' + Utils.formatReadableDate(attribute.currentExpiryDate) : null}
                                disabled={!allowEditExpiry || !loadedFeed}
                            //validationMethod={this.checkValidExpiry.bind(this)}
                            //validationText='Please enter a valid expiry date'
                            />
                        </Stack>}

                    {existingDocs && <div>
                        <DocumentList
                            theme={theme}
                            label='Evidence'
                            files={attribute ? attribute.files : null}
                            onViewDocument={onDocumentClick}
                            onDeleteDocument={this.onDeleteDocument.bind(this)}
                            documents={attribute.documents.list}
                            onFilesChange={this.onFilesChange.bind(this)}
                            disabled={!loadedFeed}
                            allowedMimeTypes={EditAttributeDialog.allowedMimeTypes}
                            maxFileSize={EditAttributeDialog.maxFileSize}
                            errorMimeType={EditAttributeDialog.errorMimeType}
                            errorFileSize={EditAttributeDialog.errorFileSize} />
                    </div>}

                    {(!existingDocs) && newFileUploadComponent && <DragDropFileUpload
                        label={existingDocs ? null : 'Evidence'}
                        files={attribute ? attribute.files : null}
                        accept='csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                        onChange={this.onFilesChange.bind(this)}
                        disabled={!loadedFeed}
                        allowedMimeTypes={EditAttributeDialog.allowedMimeTypes}
                        maxFileSize={EditAttributeDialog.maxFileSize}
                        errorMimeType={EditAttributeDialog.errorMimeType}
                        errorFileSize={EditAttributeDialog.errorFileSize} />}

                    {(!existingDocs) && !newFileUploadComponent && <MultiFileUpload
                        label="Evidence"
                        files={attribute ? attribute.files : null}
                        placeholder="Add supporting documents"
                        accept='csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                        onChange={this.onFilesChange.bind(this)}
                        disabled={!loadedFeed}
                        InputLabelProps={{ shrink: true }}
                        helperText='Supported document types: PDF, JPEG, PNG, DOC, DOCX'
                        allowedMimeTypes={EditAttributeDialog.allowedMimeTypes}
                        maxFileSize={EditAttributeDialog.maxFileSize}
                        errorMimeType={EditAttributeDialog.errorMimeType}
                        errorFileSize={EditAttributeDialog.errorFileSize}
                        sx={{ width: '100%' }}
                    />}

                    <TextField
                        label='Comments'
                        value={attribute.comment}
                        autoComplete={false}
                        clearable={false}
                        placeholder="Add any comments or notes"
                        disabled={!loadedFeed}
                        onChange={this.onCommentChange.bind(this)}
                        sx={{ width: '100%' }} />

                    {attribute.requestActivity &&
                        <RequestActivityFeed
                            user={user}
                            employee={employee}
                            attribute={attribute}
                            documents={attribute.documents}
                            activity={attribute.requestActivity}
                            onDocumentClick={onDocumentClick}
                            sx={{ marginTop: '-20px' }} />
                    }
                </Stack>
            </Dialog>
        </EditTemplate>
    }
}