import { Component } from "react";
import { Paper } from '../../components/Paper';
import { Link } from "react-router-dom";
import { AddEditJobView } from "./AddEditJobView";
import { Divider, Stack, MenuItem, Menu, ListItemText } from "@mui/material";
import { Button } from "../../components/Button";
import { TextField } from "../../components/TextField";
import { ReactComponent as AddIcon } from '../../resources/images/icons-add.svg';
import { ReactComponent as SearchIcon } from '../../resources/images/icons-search.svg';
import { JobTable } from "../../components/table/JobTable";
import { SettingsJob } from "../../data/settings/SettingsJob";
import { ErrorModel } from '../../common/models/ErrorModel';
import { Data } from '../../data/Data';
import { AppContext } from '../../common/AppContext';
import { AssignEmployeeDialog } from "./AssignEmployeeDialog";
import { AssignManagerDialog } from "./AssignManagerDialog";
import { ConfirmationDialog } from "klayowebshared";
import { TablePlaceholder } from "../../components/TablePlaceholder";
import { ReactComponent as JobsIcon } from '../../resources/images/icons-briefcase.svg';
import { ViewComponent } from "../../components/ViewComponent";
import { SettingsJobList } from "../../data/settings/SettingsJobList";
import { getMaxPossiblePageSize, trimToLowercase } from "../../utilities";
import { TableSearchDebounceTime } from "../../constants";
import { AttributeActionMenu } from "../../components/AttributeActionMenu";
import { cloneDeep } from "lodash";

const axios = require('axios').default;

export class SettingsJobsView extends ViewComponent {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            ...ViewComponent.state,
            search: null,
            showAssignEmployeeDialog: false,
            showAssignManagerDialog: false,
            focusPosition: null,
            assignEmployeeError: null,
            assignManagerError: null,
            unassignEmployee: null,
            unassignEmployeeError: null,
            deletingJob: null,
            deletingJobError: null,
            saveJobError: null,
            pageSize: SettingsJobList.defaultPageSize,
            deletedIds: []
        };

        this.debounceTimer = null;
        this.actionTarget = null;
        this.actionJob = null;
        this.actionPosition = null;
        this.actionVacancy = null;

        this.setMenuRef = menu => {
            this.menu = menu;
        };

        this.setTableRef = tableRef => {
            this.tableRef = tableRef;
        };
    }

    componentDidMount() {
        const { onLoadJobs, onLoadAttributes, attributes, categories, onLoadCategories } = this.props;
        if (!attributes) onLoadAttributes();
        if (!categories) onLoadCategories();
        onLoadJobs();
    }

    onViewChange(location) {
        if (this.pathEquals('/settings/jobs') || this.pathEquals('/settings')) this.resetErrors();
    }

    resetErrors() {
        this.setState({ saveJobError: null, deletingJobError: null, unassignEmployeeError: null, assignManagerError: null, assignEmployeeError: null });
    }

    onSearch(e) {
        const { onLoadJobs } = this.props;
        const { pageSize } = this.state;

        // clearTimeout(this.debounceTimer);

        // this.debounceTimer = setTimeout(() => {
        //     onLoadJobs(true, e.target.value, pageSize);
        // }, TableSearchDebounceTime);

        this.setState({ search: e.target.value });
    }

    // load data of paging page from server
    onPageChange(page, pageSize) {
        const { onLoadJobsPaging } = this.props;
        const { search } = this.state;

        // Data grid page start at 0
        onLoadJobsPaging(page + 1, this.state.pageSize, search);
    }

    onPageSizeChange(pageSize, paginationMode) {
        const { onLoadJobsPageSize, jobs } = this.props;
        const { search } = this.state;

        this.setState({ pageSize });

        if (paginationMode === 'server') {
            let currentPage = jobs.currentPage;
            const maxPossiblePageSize = getMaxPossiblePageSize(jobs.totalCount, pageSize);

            // prevent currentPage from overflow when increase page size
            if (currentPage > maxPossiblePageSize) {
                currentPage = maxPossiblePageSize;
            }

            onLoadJobsPageSize(currentPage, pageSize, search);
        }
    }

    onPositionAction(e, position) {
        if (this.menu) this.menu.open(e.target)
        this.actionTarget = e.target;
        this.actionPosition = position;
        this.actionVacancy = position.isVacancy ? position : null;
        this.targetPosition = position;
    }

    onJobAction(e, job) {
        if (this.menu) this.menu.open(e.target)
        this.actionTarget = e.target;
        this.actionJob = job
    }

    onJobSelect(e, job) {
        this.props.history.push('/settings/jobs/edit/' + job.jobDefinitionId);
    }

    onCloseActionMenu() {
        this.actionTarget = null;
        this.actionJob = null;
        this.actionPosition = null;
        this.actionVacancy = null;
        if (this.menu) this.menu.close();
    }

    onEditJob() {
        const { actionJob } = this;
        this.onCloseActionMenu();

        this.props.history.push('/settings/jobs/edit/' + actionJob.jobDefinitionId);
    }

    onDeleteJob() {
        const { actionJob } = this;
        this.setState({ deletingJob: actionJob });
        this.onCloseActionMenu();
    }

    onRemoveFromJob() {
        const { actionPosition } = this;
        this.setState({ unassignEmployee: actionPosition });
        this.onCloseActionMenu();
    }

    onAddVacancy(e) {
        const { jobs, onLoadJobs, onDataChanged } = this.props;
        const { actionJob } = this;
        this.context.setLoading('addjob', true);

        axios
            .post(Data.apiBasePath + '/Job', actionJob.toApiDto(), {
                withCredentials: true
            })
            .then(response => {
                const tempJobs = cloneDeep(jobs);
                const [vacancy, job] = tempJobs.addVacancy(actionJob.jobDefinitionId, response.data);
                this.setState({ focusPosition: actionJob, successSnackbar: 'Vacancy created' });
                if (onDataChanged) onDataChanged('jobs');
                actionJob.numberOfPositions = job.numberOfPositions;
                this.tableRef?.apiRef?.current?.updateRows([vacancy])
                // if (onLoadJobs) onLoadJobs(true);
            })
            .catch(e => {
                this.setState({ errorSnackbar: ErrorModel.parseServerError(e) });
            })
            .finally(() => {
                this.context.setLoading('addjob', false);
            });

        this.setState({ focusPosition: actionJob });
        this.onCloseActionMenu();
    }

    onDeleteVacancy(e) {
        const { jobs, onLoadJobs, onDataChanged } = this.props;
        const { actionPosition } = this;

        this.context.setLoading('deleting', true);

        axios
            .delete(Data.apiBasePath + '/Job', {
                data: {
                    jobId: actionPosition.jobId
                },
                withCredentials: true
            })
            .then(response => {
                // jobs.deletePosition(actionPosition);
                this.setState({ successSnackbar: 'Vacancy deleted' });
                if (onDataChanged) onDataChanged('jobs');
                const findJob = jobs?.jobs?.find(j => j.id === actionPosition?.job?.id);
                if (findJob) {
                  findJob.numberOfPositions = findJob?.numberOfPositions ? findJob?.numberOfPositions - 1 : 0;
                }
                const allIds = this.tableRef?.apiRef.current.getAllRowIds();
                const findId = allIds.find(id => id?.includes(actionPosition?.rowId));
                actionPosition.deleted = true;
                this.tableRef?.apiRef?.current?.updateRows([actionPosition]);
                this.tableRef.deletedIds = [...this.tableRef.deletedIds, findId];
                const el = document.querySelector(`[data-id=${findId}]`);
                el.style.display = "none"
                // if (onLoadJobs) onLoadJobs(true);
            })
            .catch(e => {
                this.setState({ errorSnackbar: ErrorModel.parseServerError(e) });
            })
            .finally(() => {
                this.context.setLoading('deleting', false);
            });

        this.onCloseActionMenu();
    }

    onEditReportsTo() {
        const { actionPosition } = this;
        this.onCloseActionMenu();
        this.props.history.push('/settings/jobs/assign-manager/' + actionPosition.jobId);
    }

    onAssignEmployee() {
        const { actionPosition } = this;
        this.onCloseActionMenu();
        this.props.history.push('/settings/jobs/assign-employee/' + actionPosition.jobId);
    }

    onSaveAssignedEmployee(e, position, employee) {
        const { jobs, onLoadJobs, onDataChanged } = this.props;

        this.context.setLoading('assignEmployee', true);

        axios
            .put(Data.apiBasePath + '/Job', {
                jobId: position?.jobId,
                employeeId: employee.employeeId,
                reportsToId: position?.manager ? position.manager?.jobId : null,
            }, {
                withCredentials: true
            })
            .then(response => {
                const [updated, oldPosition] = jobs.replacePosition(position, employee);
                if (onDataChanged) onDataChanged('jobs');
                // if (onLoadJobs) onLoadJobs(true);
                const updatedArray = [];
                (updated && updatedArray.push(updated));
                (oldPosition && updatedArray.push(oldPosition));
                this.tableRef?.apiRef?.current?.updateRows(updatedArray)
                this.setState({ successSnackbar: 'Employee assigned' });
                this.props.history.push('/settings/jobs');
            })
            .catch(e => {
                this.setState({ assignEmployeeError: ErrorModel.parseServerError(e) });
            })
            .finally(() => {
                this.context.setLoading('assignEmployee', false);
            });
    }

    onSaveAssignedManager(e, position, manager) {
        const { jobs, onLoadJobs, onDataChanged } = this.props;

        this.context.setLoading('assignManager', true);

        axios
            .put(Data.apiBasePath + '/Job', {
                jobId: position.jobId,
                employeeId: position.employeeId,
                reportsToId: manager ? manager.jobId : null,
            }, {
                withCredentials: true
            })
            .then(response => {
                position.manager = manager;
                this.props.history.push('/settings/jobs');
                jobs.updatePosition(position);
                if (onDataChanged) onDataChanged('jobs');
                this.tableRef?.apiRef?.current?.updateRows([position])
                // if (onLoadJobs) onLoadJobs(true);
                this.setState({ assignManagerError: null });
            })
            .catch(e => {
                this.setState({ assignManagerError: ErrorModel.parseServerError(e) });
            })
            .finally(() => {
                this.context.setLoading('assignManager', false);
            });
    }

    onCancelUnassignEmployee(e) {
        this.setState({ unassignEmployee: null, unassignEmployeeError: null });
    }

    onUnassignEmployeeConfirmed(e) {
        const { jobs, onLoadJobs, onDataChanged } = this.props;
        const { unassignEmployee } = this.state;

        this.context.setLoading('removeEmployee', true);

        axios
            .put(Data.apiBasePath + '/Job', {
                jobId: unassignEmployee.jobId,
                employeeId: null,
                reportsToId: unassignEmployee.manager ? unassignEmployee.manager.jobId : null,
            }, {
                withCredentials: true
            })
            .then(response => {
                jobs.vacatePosition(unassignEmployee);
                if (onDataChanged) onDataChanged('jobs');
                // if (onLoadJobs) onLoadJobs(true);
                this.tableRef?.apiRef?.current?.updateRows([unassignEmployee])
                this.setState({ unassignEmployee: null, unassignEmployeeError: null });
            })
            .catch(e => {
                this.setState({ unassignEmployeeError: ErrorModel.parseServerError(e) });
            })
            .finally(() => {
                this.context.setLoading('removeEmployee', false);
            });
    }

    onCancelDeleteJob(e) {
        this.setState({ deletingJob: null, deletingJobError: null });
    }

    onDeleteJobConfirmed(e) {
        const { jobs, onLoadJobs, onDataChanged } = this.props;
        const { deletingJob } = this.state;

        this.context.setLoading('deleteJob', true);

        axios
            .delete(Data.apiBasePath + '/JobDefinition', {
                data: {
                    jobDefinitionId: deletingJob.jobDefinitionId
                },
                withCredentials: true
            })
            .then(response => {
                this.setState({ deletingJob: null, deletingJobError: null, successSnackbar: 'Job deleted' });
                jobs.delete(deletingJob);
                if (onDataChanged) onDataChanged('jobs');
                if (onLoadJobs) onLoadJobs(true);
            })
            .catch(e => {
                this.setState({ deletingJobError: ErrorModel.parseServerError(e) });
            })
            .finally(() => {
                this.context.setLoading('deleteJob', false);
            });
    }

    onSaveJob(job) {
        const { jobs, onLoadJobs, onLoadAttributes, onDataChanged } = this.props;

        this.context.setLoading('addJob', true);

        if (!job.jobDefinitionId) {
            axios
                .post(Data.apiBasePath + '/JobDefinition', job.toApiDto(), {
                    withCredentials: true
                })
                .then(response => {
                    //jobs.add(job);
                    if (onDataChanged) onDataChanged('jobs');
                    if (onLoadJobs) onLoadJobs(true);
                    if (onLoadAttributes) onLoadAttributes(true);
                    this.props.history.push('/settings/jobs');
                })
                .catch(e => {
                    this.setState({ saveJobError: ErrorModel.parseServerError(e) });
                })
                .finally(() => {
                    this.context.setLoading('addJob', false);
                });
        }
        else {
            axios
                .put(Data.apiBasePath + '/JobDefinition', job.toApiDto(), {
                    withCredentials: true
                })
                .then(response => {
                    //jobs.update(job);
                    if (onDataChanged) onDataChanged('jobs');
                    if (onLoadJobs) onLoadJobs(true);
                    if (onLoadAttributes) onLoadAttributes(true);
                    this.props.history.push('/settings/jobs');
                })
                .catch(e => {
                    this.setState({ saveJobError: ErrorModel.parseServerError(e) });
                })
                .finally(() => {
                    this.context.setLoading('addJob', false);
                });
        }
    }
    renderActionMenu() {
        const { actionVacancy, actionJob, actionPosition } = this;
        if (!!actionVacancy || !!actionPosition) {
            return <div>{
                actionVacancy && <div><MenuItem onClick={this.onAssignEmployee.bind(this)}>
                    <ListItemText>Assign employee</ListItemText>
                </MenuItem><Divider /></div>
            }
                <MenuItem onClick={this.onEditReportsTo.bind(this)}>
                    <ListItemText>Edit reports to</ListItemText>
                </MenuItem>
                <MenuItem onClick={actionVacancy ? this.onDeleteVacancy.bind(this) : this.onRemoveFromJob.bind(this)}>
                    <ListItemText>{actionVacancy ? 'Delete vacancy' : 'Remove employee'}</ListItemText>
                </MenuItem>
            </div>
        }
        if (!!actionJob) {
            return <div>
                <MenuItem onClick={this.onEditJob.bind(this)}>
                    <ListItemText>Edit job</ListItemText>
                </MenuItem>
                <MenuItem onClick={this.onDeleteJob.bind(this)} disabled={actionJob ? actionJob.numberOfPositions !== 0 : true}>
                    <ListItemText>Delete job</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem onClick={this.onAddVacancy.bind(this)}>
                    <ListItemText>Add vacancy</ListItemText>
                </MenuItem>
            </div>
        }
    }

    render() {
        const { organization, theme, user, location, history, jobs, employees, managers, onDataChanged, onLoadEmployees, onLoadManagers, onShowNewAttributeDialog, onNavigation, onBlockNavigation, onAllowNavigation } = this.props;
        const { search, focusPosition, assignEmployeeError, assignManagerError, unassignEmployee, unassignEmployeeError, deletingJob, deletingJobError, saveJobError } = this.state;

        const path = location.pathname;
        const editMode = path.startsWith('/settings/jobs/edit/');
        const showAddEditJobView = path.startsWith('/settings/jobs/new') || editMode;

        const showAssignEmployeeDialog = path.startsWith('/settings/jobs/assign-employee/');
        const showAssignManagerDialog = path.startsWith('/settings/jobs/assign-manager/');

        let editJob = null;
        let editJobId = null;
        if (editMode) editJobId = path.replace('/settings/jobs/edit/', '');
        if (jobs) editJob = jobs.get(editJobId);

        let assignToPosition = null;
        let assignToPositionId = null;
        if (showAssignEmployeeDialog) assignToPositionId = path.replace('/settings/jobs/assign-employee/', '');
        else if (showAssignManagerDialog) assignToPositionId = path.replace('/settings/jobs/assign-manager/', '');
        if (jobs) assignToPosition = jobs.getPosition(assignToPositionId);

        let filterItems = [{ id: 'search', columnField: 'profilePicFullName', operatorValue: 'contains', value: search }];

        const filterJobs =  jobs ? [...jobs.positions, ...jobs.jobs].filter(pos => {
          if (search) {
            const keyword = trimToLowercase(search || '');
            const jobName = trimToLowercase(pos?.name || '');
            const posName = trimToLowercase(pos?.job?.name || '');
            return pos?.isJob ? jobName?.includes(keyword) : posName?.includes(keyword)
          } else {
            return true;
          }
        }) : []

        return (
            <div>
                {!showAddEditJobView &&
                    <Paper
                        theme={theme}
                        padding={{ xs: '46px 24px', md: '60px!important' }}
                        borderFromBreakpoint='md'>
                        <h1>Jobs</h1>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                            sx={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)', padding: '30px 0' }}>
                            <TextField
                                value={search}
                                dense={true}
                                placeholder='Search jobs'
                                disabled={user === null}
                                fullWidth={true}
                                autoComplete={false}
                                leadingIcon={<SearchIcon />}
                                onChange={this.onSearch.bind(this)}
                                sx={{ maxWidth: { md: '300px' } }} />
                            <Button
                                path={'/settings/jobs/new'}
                                size='md'
                                theme={theme}
                                variant='filled'
                                showLabelFromBreakpoint='md'
                                startIcon={<AddIcon />}
                                label='New job' />
                        </Stack>

                        <JobTable
                            ref={this.setTableRef}
                            deletedIds={this.state.deletedIds}
                            minHeight='300px'
                            theme={theme}
                            showHeaderFromBreakpoint='md'
                            rowHasAction={true}
                            filterMode='client'
                            filterItems={filterItems}
                            hideFirstLastBorder={true}
                            dense={true}
                            paginationMode='client'
                            totalCount={this.props.jobs?.totalCount}
                            // pageSize use for table client paging
                            pageSize={50}
                            onPageSizeChange={this.onPageSizeChange.bind(this)}
                            onJobSelect={this.onJobSelect.bind(this)}
                            onJobAction={this.onJobAction.bind(this)}
                            onPositionAction={this.onPositionAction.bind(this)}
                            focusPosition={focusPosition}
                            paper={false}
                            jobs={jobs ? jobs : null}
                            // rows={jobs ? [...jobs.positions, ...jobs.jobs] : null}
                            rows={filterJobs}
                            noRowsComponent={<TablePlaceholder text='Use New job button to create your first job' icon={<JobsIcon />} />} />
                    </Paper>}


                <AttributeActionMenu
                    ref={this.setMenuRef}
                    onClose={this.onCloseActionMenu.bind(this)}
                    onRender={this.renderActionMenu.bind(this)}
                />

                {showAddEditJobView &&
                    <AddEditJobView
                        editMode={editMode}
                        theme={theme}
                        error={saveJobError}
                        onShowNewAttributeDialog={onShowNewAttributeDialog}
                        job={editMode ? editJob : new SettingsJob()}
                        jobId={editJobId}
                        onBlockNavigation={onBlockNavigation}
                        onAllowNavigation={onAllowNavigation}
                        onDataChanged={onDataChanged}
                        onNavigation={onNavigation}
                        onCancel={e => history.push('/settings/jobs')}
                        onSave={this.onSaveJob.bind(this)} />}

                {showAssignEmployeeDialog &&
                    <AssignEmployeeDialog
                        employees={employees}
                        theme={theme}
                        // position={assignToPosition}
                        position={this.targetPosition}
                        onLoadEmployees={onLoadEmployees}
                        onDataChanged={onDataChanged}
                        error={assignEmployeeError}
                        onSave={this.onSaveAssignedEmployee.bind(this)}
                        onCancel={e => history.push('/settings/jobs')} />}

                {showAssignManagerDialog &&
                    <AssignManagerDialog
                        managers={managers}
                        theme={theme}
                        // position={assignToPosition}
                        position={this.targetPosition}
                        manager={assignToPosition ? assignToPosition.manager : null}
                        onLoadManagers={onLoadManagers}
                        onDataChanged={onDataChanged}
                        error={assignManagerError}
                        allowNone={true}
                        onSave={this.onSaveAssignedManager.bind(this)}
                        onCancel={e => history.push('/settings/jobs')} />}

                {unassignEmployee && <ConfirmationDialog
                    theme={theme}
                    title='Remove employee' question={<div>Are you sure you want to remove <b>{unassignEmployee.fullName}</b> from {unassignEmployee.job.name} job?</div>}
                    acceptButton='Remove'
                    error={unassignEmployeeError}
                    onCancel={this.onCancelUnassignEmployee.bind(this)}
                    onAccept={this.onUnassignEmployeeConfirmed.bind(this)} />}

                {deletingJob && <ConfirmationDialog
                    theme={theme}
                    title='Delete job' question={/* This job is being used by {deletingJob.numberOfPositions} employees. */<div>Are you sure you want to delete this job?</div>}
                    acceptButton='Delete job'
                    acceptDanger={true}
                    error={deletingJobError}
                    onCancel={this.onCancelDeleteJob.bind(this)}
                    onAccept={this.onDeleteJobConfirmed.bind(this)} />}

                {this.renderSnackbar()}

            </div>
        );
    }
}