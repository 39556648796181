import { React, Component } from 'react';
import { CircularProgress, Avatar, Badge, Box } from '@mui/material';
import { Theme } from 'klayowebshared';
import { Tooltip } from './Tooltip';
import { ReactComponent as VacancyIcon } from '../resources/images/icons-user.svg';
import { ComplianceBadge } from './ComplienceBadge';

export class ProfilePicture extends Component {

    static size = { xs: '32px', sm: '40x', md: '44px', lg: '64px', xl: '80px' };
    static fontSize = { xs: '14px', sm: '16px', md: '16px', lg: '18px', xl: '20px' };

    static defaultProps = {
        size: 'xl',
        updateCache: false
    }

    renderAvatar() {
        const { user, updating, size, tooltip, updateCache, onClick } = this.props;
        const src = user && user?.getProfilePictureUrl ? user.getProfilePictureUrl(updateCache ? new Date() : null) : null;
        const showPic = user && user.hasProfilePicture && !updating;
        const initials = user?.firstName?.substr(0, 1) + user?.lastName?.substr(0, 1);
        return <Tooltip title={tooltip ? tooltip : user ? user.fullName : null}>
            <Avatar
                src={showPic ? src : null}
                sx={{
                    width: ProfilePicture.size[size],
                    height: ProfilePicture.size[size],
                    bgcolor: Theme.getStyleVar('--prussian-blue-pb-500')
                }}
                onClick={onClick}
            >
                {updating ? <CircularProgress /> : !user || user.isVacancy ?
                    <div><VacancyIcon /></div> : !showPic &&
                    <Box
                        className='klayo_profilepic-initials'
                        sx={{ fontSize: ProfilePicture.fontSize[size] }}>{user?.getInitials ? user.getInitials() : (initials ? initials : '')}</Box>
                }
            </Avatar>
        </Tooltip>;
    }

    render() {
        const { user, size, sx, badgeContent, showCompliance } = this.props;

        return <div className={'klayo_profilepic klayo_profilepic--size-' + size + (!user || user.isVacancy ? ' klayo_profilepic-vacancy' : '')} style={{ width: size + 'px', height: size + 'px', bgcolor: Theme.getStyleVar('--prussian-blue-pb-500'), ...sx }}>

            {showCompliance ?
                <ComplianceBadge
                    isCompliant={user.isCompliant}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}>
                    {this.renderAvatar()}
                </ComplianceBadge>
                :
                <Badge
                    badgeContent={badgeContent}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}>
                    {this.renderAvatar()}
                </Badge>}
        </div>
    }
}