import { Component, React } from 'react';
import { Button } from '../../components/Button';
import { Dialog } from '../../components/dialogs/Dialog';
import styled from '@emotion/styled';
import { KLAYO_COLORS } from '../../themes';
import { ReactComponent as CloseIcon } from '../../resources/images/icons-close.svg';
import { TrainingCourseList } from '../../components/TrainingCourseList';

export class GoToTrainingDialog extends Component {

    constructor(props) {
        super(props);
    }

	onAttributeSelect(e, attribute) {
        const { history } = this.props;
        history.push('/settings/competencies/edit/' + attribute.attributeDefinitionId);
    }

    render() {
        const { theme, onClose, attribute, organization } = this.props;

        return (
            <StyledGoToTrainingDialog
                className='klayo__go-to-training-dialog'
                open={true}
                theme={theme}
                onClose={onClose}
                fullWidth
                maxWidth='sm'
                actions={[{
                    label: 'Close',
                    variant: 'filled',
                    onClick: onClose
                }]}
			>
				<div className='klayo__go-to-training-dialog-header'>
               		<h1>Go to training</h1>
					<Button
						className='klayo__go-to-training-dialog-close-btn'
						icon={<CloseIcon />}
						onClick={onClose}
						sx={{ display: { xs: 'block', md: 'none' } }} 
					/>
				</div>
				<h2> This attribute has multiple training courses linked. Please select the course you want to go to.</h2>

				<TrainingCourseList 
					courses={attribute ? attribute.trainingCourses : null}
					organization={organization}
				/>
            </StyledGoToTrainingDialog>
        )
    }
}

const StyledGoToTrainingDialog = styled(Dialog)`
	&.klayo__go-to-training-dialog {
		.MuiPaper-root {
			max-width: 640px;
		}

		.klayo__go-to-training-dialog-header {
			display: flex;
			justify-content: space-between;
			margin: 0px 0px 32px 0px;
		}

		h1 {
			color: ${KLAYO_COLORS.GreyGR900};
			font-family: Inter;
			font-size: 24px;
			font-style: normal;
			font-weight: 600;
			line-height: 32px;
			letter-spacing: -0.5px;
			margin: 0px;
		}

		h2 {
			color: ${KLAYO_COLORS.GreyGR900};
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
			letter-spacing: -0.1px;
			margin: 0px 0px 24px 0px;
		}

		.MuiDialogContent-root {
			padding-bottom: 0px;
		}

		@media (max-width: 600px) {
			.MuiPaper-root {
				max-width: 375px;
			}

			h2 {
				margin-bottom: 40px;
			}

			.klayo__go-to-training-dialog-header {
				margin-bottom: 20px;
			}

			.klayo__go-to-training-dialog-close-btn {
				display: flex;
				width: 20px;
				height: 20px;

				svg {
					width: 20px;
					height: 20px;
				}
			}
		}
	}
`