import { Typography } from "@mui/material";
import React, { Component } from "react";

export class Heading extends Component {
    static defaultProps = {
        size: 3
    }

    render() {
        const { size, sx, className, text } = this.props;

        return <Typography
            variant={'h' + size}
            className={'klayo-viewheading' + (className ? ' ' + className : '')}
            sx={{
                fontSize: {
                    xs: '20px',
                    sm: '20px',
                    md: '30px'
                },
                ...sx
            }}
        >{text}</Typography>
    }
};