import { Skeleton } from '@mui/material';
import { React, Component } from 'react';
import { Utils } from '../common/Utils';
import { AttributeProgressBar } from './AttributeProgressBar';

export class AttributeProgressBarLabel extends Component {

    static defaultProps = {
        singleRow: false,
        showFraction: true,
        showPercent: true
    }

    render() {
        const { met, total, label, onClick, showFraction, showPercent, singleRow, loading } = this.props;
        const percent = AttributeProgressBar.calculatePercent(met, total);

        return <div className={'klaro_attr-progress_label' + (singleRow ? ' klaro_attr-progress_labelsinglerow' : '')}>
            <div className='klaro_attr-progress_name' style={{ cursor: onClick ? 'pointer' : 'default' }} onClick={onClick}>{label}</div>
            <div style={{ flex: '1 1 auto', textAlign: 'right', cursor: onClick ? 'pointer' : 'default' }} onClick={onClick}>
                {percent !== null && total !== 0 ?
                    <span>
                        {loading ? <Skeleton width='90px' sx={{ float: 'right'}} /> : <>
                            {showFraction && <span className='klaro_attr-progress_fractionlabel'>{met}/{total}</span>}
                            {showPercent && <span className='klaro_attr-progress_percentlabel'>({Utils.formatPercent(percent)})</span>}
                        </>}
                    </span> : <span>n/a</span>
                }
            </div>
        </div>
    }
}
