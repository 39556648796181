import { Component } from "react";
import { Stack } from "@mui/material";
import { Link as RouteLink } from 'react-router-dom';
import { Paper } from "./Paper";
import { Skeleton } from "@mui/material";

export class StatCard extends Component {

    render() {
        const { sx, icon, title, stat, statStyle, loading } = this.props;

        return <Paper
            className='klayo-statcard'
            padding='23px'
            sx={sx}>
            <Stack
                direction='row'
                spacing={2}>
                <div className='klayo-statcard_badge'>{icon}</div>
                <div>
                    {title && <div className='klayo-statcard_title'>{title}</div>}
                    {!isNaN(stat) && <div className='klayo-statcard_stat' style={statStyle}>{loading ? <Skeleton width='32px' /> : stat}</div>}
                </div>
            </Stack>
        </Paper>
    }
};