
import { Stack } from "@mui/material";
import { Component } from "react";
import { PillButtons } from "../../components/PillButtons";
import { BillingPlan } from "../../data/billing/BillingPlan";
import { TextField } from "../../components/TextField";

export class BillingContact extends Component {
    static defaultProps = {
        disabled: true
    }

    constructor(props) {
        super(props);

        this.state = this.getBillingContact(props.billingContact);
        this.onChange(null);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.billingContact !== prevProps.billingContact) {
            this.setState(this.getBillingContact(this.props.billingContact));
        }
    }

    getBillingContact(billingContact) {
        const { user } = this.props;

        return {
            firstName: billingContact &&  billingContact.firstName ? billingContact.firstName : user ? user.firstName : '',
            lastName: billingContact &&  billingContact.lastName ? billingContact.lastName : user ? user.lastName : '',
            email: billingContact && billingContact.email ? billingContact.email : user ? user.email : '',
            phone: billingContact &&  billingContact.phone ? billingContact.phone : ''
        }
    }

    onFirstNameChange(e) {
        this.setState({ firstName: e.target.value }, () => this.onChange(e));
    }

    onLastNameChange(e) {
        this.setState({ lastName: e.target.value }, () => this.onChange(e));
    }

    onEmailChange(e) {
        this.setState({ email: e.target.value }, () => this.onChange(e));
    }

    onPhoneChange(e) {
        this.setState({ phone: e.target.value }, () => this.onChange(e));
    }

    onChange(e) {
        const { onChange } = this.props;
        if (onChange) onChange(e, this.state);
    }

    render() {
        const { disabled } = this.props;
        const { firstName, lastName, email, phone } = this.state;

        return <div>
            <h2>Billing contact</h2>
            <Stack spacing={5}>
                <Stack
                    direction='row'
                    spacing={4}
                    justifyContent='space-between'>

                    <TextField
                        label='First name'
                        value={firstName}
                        clearable={false}
                        autoComplete={false}
                        onChange={this.onFirstNameChange.bind(this)}
                        disabled={disabled}
                        sx={{ width: '100%' }} />

                    <TextField
                        label='Last name'
                        value={lastName}
                        clearable={false}
                        autoComplete={false}
                        onChange={this.onLastNameChange.bind(this)}
                        disabled={disabled}
                        sx={{ width: '100%' }} />
                </Stack>

                <Stack
                    direction='row'
                    spacing={4}
                    justifyContent='space-between'>

                    <TextField
                        label='Email'
                        value={email}
                        clearable={false}
                        autoComplete={false}
                        onChange={this.onEmailChange.bind(this)}
                        disabled={disabled}
                        sx={{ width: '100%' }} />

                    <TextField
                        label='Phone'
                        value={phone}
                        clearable={false}
                        autoComplete={false}
                        onChange={this.onPhoneChange.bind(this)}
                        disabled={disabled}
                        sx={{ width: '100%' }} />
                </Stack>
            </Stack>
        </div>
    }
}