import { Component } from "react";
import { Link } from "react-router-dom";
import { Paper } from '../../components/Paper';
import { DialogContent, DialogActions } from "@mui/material";
import { Button } from "../../components/Button";
import { Dialog } from "../../components/dialogs/Dialog";
import { TextField } from "../../components/TextField";

export class CompanyNameDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            companyName: props.organization ? props.organization.name : '',
            valid: false
        };
    }

    onNameChange(e) {
        const { organization } = this.props;
        this.setState({ companyName: e.target.value, valid: e.target.value !== organization.name && e.target.value.length > 2 });
    }

    onSave() {
        const { companyName } = this.state;
        if (this.props.onSave) this.props.onSave(companyName);
    }

    render() {
        const { organization, user, theme, onClose, error } = this.props;
        const { companyName, valid } = this.state;

        const changeAllowed = false;

        return (
            <Dialog
                open={true}
                theme={theme}
                title='Company name'
                onClose={onClose}
                maxWidth='sm'
                actions={[{
                    label: 'Save',
                    visible: changeAllowed,
                    variant: 'filled',
                    disabled: !valid,
                    onClick: this.onSave.bind(this)
                }, {
                    label: changeAllowed ? 'Cancel' : 'Close',
                    variant: changeAllowed ? 'outline' : 'filled',
                    autoFocus: !changeAllowed,
                    onClick: onClose
                }]}>
                {!changeAllowed && <div>Please contact us if you need the company named to be changed.</div>}
                {changeAllowed && <TextField
                    label="Company"
                    placeholder="Company name"
                    autoComplete={false}
                    sx={{ width: '100%' }}
                    value={companyName}
                    onChange={this.onNameChange.bind(this)}
                />}
            </Dialog>
        );
    }
}