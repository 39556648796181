import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';
import { Box, Tooltip } from '@mui/material';
import React, { Component } from 'react';
import { AbstractFilterSelect } from '../selectors/AbstractFilterSelect';
import { Utils } from '../../common/Utils';

export class AbstractChart extends Component {

    static defaultProps = {
        height: 270,
    }

    constructor(props) {
        super(props);

        this.state = {
            width: null,
            height: this.props.height
        };

        this.containerRef = React.createRef();
    }

    componentDidMount() {
        if(this.containerRef.current) {
            window.addEventListener('resize', this.updateDimensions.bind(this));
            this.updateDimensions();
        }
    }

    updateDimensions(callback) {
        const width = parseInt(this.containerRef.current ? this.containerRef.current.clientWidth : 800);
        const height = parseInt(this.containerRef.current ? this.containerRef.current.clientHeight : this.props.height);
        this.setState({ width, height }, () => {
            if(typeof callback === 'function') callback(this.state.width, this.state.height);
        });
    }
}