import { Card, CardContent, Stack } from '@mui/material';
import React, { Component } from 'react';
import { ReactComponent as CreditCardIcon } from '../resources/images/icons-credit-card.svg';
import { ReactComponent as InvoiceIcon } from '../resources/images/icons-invoice.svg';
import { Utils } from '../common/Utils';
import { Button } from './Button';
import { BillingPlan } from '../data/billing/BillingPlan';

export class PaymentMethodCard extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { billingDetails, creditCardDetails, sx, onChangeCardClick } = this.props;

        const isInvoice = billingDetails.paymentMethod === BillingPlan.paymentMethod.invoice;

        return <Card
            className='klayo-creditcarddetailscard'
            sx={sx}>
            <CardContent sx={{ padding: '0!important', margin: '0' }}>
                <Stack direction='row' spacing={3} alignItems='start'>
                    {isInvoice ? <InvoiceIcon className='klayo-creditcarddetailscard_icon' /> : <CreditCardIcon className='klayo-creditcarddetailscard_icon' />}

                    <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        justifyContent='space-between'
                        alignItems='start'
                        spacing={3}
                        sx={{ width: '100%' }}>
                        <div>
                            <div className='klayo-creditcarddetailscard_name'>{isInvoice ? billingDetails.contactDetails.companyName : creditCardDetails.cardHolderName}</div>
                            <div className='klayo-creditcarddetailscard_details'>
                                {isInvoice ?
                                    <div>Invoice ({billingDetails.cycle.label.toLowerCase()})</div> :
                                    <div>Card ending with {creditCardDetails.lastFourDigits} <span className='klayo-creditcarddetailscard_expiry'>{Utils.zeroPadNumber(creditCardDetails.expiryMonth, 2)}/{creditCardDetails.expiryYear}</span></div>}
                            </div>
                        </div>
                        {!isInvoice && <Button
                            label='Change card'
                            onClick={onChangeCardClick}
                            size='md' />}
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    }
}