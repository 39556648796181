import React, { Component } from 'react';
import { Box, Chip, Stack, FormControlLabel, Input } from '@mui/material';
import { Placeholder } from './Placeholder';
import { ReactComponent as DragIcon } from '../resources/images/icons-drag.svg';
import { ReactComponent as RemoveIcon } from '../resources/images/icons-remove.svg';
import uniqueId from 'lodash.uniqueid'
import { MultiFileUpload } from './MultiFileUpload';
import { Utils } from '../common/Utils';

export class DragDropFileUpload extends Component {

    static defaultProps = {
        allowedMimeTypes: ['*/*'],
        maxFileSize: 10240000,
        errorMimeType: 'Please upload a valid file',
        errorFileSize: 'Please upload a valid file',
        allowedMimeTypesLabel: 'PDF, JPEG, PNG, DOC, DOCX, TXT, CSV, XLS, XLSX'
    }

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            files: props.files || [],
            dragOver: false
        };

        this.id = uniqueId('klayo_fileupload');
        this.multiFileUploadRef = React.createRef();

        this.dragDropSupport = "draggable" in document.createElement("div");
    }

    onDropZoneClick(e) {
        this.multiFileUploadRef.current.selectFile(e);
    }

    onDragEnter(e) {
        e.preventDefault();
        this.setState({ dragOver: true });
    }

    onDragOver(e) {
        e.preventDefault();
    }

    onDragLeave(e) {
        e.preventDefault();
        this.setState({ dragOver: false });
    }

    onDrop(e) {
        e.preventDefault();
        this.multiFileUploadRef.current.dropFile(e);
        this.setState({ dragOver: false });
    }

    onChange(e, newFiles) {
        const { onChange } = this.props;
        this.setState({ files: newFiles });
        if (onChange) onChange(e, newFiles);
    }

    onRemoveFile(e, key, file) {
        this.multiFileUploadRef.current.onRemoveFile(e, key, file);
    }

    onError(error) {
        this.setState({ error });
    }

    static isFilesEmpty(files) {
        return !files || Object.entries(files).filter(([key, file]) => file !== null).length === 0;
    }

    render() {
        const { label, accept, allowedMimeTypes, maxFileSize, errorMimeType, errorFileSize, allowedMimeTypesLabel, disabled } = this.props;
        const { files, error, dragOver } = this.state;

        const fileEntries = Object.entries(files).filter(([key, file]) => file !== null);

        return <Box className={'klayo-upload' + (error ? ' klayo-upload--error' : '') + (dragOver ? ' klayo-upload--dragover' : '') + (disabled ? ' klayo-upload--disabled' : '')}>
            {label && <div className='klayo-upload_label'>{label}</div>}
            <Box
                className='klayo-upload_dropzone'
                onDragOver={this.onDragOver.bind(this)}
                onDragEnter={this.onDragEnter.bind(this)}
                onDragLeave={this.onDragLeave.bind(this)}
                onDrop={this.onDrop.bind(this)}
                onClick={this.onDropZoneClick.bind(this)}>
                <Placeholder text={{
                    primary: <div><b>{Utils.isTouchDevice() ? 'Tap' : 'Click'} to upload</b>{this.dragDropSupport ? ' or drag and drop here' : ''}</div>,
                    secondary: allowedMimeTypesLabel
                }}
                    primaryTextSize='16px'
                    secondaryTextSize='14px'
                    spacing={2}
                    icon={<DragIcon />}
                    sx={{ pointerEvents: 'none' }} />
            </Box>

            {error && <Box className='klayo-upload_error'>{error}</Box>}

            {!DragDropFileUpload.isFilesEmpty(files) &&
                <Box className='klayo-upload_chipcontainer'>
                    {fileEntries.map(([key, file], index) => {
                        return file && <Chip
                            key={key}
                            label={file.name}
                            deleteIcon={<RemoveIcon />}
                            onDelete={e => this.onRemoveFile(e, key)} />
                    })}
                </Box>
            }

            <MultiFileUpload
                id={this.id}
                ref={this.multiFileUploadRef}
                accept={accept}
                allowedMimeTypes={allowedMimeTypes}
                maxFileSize={maxFileSize}
                errorMimeType={errorMimeType}
                errorFileSize={errorFileSize}
                onChange={this.onChange.bind(this)}
                onError={this.onError.bind(this)}
                files={files}
                sx={{ display: 'none' }} />

        </Box>
    }
}