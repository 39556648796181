import { Table } from "./Table";
import { KLAYO_COLORS } from "../../themes";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { ReactComponent as DeleteIcon } from '../../resources/images/icons-delete-blue.svg';
import { ReactComponent as UsersIcon } from '../../resources/images/icons-users-1.svg';
export class ParticipantTable extends Table {

    static columns = {
        participant: {
            field: 'fullName',
            label: 'Participant',
        },
        marking: {
            field: 'marking',
            label: 'Assessment',
        },
        status: {
            field: 'status',
            label: 'Status',
        },
        action: {
            field: 'action',
            label: 'Actions',
        },
    };

    static defaultProps = {
        ...Table.defaultProps,
        columns: [
            { type: ParticipantTable.columns.participant, visibleFromBreakpoint: 'sm' },
            { type: ParticipantTable.columns.marking },
            { type: ParticipantTable.columns.status, visibleFromBreakpoint: 'sm' },
            { type: ParticipantTable.columns.action },
        ],
        noRowsMessage: 'Use Add participants button to select participants for this practical',
        icon: <UsersIcon />

    }

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            sortModel: this.props.sortModel ? this.props.sortModel : [{
                field: 'fullName',
                sort: 'asc',
            }]
        };
    }

    onRowClick(params, e) {
        const { onParticipantSelect } = this.props;
        if (params.row && onParticipantSelect) onParticipantSelect(e, params.row);
    }

    initColumns() {
        const { columns, sortable, onDeleteParticipantAction, isAssessment } = this.props;

        this.columns = [];

        columns.forEach(c => {
            if (this.hasColumn(c)) {

                if (c.type === ParticipantTable.columns.participant) this.columns.push({
                    field: 'fullName',
                    headerName: c.label || c.type.label,
                    sortable: sortable,
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    filterable: false,
                    disableReorder: true,
                    minWidth: 160,
                    flex: 1,
                    valueGetter: params => params.row.fullName,
                });

                if (c.type === ParticipantTable.columns.marking && isAssessment) this.columns.push({
                    field: 'marking',
                    headerName: c.label || c.type.label,
                    sortable: sortable,
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    filterable: false,
                    disableReorder: true,
                    Width: 180,
                    valueGetter: params => params.row.practical,
                    renderCell: params => <div>{params.row?.participantAttributes?.filter(pa => pa.mark !== undefined && pa.mark !== null).length} of {params.row?.participantAttributes?.length}</div>
                });

                if (c.type === ParticipantTable.columns.status) this.columns.push({
                    field: 'status',
                    headerName: c.label || c.type.label,
                    sortable: sortable,
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    filterable: false,
                    disableReorder: true,
                    width: 100,
                    headerAlign: 'left',
                    align: 'left',
                    valueGetter: params => params.row.status,
                    renderCell: params => <div>{params.row.status}</div>
                });

                if (c.type === ParticipantTable.columns.action) this.columns.push({
                    field: 'action',
                    type: 'actions',
                    filterable: false,
                    disableReorder: true,
                    width: 50,
                    align: 'right',
                    valueGetter: params => params,
                    getActions: params => params.row.status !== 'Completed' ? [
                        <GridActionsCellItem
                            icon={<DeleteIcon sx={{ color: KLAYO_COLORS.NeonBlueNB500 }} />}
                            label="Actions"
                            onClick={e => onDeleteParticipantAction(e, params.row)}
                        />
                    ] : []
                });
            }
        });
    }
};