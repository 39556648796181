import { Utils } from "../../common/Utils";
import { AttributeRequestActivity } from "./AttributeRequestActivity";
import { GapAttribute } from "./GapAttribute";
export class AbstractAttribute {

    static compliance = {
        compliant: {
            label: 'Compliant'
        },
        nonCompliant: {
            label: 'Non-compliant'
        }
    };

    //Based on JobAttributeRequirementType { Optional, Required, Mandatory }
    static type = {
        mandatory: {
            index: 2,
            label: 'Compliance',
            filterLabel: 'Compliance'
        },
        required: {
            index: 1,
            label: 'Required',
            filterLabel: 'Required + Compliance'
        },
        desirable: {
            index: 0,
            label: 'Desirable',
            filterLabel: 'Desirable'
        }
    };

    static gapReason = {
        notSet: {
            label: 'notSet'
        },
        met: {
            label: 'met'
        },
        unmetMandatory: {
            label: 'unmetMandatory'
        },
        unmet: {
            label: 'unmet'
        }
    }

    static status = {
        review: {
            index: 0,
            label: 'Under review',
            longLabel: 'Pending manager feedback',
            description: ''
        },
        feedback: {
            index: 1,
            label: 'Needs feedback',
            longLabel: 'Needs employee feedback',
            description: ''
        },
        approved: {
            index: 2,
            label: 'Approved',
            longLabel: 'Approved',
            description: ''
        },
        declined: {
            index: 3,
            label: 'Declined',
            longLabel: 'Declined',
            description: ''
        }
    };

    constructor(id, data) {

        this.childAttributes = [];

        if (data) {
            //Is child? - diff names, no children
            this.expiryDate =  Utils.parseApiDate(data.expiryDate);
            this.currentExpiryDate = Utils.parseApiDate(data.expiryDate);
            this.requestedExpiryDate = data.validationRequest ? Utils.parseApiDate(data.validationRequest.expiryDate) : null;
            this.pendingExpiryDate = data.pendingExpiryDate ? data.pendingExpiryDate : (this.requestedExpiryDate || this.currentExpiryDate);

            if (data.attributeDefinitionChildId) {
                this.attributeId = data.attributeDefinitionChildId;
                this.attributeDefinitionId = data.attributeDefinitionId;
                this.name = data.attributeDefinitionName;
                this.attributeName = data.attributeDefinitionName || data.attributeName;
                this.attribute = data.attributeDefinitionName || data.attributeName;
                this.description = data.attributeDefinitionDescription;
                this.isParent = false;
                this.hasExpiryDate = data.attributeDefinitionHasExpiryDate;
                this.hasProficiency = data.attributeDefinitionHasProficiency;
            }
            else {
                this.id = data.attributeDefinitionId;
                this.rowId = data.attributeDefinitionId;
                this.attributeDefinitionId = data.attributeDefinitionId;
                this.attributeName = data.attributeDefinitionName || data.attributeName;
                this.attribute = data.attributeDefinitionName || data.attributeName;
                this.attributeHierarchy = [this.attributeDefinitionId];
                this.isParent = data.isParent;
                this.categories = data.attributeDefinitionAttributeCategories || data.attributeDefinitionCategories || data.attributeCategoryNames;
                this.hasExpiryDate = data.hasExpiryDate || data.attributeDefinitionHasExpiryDate;
                this.hasProficiency = data.hasProficiency || data.attributeDefinitionHasProficiency;

                const childAttrs = data.attributeDefinitionChildren || data.jobAttributeChildren || data.attributeDefinitionAttributeDefinitionChildren;
                if (childAttrs && childAttrs.length !== 0) {
                    this.isParent = true;

                    childAttrs.forEach((c, index) => {
                        const childAttr = new AbstractAttribute(0, c);
                        childAttr.rowId = this.attributeDefinitionId + childAttr.attributeDefinitionId;
                        childAttr.isChild = true;
                        childAttr.childIndex = index;
                        childAttr.isLastChild = index === childAttrs.length - 1;
                        childAttr.attributeHierarchy = [this.attributeDefinitionId, childAttr.attributeDefinitionId];
                        childAttr.parentAttributeId = this.attributeId;
                        this.childAttributes.push(childAttr);
                    });
                }
            }

            this.required = data.required;
            this.mandatory = data.mandatory;

            if (this.mandatory) this.type = AbstractAttribute.type.mandatory.label;
            else if (this.required) this.type = 'Required';
            else this.type = 'Desirable';

            this.hasRequiredDetails = AbstractAttribute.hasRequiredDetails(this);
        }
    }

    loadRequestActivity(context, updateCache) {
        return new Promise((resolve, reject) => {
            if (!this.requestActivity || updateCache === true) {
                this.requestActivity = new AttributeRequestActivity({
                    attributeId: this.employeeAttributeId,
                    resolve: requestActivity => {
                        resolve(requestActivity);
                    },
                    reject,
                    context
                });
            }
            else resolve(this.requestActivity);
        });
    }

    isUnderReview() {
        return this?.status?.index === AbstractAttribute.status.review.index;
    }

    isApproved() {
        return this?.status?.index === AbstractAttribute.status.approved.index;
    }

    isDeclined() {
        return this?.status?.index === AbstractAttribute.status.declined.index;
    }

    isNeedsFeedback() {
        return this?.status?.index === AbstractAttribute.status.feedback.index;
    }

    hasValidationRequest() {
        return this.validationRequestState;
    }

    isLowProficiency() {
        return this.reason === GapAttribute.reason.lowProficiency;
    }

    isMissing() {
        return this.reason === GapAttribute.reason.missing;
    }

    static hasRequiredDetails(attr) {
        if (attr.status === AbstractAttribute.status.feedback) return true;
        if (attr.hasExpiryDate === true && !attr.expiryDate) return false;
        if (attr.hasProficiency === true && !Number.isInteger(attr.proficiency)) return false;
        return true;
    }

    hasValidPendingExpiryDate() {
        return this.pendingExpiryDate !== undefined && this.pendingExpiryDate !== null;
    }

    hasValidPendingProficiency() {
        return this.pendingProficiency !== undefined && this.pendingProficiency !== null && !isNaN(this.pendingProficiency) && this.pendingProficiency !== 0;
    }
}