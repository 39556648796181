import { SettingsAttribute } from "./SettingsAttribute";

export class SettingsJob {
    constructor(data) {

        this.attributes = [];

        if (data) {
            this.id = data.jobDefinitionId;
            this.rowId = data.jobDefinitionId;
            this.isJob = true;
            this.jobDefinitionId = data.jobDefinitionId;
            this.name = data.name;
            this.description = data.description;
            this.numberOfPositions = data.jobs ? data.jobs.length : 0;
            const attrs = data.jobAttributes || data.attributes;
            if (attrs) {
                attrs.forEach(a => {
                    const attr = new SettingsAttribute(a);
                    //this.addAttribute(attr);
                    this.attributes.push(attr);
                    this.attributes = this.attributes.concat(attr.childAttributes);
                });
            }
        }
    }

    isAttributesSame(other) {
        if (!other) return false;
        const otherNoChildren = other.filter(a => !a.isChild);
        if (other.length !== this.attributes.length) return false;

        for (const o of otherNoChildren) {
            const match = this.attributes.find(a => a.attributeId === o.attributeId && !a.isChild);
            if (!match) return false;

            if (match.requirementType && o.requirementType) {
                if (match.requirementType.index !== o.requirementType.index) return false;
            }
        }

        return true;
    }

    addAttribute(attr) {
        this.attributes = [...this.attributes, attr];
        //this.attributes.push(attr);

        //if(attr.childAttributes) attr.childAttributes.forEach(child => this.addAttribute(child));
    }

    toApiDto() {

        const attrs = [];
        this.attributes.filter(a => a.isChild !== true).forEach(a => {
            attrs.push({
                attributeDefinitionId: a.attributeDefinitionId,
                jobAttributeRequirementType: a.requirementType && a.isParent !== true ? a.requirementType.index : null,
                proficiencyMinimum: a.hasProficiency ? a.proficiencyMinimum || 1 : null,
            });

            /*a.childAttributes.forEach(child => {
                if (!attrs.some(a => a.attributeDefinitionId === child.attributeDefinitionId)) attrs.push({
                    attributeDefinitionId: child.attributeDefinitionId,
                    proficiencyValue: child.proficiencyMinimum,
                    comment: child.comment
                });
            });*/
        });

        return {
            jobDefinitionId: this.jobDefinitionId,
            name: this.name,
            description: this.description,
            jobAttributes: attrs/*Object.values(this.attributes).filter(a => a.isChild !== true).map(a => {
                return {
                    attributeDefinitionId: a.attributeDefinitionId,
                    jobAttributeRequirementType: a.requirementType && a.isParent !== true ? a.requirementType.index : null,
                    proficiencyMinimum: 0,
                    jobAttributeChildren: a.childAttributes ? Object.values(a.childAttributes).map(c => {
                        return {
                            attributeDefinitionId: c.attributeDefinitionId,
                            jobAttributeRequirementType: c.requirementType ? c.requirementType.index : null,
                            proficiencyMinimum: 0
                        }
                    }) : null
                }
            })*/
        };
    }
}