import React, { Component } from 'react';
import { Grid } from '@mui/material';
import { ProfileJobSection } from './ProfileJobSection';
import { ProfileAttributesSection } from './ProfileAttributesSection';
import { ProfileGapsSection } from './ProfileGapsSection';
import { AppContext } from '../../common/AppContext';
import { Switch, Route } from 'react-router-dom'
import { PillTabs } from '../../components/PillTabs';
import { UserProfileBox } from '../../components/UserProfileBox';

export class ProfileLayout extends Component {
    static contextType = AppContext;

    static defaultProps = {
        readOnly: true,
        myView: false,
        treeMode: false
    }

    constructor(props) {
        super(props);

        this.subPages = {
            job: {
                name: props.myView ? 'My job' : 'Job',
                allowRoot: true,
                slug: '',
                paths: [
                    '/'
                ],
                component: ProfileJobSection,
                hasAccess: () => true,
                isDefault: () => true
            },
            competencies: {
                name: props.myView ? 'My competencies' : 'Competencies',
                slug: 'competencies',
                paths: [
                    '/competencies',
                    '/competencies/:id'
                ],
                component: ProfileAttributesSection,
                hasAccess: () => true
            },
            gaps: {
                name: props.myView ? 'My gaps' : 'Gaps',
                slug: 'gaps',
                paths: [
                    '/gaps',
                    '/gaps/:id'
                ],
                component: ProfileGapsSection,
                hasAccess: () => true
            }
        };
    }

    render() {
        const { organization, user, employee, theme, profile, basePath, location, readOnly, onDeleteAttribute, onFeedbackAction, onProficiencyChangeAction, onAddChangeAction, onViewDetails, onShowAttributeDetails, treeMode, onChangeViewMode, isInstructorViewing, onEditAttribute, onAddAttributeClickCb } = this.props;

        let path = location.pathname;
        if (path === '/') path = basePath;
        
        const relativePath = path.replace(basePath, '');
        const relativePathSplit = relativePath.split('/');
        const activeTab = relativePathSplit[1] || '';
        
        //console.log(pathSplit);
        //console.log('Tab: '+(pathSplit.length > 2 ? pathSplit[2] === 'gaps' || pathSplit[2] === 'competencies' ? pathSplit[2] : '' : ''));

        //Default to attr tab if no job
        /*if (path === basePath && user && user.isCurrent && !user.hasJob) {
            path = basePath + '/' + this.subPages.competencies.slug;
            location.pathname = path;
        }*/

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <UserProfileBox
                            user={employee}
                            profile={profile}
                            showViewDetailsButton={true}
                            onViewDetails={onViewDetails}
                            readOnly={readOnly} />
                    </Grid>
                </Grid>

                <PillTabs
                    user={employee}
                    organization={organization}
                    theme={theme}
                    //path={path}
                    path={basePath + (activeTab !== '' ? '/' + activeTab : '')}
                    basePath={basePath || '/'}
                    pages={this.subPages}
                    sx={{ margin: '41px 0' }} />

                <Switch>
                    {
                        Object.entries(this.subPages).map(([key, subPage]) => {
                            const paths = subPage.paths.map(p => basePath + p);
                            //paths.push(basePath + (subPage.slug === '' ? '' : '/' + subPage.slug));
                            if (subPage.allowRoot === true) paths.push(subPage.slug === '' ? '' : '/' + subPage.slug);
                            if(employee && subPage.isDefault && subPage.isDefault(employee)) paths.push(basePath);

                            return (<Route
                                key={key}
                                exact={true}
                                path={paths}
                                render={props => {
                                    return <div>
                                        {React.createElement(subPage.component, {
                                            ...props,
                                            organization,
                                            user,
                                            employee,
                                            theme,
                                            profile,
                                            readOnly: readOnly,
                                            treeMode: treeMode,
                                            onDeleteAttribute: onDeleteAttribute,
                                            onFeedbackAction: onFeedbackAction,
                                            isInstructorViewing: isInstructorViewing,
                                            onProficiencyChangeAction: onProficiencyChangeAction,
                                            onAddChangeAction: onAddChangeAction,
                                            onShowAttributeDetails: onShowAttributeDetails,
                                            onChangeViewMode,
                                            onEditAttribute: onEditAttribute,
                                            onAddAttributeClickCb: onAddAttributeClickCb
                                        })}
                                    </div>
                                }} />)
                        })
                    }
                </Switch>

            </div>
        );
    }
}