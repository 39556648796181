import { Utils } from "../../common/Utils";
import { COMPLETED, PRACTICAL_STATUS } from "../../constants";
import { SettingsAttribute } from "../settings/SettingsAttribute";
import { ParticipantAttribute } from "./ParticipantAttribute";

export class Practical {

    constructor(data) {
        if (data) {
            // data for participant practical list --------------
            if (data.practicalId) {
                // participant id
                this.id = data.id;
                // practical id
                this.practicalId = data.practicalId;
                this.name = data.name;
                this.description = data.description;
                this.isAssessment = data.isAssessment;
                this.practicalDate = data.practicalDate;
                this.practicalTime = data.practicalTime;
                this.numberOfAttributes = data.numberOfAttributes;
                this.numberOfParticipants = data.numberOfParticipants;
                this.practicalTypeName = data.practicalTypeName;
                this.instructorFullname = data.instructorFullname;
                this.acknowledgedDate = data.acknowledgedDate;
                this.isAcknowledged = data.isAcknowledged;
                this.practicalAttributeNames = data.practicalAttributeNames;
                this.status = data.status;
                return;
            }

            // data for instructor list --------------
            this.id = data.id;
            this.practicalDate = data.practicalDate;
            this.isAssessment = data.isAssessment;
            this.name = data.name;
            this.numberOfAttributes = data.numberOfAttributes;
            this.numberOfParticipants = data.numberOfParticipants;
            this.numberOfAcknowledgedParticipants = data.numberOfAcknowledgedParticipants;
            this.status = data.status;

            // addition create data -------------
            this.description = data.description;
            this.location = data.location;
            this.weatherCondition = data.weatherCondition;
            this.instructor = data.instructor;
            this.practicalDate = data.practicalDate;
            this.practicalTime = data.practicalTime;
            this.durationInHours = data.durationInHours;
            this.isAssessment = data.isAssessment;
            this.practicalTypeId = data.practicalTypeId;
            this.practicalType = data.practicalType;
            this.attributeDefinitionIds = data.attributeDefinitionIds ? data.attributeDefinitionIds : [];
            this.attributeDefinitions = data.attributeDefinitions ? data.attributeDefinitions : data?.competencies?.length > 0 ? data.competencies.map(c => new SettingsAttribute(c)) : [];
            
            this.participants = data?.participants?.length > 0 ? data.participants.map(p => {
                if (p?.participantAttributes?.length > 0) {
                    // data for create practical

                    return {
                        employeeId: p.employeeId,
                        fullName: p.fullName,
                        participantAttributes: p.participantAttributes.map(a => new ParticipantAttribute(a))
                    }
                } else if (p?.practicalParticipantCompetencies?.length > 0) {
                    // data for edit practical

                    return {
                        acknowledgedDate: p.acknowledgedDate,
                        employeeId: p.employeeId,
                        fullName: p.employeeFullName,
                        isAcknowledged: p.isAcknowledged,
                        status: p.status,
                        participantAttributes: p.practicalParticipantCompetencies.map(c => new ParticipantAttribute(c))
                    }
                } else {
                    return {
                        acknowledgedDate: p.acknowledgedDate,
                        employeeId: p.employeeId,
                        fullName: p.employeeFullName,
                        isAcknowledged: p.isAcknowledged,
                        status: p.status,
                        participantAttributes: [],
                    }
                }
            }) : [];
            this.changelogs = data.changelogs ? data.changelogs : [];
        } else {
            this.id = null;
            this.name = null;
            this.description = null;
            this.location = null;
            this.weatherCondition = null;
            this.instructor = {
                id: null,
                name: null,
            }; //include name and id
            this.practicalDate = null;
            this.practicalTime = null;
            this.durationInHours = null;
            this.isAssessment = false;
            this.practicalTypeId = null;
            this.practicalType = null;
            this.attributeDefinitions = [];
            this.attributeDefinitionIds = [];
            this.participants = [];
            this.changelogs = [];
        }
    }

    toApiDto() {

        return {
            id: this.id,
            name: this.name,
            description: this.description,
            location: this.location,
            weatherCondition: this.weatherCondition,
            instructorId: this.instructor.id,
            practicalDate: Utils.toDtoDate(this.practicalDate),
            practicalTime: Utils.convertPracticalTime(this.practicalTime).toUpperCase(),
            durationInHours: this.durationInHours,
            isAssessment: this.isAssessment,
            practicalTypeId: this.practicalType
                ? (this.practicalType.id
                    ? this.practicalType.id
                    : this.practicalType || null) : null,
            attributeDefinitionIds: this.attributeDefinitions.map(a => a.attributeId),
            participants: this.participants.map(p => {
                return {
                    employeeId: p.employeeId,
                    participantAttributes: p.participantAttributes,
                }
            })
        };
    }

    static isCompleted(practical) {
        return PRACTICAL_STATUS[practical?.status]?.label === COMPLETED;
    }
}