import { Component } from "react";
import { Stack } from "@mui/material";
import { AppContext } from '../../common/AppContext';
import { Data } from '../../data/Data';
import { ErrorModel } from '../../common/models/ErrorModel';
import { Dialog } from "../../components/dialogs/Dialog";
import { MultiParticipantSelector } from "../../components/selectors/MultiParticipantSelector";
import { ParticipantAttribute } from "../../data/practical/ParticipantAttribute";

export class ParticipantSelectorDialog extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            participants: null,
            participantsSearch: null,
            practical: props.practical,

            attributes: null,
            selectedAttributes: [],
            selectedSuggestedAttributes: [],
            allSelectedAttributes: [],
            suggestedAttributes: [],
            allowChildren: true,
            attributesSearch: null,
        };
    }

    componentDidMount() {
        this.loadParticipants();
    }

    loadParticipants(searchText) {
        this.context.setLoading('participant', true);

        const searchParam = searchText ? `?searchText=${searchText}` : '';

        Data.get(this.context, '/Practical/SearchEmployees' + searchParam, {
            withCredentials: true
        })
            .then(response => {

                this.setState({ participants: response.data });
            })
            .catch(e => {
                this.setState({ error: ErrorModel.parseServerError(e) });
            })
            .finally(() => {
                this.context.setLoading('participant', false);
            });
    }

    onParticipantsBlur() {
        const { participantsSearch } = this.state;

        if (participantsSearch) this.loadParticipants();
    }

    onSearchParticipants(searchText) {
        this.loadParticipants(searchText);
    }

    onSave() {
        const { onSave } = this.props;
        const { allSelectedAttributes } = this.state;
        if (onSave) onSave(allSelectedAttributes);
    }

    onParticipantsChange(e, selectedParticipants) {
        const { practical } = this.state;

        const newParticpants = selectedParticipants.map((p) => {
            const existingParticipant = practical.participants.find((pp) => pp.employeeId === p.employeeId);
            if (existingParticipant) return existingParticipant;

            let participantAttribute = [];
            p.status = p.status ?? 'n/a';
            // map attribute for each participant
            practical.attributeDefinitions.forEach((a) => {
                // should make new participantAttribute object for each attribute
                participantAttribute = [...participantAttribute, new ParticipantAttribute(a)];
            });

            return { ...p, participantAttributes: participantAttribute };
        });
        
        practical.participants = newParticpants;

        this.setState({ practical });
    }

    setAttributes(allSelectedAttributes) {
        allSelectedAttributes.forEach(a => a.type = null);
        this.setState({ allSelectedAttributes });
    }

    disableCallback(e) {
        return e?.isAcknowledged;
    }

    render() {
        const { theme, onClose } = this.props;
        const { participants, practical } = this.state;
        const filteredParticipants = participants?.employees?.filter((e) => e?.employeeId !== practical?.instructor?.id);

        return <Dialog
            open={true}
            theme={theme}
            onClose={onClose}
            fullWidth={true}
            maxWidth='sm'
            title='Add participants'
            actions={[{
                label: 'Add participants',
                primary: true,
                disabled: practical && practical.participants.length === 0,
                onClick: this.onSave.bind(this)
            }, {
                label: 'Cancel',
                onClick: onClose
            }]}>
            <Stack
                direction='column'
                spacing={4}>
                <div>Select one or more participants to be added to the practical</div>

                <MultiParticipantSelector
                    label={null}
                    items={filteredParticipants ? filteredParticipants : null}
                    values={practical ? practical.participants : null}
                    onNewItemClick={false}
                    getItemId={p => p.employeeId}
                    getItemLabel={p => p.fullName}
                    onChange={this.onParticipantsChange.bind(this)}
                    onSearch={this.onSearchParticipants.bind(this)}
                    onBlur={this.onParticipantsBlur.bind(this)}
                    disableCallback={this.disableCallback.bind(this)}
                />
            </Stack>
        </Dialog>
    }
}