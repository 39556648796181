
import { Data } from "../Data";

export class NotificationsUnread extends Data {

    static instance = null;

    constructor(options) {
        super({
            api: '/Notifications/TotalUnread', callback: data => {
                options.resolve(this);
            }, ...options
        });
    }
    
    onDataReceived(data) {
        this.count = data.totalNumberOfUnreadNotification;
    }

    /*update(inc) {
        this.count += inc;
        if(this.count < 0) this.count = 0;
        if(this.onChange) this.onChange(this.count);
    }*/

    set(count) {
        this.count = count;
    }

    static get(context) {
        return new Promise((resolve, reject) => {
            if (NotificationsUnread.instance) resolve(NotificationsUnread.instance);
            else NotificationsUnread.instance = new NotificationsUnread({ resolve, reject, context });
        });
    }
}
