import { Component } from "react";
import { DialogActions, Grid, Stack, Alert } from "@mui/material";
import { Button } from "../../components/Button";
import { JobTable } from "../../components/table/JobTable";
import { VacancyTable } from "../../components/table/VacancyTable";
import { SettingsJobList } from "../../data/settings/SettingsJobList";
import { Dialog } from "../../components/dialogs/Dialog";

export class SelectVacancyDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            position: null,
            vacancy: null
        };
    }

    componentDidMount() {
        //const { onLoadManagers } = this.props;
        //if (onLoadManagers) onLoadManagers();
    }

    onSave(e) {
        const { onSave } = this.props;
        const { vacancy } = this.state;
        if (onSave) onSave(e, vacancy);
    }

    onVacancySelect(e, vacancy) {
        this.setState({ vacancy });
    }

    render() {
        const { organization, theme, user, onCancel, error, vacancies, position } = this.props;
        const { vacancy } = this.state;

        const newPosition = SettingsJobList.createVacancy();
        newPosition.fullName = 'Create new position';
        newPosition.isCreatable = true;

        return <Dialog
            theme={theme}
            open={true}
            onClose={onCancel}
            fullWidth={true}
            maxWidth='sm'
            maxHeight='300px'
            title='Select job'
            actions={[{
                label: 'Select',
                variant: 'filled',
                primary: true,
                disabled: vacancy === null,
                onClick: this.onSave.bind(this)
            }, {
                label: 'Cancel',
                onClick: onCancel
            }]}>
            <Stack spacing={4}>
                <div><b>{position.name}</b> has one or more vacancies. Please select a vacancy or create a new position.</div>
                <VacancyTable
                    theme={theme}
                    paper={false}
                    pagination={false}
                    showFooter={false}
                    hideFirstLastBorder={true}
                    dense={true}
                    rows={vacancies ? [...vacancies, newPosition] : null}
                    selected={vacancy}
                    onVacancySelect={this.onVacancySelect.bind(this)} />
            </Stack>
        </Dialog>
    }
}