import { DateLabel } from 'klayowebshared';
import { Utils } from "../../common/Utils";
import { Table } from "./Table";
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { AbstractAttribute } from "../../data/attributes/AbstractAttribute";
import { ProfilePictureGroup } from '../ProfilePictureGroup';
import { ReactComponent as CheckIcon } from '../../resources/images/icons-check.svg';
import { ReactComponent as WarningIcon } from '../../resources/images/icons-warning.svg';
import { ReactComponent as MoreVertIcon } from '../../resources/images/icons-more-vertical.svg';
import { ReactComponent as DeleteIcon } from '../../resources/images/icons-delete.svg';
import { ReactComponent as NeedsFeedbackIcon } from '../../resources/images/icons-feedback.svg';
import { ReactComponent as AttachmentIcon } from '../../resources/images/icons-attachment.svg';
import { ReactComponent as CertificateIcon } from '../../resources/images/icons-certificate.svg';
import { SelectMenu } from "../SelectMenu";
import { Link } from "react-router-dom";
import { Button } from "../Button";
import { TooltipIcon } from "../TooltipIcon";
import { Tooltip } from "../Tooltip";
import { Tooltip as TooltipMUI } from "@mui/material";

import styled from '@emotion/styled';
import { KLAYO_COLORS } from '../../themes';

export class AttributeTable extends Table {

    static columns = {
        summary: {
            field: 'summary',
            label: 'Summary',
            visibleToBreakpoint: 'md'
        },
        summaryWithPeople: {
            field: 'summaryWithPeople',
            label: 'Summary',
            visibleToBreakpoint: 'md'
        },
        lastUpdate: {
            field: 'lastUpdate',
            label: 'Last update',
            visibleFromBreakpoint: null
        },
        attribute: {
            field: 'attribute',
            label: 'Job competency',
            visibleFromBreakpoint: 'md'
        },
        requestTitle: {
            field: 'requestTitle',
            label: 'Job competency',
        },
        category: {
            field: 'category',
            label: 'Category',
            visibleFromBreakpoint: 'lg'
        },
        attributeType: {
            field: 'attributeType',
            label: 'Priority',
            visibleFromBreakpoint: 'md',
            selectable: false,
            selectableOnChild: true
        },
        requirementType: {
            field: 'requirementType',
            label: 'Type',
            visibleFromBreakpoint: 'md',
            selectable: false,
            selectableOnChild: true
        },
        currentProficiency: {
            field: 'currentProficiency',
            label: 'Current Proficiency',
            visibleFromBreakpoint: 'md'
        },
        requiredProficiency: {
            field: 'requiredProficiency',
            label: 'Required proficiency',
            visibleFromBreakpoint: 'md'
        },
        averageProficiency: {
            field: 'averageProficiency',
            label: 'Average proficiency',
            visibleFromBreakpoint: 'md'
        },
        status: {
            field: 'status',
            label: 'Status',
        },
        reason: {
            field: 'reason',
            label: 'Status',
            visibleFromBreakpoint: 'md'
        },
        reasonLong: {
            field: 'reasonLong',
            label: 'Status',
            visibleFromBreakpoint: 'md'
        },
        comment: {
            field: 'comment',
            label: 'comment',
        },
        documents: {
            field: 'documents',
            label: 'Documents',
        },
        validationRequestStateTest: {
            field: 'originalValidationRequestState',
            label: 'Status (Testing)',
        },
        expiryTest: {
            field: 'expiryTest',
            label: 'Expiry (Testing)',
        },
        expiry: {
            field: 'expiry',
            label: 'Expiry',
        },
        employees: {
            field: 'employees',
            label: 'Employees',
        },
        actions: {
            field: 'actions',
            label: 'Actions',
            visibleFromBreakpoint: 'md'
        },
        edit: {
            field: 'edit',
            label: 'Edit',
        },
        delete: {
            field: 'delete',
            label: 'Delete',
        },
        gapIcon: {
            field: 'gapIcon',
            label: 'Gap',
            visibleFromBreakpoint: 'md'
        },
        attachmentIcon: {
            field: 'attachmentIcon',
            label: 'Attachment',
            visibleFromBreakpoint: 'md'
        },
        gapIconTest: {
            field: 'gapIconTest',
            label: 'Gap test'
        },
        numEmployees: {
            field: 'numEmployees',
            label: 'Employees',
        },
        numJobs: {
            field: 'numJobs',
            label: 'Jobs',
        },
        needsFeedback: {
            field: 'needsFeedback',
            label: '',
        },
        hasTrainingCourses: {
            field: 'hasTrainingCourses',
            label: ''
        }
    };

    static defaultProps = {
        ...Table.defaultProps,
        columns: [AttributeTable.columns.attribute],
        noRowsMessage: 'No competencies, yet',
        showProficiency: false,
        rowHasAction: null,
        disableSelection: true,
    }

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            sortModel: this.props.sortModel ? this.props.sortModel : [{
                field: this.hasColumn(AttributeTable.columns.lastUpdate) ? 'lastUpdate' : 'attribute',
                sort: this.hasColumn(AttributeTable.columns.lastUpdate) ? 'desc' : 'asc',
            }]
        };
    }

    componentDidUpdate(prevProps, prevState) {
        super.componentDidUpdate(prevProps, prevState);
        if (prevProps.showProficiency !== this.props.showProficiency) {
            this.initColumns();
            this.forceUpdate();
        }
    }

    getRowId(e) {
        return this.tableId + '_' + e.rowId;
    }

    onRowClick(params, e) {
        const { onAttributeSelect, rowHasSelect } = this.props;
        if (onAttributeSelect && (!rowHasSelect || rowHasSelect(params.row))) onAttributeSelect(e, params.row);
    }

    getTreeDataPath(row) {
        return row.attributeHierarchy;
    }

    initColumns() {
        const { showProficiency, columns, sortable, readOnly, rowHasAction, rowNoActionIcon, onRequirementChange, childRowClass, rowActionDisabled } = this.props;

        this.columns = [];

        columns.forEach(c => {
            if (this.hasColumn(c)) {

                if (c.type === AttributeTable.columns.summary) this.columns.push({
                    field: 'summary',
                    headerName: c.label || c.type.label,
                    sortable: sortable,
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    visibleToBreakpoint: c.visibleToBreakpoint || c.type.visibleToBreakpoint,
                    filterable: false,
                    flex: 1,
                    numRows: showProficiency ? 3 : 2,
                    disableReorder: true,
                    renderCell: params => <div className={'klayo-table_multilinerow' + (this.props.tree && params.row.isChild === true ? ' klayo-table_childattr' : '') + (childRowClass ? ' ' + childRowClass(params.row) : '') + (params.row.isParent ? ' klayo-table_parentattr' : '') + (params.row.isParent && params.rowNode.childrenExpanded ? ' klayo-table_rowexpanded' : '')}>
                        <div className='klayo-attrtable_multilinerow_row1'>{params.row.name || params.row.attribute}{!this.props.tree && params.row.isParent ? ' (parent)' : ''}</div>
                        <div className='klayo-attrtable_multilinerow_row2'>{params.row.type ? params.row.type : 'Priority: n/a'}</div>
                        {showProficiency && <div className='klayo-attrtable_multilinerow_row3'>Proficiency: {Utils.formatProficiencyFraction(params.row.currentProficiency, params.row.requiredProficiency, params.row.validated)}</div>}
                    </div>
                });

                if (c.type === AttributeTable.columns.summaryWithPeople) this.columns.push({
                    field: 'summaryWithPeople',
                    headerName: c.label || c.type.label,
                    sortable: sortable,
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    visibleToBreakpoint: c.visibleToBreakpoint || c.type.visibleToBreakpoint,
                    filterable: false,
                    flex: 1,
                    numRows: showProficiency ? 3 : 2,
                    disableReorder: true,
                    renderCell: params => <div className='klayo-table_multilinerow'>
                        <div className='klayo-attrtable_multilinerow_row1'>{params.row.name || params.row.attribute}</div>
                        {showProficiency && <div className='klayo-attrtable_multilinerow_row2'>Average proficiency: {Utils.formatProficiency(params.row.averageProficiency)}</div>}
                        <div className='klayo-attrtable_multilinerow_row3' style={{ display: 'flex' }}>
                            <ProfilePictureGroup max={5} users={params.row.employees} onUserClick={(e, u) => this.onUserClick(e, u, params.row)} />
                        </div>
                    </div>
                });

                if (c.type === AttributeTable.columns.lastUpdate) this.columns.push({
                    field: 'lastUpdate',
                    headerName: c.label || c.type.label,
                    sortable: sortable,
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    width: 200,
                    filterable: false,
                    disableReorder: true,
                    renderCell: params => {
                        if (this.highlightRow && params.row.employeeAttributeId === this.highlightRow) {
                            this.onHighlightRow(params);
                        }
                        return <DateLabel date={params.row.lastUpdate} />;
                    }
                });

                if (c.type === AttributeTable.columns.attribute) this.columns.push({
                    field: 'attribute',
                    sortable: sortable,
                    headerName: c.label || c.type.label,
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    flex: 1,
                    filterable: false,
                    disableReorder: true,
                    renderCell: params => this.props.tree && params.row.isChild === true ?
                        <div className={'klayo-table_attrrow klayo-table_childattr' + (childRowClass ? ' ' + childRowClass(params.row) : '')}>
                            {params.row.name ? params.row.name : params.row.attribute}
                        </div>
                        :
                        <div className={'klayo-table_attrrow' + (params.row.isParent ? ' klayo-table_parentattr' : '') + (params.rowNode.childrenExpanded ? ' klayo-table_rowexpanded' : '')}>
                            {(params.row.name ? params.row.name : params.row.attribute) + (!this.props.tree && params.row.isParent ? ' (parent)' : '')}
                        </div>,
                    valueGetter: params => {
                        return params.row.name ? params.row.name : params.row.attribute;
                    }
                });

                if (c.type === AttributeTable.columns.requestTitle) this.columns.push({
                    field: 'requestTitle',
                    sortable: sortable,
                    headerName: c.label || c.type.label,
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    flex: 1,
                    filterable: false,
                    disableReorder: true,
                    renderCell: params => <div className='klayo-table_requesttitle'>
                        <div className='klayo-table_requesttitle_attr'>{params.row.name}</div>
                        <div className='klayo-table_requesttitle_reason'>
                            {params.row.statusLabel}
                            {!params.row.hasRequiredDetails && params.row.status !== AbstractAttribute.status.review &&
                                <span className='klayo-table_requesttitle_requiresdata'>Requires data{params.row.status}</span>}
                        </div>
                    </div>,
                    valueGetter: params => params.row.name
                });

                if (c.type === AttributeTable.columns.category) this.columns.push({
                    field: 'category',
                    sortable: sortable,
                    headerName: c.label || c.type.label,
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    width: 220,
                    filterable: false,
                    disableReorder: true,
                    valueGetter: params => params.row.categories && params.row.categories.length !== 0 ? params.row.categories.map(c => c.name ? c.name : c).join(", ") : 'n/a'
                });

                if (c.type === AttributeTable.columns.attributeType) this.columns.push({
                    field: 'type',
                    sortable: sortable,
                    headerName: c.label || c.type.label,
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    width: 150,
                    filterable: false,
                    disableReorder: true,
                    valueGetter: params => params.row.type ? params.row.type : 'n/a'
                });

                if (c.type === AttributeTable.columns.requirementType) this.columns.push({
                    field: 'type',
                    sortable: sortable,
                    headerName: c.label || c.type.label,
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    width: c.selectable ? 250 : 200,
                    filterable: false,
                    disableReorder: true,
                    renderCell: params => (!params.row.isChild && c.selectable) || (params.row.isChild && c.selectableOnChild) ? (params.row.isParent !== true ? <SelectMenu
                        className='klayo-table_select'
                        clearable={false}
                        dense={true}
                        noSelectionError={true}
                        placeholder='Select'
                        value={params.row.requirementType}
                        items={Object.entries(AbstractAttribute.type)}
                        onChange={(e) => {onRequirementChange(params.row, e.target.value); this.forceUpdate()}}
                        sx={{ width: '100%' }}
                    /> : 'n/a') : params.row.requirementType,
                });

                if (showProficiency) {
                    if (c.type === AttributeTable.columns.averageProficiency) this.columns.push({
                        field: 'averageProficiency',
                        headerName: c.label || 'Avg. proficiency',
                        visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                        sortable: sortable,
                        type: 'number',
                        width: 170,
                        filterable: false,
                        disableReorder: true,
                        renderCell: params => Utils.formatProficiency(params.row.averageProficiency),
                        sortComparator: (v1, v2) => v2 - v1
                    });

                    if (c.type === AttributeTable.columns.currentProficiency) this.columns.push({
                        field: 'currentProficiency',
                        headerName: c.label || 'Current proficiency',
                        visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                        sortable: sortable,
                        type: 'number',
                        width: 120,
                        filterable: false,
                        disableReorder: true,
                        renderCell: params => Utils.formatProficiency(params.row.currentProficiency, params.row.validated),
                        sortComparator: (v1, v2) => v2 - v1,
                        renderHeader: params => <div className='klayo-table_headerwrap'>{params.colDef.headerName}</div>
                    });

                    if (c.type === AttributeTable.columns.requiredProficiency) this.columns.push({
                        field: 'requiredProficiency',
                        headerName: c.label || 'Required proficiency',
                        visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                        sortable: sortable,
                        type: 'number',
                        width: 120,
                        filterable: false,
                        disableReorder: true,
                        renderCell: params => Utils.formatProficiency(params.row.requiredProficiency, params.row.validated),
                        sortComparator: (v1, v2) => v2 - v1,
                        renderHeader: params => <div className='klayo-table_headerwrap'>{params.colDef.headerName}</div>
                    });
                }

                if (c.type === AttributeTable.columns.status) this.columns.push({
                    field: 'statusLabel',
                    headerName: c.label || c.type.label,
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    width: 190,
                    filterable: false,
                    sortable: sortable,
                    disableReorder: true,
                    disableColumnMenu: true,
                    disableColumnFilter: true
                });

                if (c.type === AttributeTable.columns.reason) this.columns.push({
                    field: 'reasonLabel',
                    headerName: c.label || c.type.label,
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    width: 190,
                    filterable: false,
                    sortable: sortable,
                    disableReorder: true,
                    valueGetter: params => {
                        return params.row.hasValidationRequest() ? params.row.validationRequestState.label : params.row.reasonLabel;
                    }
                });

                if (c.type === AttributeTable.columns.validationRequestStateTest) this.columns.push({
                    field: 'validationRequestStateTest',
                    headerName: c.label || c.type.label,
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    width: 200,
                    filterable: false,
                    sortable: sortable,
                    disableReorder: true,
                    renderCell: params => {
                        return <div>
                            <div>Old: {params.row.originalValidationRequestState ? params.row.originalValidationRequestState.longLabel : ''}</div>
                            <div>New: {params.row.hasValidationRequest() ? params.row.validationRequestState.longLabel : params.row.reasonLabel}</div>
                        </div>
                    }
                });

                if (c.type === AttributeTable.columns.expiryTest) this.columns.push({
                    field: 'expiryTest',
                    headerName: c.label || c.type.label,
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    width: 200,
                    filterable: false,
                    sortable: sortable,
                    disableReorder: true,
                    renderCell: params => {
                        return <div>
                            <div>Old: {params.row.expiryDate ? Utils.formatReadableDate(params.row.expiryDate) : '-'}</div>
                            <div>New: {params.row.requestedExpiryDate ? Utils.formatReadableDate(params.row.requestedExpiryDate) : '-'}</div>
                        </div>
                    }
                });

                if (c.type === AttributeTable.columns.reasonLong) this.columns.push({
                    field: 'reasonLabelLong',
                    headerName: c.label || c.type.label,
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    width: 250,
                    align: 'right',
                    headerAlign: 'right',
                    filterable: false,
                    sortable: sortable,
                    disableReorder: true,
                    valueGetter: params => {
                        return params.row.hasValidationRequest() && !params.row.isParent ? params.row.validationRequestState.longLabel : params.row.reasonLabel;
                    },
                    renderCell: params => {
                        return params.row.hasValidationRequest() && !params.row.isParent ? params.row.validationRequestState.longLabel : params.row.reasonLabel;
                    }
                });

                if (c.type === AttributeTable.columns.comment) this.columns.push({
                    field: 'comment',
                    headerName: c.label || c.type.label,
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    width: 120,
                    filterable: false,
                    sortable: sortable,
                    disableReorder: true
                });

                if (c.type === AttributeTable.columns.documents) this.columns.push({
                    field: 'documents',
                    headerName: c.label || c.type.label,
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    width: 90,
                    filterable: false,
                    type: 'number',
                    sortable: sortable,
                    disableReorder: true,
                    valueGetter: params => params.row.files ? Object.entries(params.row.files).length : 0
                });

                if (c.type === AttributeTable.columns.expiry) this.columns.push({
                    field: 'expiry',
                    headerName: c.label || c.type.label,
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    width: 90,
                    filterable: false,
                    sortable: sortable,
                    disableReorder: true,
                    valueGetter: params => params.row.expiryDate ? Utils.formatReadableDate(params.row.expiryDate) : 0
                });

                if (c.type === AttributeTable.columns.hasTrainingCourses) this.columns.push({
                    field: 'hasTrainingCourses',
                    headerName: c.label || c.type.label,
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    visibleToBreakpoint: c.visibleToBreakpoint || c.type.visibleToBreakpoint,
                    filterable: false,
                    align: 'right',
                    width: 36,
                    disableReorder: true,
                    renderCell: params => params.row.hasTrainingCourses && (<StyledAttributeCertificationCell>
                        <TooltipMUI
                            classes={{tooltip: 'klayo__attribute-certification-tooltip'}}
                            title={<div>Training mapped</div>} 
                            placement='left' 
                            arrow
                        >
                            <CertificateIcon className='certification-icon' />
                        </TooltipMUI>
                    </StyledAttributeCertificationCell>)
                });

                if (c.type === AttributeTable.columns.actions && !readOnly) this.columns.push({
                    field: 'actions',
                    type: 'actions',
                    filterable: false,
                    align: 'right',
                    width: 50,
                    disableReorder: true,
                    getActions: params => [rowHasAction === true || (rowHasAction && rowHasAction(params.row)) ? (
                        <GridActionsCellItem
                            icon={<MoreVertIcon />}
                            label="Actions"
                            onClick={e => this.props.onAttributeAction(e, params.row)}
                        />
                    ) : rowNoActionIcon ? <Tooltip title='Under review' placement='right'>{rowNoActionIcon}</Tooltip> : (<div></div>)]
                });

                if (c.type === AttributeTable.columns.delete && !readOnly) this.columns.push({
                    field: 'delete',
                    type: 'actions',
                    filterable: false,
                    align: 'right',
                    width: 50,
                    disableReorder: true,
                    /*getActions: params => [rowHasAction === true || (rowHasAction && rowHasAction(params.row)) ? (params.row.isChild === true ? <div></div> :
                        <GridActionsCellItem
                            className='klayo-table_deleteaction'
                            icon={<Tooltip title='Cannot be deleted as this attribute is part of a parent attribute' placement='left-start'><DeleteIcon /></Tooltip>}
                            label="Delete"
                            disabled={rowActionDisabled && rowActionDisabled(params.row)}
                            onClick={e => this.props.onAttributeDelete(e, params.row)}
                        />
                    ) : rowNoActionIcon ? rowNoActionIcon : (<div></div>)],*/
                    renderCell: params => rowHasAction === true || (rowHasAction && rowHasAction(params.row)) ? (params.row.isChild === true ? <div></div> :
                        <Tooltip
                            title={rowActionDisabled && rowActionDisabled(params.row) ? 'Cannot be deleted as this competency is part of a parent competency' : 'Delete'}
                            placement='right'><span><Button
                                icon={<DeleteIcon />}
                                disabled={rowActionDisabled && rowActionDisabled(params.row)}
                                onClick={e => this.props.onAttributeDelete(e, params.row)}
                            /></span></Tooltip>) : rowNoActionIcon ? rowNoActionIcon : (<div></div>)
                });

                if (c.type === AttributeTable.columns.edit) this.columns.push({
                    field: 'edit',
                    filterable: false,
                    align: 'right',
                    disableReorder: true,
                    renderCell: params => rowHasAction === true || (rowHasAction && rowHasAction(params.row)) ?
                        <Link
                            className='klayo-table_editlink'
                            onClick={e => this.props.onAttributeEdit(e, params.row)}
                        >Edit</Link> : <div></div>
                });

                if (c.type === AttributeTable.columns.gapIconOld) this.columns.push({
                    field: 'icon',
                    headerName: '',
                    width: 60,
                    valueGetter: params => {
                        const gapReason = params.row.gapReason;
                        return gapReason === /*AbstractAttribute.gapReason.unmet || */gapReason === AbstractAttribute.gapReason.notSet ? null : gapReason === AbstractAttribute.gapReason.met ? 1 : 0;
                    },
                    renderCell: params => {
                        const gapReason = params.row.gapReason;
                        return gapReason === /*AbstractAttribute.gapReason.unmet || */gapReason === AbstractAttribute.gapReason.notSet ? null : gapReason === AbstractAttribute.gapReason.met ?
                            <Tooltip title='gfgf' placement='right'><CheckIcon className='klayo-attrtable_checkicon' /></Tooltip> :
                            <Tooltip title='gfgf' placement='right'><WarningIcon className='klayo-attrtable_warningicon' /></Tooltip>;
                    }
                });

                if (c.type === AttributeTable.columns.gapIcon) this.columns.push({
                    field: 'gapIconTest',
                    headerName: '',
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    width: 60,
                    renderCell: params => params.row.missing === false ?
                        <Tooltip title='Approved competency' placement='left'><CheckIcon className='klayo-attrtable_checkicon' /></Tooltip> :
                        <Tooltip title='Missing competency' placement='left'><WarningIcon className='klayo-attrtable_warningicon' /></Tooltip>
                });

                if (c.type === AttributeTable.columns.attachmentIcon) this.columns.push({
                    field: 'attachmentIcon',
                    headerName: '',
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    width: 44,
                    minWidth: 44,
                    renderCell: params => (params.row.hasDocuments || (params.row.files && Object.entries(params.row.files).length !== 0)) && <AttachmentIcon className='klayo-attrtable_attachment' />
                });

                if (c.type === AttributeTable.columns.employees) this.columns.push({
                    field: 'people',
                    headerName: c.label || 'People',
                    sortable: sortable,
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    visibleToBreakpoint: c.visibleToBreakpoint || c.type.visibleToBreakpoint,
                    width: 200,
                    filterable: false,
                    disableReorder: true,
                    align: 'right',
                    headerAlign: 'right',
                    renderCell: params => <ProfilePictureGroup max={5} users={params.row.employees} onUserClick={(e, u) => this.onUserClick(e, u, params.row)} />,
                    valueGetter: params => {
                        return params.row.employees.length;
                    }
                });

                if (c.type === AttributeTable.columns.numJobs) this.columns.push({
                    field: c.type.field,
                    headerName: c.label || 'Jobs',
                    type: 'number',
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    visibleToBreakpoint: c.visibleToBreakpoint || c.type.visibleToBreakpoint,
                    sortable: sortable,
                    width: 110,
                    filterable: false,
                    disableReorder: true,
                    align: 'right',
                    valueGetter: params => params.row.numberOfJobAttributes
                });

                if (c.type === AttributeTable.columns.numEmployees) this.columns.push({
                    field: c.type.field,
                    type: 'number',
                    headerName: c.label || 'Employees',
                    visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
                    visibleToBreakpoint: c.visibleToBreakpoint || c.type.visibleToBreakpoint,
                    sortable: sortable,
                    width: 110,
                    filterable: false,
                    disableReorder: true,
                    align: 'right',
                    valueGetter: params => params.row.numberOfEmployeeAttributes
                });

                if (c.type === AttributeTable.columns.needsFeedback) this.columns.push({
                    field: c.type.field,
                    type: 'number',
                    headerName: c.label || '',
                    sortable: sortable,
                    align: 'right',
                    width: 50,
                    filterable: false,
                    disableReorder: true,
                    renderCell: params => params.row.needsFeedback === true && !params.row.isChild ? <TooltipIcon icon={<NeedsFeedbackIcon />} text={params.row.comment ? params.row.comment : 'This competency needs feedback'} placement='bottom' /> : ''
                });
            }
        });
    }
};

const StyledAttributeCertificationCell = styled.div`
    display: flex;
    justify-content: center;
    .certification-icon {
        width: 20px;
        height: 20px;
        /* padding: 16px 8px; */
        color: ${KLAYO_COLORS.GreyGR400};

        :hover {
            color: ${KLAYO_COLORS.NeonBlueNB200};
            cursor: pointer;
        }
    }
`