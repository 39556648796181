import { Data } from "../Data";

export class JobSelectorList extends Data {

    static instance = null;

    constructor(options) {
        super({
            api: (options.groupId ? '/MyGroup/' + options.groupId + '/GetJobs' : '/Teams/GetJobs') + '?searchText=' + options.search, callback: data => {
                options.resolve(this, false);
            }, ...options
        });
    }
    
    onDataReceived(data) {
        this.jobs = data.jobs;
    }

    static get(context, search, groupId) {
        return new Promise((resolve, reject) => {
            if (JobSelectorList.instance && search === '') resolve(JobSelectorList.instance, true);
            else JobSelectorList.instance = new JobSelectorList({ resolve, reject, search, context, groupId });
        });
    }

    findMatch(attr) {
        return this.attributes.find(a => a.name === attr);
    }
}