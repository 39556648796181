import { JobSelectorList } from '../../data/filters/JobSelectorList';
import { AppContext } from '../../common/AppContext';
import { AbstractFilterSelect } from './AbstractFilterSelect';
export class JobSelector extends AbstractFilterSelect {

    static contextType = AppContext;

    static defaultProps = {
        label: 'Job',
        listType: 'Jobs',
        ...AbstractFilterSelect.defaultProps
    }

    constructor(props) {
        super(props);

        this.dataList = JobSelectorList;
    }

    getItemId(item) {
        return item.jobDefinitionId;
    }

    getItemLabel(item) {
        const { getItemLabel } = this.props;

        if(getItemLabel) return getItemLabel(item);
        return item;
    }

    getList(dataList) {
        return dataList.jobs;
    }
}