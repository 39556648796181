import { Component } from "react";
import { Stack } from "@mui/material";
import { AppContext } from '../../common/AppContext';
import { Dialog } from "../../components/dialogs/Dialog";
import { ParticipantCompetency } from "./ParticipantCompetency";
import styled from "@emotion/styled";
import { COMPLETED, PARTICIPANT_MARKING } from "../../constants";
import { cloneDeep } from "lodash";

export class ParticipantDetailDialog extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            practical: props.practical,
            participant: cloneDeep(props.participant),
            position: props.position,

            attributes: null,
            selectedAttributes: [],
            selectedSuggestedAttributes: [],
            allSelectedAttributes: [],
            suggestedAttributes: [],
            allowChildren: true,
            attributesSearch: null,
        };

    }

    isValid(participant) {
        if (!participant || !this.isParticipantCompetencyValid(participant)) return false;

        return true;
    }

    isParticipantCompetencyValid(participant) {
        const { practical } = this.state;
        if (participant?.participantAttributes.length > 0 && practical?.isAssessment) {
            for (const attribute of participant.participantAttributes) {
                // check if mark is not choose
                if (!attribute?.mark && attribute?.mark !== PARTICIPANT_MARKING.None.value) {
                    return false;
                }
                // check if mark is pass and expiry date is not set when expiry date is required
                if ((attribute?.mark === PARTICIPANT_MARKING.Pass.value) && !attribute?.expiryDate && attribute?.hasExpiryDate) {
                    return false;
                }
            }
        }

        return true;
    }

    onCompetencyChange() {
        this.forceUpdate();
    }

    onSave() {
        const { onSave } = this.props;
        const { participant } = this.state;

        if (onSave) onSave(participant);
    }

    render() {
        const { theme, onClose } = this.props;
        const { practical, participant } = this.state;

        const saveCancelActions = [{
            label: 'Save',
            primary: true,
            disabled: !this.isValid(participant ? participant : null),
            onClick: this.onSave.bind(this)
        }, {
            label: 'Cancel',
            onClick: onClose,
        }]
        const closeAction = [{
            label: 'Close',
            onClick: onClose,
        }]
        const actionList = participant?.status !== COMPLETED ? saveCancelActions : closeAction;


        return <ParticipantDetailDialogStyled
            className='klayo_participant-detail-dialog'
            open={true}
            theme={theme}
            onClose={onClose}
            fullWidth={true}
            maxWidth='md'
            title={participant ? participant.fullName : ''}
            actions={actionList}>
            <Stack
                direction='column'
                gap='48px'>
                {participant && participant.participantAttributes.map((a =>
                    <ParticipantCompetency
                        isAssessment={practical ? practical.isAssessment : null}
                        key={a.attributeDefinitionId}
                        participant={participant}
                        practical={practical}
                        onCompetencyChange={this.onCompetencyChange.bind(this)}
                        participantAttribute={a}
                        theme={theme} />)
                )}
            </Stack>
        </ParticipantDetailDialogStyled>
    }
}

const ParticipantDetailDialogStyled = styled(Dialog)`
    &.klayo_participant-detail-dialog {
        .MuiDialogContent-root {
            padding-bottom: 0px;
            overflow: visible;
        }
    }
`