import { Component } from "react";
import { AppContext } from '../common/AppContext';
import { Snackbar } from "./Snackbar";

export class ViewComponent extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            attributeSearch: null,
            categorySearch: null,
            errorSnackbar: props.errorSnackbar,
            successSnackbar: null,
            infoSnackbar: null
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location !== prevProps.location) this.onViewChange(this.props.location);
        if (this.state.errorSnackbar !== prevState.errorSnackbar) this.forceUpdate();
        if (this.props.errorSnackbar !== prevProps.errorSnackbar) this.setState({ errorSnackbar: this.props.errorSnackbar });
    }

    onViewChange(location) {

    }

    pathStartsWith(path) {
        return this.props.location.pathname.startsWith(path);
    }

    pathEquals(path) {
        return this.props.location.pathname === path;
    }

    hasQuery(query) {
        return this.props.location.search.includes(query);
    }

    onCloseSnackbar() {
        this.setState({ errorSnackbar: null, successSnackbar: null });
    }

    renderSnackbar() {
        const { errorSnackbar, successSnackbar, infoSnackbar } = this.state;

        return (errorSnackbar || successSnackbar || infoSnackbar) && <Snackbar
            open={true}
            duration={5000}
            onClose={this.onCloseSnackbar.bind(this)}
            type={errorSnackbar ? 'error' : successSnackbar ? 'success' : 'info'}
            message={errorSnackbar || successSnackbar || infoSnackbar} />
    }
}
