
export class BillingInvoice {

    static invoiceStatus = {
        unpaid: {
            index: 0,
            label: 'Unpaid'
        },
        paid: {
            index: 1,
            label: 'Paid'
        },
        cancelled: {
            index: 2,
            label: 'Cancelled'
        }
    };

    constructor(data) {
        this.invoiceId = data.invoiceId;
        this.invoiceNumber = data.invoiceNumber;//+BillingInvoice.invoiceStart;
        this.status = Object.values(BillingInvoice.invoiceStatus)[data.status];
        this.title = data.title;
        this.date = data.invoiceDate;
        this.downloadUrl = data.downloadUrl;
        this.amountInCents = data.amountInCents;
        this.amount = this.amountInCents / 100;
        this.amountPaidInCents = data.amountPaidInCents;
        this.amountPaid = this.amountPaidInCents / 100;
    }
}