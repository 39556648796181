import { Data } from "../Data";
import { GapAttributeList } from "../attributes/GapAttributeList";
import { UserAttributeSummary } from "./UserAttributeSummary";
import { EmployeeAttributeList } from "../attributes/EmployeeAttributeList";
import { JobAttributeList } from "../attributes/JobAttributeList";
import { AbstractAttribute } from "../attributes/AbstractAttribute";

export class UserProfile extends Data {

    constructor(options) {
        super({
            api: '/Profile?employeeId=' + options.employeeId,
            callback: data => {

                const { employee, jobDefinition, gapAttributes, job } = data;

                this.employeeId = employee.employeeId;
                this.employeeNumber = employee.employeeNumber;
                this.gender = employee.gender;
                this.email = employee.email;
                this.fullName = employee.fullName;
                this.jobTitle = employee.jobTitle;
                this.location = employee.location;
                this.preferredName = employee.preferredName;
                this.gender = employee.gender;
                this.ethnicity = employee.ethnicity;
                this.isAdmin = employee.isOrganizationAdmin || employee.isTeamLead;
                this.reportsTo = employee.reportsTo;
                this.hasManager = this.reportsTo !== null;
                this.hasGroupManager = employee.hasAGroupManagerApprover;
                this.hasApprover = this.hasManager === true || this.hasGroupManager === true;
                this.startDate = employee.startDate;
                this.location = employee.location;
                this.locationName = employee.locationName;
                this.dateOfBirth = employee.dateOfBirth;
                this.organizationName = employee.organizationName;

                this.employeeAttributes = new EmployeeAttributeList({ data: employee.employeeAttributes });
                this.gapAttributes = new GapAttributeList({ data: gapAttributes });
                this.attributeSummary = new UserAttributeSummary(employee.attributeSummaryDto);

                //Job
                if (jobDefinition) {
                    this.jobDescription = jobDefinition.description;
                    this.jobAttributes = new JobAttributeList({ data: jobDefinition.jobAttributes });
                    this.updateJobAttributes();
                    this.gapAttributes.initAttributesLists(this.jobAttributes, this.employeeAttributes);
                }

                //Job
                this.hasJob = job !== null && job !== undefined;
                if (this.hasJob) {
                    this.department = job.department;
                }


                options.resolve(this);

            }, ...options
        });
    }

    onDataReceived(data) {
    }

    static get(context, employeeId) {
        return new Promise((resolve, reject) => {
            new UserProfile({ employeeId: employeeId, resolve, reject, context });
        });
    }

    updateJobAttributes() {
        this.jobAttributes.attributes.forEach(a => {
            a.currentProficiency = this.getProficiency(a.attributeDefinitionId);
            a.missing = a.gapReason !== AbstractAttribute.gapReason.met; //emplAttr === undefined;

            if(a.childAttributes) a.childAttributes.forEach(c => {
                const emplChildAttr = this.jobAttributes.getByDefinition(c.attributeDefinitionId);
                if(emplChildAttr) c.gapReason = emplChildAttr.gapReason;

                c.missing = c.gapReason !== AbstractAttribute.gapReason.met;
                //if(c.missing) a.missing = true;
            });
        });
    }

    removeAttribute(attr) {
        this.employeeAttributes.removeAttribute(attr);
    }

    getProficiency(jobDefId) {
        const attr = this.employeeAttributes.getByDefinition(jobDefId);
        return attr ? attr.currentProficiency : null;
    }

    isComplient(attr) {
        if (!attr) return false;

        const reqProf = attr.requiredProficiency;
        const curProd = this.getProficiency(attr.attributeDefinitionId);
        const profMet = curProd >= reqProf;

        return attr.mandatory ? profMet : (profMet ? true : null);
    }

    findMatch(attr) {
        return this.list.find(a => a.name === attr);
    }

    hasAttribute(attr) {
        return this.employeeAttributes?.getAttributeByEmployeeAttributeId(attr.attributeDefinitionId);
    }
}