
import { Dialog, Box, AppBar, Toolbar, DialogContent } from "@mui/material";
import { AbstractDialog } from "../../components/AbstractDialog";
import { ProfileLayout } from "./ProfileLayout";
import { AppContext } from '../../common/AppContext';
import { Button } from "../../components/Button";
import { ReactComponent as CloseIcon } from '../../resources/images/icons-close.svg';
import { ReactComponent as Logo } from '../../resources/images/logo.svg';
import { UserProfileDialog } from './UserProfileDialog';
import { AddEditAttributeDialog } from '../profile/AddEditAttributeDialog';

export class EmployeeProfileDialog extends AbstractDialog {

    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            profile: null,
            showProfileDialog: false
        };
    }

    componentDidMount() {
        this.setLoading('employee', !this.props.employee);
        this.setEmployee(this.props.employee);
    }

    componentDidUpdate(prevProps) {
        if (this.props.employee !== prevProps.employee) {
            this.setLoading('employee', false);
            this.setEmployee(this.props.employee);
        }
    }

    onViewDetails() {
        this.setState({ showProfileDialog: true });
    }

    onCloseProfileDialog() {
        this.setState({ showProfileDialog: false });
    }

    setEmployee(employee, updateCache) {
        this.setLoading('profile', true);

        employee.loadProfile(this.context, updateCache)
            .then(profile => {
                this.setState({ profile: profile });
            })
            .catch(error => {
                //Todo: handle error
                //alert('error: ' + error);
            })
            .finally(() => this.setLoading('profile', false));
    }

    reload() {
        this.setEmployee(this.props.employee, true);
    }

    render() {
        const { addOrEditCompetencies, onClose, theme, user, employee, attribute, basePath, location, organization, onShowAttributeDetails, onCloseAttributeDialog, treeMode, onChangeViewMode, isInstructorViewing, isSelfView, onEditAttribute, addEditAttributeDialogMode, onAddAttributeClickCb, isTeamView, employeeAttribute, onAddChangeAction, onDeleteAttribute, onFeedbackAction, onProficiencyChangeAction, isNotInstructor} = this.props;
        const { profile, showProfileDialog } = this.state;

        return (
            <Dialog
                open={true}
                className='klaro_employeeprofile'
                fullScreen={true}
                onClose={onClose}
                maxWidth='xs'>
                {super.renderLoadingBar()}
                <AppBar
                    className='klaro_employeeprofile-appbar'
                    elevation={0}>
                    <Toolbar>
                        <Logo className="klaro-appbar_logo" alt="Klayo Logo" />
                        <Box sx={{ flexGrow: 1 }}>
                        </Box>
                        <Box>
                            <Button
                                autoFocus
                                label='Close'
                                variant="outlined"
                                size='md'
                                startIcon={<CloseIcon />}
                                onClick={onClose} />
                        </Box>
                    </Toolbar>
                </AppBar>
                <DialogContent sx={{
                    marginTop: { xs: '56px', md: '64px' },
                    padding: { xs: '40px 16px', md: '40px 32px' },
                    backgroundColor: '#F8F8FE'
                }}>
                    <Box sx={{ maxWidth: { xs: 'auto', lg: '1040px', xl: '1440px' }, margin: '0 auto' }}>
                        <ProfileLayout
                            organization={organization}
                            user={user}
                            myView={isSelfView}
                            employee={employee}
                            theme={theme}
                            profile={profile}
                            basePath={basePath}
                            location={location}
                            treeMode={treeMode}
                            isInstructorViewing={isInstructorViewing}
                            onChangeViewMode={onChangeViewMode}
                            readOnly={!isInstructorViewing && !isSelfView}
                            onShowAttributeDetails={onShowAttributeDetails}
                            onViewDetails={this.onViewDetails.bind(this)}
                            onEditAttribute={onEditAttribute}
                            onAddAttributeClickCb={onAddAttributeClickCb}
                            onAddChangeAction={onAddChangeAction}
                            onDeleteAttribute={onDeleteAttribute}
                            onFeedbackAction={onFeedbackAction}
                            onProficiencyChangeAction={onProficiencyChangeAction}
                            isTeamView={isTeamView}
                        />
                    </Box>

                    {showProfileDialog &&
                        <UserProfileDialog
                            user={employee}
                            employee={employee}
                            theme={theme}
                            profile={profile}
                            onClose={this.onCloseProfileDialog.bind(this)}
                            readOnly={true} />}

                    {/* used for company */}
                    {(attribute && profile && !isTeamView) &&
                        <AddEditAttributeDialog
                            organization={organization}
                            user={user}
                            employee={employee}
                            theme={theme}
                            profile={profile}
                            attributeId={attribute}
                            readOnly={true}
                            onClose={onCloseAttributeDialog}
                            mode={AddEditAttributeDialog.mode.details} />}

                    {/* used for team */}
                    {(addOrEditCompetencies && profile && (isSelfView || isNotInstructor) && isTeamView) &&
                        <AddEditAttributeDialog
                            organization={organization}
                            user={user}
                            employee={employee}
                            theme={theme}
                            profile={profile}
                            attributeId={attribute}
                            attribute={employeeAttribute}
                            readOnly={addEditAttributeDialogMode.hash === AddEditAttributeDialog.mode.details.hash}
                            onClose={onCloseAttributeDialog}
                            mode={(!isSelfView && isNotInstructor) ? AddEditAttributeDialog.mode.details : addEditAttributeDialogMode} />}

                </DialogContent>
            </Dialog>
        )
    }
}
