import { Component } from "react";
import { Paper } from '../../components/Paper';
import { Link } from "react-router-dom";
import { AddEditEmployeeView } from "./AddEditEmployeeView";
import { Divider, Stack, MenuItem, Menu, ListItemText } from "@mui/material";
import { Button } from "../../components/Button";
import { TextField } from "../../components/TextField";
import { ReactComponent as AddIcon } from '../../resources/images/icons-add.svg';
import { ReactComponent as SearchIcon } from '../../resources/images/icons-search.svg';
import { GroupsTable } from "../../components/table/GroupsTable";
import { SettingsEmployee } from "../../data/settings/SettingsEmployee";
import { ErrorModel } from '../../common/models/ErrorModel';
import { Data } from '../../data/Data';
import { AppContext } from '../../common/AppContext';
import { ViewComponent } from "../../components/ViewComponent";
import { Snackbar } from "../../components/Snackbar";
import { AddEditGroupView } from "./AddEditGroupView";
import { SettingsGroup } from "../../data/settings/SettingsGroup";
import { ConfirmationDialog } from "klayowebshared";
import { trimToLowercase } from "../../utilities";

const axios = require('axios').default;
export class SettingsGroupsView extends ViewComponent {
    static contextType = AppContext;

    static defaultProps = {
        allowAssignAdminBilling: true
    }

    constructor(props) {
        super(props);

        this.state = {
            ...ViewComponent.state,
            saveGroupError: null,
            search: null,
            actionGroup: null,
            deletingGroup: null
        };
    }

    componentDidMount() {
        const { onLoadGroups } = this.props;
        onLoadGroups();
    }

    onViewChange(location) {
        if (this.pathEquals('/settings/groups')) this.resetErrors();
    }

    resetErrors() {
        this.setState({ saveGroupError: null });
    }

    onSearch(e) {
        this.setState({ search: e.target.value });
    }

    getGroupId() {
        const { location } = this.props;

        const path = location.pathname;
        return path.includes('edit') ? path.replace('/settings/groups/edit/', '') : null;
    }

    getGroup() {
        const { groups } = this.props;
        if (!groups) return null;

        const editGroupId = this.getGroupId();
        return groups.get(editGroupId);
    }

    onGroupAction(e, group) {
        this.setState({ actionTarget: e.target, actionGroup: group });
    }

    onGroupSelect(e, group) {
        const { history } = this.props;
        history.push('/settings/groups/edit/' + group.groupId);
    }

    onEditGroup() {
        const { history } = this.props;
        const { actionGroup } = this.state;
        this.onCloseActionMenu();

        history.push('/settings/groups/edit/' + actionGroup.groupId);
    }

    onDeleteGroup() {
        const { actionGroup } = this.state;
        this.setState({ deletingGroup: actionGroup });
        this.onCloseActionMenu();
    }

    onCloseActionMenu() {
        this.setState({ actionTarget: null, actionGroup: null });
    }

    onDeleteGroupConfirmed() {
        const { deletingGroup } = this.state;
        this.deleteGroup(deletingGroup);
        this.setState({ deletingGroup: null });
    }

    onCancelDeleteGroup() {
        this.setState({ deletingGroup: null });
    }

    deleteGroup(group) {
        const { groups, onLoadGroups } = this.props;
        this.context.setLoading('deleting', true);

        axios
            .delete(Data.apiBasePath + '/Group', {
                data: {
                    groupId: group.groupId
                },
                withCredentials: true
            })
            .then(response => {
                groups.delete(group);
                if (onLoadGroups) onLoadGroups(true);
            })
            .catch(e => {
                this.setState({ errorSnackbar: ErrorModel.parseServerError(e) });
            })
            .finally(() => {
                this.context.setLoading('deleting', false);
            });
    }

    onSaveGroup(group, callback) {
        const { user, groups, onLoadGroups, onDataChanged } = this.props;

        this.resetErrors();
        this.context.setLoading('saveGroup', true);

        if (!group.groupId) {
            axios
                .post(Data.apiBasePath + '/Group', group.toApiDto(), {
                    withCredentials: true
                })
                .then(response => {
                    group.groupId = response.data.group.groupId;
                    groups.add(group);
                    if (onLoadGroups) onLoadGroups(true);
                    if (onDataChanged) onDataChanged('groups');
                    this.props.history.push('/settings/groups');
                })
                .catch(e => {
                    this.setState({ saveGroupError: ErrorModel.parseServerError(e) });
                })
                .finally(() => {
                    this.context.setLoading('saveGroup', false);
                    callback();
                });
        }
        else {
            axios
                .put(Data.apiBasePath + '/Group', group.toApiDto(), {
                    withCredentials: true
                })
                .then(response => {
                    groups.update(group);
                    if (onLoadGroups) onLoadGroups(true);
                    if (onDataChanged) onDataChanged('groups');
                    this.props.history.push('/settings/groups');
                })
                .catch(e => {
                    this.setState({ saveGroupError: ErrorModel.parseServerError(e) });
                })
                .finally(() => {
                    this.context.setLoading('saveGroup', false);
                });
        }
    }

    render() {
        const { organization, theme, user, location, history, jobs, groups, orgSettings, onDataChanged, onNavigation, onBlockNavigation, onAllowNavigation, onLoadJobs } = this.props;
        const { search, saveGroupError, actionTarget, actionGroup, deletingGroup } = this.state;

        const path = location.pathname;
        const editMode = path.includes('edit');
        const showAddEditGroupView = path.startsWith('/settings/groups/new') || path.startsWith('/settings/groups/edit');

        const editGroup = this.getGroup();
        const editGroupId = this.getGroupId();

        let filterItems = [{ id: 'search', columnField: 'name', operatorValue: 'contains', value: search }];

        const filterGroups =  groups ? groups.groups.filter(group => {
          if (search) {
            const keyword = trimToLowercase(search || '');
            const groupName = trimToLowercase(group?.name || '');
            return groupName?.includes(keyword);
          } else {
            return true;
          }
        }) : []

        return <div>
            {!showAddEditGroupView ? <Paper
                theme={theme}
                padding={{ xs: '46px 24px', md: '60px' }}
                borderFromBreakpoint='md'>
                <h1>Groups</h1>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    sx={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)', padding: '30px 0' }}>
                    {groups && groups.groups.length > 0 ? <TextField
                        value={search}
                        dense={true}
                        placeholder='Search groups'
                        disabled={user === null}
                        fullWidth={true}
                        autoComplete={false}
                        leadingIcon={<SearchIcon />}
                        onChange={this.onSearch.bind(this)}
                        sx={{ maxWidth: { md: '300px' } }} /> : <div></div>}
                    <Button
                        path={'/settings/groups/new'}
                        size='md'
                        theme={theme}
                        variant='filled'
                        showLabelFromBreakpoint='md'
                        startIcon={<AddIcon />}
                        label='New group' />
                </Stack>
                <GroupsTable
                    pagination={false}
                    showFooter={false}
                    rowHasAction={true}
                    minHeight='300px'
                    theme={theme}
                    sortable={true}
                    showHeaderFromBreakpoint='md'
                    filterItems={filterItems}
                    hideFirstLastBorder={true}
                    dense={true}
                    columns={[
                        { type: GroupsTable.columns.name },
                        { type: GroupsTable.columns.memberCount },
                        { type: GroupsTable.columns.managerCount },
                        { type: GroupsTable.columns.actions }
                    ]}
                    sortModel={[{
                        field: 'name',
                        sort: 'asc',
                    }]}
                    onGroupSelect={this.onGroupSelect.bind(this)}
                    onGroupAction={this.onGroupAction.bind(this)}
                    paper={false}
                    // rows={groups ? groups.groups : null} 
                    rows={filterGroups} 
                    />

                {actionGroup &&
                    <Menu
                        open={actionTarget !== null}
                        anchorEl={actionTarget}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        onClose={this.onCloseActionMenu.bind(this)}
                    >
                        <MenuItem onClick={this.onEditGroup.bind(this)}>
                            <ListItemText>Edit group</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={this.onDeleteGroup.bind(this)}>
                            <ListItemText>Delete group</ListItemText>
                        </MenuItem>
                    </Menu>
                }

                {deletingGroup &&
                    <ConfirmationDialog
                        theme={theme}
                        title='Delete group'
                        question={'Are you sure you want to delete ' + deletingGroup.name + '? This operation cannot be undone.'}
                        cancelButton='Cancel'
                        acceptButton='Delete group'
                        acceptDanger={true}
                        onCancel={this.onCancelDeleteGroup.bind(this)}
                        onAccept={this.onDeleteGroupConfirmed.bind(this)} />}

            </Paper>
                :
                <AddEditGroupView
                    user={user}
                    organization={organization}
                    orgSettings={orgSettings}
                    theme={theme}
                    editMode={editMode}
                    group={editMode ? editGroup : new SettingsGroup()}
                    groupId={editGroupId}
                    error={saveGroupError}
                    jobs={jobs}
                    locations={orgSettings ? orgSettings.locations : null}
                    onDataChanged={onDataChanged}
                    onCancel={e => history.push('/settings/groups')}
                    onSave={this.onSaveGroup.bind(this)}
                    onLoadJobs={onLoadJobs}
                    onBlockNavigation={onBlockNavigation}
                    onAllowNavigation={onAllowNavigation}
                    onNavigation={onNavigation} />
            }
        </div>
    }
}