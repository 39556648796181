import { Autocomplete, ListItem, ListItemIcon, ListItemText, Chip, Stack, createFilterOptions } from '@mui/material';
import React, { Component } from 'react';
import { ReactComponent as DropIcon } from '../../resources/images/icons-chevron-double.svg';
import { ReactComponent as RemoveIcon } from '../../resources/images/icons-remove.svg';
import { ReactComponent as AddIcon } from '../../resources/images/icons-add.svg';
import { TextField } from '../TextField';

import { MultiSelectorContent } from './MultiSelectorContent';
import IntegrationAbbreviation from '../../views/settings/IntegrationAbbreviation';

export class MultiSelector extends Component {

    static defaultProps = {
        allowMultiple: true,
        newItemLabel: null,
        hasAllItem: false,
        allItemLabel: 'All',
        allSelected: false,
    }

    constructor(props) {
        super(props);

        this.state = {
            selectedValues: props.values ? props.values : [],
            allSelected: props.allSelected,
            valid: null,
            inputValue: null,
            hasAllItem: props.hasAllItem,
            allItemLabel: props.allItemLabel,
            selectorContainerSize: null,
        };

        this.filterOptions = createFilterOptions({
            matchFrom: 'any',
            limit: props.maxResults,
            stringify: option => this.getItemLabel(option),
        });

        this.selectorContainerRef = React.createRef();
    }

    componentDidMount() {
        const { isValidateOnMount } = this.props;

        this.onGetSelectorContainerSize();

        if (isValidateOnMount) {
            this.validate(null);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.values !== this.props.values) {
            this.setState({ selectedValues: this.props.values });
            this.validate(null);
        }
        if (prevProps.allSelected !== this.props.allSelected) this.setState({ allSelected: this.props.allSelected });
    }

    onGetSelectorContainerSize() {
        const selectorContainerSize = this.selectorContainerRef?.current.getBoundingClientRect();
        this.setState({ selectorContainerSize });
    }

    onChange(e, selected, reason, details) {
        const { onChange } = this.props;
        const { selectedValues } = this.state;

        if(Array.isArray(selected) && selected.some(i => i.isNewItem)) {
            this.onNewItemClick(e);
            return;
        }

        this.setState({ allSelected: selected.isAllItem, selectedValues: Array.isArray(selected) ? selected : (selected.isAllItem ? [selected] : [...selectedValues.filter(i => !i.isAllItem), selected].filter((v, i, a) => a.indexOf(v) === i)) }, () => {
            if (onChange) onChange(e, this.state.selectedValues, selected.isAllItem);
        });
    }

    onInputChange(e) {
        const { onSearch } = this.props;

        if (e && onSearch) onSearch(e.target.value);
        if (e) this.setState({ inputValue: e.target.value });
    }

    addItem(newItem) {
        const { onChange } = this.props;
        const { selectedValues } = this.state;

        this.setState({ selectedValues: [...selectedValues, newItem] }, () => {
            if (onChange) onChange(null, this.state.selectedValues);
        });
    }

    onNewItemClick(e) {
        const { onNewItemClick } = this.props;
        const { inputValue } = this.state;

        if (onNewItemClick) onNewItemClick(e, inputValue, newItem => {
            if (newItem) this.addItem(newItem);
        });
    }

    onBlur(e) {
        const { onBlur } = this.props;

        this.validate(e);
        this.setState({ inputValue: null });

        if (onBlur) onBlur(e);
    }

    validate(e) {
        const { validationMethod } = this.props;
        const { valid } = this.state;

        if (validationMethod) {
            const newValid = validationMethod(e, e ? e.target.value : null);
            if (newValid !== valid) this.setState({ valid: newValid });
        }
    }

    renderNewItemOption(props) {
        const { newItemLabel } = this.props;
        const { inputValue } = this.state;

        return <ListItem {...props}
            className='klayo-multiselector_additem'
            onClick={this.onNewItemClick.bind(this)}>
            <ListItemIcon><AddIcon /></ListItemIcon>
            <ListItemText>{newItemLabel ? newItemLabel : 'Create ' + (inputValue ? inputValue : '')}</ListItemText>
        </ListItem>
    }

    // render autocomplete
    renderOption(props, option, state) {
        const { hasNewItem, organization } = this.props;
        const { selectorContainerSize } = this.state;

        if (hasNewItem && option.isNewItem) return this.renderNewItemOption(props);

        return (
            <MultiSelectorContent 
                organization={organization}
                option={option}
                inputValue={state.inputValue}
                props={props}
                selectorContainerSize={selectorContainerSize}
                getItemLabel={this.getItemLabel.bind(this)}
                getItemId={this.getItemId.bind(this)}
            />
        ) 
    }

    getItemLabel(item) {
        const { hasNoneItem, noneItemLabel, hasAllItem, allItemLabel, hasNewItem, getItemLabel, newItemLabel } = this.props;
        const { inputValue } = this.state;

        if (hasNoneItem && item.isNoneItem === true) 
            return noneItemLabel;

        if (hasAllItem && item.isAllItem === true) 
            return allItemLabel;

        if (hasNewItem && item.isNewItem === true) 
            return inputValue;

        return item ? getItemLabel && !item.name ? getItemLabel(item) : item.name : '';
    }

    getItemId(item) {
        const { getItemId } = this.props;
        return getItemId ? getItemId(item) : item;
    }

    onRemoveItem(e, item) {
        const { selectedValues } = this.state;
        const { onChange } = this.props;

        this.setState({ selectedValues: selectedValues.filter(i => this.getItemId(i) !== this.getItemId(item)) }, () => {
            if (onChange) onChange(e, this.state.selectedValues);
        });
    }

    onRemoveAllItem(e) {
        const { onChange } = this.props;
        this.setState({ selectedValues: [] }, () => {
            if (onChange) onChange(e, this.state.selectedValues);
        });
    }

    compareItems(a, b) {
        if (a === null || b === null) return 0;
        return this.getItemId(a) === this.getItemId(b);
    }

    filter(items) {
        const { itemFilter } = this.props;
        const { selectedValues } = this.state;
        return items.filter(o => o === null || (selectedValues.find(s => this.compareItems(s, o)) === undefined && (!itemFilter || (itemFilter && itemFilter(o)))));
    }

    render() {
        const { items, label, sx, helperText, placeHolder, validationText, itemFilter, renderSelector, hasNoneItem, noneItemLabel, hasAllItem, allItemLabel, hasNewItem, disabled, className, organization, disableCallback } = this.props;
        const { selectedValues, valid, allSelected } = this.state;

        const hasSelected = selectedValues && selectedValues.length ? 'has-selected' : '';

        return (
            <Stack
                className={'klayo-multiselector' + (hasNewItem ? ' klayo-multiselector--withnewitemoption' : '') + (className ? ' ' + className : '')}
                direction='column'
                ref={this.selectorContainerRef}
                spacing={2}>
                {renderSelector ?
                    renderSelector({
                        onChange: this.onChange.bind(this),
                        onBlur: this.onBlur.bind(this),
                        clearable: false,
                        clearOnSelection: true,
                        excludeItems: selectedValues,
                        hasNoneItem: hasNoneItem,
                        noneItemLabel: noneItemLabel,
                        hasAllItem: hasAllItem,
                        allItemLabel: allItemLabel,
                        disabled: disabled
                    }) :
                    <Autocomplete
                        className="klayo-selector"
                        options={items ? itemFilter ? this.filter(items.filter(i => i ? itemFilter(i) : true)) : this.filter(items) : []}
                        //value={itemFilter ? selectedValues.filter(i => itemFilter(i)) : selectedValues}
                        value={selectedValues}
                        getOptionLabel={this.getItemLabel.bind(this)}
                        onChange={this.onChange.bind(this)}
                        onInputChange={this.onInputChange.bind(this)}
                        filterOptions={(options, params) => {
                            const filtered = this.filterOptions(options, params);

                            if (hasAllItem)
                                filtered.concat({ id: -11, name: allItemLabel, isAllItem: true });

                            let showNewItemOption = !(filtered.length === 1 && this.getItemLabel(filtered[0]).toUpperCase() === params.inputValue.trim().toUpperCase()) && hasNewItem;
                            return showNewItemOption ? [...filtered, { id: -12, name: allItemLabel, isNewItem: true }] : filtered;
                        }}
                        autoComplete
                        includeInputInList
                        filterSelectedOptions
                        popupIcon={<DropIcon />}
                        clearIcon={<RemoveIcon />}
                        disableClearable={true}
                        handleHomeEndKeys
                        onBlur={this.onBlur.bind(this)}
                        disabled={disabled}
                        componentsProps={{
                            paper: {
                                className: 'klayo-multiselector_dropdownlist_paper' + (hasNewItem ? ' klayo-multiselector_dropdownlist_paper--withnewitemoption' : '')
                            }
                        }}
                        ListboxProps={{
                            className: 'klayo-multiselector_dropdownlist' + (hasNewItem ? ' klayo-multiselector_dropdownlist--withnewitemoption' : '')
                        }}
                        ChipProps={{
                            deleteIcon: <RemoveIcon />
                        }}
                        multiple
                        renderOption={this.renderOption.bind(this)}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label={label}
                                placeholder={placeHolder ? placeHolder : 'Search'}
                                error={valid === false}
                                helperText={valid === false ? validationText : helperText}
                                sx={{ width: '100%' }}
                                endAdornment={params.InputProps.endAdornment}
                            />
                        }
                        sx={sx}
                    />}
                <Stack className={`klaro-chiparray ${hasSelected}`} direction='row' spacing={1} sx={{ ...sx, flexWrap: 'wrap' }}>
                    {(hasAllItem && allSelected) ?
                        <Chip
                            key={0}
                            label={allItemLabel}
                            onDelete={e => this.onRemoveAllItem(e)}
                            disabled={disabled}
                            deleteIcon={<RemoveIcon />} />
                        :
                        Object.values(selectedValues).map((i, index) => {
                            
                            const itemLabel = organization && organization.hasMultipleIntegrations ? <div>
                                {this.getItemLabel(i)} <IntegrationAbbreviation data={i} type={IntegrationAbbreviation.dataType.integrationUser.value} />
                            </div> : <div>{this.getItemLabel(i)}</div>;
                            const disableItem = disableCallback ? disableCallback(i) : false;

                            return <Chip
                                key={index}
                                label={itemLabel}
                                disabled={disabled || disableItem}
                                onDelete={e => this.onRemoveItem(e, i)}
                                deleteIcon={<RemoveIcon />} />
                        })}
                </Stack>
            </Stack>
        );
    }
}