import React from 'react';
import App from '../../App';
import { AbstractView } from '../AbstractPage';
import { ProfileJobSection } from './ProfileJobSection';
import { ProfileAttributesSection } from './ProfileAttributesSection';
import { ProfileGapsSection } from './ProfileGapsSection';
import { DeleteConfirmationDialog } from '../other/DeleteConfirmationDialog';
import { InvalidProfilePictureDialog } from './InvalidProfilePictureDialog';
import { AppContext } from '../../common/AppContext';
import { AddEditAttributeDialog } from './AddEditAttributeDialog';
import { Data } from '../../data/Data';
import { ProfileLayout } from './ProfileLayout';
import { EventBus } from '../../common/EventBus';
import { UserProfileDialog } from './UserProfileDialog';
import { View } from '../../components/View';
import { ErrorModel } from '../../common/models/ErrorModel';
import { Snackbar } from '../../components/Snackbar';
import { ViewHeading } from '../../components/typography/ViewHeading';

const axios = require('axios').default;

export class Profile extends AbstractView {
    static contextType = AppContext;

    static subPages = {
        job: { name: 'My job', slug: '', component: ProfileJobSection },
        attributes: { name: 'My attributes', slug: 'attributes', component: ProfileAttributesSection },
        gaps: { name: 'My gaps', slug: 'gaps', component: ProfileGapsSection }
    };

    static allowedFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    static maxFileSize = 500000;  //kb

    static errorMimetype = 'Please upload a valid profile picture (jpeg or png)';
    static errorFilesize = 'Please upload a valid profile picture (500kb or less)';

    constructor(props) {
        super(props, App.pages.profile);

        this.state = {
            profile: null,
            subPage: Profile.subPages.job.slug,
            profilePicUpdating: false,
            profilePicUpdated: false,
            deletingAttribute: null,
            invalidProfilePicture: null,
            profilePictureReset: false,
            dialogAttribute: null,
            addEditAttributeDialogMode: AddEditAttributeDialog.mode.add,
            showProfileDialog: false,
            deleteAttributeError: null
        };
    }

    onHasUser(user, organization) {
        if (this.verifyAccess()) this.loadProfile();
    }

    onFeedbackAction(e, attr) {
        this.openAddEditAttributeDialog(e, attr, AddEditAttributeDialog.mode.feedback);
    }

    onProficiencyChangeAction(e, attr) {
        this.openAddEditAttributeDialog(e, attr, AddEditAttributeDialog.mode.proficiencyChange);
    }

    onShowAttributeDetails(e, attr) {
        if(attr.isNeedsFeedback()) this.onFeedbackAction(e, attr);
        else this.openAddEditAttributeDialog(e, attr, AddEditAttributeDialog.mode.details);
    }

    onAddChangeAction(e, attr) {
        this.openAddEditAttributeDialog(e, attr, AddEditAttributeDialog.mode.add);
    }

    openAddEditAttributeDialog(e, attribute, mode) {
        this.setState({ dialogAttribute: attribute, addEditAttributeDialogMode: mode }, state => {
            window.location = this.props.location.pathname + '#' + mode.hash;
        });
    }

    onProfilePictureChange(e) {
        const file = e.target.files[0];

        this.setState({ profilePictureReset: true });

        if (!Profile.allowedFileTypes.includes(file.type)) {
            this.setState({ invalidProfilePicture: Profile.errorMimetype })
            return;
        }

        if (file.size > Profile.maxFileSize) {
            this.setState({ invalidProfilePicture: Profile.errorFilesize })
            return;
        }

        const reader = new FileReader();
        reader.addEventListener('load', result => {
            this.setState({ profilePicUpdating: true });

            let formData = new FormData();
            formData.append('File', file);

            axios.post(Data.apiBasePath + '/Profile/Avatar', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                withCredentials: true
            })
                .then(response => {
                    this.props.user.hasProfilePicture = true;

                    this.setState({ profilePicUpdating: false, profilePicUpdated: true });
                    this.context.eventBus.dispatch(EventBus.event.profilePictureChanged, null);
                })
                .catch(e => {
                    this.setState({ invalidProfilePicture: e.response ? e.response.data === 'Unsupported File Type' ? Profile.errorMimetype : e.response.data : e.message, profilePicUpdating: false })
                });

        }, false);
        reader.readAsDataURL(file);
    }

    loadProfile(updateCache) {
        const { user, onShowError } = this.props;
        this.context.setLoading('profile', true);

        user.loadProfile(this.context, updateCache)
            .then(profile => {
                this.setState({ profile: profile });
            })
            .catch(error => onShowError(new ErrorModel(ErrorModel.Severity.FAIL, error, 'Profile API')))
            .finally(() => this.context.setLoading('profile', false));
    }

    onSnackbarClose(event, reason) {
        this.setState({ profilePicUpdated: false });
    };

    closeDeleteDialog() {
        this.setState({ deletingAttribute: null, deleteAttributeError: null });
    }

    closeInvalidProfilePictureDialog() {
        this.setState({ invalidProfilePicture: null });
    }

    deleteAttribute() {
        const { user } = this.props;
        const { deletingAttribute } = this.state;

        axios.delete(Data.apiBasePath + '/EmployeeAttribute', {
            data: {
                employeeAttributeId: deletingAttribute.employeeAttributeId
            },
            withCredentials: true
        })
            .then(response => {
                user.profile.removeAttribute(deletingAttribute);
                this.setState({ deletingAttribute: null, deleteAttributeError: null });
                this.reload();
            })
            .catch(e => {
                this.setState({ deleteAttributeError: ErrorModel.parseServerError(e) });
            });
    }

    onDeleteAttribute(e, attr) {
        this.setState({ deletingAttribute: attr });
    }

    closeAddEditDialog(refresh) {
        this.props.history.push(this.props.location.pathname);
        this.setState({ addEditAttributeDialogMode: null, dialogAttribute: null });

        if (refresh) this.reload();
    }

    onViewDetails() {
        this.setState({ showProfileDialog: true });
    }

    onCloseProfileDialog() {
        this.setState({ showProfileDialog: false });
    }

    reload() {
        this.loadProfile(true);
    }

    render() {
        if (!this.verifyAccess()) return this.renderAccessDenied();

        const { organization, user, theme, location, treeMode, onChangeViewMode } = this.props;
        const { profile, dialogAttribute, addEditAttributeDialogMode, showProfileDialog, deleteAttributeError } = this.state;
        const { profilePicUpdating, profilePicUpdated, invalidProfilePicture, profilePictureReset, deletingAttribute } = this.state;

        const addDialogOpen = user && (location.hash === '#add' || location.hash === '#edit' || location.hash === '#details');

        return <View theme={theme} scrollToTopOnMount={true}>
            <ViewHeading text='My profile' />

            <ProfileLayout
                organization={organization}
                user={user}
                employee={user}
                profile={profile}
                theme={theme}
                basePath='/profile'
                location={location}
                readOnly={false}
                myView={true}
                treeMode={treeMode}
                onChangeViewMode={onChangeViewMode}
                invalidProfilePicture={invalidProfilePicture}
                onDeleteAttribute={this.onDeleteAttribute.bind(this)}
                onFeedbackAction={this.onFeedbackAction.bind(this)}
                onProficiencyChangeAction={this.onProficiencyChangeAction.bind(this)}
                onShowAttributeDetails={this.onShowAttributeDetails.bind(this)}
                onViewDetails={this.onViewDetails.bind(this)}
                onAddChangeAction={this.onAddChangeAction.bind(this)} />

            {deletingAttribute &&
                <DeleteConfirmationDialog
                    theme={theme}
                    error={deleteAttributeError}
                    onClose={this.closeDeleteDialog.bind(this)}
                    onAccept={this.deleteAttribute.bind(this)} />}

            {invalidProfilePicture &&
                <InvalidProfilePictureDialog
                    theme={theme}
                    reason={invalidProfilePicture}
                    onClose={this.closeInvalidProfilePictureDialog.bind(this)} />}

            {addDialogOpen && user && profile && ((addEditAttributeDialogMode !== AddEditAttributeDialog.mode.add) || (addEditAttributeDialogMode === AddEditAttributeDialog.mode.add && profile.hasApprover !== false)) &&
                <AddEditAttributeDialog
                    organization={organization}
                    user={user}
                    employee={user}
                    theme={theme}
                    profile={profile}
                    onClose={this.closeAddEditDialog.bind(this)}
                    attribute={dialogAttribute}
                    mode={addEditAttributeDialogMode} />}

            {showProfileDialog &&
                <UserProfileDialog
                    user={user}
                    employee={user}
                    theme={theme}
                    profile={profile}
                    onClose={this.onCloseProfileDialog.bind(this)}
                    readOnly={false}
                    profilePictureUpdating={profilePicUpdating}
                    onProfilePictureChange={this.onProfilePictureChange.bind(this)}
                    profilePictureReset={profilePictureReset} />}

            <Snackbar
                open={profilePicUpdated}
                duration={3000}
                onClose={this.onSnackbarClose.bind(this)}
                message="Updated profile picture"
            />
        </View>
    }
}