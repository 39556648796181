import { Component } from "react";
import { Dialog } from "../../components/dialogs/Dialog";
import { EmployeeTable } from "../../components/table/EmployeeTable";

export class GroupMembersDialog extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { theme, employees, onCancel } = this.props;

        return <Dialog
            open={true}
            onClose={onCancel}
            theme={theme}
            fullWidth={true}
            title='Group members'
            maxWidth='sm'
            actions={[{
                label: 'Close',
                primary: true,
                onClick: onCancel
            }]}>
            <EmployeeTable
                pagination={false}
                showFooter={false}
                rowHasAction={true}
                theme={theme}
                showHeaderFromBreakpoint='md'
                hideFirstLastBorder={true}
                dense={true}
                columns={[
                    { type: EmployeeTable.columns.fullName },
                    { type: EmployeeTable.columns.jobTitle }
                ]}
                sortModel={[{
                    field: 'fullName',
                    sort: 'asc',
                }]}
                paper={false}
                rows={employees}
                sx={{ maxHeight: '400px'}} />
        </Dialog>
    }
}