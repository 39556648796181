import { Component, React } from 'react';
import { Dialog } from '../../components/dialogs/Dialog';
import styled from '@emotion/styled';
import { KLAYO_COLORS } from '../../themes';
import { Stack } from '@mui/material';
import { DateLabel } from 'klayowebshared';
import { Tooltip as CustomTooltip } from '../../components/Tooltip';
import { Utils } from '../../common/Utils';

export class PracticalConfirmationDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            practical: props.practical,
        }
    }

    componentDidUpdate(prevProps) {
        const { participantPracticals, practical } = this.props;

        if (prevProps.practical !== practical || prevProps.participantPracticals !== participantPracticals) {
            if (participantPracticals?.length > 0 && !practical) this.onGetConfirmationDetails();
        }

    }

    getPracticalIdFromPath() {
        const { location } = this.props;
        const path = location.pathname;
        const pathParts = path.split('/');
        const id = pathParts[pathParts.length - 1];

        return id;
    }

    onGetConfirmationDetails() {
        const { participantPracticals } = this.props;

        const participantPractical = participantPracticals.find(pp => pp.id === this.getPracticalIdFromPath());
        this.setState({ practical: participantPractical });
    }

    renderDateField(data) {
      const { practicalDate, practicalTime } = data;
      const date = Utils.formatDate(practicalDate);
      const time = Utils.convertPracticalTime(practicalTime, true);
      const string = `${date} ${time}`.trim();
      return <CustomTooltip title={string}>
          <div className='content'>{date}</div>
      </CustomTooltip>
  }

    render() {
        const { theme, onClose, onConfirm, participantPracticals } = this.props;
        const { practical } = this.state;

        const confirmationContent = !!practical && practical?.id
            ? 'The following competencies will be updated on your profile:'
            : 'You are not a participant in this practical'
        const confirmationButtons = !!practical && !practical?.isAcknowledged ? [{
            label: 'Confirm',
            primary: true,
            onClick: () => onConfirm(practical),
        }, {
            label: 'Close',
            variant: 'outlined',
            onClick: onClose
        }] : [{
            label: 'Close',
            variant: 'filled',
            onClick: onClose
        }]

        return (
            <StyledPracticalConfirmationDialog
                className='klayo__confirmation-attribute-dialog'
                open={participantPracticals.length > 0 ? true : false}
                theme={theme}
                onClose={onClose}
                fullWidth
                maxWidth='sm'
                actions={confirmationButtons}>
                <h1>{practical && practical.name}</h1>
                {practical ? <Stack direction='row'>
                    <div className='klayo__confirmation-sub-content first'>
                        <p className='title'>Date</p>
                        {this.renderDateField(practical)}
                    </div>
                    <div className='klayo__confirmation-sub-content'>
                        <p className='title'>Type</p>
                        <p className='content'>{practical?.practicalTypeName}</p>
                    </div>
                    <div className='klayo__confirmation-sub-content'>
                        <p className='title'>Instructor</p>
                        <p className='content'>{practical?.instructorFullname}</p>
                    </div>
                    {practical?.acknowledgedDate && (
                        <div className='klayo__confirmation-sub-content'>
                            <p className='title'>Confirmed</p>
                            <DateLabel date={practical?.acknowledgedDate} />
                        </div>
                    )}
                </Stack> : null}
                <p className='klayo__confirmation-description'>{practical && practical.description}</p>
                <h2>{confirmationContent}</h2>
                {practical?.practicalAttributeNames.map(pName => {
                    return <li className='klayo__confirmation-attribute-name'>{pName}</li>
                })}
            </StyledPracticalConfirmationDialog>
        )
    }
}

const StyledPracticalConfirmationDialog = styled(Dialog)`
	&.klayo__confirmation-attribute-dialog {

		h1 {
			color: ${KLAYO_COLORS.GreyGR900};
			font-family: Inter;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: 28px;
			letter-spacing: -0.3px;
			margin-top: 0px;
			margin-bottom: 12px;
		}

		h2 {
			color: ${KLAYO_COLORS.GreyGR900};
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
			letter-spacing: -0.1px;
			margin-top: 24px;
			margin-bottom: 8px;
		}

		.klayo__confirmation-description {
			color: ${KLAYO_COLORS.GreyGR900};
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
			letter-spacing: -0.1px;
		}

		.klayo__confirmation-attribute-name {
			&::before {
				content: '•';
				color: black;
				margin: 0px 8px;
			}
			list-style-type: none;

			color: ${KLAYO_COLORS.GreyGR900};
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px;
			letter-spacing: -0.1px;
		}

		.MuiDialogContent-root {
			padding: 40px 40px 0px 40px;
		}

		.MuiPaper-root {
			max-width: 640px;
		}

		.klayo__confirmation-sub-content {
			padding: 0px 16px;
			border-left: 1px solid ${KLAYO_COLORS.GreyGR200};

			&.first {
				padding: 0px 16px 0px 0px;
				border-left: none;
			}

			.title {
				font-family: Inter;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 16px;
				color: ${KLAYO_COLORS.GreyGR500};
				margin: 0px;
			}

			.content {
				font-family: Inter;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
				color: ${KLAYO_COLORS.GreyGR900};
				margin: 0px;
			}
		}
	}
`