import { Box, LinearProgress, Stack } from "@mui/material";
import axios from "axios";
import { EditTemplate } from "klayowebshared";
import { cloneDeep, isEmpty, isEqual } from "lodash";
import { Component } from "react";
import { AppContext } from "../../common/AppContext";
import { ErrorModel } from "../../common/models/ErrorModel";
import { Utils } from "../../common/Utils";
import { Alert } from "../../components/Alert";
import { DatePicker } from "../../components/DatePicker";
import { Dialog } from "../../components/dialogs/Dialog";
import { DragDropFileUpload } from "../../components/DragDropFileUpload";
import { MultiFileUpload } from "../../components/MultiFileUpload";
import { RadioGroup } from "../../components/RadioGroup";
import { RequestActivityFeed } from "../../components/RequestActivityFeed";
import { AttributeSelector } from "../../components/selectors/AttributeSelector";
import { ProficiencySelector } from "../../components/selectors/ProficiencySelector";
import { DocumentList } from "../../components/table/DocumentList";
import { TextField } from "../../components/TextField";
import { AbstractAttribute } from "../../data/attributes/AbstractAttribute";
import { AttributeDefinitionSingleList } from "../../data/attributes/AttributeDefinitionSingleList";
import { Data } from "../../data/Data";

export class InstructorAddEditAttributeDialog extends Component {
    static contextType = AppContext;
    static defaultProps = {
        allowNeedsFeedback: true,
        orgHasProficiency: false,
        newFileUploadComponent: true
    }

    static allowedMimeTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain', 'text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
    static maxFileSize = 10240000;
    static errorMimeType = 'Please upload a valid evidence file (doc, docx, pdf, txt, csv, xls, xlsx, png, or jpeg).';
    static errorFileSize = 'Please upload a valid evidence file (10Mb or less)';

    constructor(props) {
        super(props);

        this.state = {
            allowEditProficiency: this.isEditProficiencyAllowed(props.attribute),
            allowEditExpiry: this.isEditExpiryAllowed(props.attribute),
            proficiencyRequired: this.isProficiencyRequired(props.attribute),
            expiryRequired: this.isExpiryRequired(props.attribute),
            error: null,
            expiryDateValid: true,
            proficiencyError: false,
            expiryError: false,
            hasEdit: false,
            comments: '',
            files: null,
            proficiency: null,
            expiry: null,
            deletedDocumentIds: null,
        };

        this.existingAttribute = cloneDeep(props?.attribute);
    }

    componentDidMount() {
        const { attribute, employee } = this.props;
        const attributeClone = cloneDeep(attribute)
        this.setState({ attribute: cloneDeep(attributeClone) });
        if (attributeClone?.employeeAttributeId) {
            this.loadPreviousActivity(attributeClone);
        }

        this.context.setLoading('definitions', true);

        AttributeDefinitionSingleList.get(this.context).then(list => {
            this.setState({ definitions: list });
        })
            .catch(error => {
            })
            .finally(() => this.context.setLoading('definitions', false));

        this.loadProfile();
    }

    componentDidUpdate(prevProps, prevState) {
        const { attribute } = this.props;
        if (prevProps.attribute !== this.props.attribute && !attribute.isNew && !attribute.requestActivity) {
            this.loadPreviousActivity(attribute);
        }
    }

    loadPreviousActivity(attribute) {
        this.setState({ submitting: true });

        attribute.loadRequestActivity(this.context)
            .then(activity => {
                attribute.documents = activity?.documents;
                this.setState({
                    submitting: false,
                    attribute,
                });
            })
            .catch(e => {
                console.log(e);
                alert(e.message);
            })
            .finally(() => this.setState({ submitting: false }));
    }

    loadProfile(updateCache) {
        const { employee, onShowError } = this.props;
        this.context.setLoading('profile', true);

        employee.loadProfile(this.context, updateCache)
            .then(profile => {
                this.setState({ profile: profile });
            })
            .catch(error => onShowError(new ErrorModel(ErrorModel.Severity.FAIL, error, 'Profile API')))
            .finally(() => this.context.setLoading('profile', false));
    }

    isEditProficiencyAllowed(attr) {
        if (!attr) return false;
        return attr.validationRequestState !== AbstractAttribute.status.feedback;
    }

    isEditExpiryAllowed(attr) {
        if (!attr) return false;
        return attr.validationRequestState?.index !== AbstractAttribute.status.feedback.index;
    }

    isProficiencyRequired(attr) {
        const {orgHasProficiency} = this.props;
        if (!attr || !attr.hasProficiency) return false;
        return this.isEditProficiencyAllowed(attr) && orgHasProficiency;
    }

    isExpiryRequired(attr) {
        if (!attr || !attr.hasExpiryDate) return false;
        return this.isEditExpiryAllowed(attr);
    }

    validate() {
        const { orgHasProficiency } = this.props;
        const { attribute } = this.state;
        const { expiryDateValid } = this.state;

        if (orgHasProficiency && this.isProficiencyRequired(attribute) && !attribute.hasValidPendingProficiency()) { this.setState({ error: 'Please select the competency proficiency level', proficiencyError: true }); return false; }
        if (this.isExpiryRequired(attribute) && (!attribute.hasValidPendingExpiryDate())) { this.setState({ error: 'Please select the competency expiry date', expiryError: true, proficiencyError: false }); return false; }

        return true;
    }

    onCommentChange(e) {
        const { attribute } = this.state;
        attribute.comment = e.target.value;
        this.updateAttributeState(attribute);
    }

    onProficiencyChange(e, proficiency) {
        const { attribute } = this.state;
        attribute.pendingProficiency = proficiency;
        this.updateAttributeState(attribute);
    }

    onFilesChange(e, files) {
        const { attribute } = this.state;
        attribute.files = files;
        this.updateAttributeState(attribute);
    }

    onStatusChange(e) {
        const { attribute } = this.state;
        attribute.validationRequestState = Object.values(AbstractAttribute.status)[e.target.value];
        this.updateAttributeState(attribute);
    }

    onExpiryDateChange(date, valid) {
        const { attribute } = this.state;
        attribute.pendingExpiryDate = date;
        this.updateAttributeState(attribute, valid);
    }

    updateAttributeState(attribute, expiryDateValid) {
        this.setState({
            attribute,
            allowEditProficiency: this.isEditProficiencyAllowed(attribute),
            allowEditExpiry: this.isEditExpiryAllowed(attribute),
            proficiencyRequired: this.isProficiencyRequired(attribute),
            expiryRequired: this.isExpiryRequired(attribute),
            expiryDateValid: expiryDateValid || this.state.expiryDateValid
        });
    }

    onShowUploadComponent(e) {
        this.setState({ showUploadComponent: true });
    }

    onDeleteDocument(e, doc) {
        const { deletedDocumentIds } = this.state;
        const idList = isEmpty(deletedDocumentIds)
            ? [doc.documentId]
            : [...deletedDocumentIds, doc.documentId];
        this.setState({ deletedDocumentIds: idList });
    }

    onEditStateChange(hasEdit) {
        this.setState({ hasEdit });
    }

    onNavigation(e, callback) {
        const { onNavigation } = this.props;
        return onNavigation && onNavigation(e, callback);
    }

    onCancel(e, source) {
        const { onClose } = this.props;
        const { hasEdit } = this.state;
        const { onBlockNavigation } = this.props;
        if (onBlockNavigation) onBlockNavigation(false, 'Employee attribute');
        // if (hasEdit) this.onNavigation(e, this.stopBlockingNavAndClose.bind(this));
        // else if (onClose)
        onClose(false);
    }

    stopBlockingNavAndClose(e) {
        const { onCancel, onBlockNavigation } = this.props;
        if (onBlockNavigation) onBlockNavigation(false, 'Employee attribute');
        if (onCancel) onCancel(e);
    }

    onAttributeChange(e, attribute, alreadyHasAttribute) {
        const { profile } = this.state;
        this.setState({ attribute: cloneDeep(attribute), alreadyHasAttribute: alreadyHasAttribute, error: null });

        if (attribute) {
            const existingAttribute = profile.employeeAttributes.getAttribute(attribute.attributeDefinitionId);
            if (existingAttribute) {
            }

            if (attribute.isParent) {
                attribute.childAttributes.forEach(child => {
                    const existingChild = profile.employeeAttributes.getAttribute(child.attributeDefinitionId);

                    child.alreadyHasAttribute = existingChild !== undefined;
                    if (child.alreadyHasAttribute) {
                        child.status = existingChild.status;
                        child.statusLabel = existingChild.statusLabel;
                        child.expiryDate = existingChild.expiryDate;
                    }
                    else child.statusLabel = 'Missing';

                    child.hasRequiredDetails = AbstractAttribute.hasRequiredDetails(child);
                });
            }
        }
        else {
            this.setState({ comments: '', files: null, proficiency: null, expiry: null, deletedDocumentIds: null });
        }
    }

    onDocumentClick(e, doc) {
        this.setState({ downloadingDoc: doc });

        Data.call('get', '/EmployeeAttribute/download/' + doc.documentId, { responseType: 'blob' })
            .then(response => {
                const blob = new Blob([response.data], { type: response.headers['content-type'] });

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', doc.documentName);
                document.body.appendChild(link);
                link.click();

            })
            .catch(e => {
                this.setState({ error: ErrorModel.parseServerError(e) });
            })
            // .finally(() => this.setLoading('downloadingDoc', false))
            ;
    }

    onSave(e) {
        const { onClose, onBlockNavigation, employee } = this.props;
        const { attribute, deletedDocumentIds } = this.state;
        const initialValues = { attribute: null, comments: '', files: null, proficiency: null, expiry: null, deletedDocumentIds: null }

        if (!this.validate()) return;
        if (onBlockNavigation) onBlockNavigation(false, 'Employee attribute');
        this.setState({ proficiencyError: false, expiryError: false, submitting: true });

        const formData = new FormData();
        const { employeeAttributeId, pendingProficiency, comment, pendingExpiryDate, files, id, validationRequestState } = attribute;
        if (validationRequestState?.index) formData.append('State', validationRequestState?.index);
        if (pendingProficiency) formData.append('ProficiencyValue', Number.isInteger(pendingProficiency) ? pendingProficiency : 0);
        if (comment) formData.append('CommentText', comment);
        if (pendingExpiryDate) formData.append('ExpiryDate', Utils.toApiDate(pendingExpiryDate));
        if (files) Object.entries(files).map((f, i) => {
            if (f[1] !== null) { formData.append('FormFiles', f[1]); }
        });
        if (!employeeAttributeId) {
            if (employee?.id) formData.append('EmployeeId', employee?.id);
            if (id) formData.append('AttributeDefinitionId', id);

            axios.post(Data.apiBasePath + '/EmployeeAttribute/byinstructor', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                withCredentials: true
            })
                .then(_ => {
                    this.setState({ submitting: false, ...initialValues });
                    onClose(true, 'add');
                })
                .catch(e => {
                    this.setState({ error: ErrorModel.parseServerError(e) });
                })
                .finally(() => this.setState({ submitting: false }));
        } else {
            formData.append('EmployeeAttributeId', employeeAttributeId);
            if (!isEmpty(deletedDocumentIds)) {
              deletedDocumentIds.forEach(d =>  formData.append('DeletedEvidences', d));
            }
            axios.put(Data.apiBasePath + '/EmployeeAttribute/byinstructor', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                withCredentials: true
            })
                .then(_ => {
                    this.setState({ submitting: false, ...initialValues });
                    onClose(true, 'edit');
                })
                .catch(e => {
                    this.setState({ error: ErrorModel.parseServerError(e) });
                })
                .finally(() => this.setState({ submitting: false }));
        }
    }

    validateRequireField() {
        const {attribute} = this.state;
        if (!attribute?.pendingExpiryDate && this.isExpiryRequired(attribute)) return true;
        if (!attribute?.pendingProficiency && this.isProficiencyRequired(attribute)) return true;
        return false
    }


    render() {
        const { user, employee, theme, onCancel, allowNeedsFeedback, orgHasProficiency, newFileUploadComponent, onAllowNavigation, onBlockNavigation, location, onClose } = this.props;
        const { allowEditProficiency, comments, allowEditExpiry, proficiencyRequired, expiryRequired, error, proficiencyError, expiryError, hasEdit, definitions, attribute, submitting, deletedDocumentIds } = this.state;

        const existingDocs = attribute?.documents && attribute?.documents.list.length > 0;
        const currentDocs = attribute?.documents?.list?.filter(d => !deletedDocumentIds?.includes(d?.documentId));

        const loadedFeed = attribute?.isNew || attribute?.requestActivity;

        const isAddMode = location.hash === '#add'
        return <EditTemplate
            theme={theme}
            name='Employee attribute'
            onAllowNavigation={onAllowNavigation}
            onBlockNavigation={onBlockNavigation}
            onEditStateChange={this.onEditStateChange.bind(this)}
            detectEdit={!isAddMode}
            compare={{
                existing: this.existingAttribute,
                editing: attribute,
                members: [
                    {
                        name: 'status',
                        detectEdit: (existing, editing) => {
                            return existing?.validationRequestState?.index !== editing?.validationRequestState?.index;
                        }
                    },
                    {
                        name: 'comment',
                        detectEdit: (existing, editing) => {
                            return editing?.comment && existing?.comment !== editing?.comment;
                        }
                    },
                    {
                        name: 'files',
                        detectEdit: (_, editing) => {
                            return !!editing?.files && Object.entries(editing.files)?.length;
                        }
                    },
                    {
                        name: 'expiry',
                        detectEdit: (existing, editing) => {
                            return Utils.formatReadableDate(existing?.pendingExpiryDate) !==
                                Utils.formatReadableDate(editing?.pendingExpiryDate);
                        }
                    },
                    {
                        name: 'deletedDocuments',
                        detectEdit: () => {
                            return deletedDocumentIds?.length > 0
                        }
                    }
                ]
            }}>
            <Dialog
                theme={theme}
                open={true}
                onClose={onCancel}
                fullWidth={true}
                maxWidth='sm'
                title={isAddMode ? 'Add competency' : 'Edit competency'}
                actions={[{
                    label: isAddMode ? 'Add' : 'Save',
                    primary: true,
                    disabled: submitting || !hasEdit || this.validateRequireField(),
                    onClick: this.onSave.bind(this)
                }, {
                    label: 'Cancel',
                    disabled: submitting,
                    onClick: this.onCancel.bind(this)
                }]}>
                {submitting ? (<LinearProgress sx={{ mb: 4 }} />) : null}

                {(definitions && isAddMode && employee?.profile?.employeeAttributes) ? (<Box mb={4}><AttributeSelector
                    label='Competency (required)'
                    definitions={definitions}
                    employeeAttributes={employee && employee.profile && employee.profile.employeeAttributes ? employee.profile.employeeAttributes : null}
                    attribute={attribute}
                    allowSelection={true}
                    showExistingEmployeeAttributes={false}
                    onChange={this.onAttributeChange.bind(this)}
                />
                </Box>) : null}

                {(attribute) && <Stack spacing={5} >
                    {error && <Alert severity='error' scrollTo={true}>{error}</Alert>}

                    {attribute?.employeeAttributeId ? <TextField
                        label='Competency'
                        disabled={true}
                        clearable={false}
                        value={attribute?.attributeName}
                    /> : null}

                    <RadioGroup
                        label='Competency status'
                        items={[
                            {
                                value: AbstractAttribute.status.approved.index,
                                label: 'Approved'
                            },
                            {
                                value: AbstractAttribute.status.feedback.index,
                                label: 'Needs employee feedback'
                            },
                            {
                                value: AbstractAttribute.status.review.index,
                                label: 'Pending manager feedback',
                                helperText: !allowNeedsFeedback ? 'A reporting manager needs to be assigned to this employee' : null,
                                disabled: !allowNeedsFeedback
                            }]}
                        value={attribute && (attribute?.validationRequestState || attribute?.status) ? (attribute.validationRequestState?.index || attribute.status?.index) : null}
                        disabled={submitting}
                        onChange={this.onStatusChange.bind(this)}
                    />

                    {orgHasProficiency && attribute && attribute.hasProficiency &&
                        <ProficiencySelector
                            label={'Proficiency' + (proficiencyRequired ? ' (required)' : '')}
                            proficiency={attribute.validationRequestState === AbstractAttribute.status.feedback ? (attribute.requestedProficiency || attribute.currentProficiency) : attribute.pendingProficiency}
                            originalProficiency={attribute.currentProficiency || attribute.requestedProficiency}
                            forceShowOriginal={false}
                            disabled={!allowEditProficiency || submitting}
                            autoFocus={proficiencyError}
                            error={proficiencyError}
                            onChange={this.onProficiencyChange.bind(this)} />}

                    {attribute && attribute.hasExpiryDate &&

                        <Stack>
                            <DatePicker
                                theme={theme}
                                label={'Expiry date' + (expiryRequired ? ' (required)' : '')}
                                onChange={this.onExpiryDateChange.bind(this)}
                                value={isEqual(attribute?.validationRequestState, AbstractAttribute.status.feedback) ?
                                    (attribute.requestedExpiryDate || attribute.currentExpiryDate) ? new Date(attribute.requestedExpiryDate || attribute.currentExpiryDate) : null :
                                    attribute.pendingExpiryDate ? new Date(attribute.pendingExpiryDate) : null}
                                allowPast={true}
                                showPastWarning={allowEditExpiry}
                                showPastWarningMessage='The selected expiry date is in the past. You can save it as is, but the competency will expire overnight.'
                                allowEmpty={false}
                                autoFocus={expiryError}
                                error={expiryError}
                                helperText={attribute.currentExpiryDate ? 'Current expiry: ' + Utils.formatReadableDate(attribute.currentExpiryDate) : null}
                                disabled={!allowEditExpiry || submitting}
                                // validationMethod={this.checkValidExpiry.bind(this)}
                                validationText='Please enter a valid expiry date'
                            />
                        </Stack>}

                    {existingDocs && <div>
                        <DocumentList
                            theme={theme}
                            label='Evidence'
                            files={attribute ? attribute.files : null}
                            onViewDocument={this.onDocumentClick.bind(this)}
                            onDeleteDocument={this.onDeleteDocument.bind(this)}
                            documents={currentDocs}
                            onFilesChange={this.onFilesChange.bind(this)}
                            // disabled={!loadedFeed}
                            allowedMimeTypes={InstructorAddEditAttributeDialog.allowedMimeTypes}
                            maxFileSize={InstructorAddEditAttributeDialog.maxFileSize}
                            errorMimeType={InstructorAddEditAttributeDialog.errorMimeType}
                            errorFileSize={InstructorAddEditAttributeDialog.errorFileSize} />
                    </div>}

                    {(!existingDocs) && newFileUploadComponent && <DragDropFileUpload
                        label={existingDocs ? null : 'Evidence'}
                        files={attribute ? attribute.files : null}
                        accept='csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                        onChange={this.onFilesChange.bind(this)}
                        disabled={submitting}
                        allowedMimeTypes={InstructorAddEditAttributeDialog.allowedMimeTypes}
                        maxFileSize={InstructorAddEditAttributeDialog.maxFileSize}
                        errorMimeType={InstructorAddEditAttributeDialog.errorMimeType}
                        errorFileSize={InstructorAddEditAttributeDialog.errorFileSize} />}

                    {(!existingDocs) && !newFileUploadComponent && <MultiFileUpload
                        label="Evidence"
                        files={attribute ? attribute.files : null}
                        placeholder="Add supporting documents"
                        accept='csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                        onChange={this.onFilesChange.bind(this)}
                        // disabled={!loadedFeed}
                        InputLabelProps={{ shrink: true }}
                        helperText='Supported document types: PDF, JPEG, PNG, DOC, DOCX'
                        allowedMimeTypes={InstructorAddEditAttributeDialog.allowedMimeTypes}
                        maxFileSize={InstructorAddEditAttributeDialog.maxFileSize}
                        errorMimeType={InstructorAddEditAttributeDialog.errorMimeType}
                        errorFileSize={InstructorAddEditAttributeDialog.errorFileSize}
                        sx={{ width: '100%' }}
                    />}

                    <TextField
                        label='Comments'
                        value={attribute.comment}
                        autoComplete={false}
                        clearable={false}
                        placeholder="Add any comments or notes"
                        disabled={submitting}
                        onChange={this.onCommentChange.bind(this)}
                        sx={{ width: '100%' }} />

                    {attribute?.requestActivity &&
                        <RequestActivityFeed
                            user={user}
                            employee={employee}
                            attribute={attribute}
                            documents={attribute?.documents}
                            activity={attribute?.requestActivity}
                            onDocumentClick={this.onDocumentClick.bind(this)}
                            sx={{ marginTop: '-20px' }} />
                    }
                </Stack>}
            </Dialog>
        </EditTemplate>
    }
}