import { Card, CardContent, Stack } from '@mui/material';
import React, { Component } from 'react';
import { ReactComponent as StarterIcon } from '../resources/images/icons-building-1.svg';
import { ReactComponent as LargeIcon } from '../resources/images/icons-building-2.svg';
import { ReactComponent as OrganizationIcon } from '../resources/images/icons-building-3.svg';
import { ReactComponent as EnterpriseIcon } from '../resources/images/icons-building-4.svg';
import { Utils } from '../common/Utils';
import { Button } from './Button';

export class BillingGroupCard extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { group, onClick, selected, existing, disabled } = this.props;

        return <Card
            className={'klayo_billingplancard klayo_billingplancard-' + group.name.toLowerCase() + (selected ? ' klayo_billingplancard--selected' : '') + (disabled ? ' klayo_billingplancard--disabled' : '')}
            onClick={onClick && !disabled && !group.isCustom ? e => onClick(e, group) : null}>
            <CardContent>
                <div className='klayo_billingplancard_contents'>
                    <div className='klayo_billingplancard_icon'>
                        {group.employeeCap <= 25 ? <StarterIcon /> : group.employeeCap <= 50 ? <LargeIcon /> : <OrganizationIcon />}
                    </div>
                    <div className='klayo_billingplancard_details'>
                        <div className='klayo_billingplancard_name'>{(group.isCustom ? 'Enterprise' : group.name)+(existing ? ' (Your current plan)' : '')}</div>
                        <div className='klayo_billingplancard_users'>{(group.employeeCapDescription || (group.isCustom ? 'Over 200 users' : 'Up to ' + group.employeeCap + ' users'))+(!group.isAllowed ? ' (Over limit for this plan)' : '')}</div>
                    </div>
                    {group.isCustom &&
                        <div>
                            <Button
                            label='Contact us'
                            size='md'
                            onClick={onClick ? e => onClick(e, group) : null} />
                        </div>}
                    {!group.isCustom &&
                        <div className='klayo_billingplancard_price'>
                            <div className='klayo_billingplancard_pricepercycle'>{group.pricePerMonthDescription || ('$'+Utils.formatCurrency(group.pricePerCycle)+' / '+group.cycle.perLabel)}</div>
                            <div className='klayo_billingplancard_priceperuser'>{group.pricePerEmployeeDescription || ('$'+Utils.formatCurrency(group.pricePerUser)+' / user')}</div>
                        </div>}
                </div>
            </CardContent>
        </Card>
    }
}