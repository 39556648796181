
import { Data } from "../Data";
export class AbstractAttributeList extends Data {

    static allFilter = { key: 'all', label: 'Show all' };

    constructor(options) {
        super(options);
        this.attributes = new Array();
    }

    initAttributesLists() {
        let childAttrs = [];
        this.attributes.forEach(a => childAttrs = childAttrs.concat(a.childAttributes));

        this.allAttributes = [...this.attributes, ...childAttrs];
        //this.flatAttributes = [...this.attributes.filter(a => !a.isParent), ...childAttrs];
        //this.flatAttributes = this.flatAttributes.filter((v, i, a) => a.findIndex(a2 => v.attributeDefinitionId === a2.attributeDefinitionId) === i);

        this.flatAttributes = [...this.attributes];
        this.treeAttributes = [...this.attributes.filter(a => !childAttrs.find(a2 => a.attributeDefinitionId === a2.attributeDefinitionId)), ...childAttrs];
    }

    getAttributeByEmployeeAttributeId(id) {
        return this.attributes.find(a => a.employeeAttributeId === id);
    }

    fetchAttributeByEmployeeAttributeId(context, id) {
        return new Promise((resolve, reject) => {
            const attr = this.getAttributeByEmployeeAttributeId(id);
            if (attr) resolve(attr);
            else {
                this.updateData(context)
                    .then(data => {
                        const attr = this.getAttributeByEmployeeAttributeId(id);
                        
                        if(attr) resolve(attr);
                        else reject();
                    });
            }
        });
    }

    getAttribute(id) {
        return this.attributes.find(a => a.attributeDefinitionId === id && a.isChild !== true);
    }

    hasDefinition(id) {
        return this.attributes.find(a => a.attributeDefinitionId === id) !== undefined;
    }

    removeAttribute(attr) {
        this.attributes = this.attributes.filter(a => a.employeeAttributeId !== attr.employeeAttributeId);
    }

    getRequiredAttributes() {
        return this.attributes.filter(a => a.required).length;
    }

    getMandatoryAttributes() {
        return this.attributes.filter(a => a.mandatory).length;
    }
}