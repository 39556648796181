import styled from "@emotion/styled";
import { Box, Fade, Grid, Stack, Step, StepLabel, Stepper, Tab, Tabs, Typography } from "@mui/material";
import { Dialog, EditTemplate, Switch } from "klayowebshared";
import { isEmpty, isEqual } from "lodash";
import { Component } from "react";
import { AppContext } from '../../common/AppContext';
import { ErrorModel } from '../../common/models/ErrorModel';
import { Alert } from "../../components/Alert";
import { Button } from "../../components/Button";
import { DatePicker } from "../../components/DatePicker";
import { MenuSelect } from "../../components/MenuSelect";
import { PaperOrDialog } from "../../components/PaperOrDialog";
import { MultiPracticalAttributeSelector } from "../../components/selectors/MultiPracticalAttributeSelector";
import { ParticipantTable } from "../../components/table/ParticipantTable";
import { PracticalLogTable } from "../../components/table/PracticalLogTable";
import { TextField } from "../../components/TextField";
import { TimePicker } from '../../components/TimePicker';
import { TooltipIcon } from "../../components/TooltipIcon";
import { COMPLETED, PAGE_SIZE_FOR_ADD_EDIT, PRACTICAL_STATUS, TableSearchDebounceTime } from "../../constants";
import { Data } from '../../data/Data';
import { Organization } from '../../data/Organization';
import { ParticipantAttribute } from "../../data/practical/ParticipantAttribute";
import { Practical } from "../../data/practical/Practical";
import { SettingsAttributeList } from "../../data/settings/SettingsAttributeList";
import { ReactComponent as AddIcon } from '../../resources/images/icons-add.svg';
import { ReactComponent as LeftIcon } from '../../resources/images/icons-arrow-left.svg';
import { ReactComponent as RightIcon } from '../../resources/images/icons-arrow-right.svg';
import { ReactComponent as SearchIcon } from '../../resources/images/icons-search.svg';
import { KLAYO_COLORS } from "../../themes";
import { trimToLowercase } from "../../utilities";
import { ParticipantDetailDialog } from "./ParticipantDetailDialog";
import { ParticipantSelectorDialog } from "./ParticipantSelectorDialog";
import { Weather } from "./Weather";

const axios = require('axios').default;

export class AddEditPracticalView extends Component {
    static contextType = AppContext;
    static openWeatherApiKey = 'b948388fab21bf4f38575894aef7a69a'

    constructor(props) {
        super(props);

        this.state = {
            practical: props.practical,
            practicalTypes: null,
            attributes: null,
            participant: null,
            attributesSearch: null,
            showParticipantSelectDialog: false,
            showParticipantDetailDialog: false,
            isWrongDate: false,
            searchParticipant: null,
            employeeLoaded: !props.editMode,
            error: props.error,
            step: 0,
            selectedPositionVacancies: null,
            selectedVacancy: null,
            actionTarget: null,
            actionAttribute: null,
            editAttribute: null,
            hasEdit: false,
            saving: false,
            organization: null,
            showMissingAddressDialog: false,
            loadingWeather: false
        };
        this.existingPractical = new Practical(props.practical);
        this.debounceTimer = null;
        this.openWeatherApiKey = AddEditPracticalView.openWeatherApiKey;
    }

    componentDidMount() {
        const { editMode, isDuplicateMode } = this.props;
        this.loadOrganization();
        this.onLoadPracticalType();
        this.onLoadInstructorInfo();
        this.onLoadAttributes();
        if (editMode || isDuplicateMode) {
            this.onLoadPracticalDetail();
            this.getPracticalType();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { practical, practicalTypes } = this.state;
        const { editMode, isDuplicateMode } = this.props;

        if (prevState.practical !== practical) {
            this.onLoadInstructorInfo();
        }

        if ((editMode || isDuplicateMode) && (prevState.practicalTypes !== practicalTypes || prevState.practical !== practical)) {
            this.getPracticalType();
        }
    }

    getPracticalIdFromPath() {
        const { location } = this.props;
        const path = location.pathname;
        const pathParts = path.split('/');
        const practicalID = pathParts[pathParts.length - 1];

        return practicalID;
    }

    onLoadPracticalDetail() {
        const { practical, isDuplicateMode } = this.props;
        const practicalID = practical && practical.id ? practical.id : this.getPracticalIdFromPath();

        if (!practicalID) return;

        if (practical) {
            this.context.setLoading('practical', true);

            Data.get(this.context, `/Practical/${practicalID}`)
                .then(response => {
                    const practicalDetail = new Practical(response.data.practical);
                    this.existingPractical = new Practical(response.data.practical);
                    if (isDuplicateMode) {
                        practicalDetail.id = null;
                        practicalDetail.status = PRACTICAL_STATUS[0].value;
                        practicalDetail.practicalDate = null;
                        practicalDetail.practicalTime = null;
                        if (practicalDetail && practicalDetail.participants && practicalDetail.participants.length > 0) {
                            practicalDetail.participants.forEach(participant => {
                                participant.status = 'n/a';
                                participant.isAcknowledged = false;
                                participant.acknowledgedDate = null;
                                participant.participantAttributes = participant.participantAttributes.map(competency => {
                                    const competencyDefinition = practicalDetail.attributeDefinitions.find(attr => attr.attributeDefinitionId === competency.attributeDefinitionId);
                                    return {
                                        attendantStatus: practicalDetail.isAssessment ? true : null,
                                        mark: null,
                                        expiryDate: null,
                                        comment: "",
                                        attributeDefinitionId: competency.attributeDefinitionId,
                                        hasExpiryDate: competencyDefinition.hasExpiryDate,
                                        name: competency.name,
                                    };
                                });
                            });
                        }
                    }
                    this.setState({ practical: practicalDetail });
                })
                .catch(e => {
                    this.setState({ error: ErrorModel.parseServerError(e) })
                })
                .finally(() => {
                    this.context.setLoading('practical', false);
                });
        }
    }

    onLoadInstructorInfo() {
        const { user } = this.props;
        const { practical } = this.state;

        if (user && practical) {
            practical.instructor = {
                name: user ? user.fullName : null,
                id: user ? user.employeeId : null,
            }
            this.setState({ practical });
        }
    }

    onLoadPracticalType() {
        this.context.setLoading('loading', true);

        Data.get(this.context, '/Practical/types')
            .then(response => {
                this.setState({ practicalTypes: response.data });
            })
            .catch(e => {
                this.setState({ error: ErrorModel.parseServerError(e) })
            })
            .finally(() => {
                this.context.setLoading('loading', false);
            });
    }

    onLoadAttributes(searchText) {
        this.context.setLoading('attributes', true);

        const pagingOptions = { pageNumber: 1, pageSize: PAGE_SIZE_FOR_ADD_EDIT, searchText: searchText };

        // get setting attributes data then set to attributes state
        SettingsAttributeList
            .getWithPaging(this.context, pagingOptions).then(attributes => {
                this.setState({ attributes, attributesSearch: searchText });
            })
            .catch(error => {})
            .finally(() => this.context.setLoading('attributes', false));
    }

    onEditPractical() {
        const { history, onAddEditSuccess } = this.props;
        const { practical } = this.state;
        const successMessage = 'Practical edited successfully';

        this.context.setLoading('editPractical', true);
        const instructorPracticalData = new Practical(practical);
        this.setState({saving: true})

        axios
            .put(Data.apiBasePath + '/Practical', instructorPracticalData.toApiDto(), {
                withCredentials: true
            })
            .then(() => {
                onAddEditSuccess(successMessage);
                history.push('/practicals');
            })
            .catch(e => {
                this.setState({ error: ErrorModel.parseServerError(e) });
            })
            .finally(() => {
                this.context.setLoading('editPractical', false);
                this.stopBlockingNavAndClose();
                this.setState({saving: false});
            });
    }

    onSavePractical() {
        const { history, onAddEditSuccess, isDuplicateMode, onDuplicateModeSave } = this.props;
        const { practical } = this.state;
        const successMessage = 'Practical created successfully';

        this.context.setLoading('savePractical', true);
        const instructorPracticalData = new Practical(practical);
        this.setState({saving: true})

        axios
            .post(Data.apiBasePath + '/Practical', instructorPracticalData.toApiDto(), {
                withCredentials: true
            })
            .then(() => {
                if (isDuplicateMode) {
                    onDuplicateModeSave();
                }
                onAddEditSuccess(successMessage);
                history.push('/practicals');
            })
            .catch(e => {
                this.setState({ error: ErrorModel.parseServerError(e) });
            })
            .finally(() => {
                this.context.setLoading('savePractical', false);
                this.stopBlockingNavAndClose();
                this.setState({saving: false});
            });
    }

    onSearchAttributes(searchText) {
        clearTimeout(this.debounceTimer);

        this.debounceTimer = setTimeout(() => {
            this.onLoadAttributes(searchText);
        }, TableSearchDebounceTime);
    }

    isValid(practical, step) {
        const { editMode } = this.props;

        if (!practical || !this.isPracticalNameValid(practical) || !this.isPracticalAttributesValid(practical) || !this.isPracticalTypeValid(practical) || !this.isDateValid(practical)) return false;
        // check for if step 2 of create practical or editMode not has participants
        if ((step === 1 || editMode) && !this.isParticipantsValid(practical)) return false;
        return true;
    }

    isPracticalNameValid(practical) {
        return (practical && practical.name && practical.name?.trim()?.length > 0 && practical.name?.length <= 500) === true;
    }

    isPracticalAttributesValid(practical) {
        return (practical && practical?.attributeDefinitions?.length > 0) === true;
    }

    isParticipantsValid(practical) {
        return (practical && practical?.participants?.length > 0) === true;
    }

    isPracticalTypeValid(practical) {
        return (practical && practical.practicalType !== null) === true;
    }

    isLocationValid(practical) {
        return (practical && (practical.location === null || practical?.location?.length <= 200)) === true;
    }

    isWeatherValid(practical) {
        return (practical && (practical.weatherCondition === null || practical?.weatherCondition?.length <= 2000)) === true;
    }

    isDurationValid(practical) {
        const regFormat = /^.?.\..?.?$/;
        const regFormatNum = /^[0-9]*$/;

        if (regFormatNum.test(practical.durationInHours)) {
            if (Number.parseInt(practical.durationInHours) > 23) {
                return false;
            }

            return true;
        }

        if (regFormat.test(practical.durationInHours)) {
            const durationTime = practical.durationInHours.split('.');
            const hour = durationTime[0];
            const minute = durationTime[1];

            if (Number.parseInt(hour) > 23 || Number.parseInt(minute) > 59) {
                return false;
            }

            return true;
        }

        return false;
    }

    isDateValid(practical) {
        const { isWrongDate } = this.state;

        return (practical && practical.practicalDate !== null && !isWrongDate) === true;
    }

    onPracticalNameChange(e) {
        const { practical } = this.state;

        practical.name = e.target.value;
        this.setState({ practical });
    }

    onDescriptionChange(e) {
        const { practical } = this.state;

        practical.description = e.target.value;
        this.setState({ practical });
    }

    onLocationChange(e) {
        const { practical } = this.state;

        practical.location = e.target.value;
        this.setState({ practical });
    }

    onWeatherConditionChange(e) {
        const { practical } = this.state;

        practical.weatherCondition = e.target.value;
        this.setState({ practical });
    }

    onDurationHourChange(e) {
        const { practical } = this.state;
        const regOnlyNumber = /^[0-9.]*$/;

        // allow only number and dot
        if (regOnlyNumber.test(e.target.value) && e.target.value.length < 6) {
            practical.durationInHours = e.target.value;
            this.setState({ practical });
        }
    }

    onAssessmentEnable(e) {
        const { practical } = this.state;

        practical.isAssessment = e.target.checked;
        this.setState({ practical });
    }

    onAttributesChange(e, attributes) {
        const { practical } = this.state;

        practical.attributeDefinitions = attributes;

        // handle add competency if participant already added
        if (practical.participants.length > 0) {
            practical.participants.forEach(participant => {
                participant.participantAttributes = participant.participantAttributes.filter(pa => attributes.find(attr => attr.attributeId === pa.attributeDefinitionId) !== undefined);
                const attributesToAdd = attributes.filter(attr => participant.participantAttributes.find(pa => pa.attributeDefinitionId === attr.attributeId) === undefined);
                attributesToAdd.forEach(attr => {
                    participant.participantAttributes.push(new ParticipantAttribute(attr));
                });
            });
        }

        this.setState({ practical });
    }

    onAttributeBlur() {
        const { attributesSearch } = this.state;
        if (attributesSearch) this.onLoadAttributes();
    }

    onDateChange(date, valid) {
        const { practical } = this.state;

        practical.practicalDate = date;
        this.setState({ practical, isWrongDate: !valid });
    }

    onTimeChange(time) {
        const { practical } = this.state;

        practical.practicalTime = time;
        this.setState({ practical });
    }

    onShowPracticalSelectDialog() {
        this.setState({ showParticipantSelectDialog: true });
    }

    onClosePracticalSelectDialog() {
        this.setState({ showParticipantSelectDialog: false });
    }

    onClosePracticalDetailDialog() {
        this.setState({ showParticipantDetailDialog: false });
    }

    onParticipantAdded() {
        this.setState({ showParticipantSelectDialog: false });
    }

    onCompetencyAdded(participant) {
        const { practical } = this.state;

        const editedParticipantList = practical.participants.map(p => {
            if (p.employeeId === participant.employeeId) {
                return participant;
            }

            return p;
        });

        practical.participants = [...editedParticipantList];

        this.setState({ practical, showParticipantDetailDialog: false });
    }

    onSearchParticipant(e) {
        this.setState({ searchParticipant: e.target.value });
    }

    onCloseActionMenu() {
        this.setState({ actionTarget: null, actionAttribute: null });
    }

    onBack() {
        this.setState({ step: 0 });
    }

    onNavigation(e, callback) {
        const { onNavigation } = this.props;
        return onNavigation && onNavigation(e, callback);
    }

    onCancel(e, source) {
        this.onNavigation(e, this.stopBlockingNavAndClose.bind(this));
    }

    stopBlockingNavAndClose(e) {
        const { onCancel, onBlockNavigation } = this.props;
        if (onBlockNavigation) onBlockNavigation(false, 'Practical');
        if (onCancel) onCancel(e);
    }

    onParticipantSelect(e, participant) {
        this.setState({ participant, showParticipantDetailDialog: true });
    }

    onDeleteParticipantAction(e, participant) {
        const { practical } = this.state;

        const deletedParticipants = practical.participants.filter(p => p.employeeId !== participant.employeeId)

        practical.participants = deletedParticipants;
        this.setState({ practical });
    }

    onSave() {
        const { editMode } = this.props;
        const { step } = this.state;

        if (!editMode) {
            this.setState({ step: 1, error: null });
        }

        if (step === 1 && !editMode) {
            this.onSavePractical();
        }

        if (editMode) {
            this.onEditPractical();
        }


        // if (!editMode && step === 0 && this.isValid(employee, 0)) this.setState({ step: 1, error: null });
        // if (onSave && (editMode || step === 1)) {
        //     if (onBlockNavigation) onBlockNavigation(false, 'Employee');
        //     this.setState({ saving: true });
        //     onSave(employee, () => this.setState({ saving: false }));
        // }
    }

    onEditStateChange(hasEdit) {
        this.setState({ hasEdit });
    }

    onPracticalTypeChange(e) {
        const { practical } = this.state;

        practical.practicalType = e.target.value;

        this.setState({ practical });
    }

    onFormatPracticalType(practicalTypes) {

        if (practicalTypes) {

            const newFormattedTypes = practicalTypes.practicalTypes.reduce((acc, curr) => {
                acc[curr.id] = {
                    id: curr.id,
                    name: curr.name,
                    value: curr.id,
                    label: curr.name,
                }
                return acc;
            }, {});

            // return Object.entries(newFormattedTypes);
            return Object.values(newFormattedTypes);
        }

        return null;
    }

    onTabChange(e, newStep) {
        this.setState({ step: newStep });
    }

    getPracticalType() {
        const { editMode, isDuplicateMode } = this.props;
        const { practicalTypes, practical } = this.state;

        if ((editMode || isDuplicateMode) && practicalTypes) {
            const practicalType = practicalTypes.practicalTypes.find(type => type.id === practical.practicalTypeId);
            practical.practicalType = practicalType?.id;

            this.setState({ practical });
        }
    }

    loadOrganization() {
        this.context.setLoading('org', true);
        const { editMode, isDuplicateMode } = this.props;
        const { practical } = this.state;

        Organization.get(this.context)
            .then(organization => {
                this.setState({ organization });
                if (!editMode && !isDuplicateMode) {
                    practical.location = organization?.address?.street;
                    this.setState({ practical });
                }
            }).catch(e => {
                this.setState({ error: ErrorModel.parseServerError(e) });
            })
            .finally(() => {
                this.context.setLoading('org', false);
            });
    }

    onGetWeather() {
        const { organization, loadingWeather, practical } = this.state;
        if (loadingWeather) return;
        if (!practical.practicalDate) {
            this.setState({ error: 'You should choose a date before retrieving weather data.' });
            return;
        }
        this.setState({ error: null })
        if (isEmpty(organization?.address)) {
            this.setState({ showMissingAddressDialog: true });
        } else {
            this.context.setLoading('weather', true);
            this.setState({ loadingWeather: true })
            const params = {
                lat: organization?.address?.latitude,
                lon: organization?.address?.longitude,
                date: practical?.practicalDate,
                time: practical?.practicalTime
            }
            const weather = new Weather(params);
            weather.getWeather()
                .then(res => {
                    practical.weatherCondition = res
                    this.setState({ practical })
                })
                .catch((e) => {
                    this.setState({ error: e.message })
                })
                .finally(() => {
                    this.context.setLoading('weather', false);
                    this.setState({ loadingWeather: false })
                })
        }
    }

    onCloseMissingAddressDialog() {
        this.setState({ showMissingAddressDialog: false })
    }

    render() {
        const { theme, className, editMode, onLoadAttributes, onAllowNavigation, onBlockNavigation } = this.props;
        const { error, attributes, participant, practicalTypes, practical, showParticipantSelectDialog, showParticipantDetailDialog, step, saving, searchParticipant, editAttribute, hasEdit, showMissingAddressDialog, organization, loadingWeather } = this.state;
        let filterItems = [{ id: 'search', columnField: 'fullName', operatorValue: 'contains', value: searchParticipant }];

        const filterParticipant = practical && practical.participants
            ? practical.participants
                ?.filter(p => String(p?.fullName || '')
                ?.toLowerCase()
                ?.includes(trimToLowercase(searchParticipant || '')))
            : [];
        return (
            <>
                <EditTemplate
                    theme={theme}
                    name='Practicals'
                    onAllowNavigation={onAllowNavigation}
                    onBlockNavigation={onBlockNavigation}
                    onEditStateChange={this.onEditStateChange.bind(this)}
                    detectEdit={!saving && !editAttribute}
                    compare={{
                        existing: this.existingPractical,
                        editing: practical,
                        members: [
                            { name: 'name' },
                            { name: 'description' },
                            {
                                name: 'practicalType',
                                detectEdit: (existing, editing) => existing?.practicalTypeId !== editing?.practicalType,
                            },
                            { name: 'practicalDate' },
                            { name: 'practicalTime' },
                            { name: 'location' },
                            { name: 'weatherCondition' },
                            {
                                name: 'durationInHours',
                                detectEdit: (existing, editing) => String(existing.durationInHours) !== String(editing.durationInHours)
                            },
                            { name: 'isAssessment' },
                            {
                                name: 'attributeDefinitions',
                                detectEdit: (existing, editing) => !isEqual(existing.attributeDefinitions, editing.attributeDefinitions),
                            },
                            {
                                name: 'participants',
                                detectEdit: (existing, editing) => !isEqual(existing.participants, editing.participants)
                            },
                        ],
                    }}>
                    <StyledAddEditEmployeeSetting className={`${className ? className : ''}`}>
                        <PaperOrDialog
                            theme={theme}
                            dialogToBreakpoint='md'
                            disabledToBreakpoint='md'
                            paperPadding={{ xs: '56px 64px' }}
                            title={editMode ? 'Practical details' : 'Create practical'}
                            titleSx={{ fontSize: { xs: '20px', md: '30px' }, fontWeight: 'bold' }}
                            titleSize={1}
                            backButton={editMode}
                            onBack={this.onCancel.bind(this)}>

                            <Stack
                                direction="column"
                                spacing={4}
                            >
                                {error && <Alert
                                    severity='error'
                                    sx={{ width: '100%' }}>{error}</Alert>}

                                {!editMode ? <Stepper activeStep={step}>
                                    <Step
                                        onClick={this.onBack.bind(this)}
                                        sx={{ cursor: step !== 0 ? 'pointer' : 'default' }}>
                                        <StepLabel>Practical information</StepLabel>
                                    </Step>
                                    <Step>
                                        <StepLabel>Participants</StepLabel>
                                    </Step>
                                </Stepper> :
                                    <div>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <Tabs
                                                centered={true}
                                                value={step}
                                                onChange={this.onTabChange.bind(this)}
                                                variant='fullWidth'
                                                sx={{ width: '100%' }}>
                                                <Tab
                                                    label="Information"
                                                    value={0} />
                                                <Tab
                                                    label="Participants"
                                                    value={1} />
                                                {practical.status !== PRACTICAL_STATUS[0].value && <Tab
                                                    label="Change log"
                                                    value={2} />
                                                }
                                            </Tabs>
                                        </Box>
                                    </div>}

                                {step === 0 && <Fade
                                    className='klayo__add-edit-employee-setting-container'
                                    in={true}
                                >
                                    <div>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label='Practical name (required)'
                                                    value={practical ? practical.name : null}
                                                    clearable={false}
                                                    autoComplete={false}
                                                    onChange={this.onPracticalNameChange.bind(this)}
                                                    disabled={saving}
                                                    validationMethod={() => this.isPracticalNameValid(practical)}
                                                    validationText='Please enter a valid practical name(not empty and less than 500 character)'
                                                    // loading={!employeeLoaded}
                                                    sx={{ width: '100%' }} />

                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label='Description'
                                                    value={practical ? practical.description : null}
                                                    multiline={true}
                                                    clearable={false}
                                                    autoComplete={false}
                                                    onChange={this.onDescriptionChange.bind(this)}
                                                    minRows={3}
                                                    disabled={saving}
                                                    sx={{ width: '100%' }} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <MenuSelect
                                                    className='klayo_practical-type-select-menu'
                                                    label='Practical type (required)'
                                                    value={practical ? practical?.practicalType : null}
                                                    firstValueDefault={false}
                                                    noSelectionError={true}
                                                    dense={true}
                                                    disabled={false}
                                                    items={this.onFormatPracticalType(practicalTypes)}
                                                    onChange={this.onPracticalTypeChange.bind(this)}
                                                    sx={{ width: '100%' }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label='Instructor (required)'
                                                    value={practical ? practical.instructor?.name : null}
                                                    clearable={false}
                                                    autoComplete={false}
                                                    disabled={true}
                                                    sx={{ width: '100%' }} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Stack gap='40px' flexDirection={'row'}>
                                                    <DatePicker
                                                        sx={{ width: '180px' }}
                                                        theme={theme}
                                                        label='Date (required)'
                                                        onChange={this.onDateChange.bind(this)}
                                                        value={practical && practical.practicalDate ? new Date(practical.practicalDate) : null}
                                                        allowEmpty={false}
                                                    // disabled={saving}
                                                    // loading={!employeeLoaded}
                                                    />

                                                    <TimePicker
                                                        label='Time'
                                                        theme={theme}
                                                        allowEmpty={true}
                                                        value={practical && practical.practicalTime ? practical.practicalTime : null}
                                                        onChange={this.onTimeChange.bind(this)}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label='Location'
                                                    value={practical ? practical.location : null}
                                                    clearable={false}
                                                    autoComplete={false}
                                                    onChange={this.onLocationChange.bind(this)}
                                                    validationMethod={() => this.isLocationValid(practical)}
                                                    validationText='Please enter a valid location(less than 200 characters)'
                                                    // disabled={saving}
                                                    // loading={!employeeLoaded}
                                                    sx={{ width: '100%' }} />
                                            </Grid>
                                            <Grid item xs={12} sx={{ position: 'relative' }}>
                                                <TextField
                                                    label='Weather conditions'
                                                    helperText='Add date and time and use the Get weather link to automatically populate the weather conditions'
                                                    value={practical ? practical.weatherCondition : null}
                                                    multiline={true}
                                                    clearable={false}
                                                    autoComplete={false}
                                                    onChange={this.onWeatherConditionChange.bind(this)}
                                                    validationMethod={() => this.isWeatherValid(practical)}
                                                    validationText='Please enter a valid weather(less than 2000 characters)'
                                                    minRows={3}
                                                    disabled={saving}
                                                    // loading={!employeeLoaded}
                                                    sx={{ width: '100%' }} />
                                                <Box onClick={this.onGetWeather.bind(this)} className={`klayo_practical-helper-box ${loadingWeather ? 'klayo_practical-helper-box-disabled' : ''}`}>
                                                    <Typography className={`klayo_practical-helper-text ${loadingWeather ? 'klayo_practical-helper-text-disabled' : ''}`}>
                                                        {loadingWeather ? 'Retrieving weather ...' : 'Get weather'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label='Duration in hours'
                                                    value={practical ? practical.durationInHours : null}
                                                    helperText="hh.mm ie 2.15 is 2 hours 15 mins"
                                                    clearable={false}
                                                    autoComplete={false}
                                                    onChange={this.onDurationHourChange.bind(this)}
                                                    validationMethod={() => this.isDurationValid(practical)}
                                                    validationText='Please enter a valid duration(using only numbers with format: hh.mm)'
                                                    disabled={saving}
                                                    sx={{ width: '100%' }} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <PracticalAssessmentPaper>
                                                    <Switch
                                                        label='This practical is a formal assessment'
                                                        checked={practical.isAssessment}
                                                        onChange={this.onAssessmentEnable.bind(this)}
                                                        disabled={practical.participants?.some(p => p.status === COMPLETED)} />
                                                    <TooltipIcon text='Turn this on if this practical is a formal proficiency check or assessment' />
                                                </PracticalAssessmentPaper>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MultiPracticalAttributeSelector
                                                    label='Competencies (required)'
                                                    placeHolder='Search and add competencies'
                                                    items={attributes ? attributes.attributes : null}
                                                    values={practical ? practical.attributeDefinitions : []}
                                                    getItemId={attr => attr.attributeId}
                                                    onChange={this.onAttributesChange.bind(this)}
                                                    onSearch={this.onSearchAttributes.bind(this)}
                                                    onBlur={this.onAttributeBlur.bind(this)}
                                                    disabled={practical && (practical.status === PRACTICAL_STATUS[2].value || (practical.participants?.some(p => p.isAcknowledged)))}
                                                    validationMethod={() => this.isPracticalAttributesValid(practical)}
                                                    validationText='Please enter a valid practical competency' />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Fade>}

                                {step === 1 && <>
                                    <div>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <TextField
                                                value={searchParticipant}
                                                dense={true}
                                                placeholder='Search participant'
                                                disabled={false}
                                                autoComplete={false}
                                                leadingIcon={<SearchIcon />}
                                                onChange={this.onSearchParticipant.bind(this)}
                                                sx={{ minWidth: '300px' }} />

                                            {practical && practical.status !== PRACTICAL_STATUS[2].value && <Button
                                                onClick={this.onShowPracticalSelectDialog.bind(this)}
                                                size='md'
                                                variant='outlined'
                                                startIcon={<AddIcon />}
                                                label='Add participants' />}
                                        </Stack>
                                    </div>
                                    <div>
                                        <ParticipantTable
                                            sortable={true}
                                            rowHasAction={true}
                                            minHeight='300px'
                                            theme={theme}
                                            pagination={false}
                                            showFooterRowCount={false}
                                            filterItems={filterItems}
                                            hideFirstLastBorder={true}
                                            dense={true}
                                            isAssessment={practical.isAssessment}
                                            selectable={true}
                                            onParticipantSelect={this.onParticipantSelect.bind(this)}
                                            onDeleteParticipantAction={this.onDeleteParticipantAction.bind(this)}
                                            paper={false}
                                            // rows={practical && practical.participants ? practical.participants : []} />
                                            rows={filterParticipant} />
                                    </div>
                                </>}

                                {step === 2 && practical.status !== PRACTICAL_STATUS[0].value && <>
                                    <PracticalLogTable
                                        sortable={true}
                                        rowHasAction={true}
                                        minHeight='300px'
                                        theme={theme}
                                        showFooterRowCount={false}
                                        pagination={false}
                                        hideFirstLastBorder={true}
                                        dense={true}
                                        selectable={true}
                                        paper={false}
                                        rows={practical && practical.changelogs ? practical.changelogs : []} />
                                </>}

                                <StyleEmployeeSettingBottomBtn
                                    className='klayo__add-edit-employee-bottom-btn-container'
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                    editMode={editMode ? true : false}
                                >
                                    <Button
                                        onClick={this.onCancel.bind(this)}
                                        disabled={saving}
                                        label={Practical.isCompleted(practical) ? 'Close' : 'Cancel'} />
                                    <Stack
                                        direction="row"
                                        spacing={2}>
                                        {step > 0 && !editMode && <Button
                                            variant='outlined'
                                            onClick={this.onBack.bind(this)}
                                            startIcon={<LeftIcon />}
                                            label='Back' />}
                                        {practical?.status !== PRACTICAL_STATUS[2].value && (
                                            <Button
                                                variant='filled'
                                                // disabled={!this.isValid(employee, step) || !hasEdit || saving}
                                                // disabled={false}
                                                blurDetect={step}
                                                disabled={!this.isValid(practical, step) || (editMode && !hasEdit) || saving}
                                                onClick={this.onSave.bind(this)}
                                                endIcon={!editMode && step === 0 ? <RightIcon /> : null}
                                                label={editMode ? 'Save' : step === 0 ? 'Continue' : 'Create'} />
                                        )}
                                    </Stack>
                                </StyleEmployeeSettingBottomBtn>
                            </Stack>
                        </PaperOrDialog>
                    </StyledAddEditEmployeeSetting>

                    {showParticipantSelectDialog &&
                        <ParticipantSelectorDialog
                            theme={theme}
                            attributes={attributes}
                            practical={practical}
                            allowChildren={false}
                            onLoadAttributes={onLoadAttributes}
                            onShowNewAttributeDialog={false}
                            onClose={this.onClosePracticalSelectDialog.bind(this)}
                            onSave={this.onParticipantAdded.bind(this)}
                        />}
                    {showParticipantDetailDialog &&
                        <ParticipantDetailDialog
                            theme={theme}
                            participant={participant}
                            practical={practical}
                            allowChildren={false}
                            onLoadAttributes={onLoadAttributes}
                            onShowNewAttributeDialog={false}
                            onClose={this.onClosePracticalDetailDialog.bind(this)}
                            onSave={this.onCompetencyAdded.bind(this)}
                        />}
                </EditTemplate>
                {showMissingAddressDialog ? <Dialog
                    open={true}
                    theme={theme}
                    title={'Company address missing'}
                    onClose={this.onCloseMissingAddressDialog.bind(this)}
                    maxWidth='sm'
                    actions={[{
                        label: 'Close',
                        onClick: this.onCloseMissingAddressDialog.bind(this)
                    }]}>
                    <Stack direction="column"
                        spacing={4}
                    >
                        <div>{`Company address hasn't been added yet. Go to settings > Company to add it or request your system admin to add it.`}</div>
                    </Stack>
                </Dialog>
                    : null}
            </>
        )
    }
}

const StyledAddEditEmployeeSetting = styled.div`
    &.klayo__add-edit-employee-setting {
        .klayo__add-edit-employee-setting-container {
            margin-top: 40px;

            h2 {
                margin-bottom: 40px;
            }

            .klayo__add-edit-employee-personal-information {
                .MuiGrid-item {
                    padding-top: 44px;
                }
            }

            .klayo__add-edit-employee-divider {
                margin-top: 40px;
            }

            .klayo__add-edit-employee-information {
                margin-top: 40px;
                
                .MuiGrid-item {
                    padding-top: 44px;
                }
            }
        }

        .klayo__add-edit-employee-bottom-btn-container {
            margin-top: 40px;
        }
    }
`

const StyleEmployeeSettingBottomBtn = styled(Stack)`
    &.klayo__add-edit-employee-bottom-btn-container {
        justify-content: ${props => (props.editMode ? 'start' : 'space-between')};
    }
`;

const PracticalAssessmentPaper = styled(Stack)`
    padding: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid ${KLAYO_COLORS.GreyGR200};
    border-radius: 21px;

    .klayo-iconbutton.klayo-button-lg {
        padding: 0px;
    }
`;