import { Component } from "react";
import { TextField } from "../../components/TextField";
import styled from "@emotion/styled";

export class MultiStudentIdTextField extends Component {

  render() {
	const { employee, organization, integration, saving, employeeLoaded, onStudentIdChange, isStudentIdValid } = this.props;

	return (
		<MultiStudentIdTextFieldStyled className="klayo_multi-student-id-text-field">
			{integration && integration.integrations && integration.integrations.length > 0 && integration.integrations.map((item) => {
				const labelAbbreviation = item.abbreviation ? item.abbreviation : item.name;
				const label = organization.hasMultipleIntegrations ? `Student ${labelAbbreviation}` : `Student ID`;
				let name = '';

				// fill data in text field
				if (employee) {
					if (employee.integrationUsers && employee.integrationUsers.length > 0) {
						const filledIntegration = employee?.integrationUsers.find((integrationUser) => integrationUser.organizationIntegrationId === item.id);

						name = filledIntegration ? filledIntegration.username : '';
					}
				}

				return (
					<TextField
						label={label}
						clearable={false}
						onChange={(e) => onStudentIdChange(e, item.id)}
						value={name}
						disabled={saving}
						validationMethod={(e, value) => isStudentIdValid(employee)}
						validationText='Turn off to specify different student IDs for each provider, turn on to use the same ID for all.'
						loading={!employeeLoaded}
						sx={{ width: '100%' }} 
					/>
				)
			})}	
		</MultiStudentIdTextFieldStyled>
	)
  }
}

const MultiStudentIdTextFieldStyled = styled.div`
	&.klayo_multi-student-id-text-field {
		display: flex;
		flex-direction: column;
		gap: 44px;

		.klayo-textfield .MuiInputLabel-root {
			width: 93%;
		}
	}
`