export class SettingsCourse {
    constructor(data) {

        if (data) {
            this.id = data.id;
            this.rowId = data.id;
            this.courseId = data.courseId;
            this.name = data.courseName;
            this.description = data.courseDescription;
            this.recurrence = data.recurrence;
            this.numberOfAssociatedAttributes= data.numberOfAssociatedAttributes;
			this.associatedAttribute = data.associatedAttributeDefinitions;
            this.organizationIntegrationName = data.organizationIntegrationName;
            this.organizationIntegrationAbbreviation = data.organizationIntegrationAbbreviation;
        }
    }
}