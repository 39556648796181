import { AppContext } from '../../common/AppContext';
import { AbstractFilterSelect } from './AbstractFilterSelect';
import { EmployeeSelectorList } from '../../data/filters/EmployeeSelectorList';
import { ManagerSelectorList } from '../../data/filters/ManagerSelectorList';
import { Utils } from '../../common/Utils';

export class EmployeeSelector extends AbstractFilterSelect {

    static contextType = AppContext;

    static defaultProps = {
        label: 'People',
        managersOnly: false,
        listType: 'Employees',
        ...AbstractFilterSelect.defaultProps
    }

    constructor(props) {
        super(props);

        this.dataList = props.dataList ?? (props.managersOnly ? ManagerSelectorList : EmployeeSelectorList);
    }

    compareItems(a, b) {
        return a.employeeId === b.employeeId;
    }

    getItemLabel(item) {
        const { hasNoneItem, noneItemLabel, hasAllItem, allItemLabel, hasNewItem, newItemLabel} = this.props;

        if (hasNoneItem && item.isNoneItem === true) return noneItemLabel;
        else if (hasAllItem && item.isAllItem === true) return allItemLabel;
        else if (hasNewItem && item.isNewItem === true) return newItemLabel;
        return item.fullName;
    }

    getItemSecondaryAction(item) {
        const { theme, getItemSecondaryAction } = this.props;
        return getItemSecondaryAction ? getItemSecondaryAction(item) : theme && theme.isBreakpointDown('sm') ? null : Utils.truncate(item.jobTitle, theme && theme.isBreakpointUp('md') ? 30 : 20);
    }

    getList(dataList) {
        return dataList.employees;
    }

    findValueItem(items, value) {
        return items ? items.find(c => c.fullName === value) : items.find(c => c.fullName && c.fullName.includes(value));
    }
}