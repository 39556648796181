import { AppBar, Toolbar, Dialog, IconButton, DialogContent, Typography } from '@mui/material';
import ReactDom, { React, Component } from 'react';
import { ReactComponent as CloseIcon } from '../../resources/images/icons-close.svg';


export class DevComponentsDialog extends Component {

    render() {


        const { onClose } = this.props;

        return (
            <Dialog
                className='klayo-devcomponents'
                open={true}
                onClose={onClose}
                fullScreen
                maxWidth='xl'>
                <AppBar className='klayo-devcomponents_appbar' sx={{ position: 'relative' }} elevation={0} color='secondary'>
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Klayo Components
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={onClose}
                            aria-label="close"
                        ><CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <DialogContent>
                </DialogContent>
            </Dialog>
        );
    }
}