import { Data } from "../Data";
import { User } from "./User";

export class SwitchUserList extends Data {

    static instance = null;

    constructor(options) {
        super({
            api: '/User/EmployeeIds', callback: data => {
                options.resolve({ data: this, cached: false });
            }, ...options
        });
    }
    
    onDataReceived(data) {
        this.users = [];

        Object.entries(data).forEach(([key, d]) => {
            this.users.push(new User({ data: d }));
        });
    }

    static get(context) {
        return new Promise((resolve, reject) => {
            if (SwitchUserList.instance) resolve({ data: SwitchUserList.instance, cached: true });
            else SwitchUserList.instance = new SwitchUserList({ resolve, reject, context });
        });
    }

    findMatch(attr) {
        return this.list.find(a => a.name === attr);
    }
}