import { endOfToday, isToday, isYesterday, startOfToday } from "date-fns";
import { get } from "lodash";


export class Weather {
    static openWeatherApiKey = process.env.REACT_APP_OPEN_WEATHER_API_KEY;
    static currentWeatherApi = `https://api.openweathermap.org/data/3.0/onecall?lat={lat}&lon={lon}&exclude=minutely,hourly&appid=${this.openWeatherApiKey}&units=metric`
    static timestampApi = `https://api.openweathermap.org/data/3.0/onecall/timemachine?lat={lat}&lon={lon}&dt={timestamp}&appid=${this.openWeatherApiKey}&units=metric`
    static dailyAggregationApi = `https://api.openweathermap.org/data/3.0/onecall/day_summary?lat={lat}&lon={lon}&date={date}&appid=${this.openWeatherApiKey}&units=metric`
    static currentFieldList = [
        { label: 'Temperature', unit: '°C', path: 'temp' },
        { label: 'Feels like', unit: '°C', path: 'feels_like' },
        { label: 'Pressure', unit: 'hPa', path: 'pressure' },
        { label: 'Humidity', unit: '%', path: 'humidity' },
        { label: 'Dew point', unit: '°C', path: 'dew_point' },
        { label: 'UVI', unit: 'W/m2', path: 'uvi' },
        { label: 'Wind speed', unit: 'm/s', path: 'wind_speed' },
        { label: 'Cloud', unit: '%', path: 'clouds' },
        { label: 'Visibility', unit: 'm', path: 'visibility' },
        { isDesc: true, path: 'weather', innerPath: 'description' },
    ]
    static timeStampFieldList = [
        { label: 'Temperature', unit: '°C', path: 'temp' },
        { label: 'Feels like', unit: '°C', path: 'feels_like' },
        { label: 'Humidity', unit: '%', path: 'humidity' },
        { label: 'Wind speed', unit: 'm/s', path: 'wind_speed' },
        { label: 'Pressure', unit: 'hPa', path: 'pressure' },
        { label: 'Visibility', unit: 'm', path: 'visibility' },
    ];
    static aggregationFieldList = [
        { label: 'Cloud cover', unit: '%', path: 'cloud_cover.afternoon' },
        { label: 'Humidity', unit: '%', path: 'humidity.afternoon' },
        { label: 'Precipitation', unit: 'mm', path: 'precipitation.total' },
        { label: 'Wind speed', unit: 'm/s', path: 'wind.max.speed' },
        { label: 'Pressure', unit: 'hPa', path: 'pressure.afternoon' },
        { label: 'Max temperature', unit: '°C', path: 'temperature.max' },
        { label: 'Min temperature', unit: '°C', path: 'temperature.min' },
    ]
    constructor(data) {
        this.lat = data.lat;
        this.lon = data.lon;
        this.date = data.date;
        this.time = data.time;
        const resultYMD = this.getYearMonthDay(data);
        this.year = resultYMD?.year;
        this.month = resultYMD?.month; // month index 0 - 11
        this.day = resultYMD?.day;
        const resultHM = this.getHourMinuteHH(data);
        this.hour = resultHM?.hour;
        this.minute = resultHM?.minute;
        this.today = startOfToday().valueOf();
        this.next4days = startOfToday().valueOf() + 4 * 24 * 60 * 60 * 1000;
    }
    /* data converting methods */
    convertToString(data, fieldList) {
        const properties = [];
        fieldList.forEach(field => {
            const getField = get(data, field.path, '')
            if (getField && !field?.isDesc) {
                properties.push(`${field.label}: ${getField}${field.unit}`)
            }
            if (getField?.length && field?.isDesc) {
                const array = getField.map((item) => item?.description)
                properties.push(array.join(', '))
            }

        })
        return properties.join(', ')
    }
    translateDataTimeStamp(data) {
        if (!data.data?.length) return ''
        const target = data.data[0];
        return this.convertToString(target, Weather.timeStampFieldList);
    }
    translateDataAggregation(data) {
        return this.convertToString(data, Weather.aggregationFieldList);
    }
    translateDataCurrent(data) {
        const target = data.current;
        return this.convertToString(target, Weather.currentFieldList);
    }
    /* time preprocessing methods */
    getYearMonthDay(data) {
        if (!data.date) return null;
        const dateTemp = new Date(data.date)
        const year = dateTemp.getFullYear();
        const month = dateTemp.getMonth();
        const day = dateTemp.getDate();
        return { year, month, day }
    }
    getHourMinuteHH(data) {
        if (!data.time) return null;
        const splits = String(this.time).trim().split(' ');
        const suffix = splits[1];
        const timeSegments = splits[0].split(':');
        let hour = Number(timeSegments[0] === '12' ? 0 : timeSegments[0]);
        hour += suffix === 'PM' ? 12 : 0;
        const minute = Number(timeSegments[1]);
        return { hour, minute }
    }
    /* API endpoint constructing methods */
    constructTimeStampApi() {
        const { year, month, day, hour, minute } = this;
        const timestamp = Math.floor(new Date(year, month, day, hour || 0, minute || 0, 0).valueOf() / 1000)
        return Weather.timestampApi
            .replace('{lat}', this.lat)
            .replace('{lon}', this.lon)
            .replace('{timestamp}', timestamp)
    }
    constructAggregationApi() {
        const { year, month, day } = this;
        const date = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`
        return Weather.dailyAggregationApi
            .replace('{lat}', this.lat)
            .replace('{lon}', this.lon)
            .replace('{date}', date)
    }
    constructCurrentApi() {
        return Weather.currentWeatherApi
            .replace('{lat}', this.lat)
            .replace('{lon}', this.lon)
    }
    /* condition checking methods */
    isToday() {
        const { day, month, year } = this;
        return isToday(new Date(year, month, day))
    }
    isPastDate() {
        const { day, month, year, hour, minute, today } = this;
        const timestamp = new Date(year, month, day, hour || 0, minute || 0, 0).valueOf();
        return timestamp < today;
    }
    isWithin4Days() {
        const { day, month, year, hour, minute, today, next4days } = this;
        const tomorrow = endOfToday().valueOf() + 1;
        const calcDate = new Date(year, month, day, hour || 0, minute || 0, 0);
        return calcDate >= tomorrow && calcDate <= next4days;
    }
    isOutside4Days() {
        const { day, month, year, hour, minute, today, next4days } = this;
        const calcDate = new Date(year, month, day, hour || 0, minute || 0, 0);
        return calcDate > next4days;
    }
    isYesterday() {
        const { day, month, year } = this;
        return isYesterday(new Date(year, month, day))
    }
    /* getWeather - main controller */
    getWeather() {
        return new Promise((resolve, reject) => {
            let apiEndpoint = this.time ? this.constructTimeStampApi() : this.constructAggregationApi();
            let dataProcessCb = this.time ? this.translateDataTimeStamp.bind(this) : this.translateDataAggregation.bind(this);
            if (this.isToday() && !this.time) {
                apiEndpoint = this.constructCurrentApi();
                dataProcessCb = this.translateDataCurrent.bind(this)
            }
            if (this.isWithin4Days() || this.isYesterday() || (this.isToday() && this.time)) {
                apiEndpoint = this.constructTimeStampApi();
                dataProcessCb = this.translateDataTimeStamp.bind(this)
            }
            if (this.isOutside4Days()) {
                apiEndpoint = this.constructAggregationApi();
                dataProcessCb = this.translateDataAggregation.bind(this);
            }
            fetch(apiEndpoint)
                .then(res => res.json())
                .then(res => {
                    const string = dataProcessCb(res)
                    if (res.code) {
                        return reject(res);
                    }
                    resolve(string);
                })
                .catch((error) => {
                    reject(error);
                })
        })
    }
}