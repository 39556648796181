import { Data } from "../Data";
import { BillingPlan } from "./BillingPlan";

export class BillingPlans extends Data {

    static instance = null;

    constructor(options) {
        super({
            api: '/Organization/BillingPlans', callback: data => {
                options.resolve(this, false);
            }, ...options
        });

        this.groups = [];
    }

    onDataReceived(data) {
        data.billingPlans.forEach(p => {
            const plan = new BillingPlan(p);
            this.groups.push(plan);
        });

        this.enterprisePlan = this.groups.find(p => p.name === 'Enterprise');
        this.enterprisePlan.isCustom = true;
    }

    static get(context, updateCache) {
        return new Promise((resolve, reject) => {
            if (BillingPlans.instance && updateCache !== true) resolve(BillingPlans.instance, true);
            else BillingPlans.instance = new BillingPlans({ resolve, reject, context });
        });
    }
}