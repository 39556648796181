import { AbstractAttributeList } from "./AbstractAttributeList";
import AttributeSummary from "./AttributeSummary"

export class AbstractTeamAttributeList extends AbstractAttributeList {

    constructor(options) {
        super(options);

        this.classificationsSummary = {};
        this.categorySummary = {};

        options.data.forEach((attr, index) => {
            const summary = new AttributeSummary(index, attr);
            this.attributes.push(summary);
            this.classificationsSummary[attr.attributeName] = { id: index, ...summary };

            //const catName = attr.category && attr.category !== '' ? attr.category : 'Other';
            //const category = this.categorySummary[catName];
            //if (category) category.employees++;
            //else this.categorySummary[catName] = { category: catName, employees: 1 };
        });
    }

    initAttributesLists() {
        super.initAttributesLists();

        this.attributes.forEach(a => {
            if (a.categories) a.categories.forEach(c => {
                const category = this.categorySummary[c];
                if (category) category.employees++;
                else this.categorySummary[c] = { category: c, employees: 1 };
            });
        });
    }
}