import { Data } from "../Data";
import { User } from "../users/User";

export class SettingsManagerList extends Data {

    static instance = null;

    constructor(options) {
        super({
            api: '/Employee/SearchManager?searchText=', callback: data => {
                options.resolve(this, false);
            }, ...options
        });
    }
    
    onDataReceived(data) {
        this.employees = [];
        data.employees.forEach((e, index) => {
            const manager = new User({ data: { id: index, ...e } });
            manager.jobId = e.jobId;
            manager.fullName = e.employeeFullName;
            if(manager.fullName === null) manager.fullName = 'Vacancy';
            manager.jobTitle = e.jobDefinitionName;
            manager.reportsToEmployeeFullname = e.reportsToEmployeeFullname;
            manager.directReports = e.directReports;
            this.employees.push(manager);
        });
    }

    static get(context, updateCache) {
        return new Promise((resolve, reject) => {
            if (SettingsManagerList.instance && updateCache !== true) resolve(SettingsManagerList.instance, true);
            else SettingsManagerList.instance = new SettingsManagerList({ resolve, reject, context });
        });
    }
}