import { Table } from "./Table";
import { ReactComponent as MoreVertIcon } from '../../resources/images/icons-more-vertical.svg';
import { DataGridPro, GridActionsCellItem } from '@mui/x-data-grid-pro';
import { DateLabel } from 'klayowebshared';

export class DocumentTable extends Table {

    static columns = {
        name: {
            field: 'name',
            label: 'Name',
        },
        actions: {
            field: 'actions',
            label: 'Actions',
        },
    };

    static defaultProps = {
        ...Table.defaultProps,
        columns: [
            { type: DocumentTable.columns.name },
            { type: DocumentTable.columns.actions }
        ],
        noRowsMessage: null,
        rowHasAction: null
    }

    constructor(props) {
        super(props);
    }

    getRowId(e) {
        return this.tableId + '_' + e.documentId;
    }

    initColumns() {
        const { columns, sortable, onDocumentAction } = this.props;

        this.columns = [];

        columns.forEach(c => {
            if (this.hasColumn(c)) {

                if (c.type === DocumentTable.columns.name) this.columns.push({
                    field: 'name',
                    sortable: sortable,
                    headerName: c.label,
                    flex: true,
                    filterable: false,
                    disableReorder: true,
                    valueGetter: params => params.row.documentName,
                    renderCell: params => <div><DateLabel date={params.row.createDate} /><div>{params.row.documentName}</div></div>
                });
            }

            if (c.type === DocumentTable.columns.actions) this.columns.push({
                field: 'actions',
                type: 'actions',
                filterable: false,
                disableReorder: true,
                align: 'right',
                getActions: params => [
                    <GridActionsCellItem
                        icon={<MoreVertIcon />}
                        label="Actions"
                        onClick={e => onDocumentAction(e, params.row)}
                    />
                ]
            });
        });
    }
};