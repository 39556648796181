import { Grid, AlertTitle, LinearProgress } from '@mui/material';
import { Component, React } from 'react';
import { AttributeDefinitionList } from '../../data/attributes/AttributeDefinitionList';
import { MultiFileUpload } from '../../components/MultiFileUpload';
import { Alert } from '../../components/Alert';
import { AppContext } from '../../common/AppContext';
import { AttributeSelector } from '../../components/selectors/AttributeSelector';
import { ProficiencySelector } from '../../components/selectors/ProficiencySelector';
import { RequestActivityFeed } from '../../components/RequestActivityFeed';
import { Utils } from '../../common/Utils';
import { Data } from '../../data/Data';
import { ErrorModel } from '../../common/models/ErrorModel';
import { ConfirmationDialog } from 'klayowebshared';
import { TextField } from '../../components/TextField';
import { AttributeTable } from '../../components/table/AttributeTable';
import { DatePicker } from '../../components/DatePicker';
import { AbstractAttribute } from '../../data/attributes/AbstractAttribute';
import { Snackbar } from '../../components/Snackbar';
import { Dialog } from '../../components/dialogs/Dialog';
import { DragDropFileUpload } from '../../components/DragDropFileUpload';
import { TrainingCourseList } from '../../components/TrainingCourseList';
import styled from '@emotion/styled';
import { KLAYO_COLORS } from '../../themes';
import { unFocusTableRowOnClose } from '../../utilities';
import { isEqual } from 'lodash';

const axios = require('axios').default;

export class AddEditAttributeDialog extends Component {

    static contextType = AppContext;

    static allowedMimeTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    static maxFileSize = 10240000;
    static errorMimeType = 'Please upload a valid evidence file (doc, docx, pdf, png, or jpeg).';
    static errorFileSize = 'Please upload a valid evidence file (10Mb or less)';

    static defaultProps = {
        newFileUploadComponent: true,
        readOnly: false
    }

    static mode = {
        add: {
            title: 'Add new competency request',
            hash: 'add'
        },
        proficiencyChange: {
            title: 'Request changes',
            hash: 'edit'
        },
        feedback: {
            title: 'Change competency request',
            hash: 'edit'
        },
        childDetails: {
            title: 'Competency details',
            hash: 'childDetails'
        },
        details: {
            title: 'Competency details',
            hash: 'details'
        }
    };

    constructor(props) {
        super(props);

        let attribute = this.props.attribute;

        if (props.attributeId) {
            attribute = props.profile.employeeAttributes?.getAttributeByEmployeeAttributeId(props.attributeId);
        }

        this.state = {
            attribute: attribute,
            mode: props.mode,
            definitions: null,
            submitAttempts: 0,
            expiry: AddEditAttributeDialog.getAttributeExpiry(attribute, props.mode),
            expiryIsPast: false,
            proficiency: AddEditAttributeDialog.getAttributeProficiency(attribute, props.mode),
            comments: AddEditAttributeDialog.getAttributeComments(attribute, props.mode),
            files: AddEditAttributeDialog.getAttributeFiles(attribute, props.mode),
            submitting: false,
            showConfirmClose: false,
            error: null,
            allowAttributeChange: attribute === null,
            alreadyHasAttribute: false,
            editChildAttribute: null,
            downloadingDoc: null
        };

        this.childAttributesDetails = [];
    }

    static getAttributeProficiency(attr, mode) {
        if (!attr) return 0;
        if (isEqual(mode, AddEditAttributeDialog.mode.feedback) || isEqual(mode, AddEditAttributeDialog.mode.details) && attr.requestedProficiency) return attr.requestedProficiency;
        else if (isEqual(mode, AddEditAttributeDialog.mode.childDetails)) return attr.editProficiency || null;
        return attr.currentProficiency || null;
    }

    static getAttributeExpiry(attr, mode) {
        if (!attr) return null;
        if (isEqual(mode, AddEditAttributeDialog.mode.feedback) || isEqual(mode, AddEditAttributeDialog.mode.details) && attr.requestedExpiryDate) return attr.requestedExpiryDate;
        else if (isEqual(mode, AddEditAttributeDialog.mode.childDetails)) return attr.editExpiry || attr.expiryDate || null;
        return attr.currentExpiryDate || 0;
    }

    static getAttributeComments(attr, mode) {
        if (!attr) return '';
        if (isEqual(mode, AddEditAttributeDialog.mode.childDetails)) return attr.editComments || '';
        return '';
    }

    static getAttributeFiles(attr, mode) {
        if (!attr) return null;
        if (isEqual(mode, AddEditAttributeDialog.mode.childDetails)) return attr.editFiles || null;
        return null;
    }

    componentDidMount() {
        const { profile } = this.props;
        const { attribute } = this.state;

        this.context.setLoading('definitions', true);

        AttributeDefinitionList.get(this.context).then(list => {
            this.setState({ definitions: list });
        })
            .catch(error => {
                //Todo: handle error
                //alert('error 453435: ' + error);
            })
            .finally(() => this.context.setLoading('definitions', false));

        if (attribute && (!this.isModeEqual(AddEditAttributeDialog.mode.add)) && !this.isModeEqual(AddEditAttributeDialog.mode.childDetails)) {

            /*const existingAttribute = profile.employeeAttributes.getAttribute(attribute.attributeDefinitionId);
            if (existingAttribute) {
                attribute.expiryDate = existingAttribute.expiryDate;
                this.state.expiry = attribute.expiryDate;
            }*/

            this.loadPreviousActivity(attribute);
        }
    }

    componentDidUpdate(prevProps) {
        const { attribute } = this.state;
        if (this.props.attribute !== prevProps.attribute) this.setState({ attribute: this.props.attribute });
    }

    loadPreviousActivity(attribute) {
        this.setState({ submitting: true });
        attribute.loadRequestActivity(this.context)
            .then(activity => {
                this.setState({ submitting: false, attribute: attribute });
            })
            .catch(e => {
                alert(e.message);
            })
            .finally(() => this.setState({ submitting: false }));
    }

    checkValid() {
        const { organization } = this.props;
        const { mode, alreadyHasAttribute, attribute, proficiency } = this.state;
        const dupAttr = this.isModeEqual(AddEditAttributeDialog.mode.add) && alreadyHasAttribute;
        if (dupAttr) return false;

        if (attribute == null) return false;

        const hasProficiency = organization && organization.showProficiency && attribute && attribute.hasProficiency;
        if (hasProficiency && !this.checkValidProficiency()) return false;

        const hasExpiryDate = attribute && attribute.hasExpiryDate && !attribute.isParent;
        if (hasExpiryDate && !this.checkValidExpiry()) return false;

        const isParent = attribute && attribute.isParent;
        if (isParent && !this.checkValidChildren()) return false;

        return true;
    }

    checkValidExpiry() {
        const { expiry } = this.state;
        const { attribute } = this.state;

        if ((attribute && !attribute.hasExpiryDate)) return true;
        return expiry !== null;// && expiry > new Date();
    }

    checkValidChildren() {
        const { profile } = this.props;
        const { attribute } = this.state;

        if ((!attribute || !attribute.isParent)) return true;
        let valid = true;
        attribute.childAttributes.filter(child => !profile.employeeAttributes.hasDefinition(child.attributeDefinitionId)).forEach((child, index) => {
            const childDetails = this.childAttributesDetails[child.attributeDefinitionId];
            if (child.hasExpiryDate && (!childDetails || !childDetails.expiry)) valid = false;
            if (child.hasProficiency && (!childDetails || !childDetails.proficiency)) valid = false;

        });
        return valid;
    }

    checkValidProficiency() {
        const { attribute, proficiency } = this.state;
        return attribute !== null && proficiency && !isNaN(proficiency) && proficiency !== 0;
    }

    onClose() {
        const { mode } = this.state;

        unFocusTableRowOnClose();

        const checkChanges = !this.isModeEqual(AddEditAttributeDialog.mode.childDetails) && !this.isModeEqual(AddEditAttributeDialog.mode.details);
        if (this.state.submitting) return;

        if (checkChanges && this.hasChanges()) this.setState({ showConfirmClose: true });
        else this.props.onClose(false);
    }

    onCancelClose() {
        this.setState({ showConfirmClose: false });
    }

    onAcceptClose() {
        this.setState({ showConfirmClose: false });
        this.props.onClose(false);
    }

    onRequestChanges() {
        this.setState({ mode: AddEditAttributeDialog.mode.proficiencyChange });
    }

    onSubmit(event) {
        this.setState({ submitAttempts: this.state.submitAttempts + 1 }, state => {
            if (this.checkValid()) this.submit();
        });
    }

    hasChanges() {
        const { organization } = this.props;
        const { mode, proficiency, attribute, comments, files, expiry } = this.state;
        const commentsChange = mode == AddEditAttributeDialog.mode.add || comments.length > 0;

        const hasExpiry = attribute && attribute.hasExpiryDate;
        const expiryChange = hasExpiry && expiry !== attribute.expiry;

        const hasProficiency = organization && organization.showProficiency && attribute && attribute.hasProficiency;
        const profChange = hasProficiency && proficiency !== attribute.currentProficiency;

        return profChange || commentsChange || expiryChange || files;
    }

    appendFormData(formData, index, attribute, proficiency, comments, expiry, files) {
        formData.append('Attributes[' + index + '][AttributeDefinitionId]', attribute.attributeDefinitionId);
        if (proficiency) formData.append('Attributes[' + index + '][ProficiencyValue]', Number.isInteger(proficiency) ? proficiency : 0);
        if (comments) formData.append('Attributes[' + index + '][CommentText]', comments);
        if (expiry) formData.append('Attributes[' + index + '][ExpiryDate]', Utils.toApiDate(expiry));
        if (files) Object.entries(files).map((f, i) => {
            if (f[1] !== null) formData.append('Attributes[' + index + '][FormFiles]', f[1]);
        });
    }

    submit() {
        const { onClose, onSaveChildAttribute, profile } = this.props;
        const { attribute, files, comments, proficiency, expiry } = this.state;

        this.setState({ submitting: true });

        const mode = this.state.mode || AddEditAttributeDialog.mode.add;

        //Adding

        const formData = new FormData();
        if (attribute.isParent) {
            formData.append('Attributes[0][AttributeDefinitionId]', attribute.attributeDefinitionId);
            attribute.childAttributes.filter(child => !profile.employeeAttributes.hasDefinition(child.attributeDefinitionId)).forEach((child, index) => {
                const childDetails = this.childAttributesDetails[child.attributeDefinitionId];
                if (childDetails) this.appendFormData(formData, index + 1, child, childDetails.proficiency, childDetails.comments, childDetails.expiry, childDetails.files);
                else this.appendFormData(formData, index + 1, child);
            });
        }
        else this.appendFormData(formData, 0, attribute, proficiency, comments, expiry, files);

        if (this.isModeEqual(AddEditAttributeDialog.mode.add)) {

            const formData = new FormData();
            if (attribute.isParent) {
                formData.append('Attributes[0][AttributeDefinitionId]', attribute.attributeDefinitionId);
                attribute.childAttributes.filter(child => !profile.employeeAttributes.hasDefinition(child.attributeDefinitionId)).forEach((child, index) => {
                    const childDetails = this.childAttributesDetails[child.attributeDefinitionId];
                    if (childDetails) this.appendFormData(formData, index + 1, child, childDetails.proficiency, childDetails.comments, childDetails.expiry, childDetails.files);
                    else this.appendFormData(formData, index + 1, child);
                });
            }
            else this.appendFormData(formData, 0, attribute, proficiency, comments, expiry, files);


            axios.post(Data.apiBasePath + '/EmployeeAttribute', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                withCredentials: true
            })
                .then(response => {

                    const newAttrb = { ...attribute, employeeAttributeId: response.data.employeeAttributeId };
                    this.setState({ submitting: false });
                    onClose(true);
                })
                .catch(e => {
                    this.setState({ error: ErrorModel.parseServerError(e) });
                })
                .finally(() => this.setState({ submitting: false }));
        }
        else if (this.isModeEqual(AddEditAttributeDialog.mode.proficiencyChange)) {
            const formData = new FormData();
            formData.append('EmployeeAttributeId', attribute.employeeAttributeId);
            if (proficiency) formData.append('ProficiencyValue', Number.isInteger(proficiency) ? proficiency : 0);
            if (comments) formData.append('CommentText', comments);
            if (expiry) formData.append('ExpiryDate', Utils.toApiDate(expiry));
            if (files) Object.entries(files).map((f, i) => {
                if (f[1] !== null) formData.append('FormFiles', f[1]);
            });

            axios.put(Data.apiBasePath + '/EmployeeAttribute', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                withCredentials: true
            })
                .then(response => {
                    this.setState({ submitting: false });
                    onClose(true);
                })
                .catch(e => {
                    this.setState({ error: ErrorModel.parseServerError(e) });
                })
                .finally(() => this.setState({ submitting: false }));
        }
        else if (this.isModeEqual(AddEditAttributeDialog.mode.feedback)) {
            const formData = new FormData();
            formData.append('EmployeeAttributeValidationRequestId', attribute.validationRequestId);
            if (proficiency) formData.append('ProficiencyValue', Number.isInteger(proficiency) ? proficiency : 0);
            if (comments) formData.append('CommentText', comments);
            if (expiry) formData.append('ExpiryDate', Utils.toApiDate(expiry));
            if (files) Object.entries(files).map((f, i) => {
                if (f[1] !== null) formData.append('FormFiles', f[1]);
            });

            axios.post(Data.apiBasePath + '/EmployeeValidationRequest/RespondChange', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                withCredentials: true
            })
                .then(response => {
                    this.setState({ submitting: false });
                    onClose(true);
                })
                .catch(e => {
                    this.setState({ error: ErrorModel.parseServerError(e) });
                })
                .finally(() => this.setState({ submitting: false }));
        }
        else if (this.isModeEqual(AddEditAttributeDialog.mode.childDetails)) {
            onSaveChildAttribute(attribute, { files, comments, proficiency, expiry });
            onClose(false);
        }
    }

    onAttributeChange(e, attribute, alreadyHasAttribute) {
        const { profile } = this.props;
        this.setState({ attribute: attribute, alreadyHasAttribute: alreadyHasAttribute, error: null });

        if (attribute) {
            const existingAttribute = profile.employeeAttributes.getAttribute(attribute.attributeDefinitionId);
            if (existingAttribute) {
            }

            if (attribute.isParent) {
                attribute.childAttributes.forEach(child => {
                    const existingChild = profile.employeeAttributes.getAttribute(child.attributeDefinitionId);

                    child.alreadyHasAttribute = existingChild !== undefined;
                    if (child.alreadyHasAttribute) {
                        child.status = existingChild.status;
                        child.statusLabel = existingChild.statusLabel;
                        child.expiryDate = existingChild.expiryDate;
                    }
                    else child.statusLabel = 'Missing';

                    child.hasRequiredDetails = AbstractAttribute.hasRequiredDetails(child);

                });
            }
        }
        else {
            this.setState({ comments: '', files: null, proficiency: null, expiry: null });
        }
    }

    onCommentsChange(e) {
        const { attribute } = this.state;
        attribute.editComments = e.target.value;
        this.setState({ comments: e.target.value, attribute });
    }

    onExpiryChange(e) {
        const { attribute } = this.state;
        attribute.editExpiry = e.target.value;
        this.setState({ expiry: e.target.value, attribute });
    }

    onFilesChange(e, files) {
        const { attribute } = this.state;
        attribute.editFiles = files;
        this.setState({ files: files, attribute });
    }

    onChangeProficiency(e, proficiency) {
        const { attribute } = this.state;
        attribute.editProficiency = proficiency;
        this.setState({ proficiency: proficiency, attribute });
    }

    onAttributeEdit(e, editChildAttribute) {
        e.preventDefault();
        this.setState({ editChildAttribute });
    }

    onSaveChildAttribute(editChildAttribute, details) {
        const { attribute } = this.state;

        this.childAttributesDetails[editChildAttribute.attributeDefinitionId] = details;

        if (attribute) {
            const child = attribute.childAttributes.find(child => child.attributeDefinitionId === editChildAttribute.attributeDefinitionId);
            child.expiryDate = details.expiry;
            child.proficiency = details.proficiency;
            child.hasRequiredDetails = AbstractAttribute.hasRequiredDetails(child);
        }
    }

    onExpiryDateChange(date, valid) {
        if (valid) this.setState({ expiry: date });
    }

    onCloseEditChildAttributeDialog() {
        this.setState({ editChildAttribute: null });
    }

    onDocumentClick(e, doc) {
        //this.setLoading('downloadingDoc', true);
        this.setState({ downloadingDoc: doc });
        //window.open(Data.apiBasePath + '/EmployeeAttribute/download/' + doc.documentId, '_blank');

        Data.call('get', '/EmployeeAttribute/download/' + doc.documentId, { responseType: 'blob' })
            .then(response => {
                const blob = new Blob([response.data], { type: response.headers['content-type'] });

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', doc.documentName);
                document.body.appendChild(link);
                link.click();

            })
            .catch(e => {
                this.setState({ error: ErrorModel.parseServerError(e) });
            })
            //.finally(() => /*this.setLoading('downloadingDoc', false)*/)
            ;
    }

    onDocumentDownloadSnackbarClose() {
        this.setState({ downloadingDoc: null });
    }

    isModeEqual(target) {
        const mode = this.state.mode || AddEditAttributeDialog.mode.add;
        return mode?.hash && target?.hash ? isEqual(mode, target) : false;

    }

    render() {
        const { organization, user, employee, theme, profile, newFileUploadComponent, readOnly } = this.props;
        const { submitting, definitions, attribute, submitAttempts, showConfirmClose, proficiency, error, allowAttributeChange, comments, expiry, editChildAttribute, files, downloadingDoc } = this.state;
        const mode = this.state.mode || AddEditAttributeDialog.mode.add;

        const valid = this.checkValid();
        const allowSubmit = !this.state.submitting && valid && attribute && (attribute.isParent || (!attribute.isParent && (this.isModeEqual(AddEditAttributeDialog.mode.add) || this.hasChanges())));

        const actions = [];
        if (!this.isModeEqual(AddEditAttributeDialog.mode.details)) actions.push({
            label: this.isModeEqual(AddEditAttributeDialog.mode.childDetails) ? 'Save & Close' : 'Submit request',
            variant: 'filled',
            primary: true,
            onClick: this.onSubmit.bind(this),
            disabled: !allowSubmit
        });

        actions.push({
            label: this.isModeEqual(AddEditAttributeDialog.mode.details) ? 'Close' : 'Cancel',
            autoFocus: false,
            variant: this.isModeEqual(AddEditAttributeDialog.mode.details) ? 'filled' : 'outlined',
            onClick: this.onClose.bind(this),
            disabled: this.state.submitting
        });

        if (!readOnly && this.isModeEqual(AddEditAttributeDialog.mode.details) && attribute && !attribute.isParent && attribute.status === AbstractAttribute.status.approved) actions.push({
            onClick: this.onRequestChanges.bind(this),
            label: 'Request changes',
            disabled: employee === null || employee.hasJob === false || (profile && (profile.hasApprover === false))
        });

        return <StyledAddEditAttributeDialog
            open={true}
            theme={theme}
            className='klaro_addeattrdialog'
            onClose={this.onClose.bind(this)}
            fullWidth={true}
            scroll='body'
            title={mode.title}
            maxWidth='sm'
            actions={actions}>
            {submitting && (<LinearProgress />)}

            <Grid className='AddEditAttributeDialogSection' container spacing={3}>
                {/* {'valid: ' + valid} */}
                {error && (
                    <Grid item xs={12} md={12} lg={12}>
                        <Alert severity="error">{error}</Alert>
                    </Grid>
                )}
                <Grid item xs={12} md={12} lg={12} sx={{ display: !valid && submitAttempts !== 0 ? 'block' : 'none' }}>
                    <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        Please select an competency from the list
                    </Alert>
                </Grid>
                <Grid item xs={12} md={12} lg={12} style={{ paddingTop: '0px' }}>
                    {definitions && (<AttributeSelector
                        label='Competency (required)'
                        definitions={definitions}
                        employeeAttributes={employee && employee.profile && employee.profile.employeeAttributes ? employee.profile.employeeAttributes : null}
                        attribute={attribute}
                        allowSelection={allowAttributeChange}
                        showExistingEmployeeAttributes={false}
                        onChange={this.onAttributeChange.bind(this)} />)}
                </Grid>

                {attribute &&
                    <Grid item xs={12} md={12} lg={12}>
                        <Grid container spacing={4}>
                            {!attribute.isParent && organization && organization.showProficiency && attribute && attribute.hasProficiency &&
                                <Grid item xs={12} md={12} lg={12} sx={{ display: attribute === null ? 'none' : 'block' }}>
                                    <ProficiencySelector
                                        label='Proficiency (required)'
                                        proficiency={proficiency}
                                        originalProficiency={attribute.currentProficiency}
                                        forceShowOriginal={!this.isModeEqual(AddEditAttributeDialog.mode.details)}
                                        disabled={this.isModeEqual(AddEditAttributeDialog.mode.details)}
                                        onChange={this.onChangeProficiency.bind(this)} />
                                </Grid>}

                            {!attribute.isParent && attribute.hasExpiryDate &&
                                <Grid item xs={12} md={12} lg={12} sx={{ display: attribute === null ? 'none' : 'block' }} style={{ paddingTop: '44px' }}>
                                    <DatePicker
                                        theme={theme}
                                        label='Expiry date (required)'
                                        onChange={this.onExpiryDateChange.bind(this)}
                                        value={expiry ? new Date(expiry) : null}
                                        disabled={this.isModeEqual(AddEditAttributeDialog.mode.details)}
                                        allowPast={true}
                                        showPastWarning={!this.isModeEqual(AddEditAttributeDialog.mode.details)}
                                        showPastWarningMessage='The selected expiry date is in the past. You can save it as is, but the competency will expire overnight.'
                                        allowEmpty={true}
                                    //validationMethod={this.checkValidExpiry.bind(this)}
                                    //validationText='Please enter a valid expiry date'
                                    />
                                </Grid>}

                            {!attribute.isParent && !this.isModeEqual(AddEditAttributeDialog.mode.details) &&
                                <Grid item xs={12} md={12} lg={12} style={{ paddingTop: '44px' }}>


                                    {newFileUploadComponent && <DragDropFileUpload
                                        id="klaro_attrchange_evidence"
                                        label="Evidence (optional)"
                                        files={files}
                                        accept='csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                                        onChange={this.onFilesChange.bind(this)}
                                        allowedMimeTypes={AddEditAttributeDialog.allowedMimeTypes}
                                        maxFileSize={AddEditAttributeDialog.maxFileSize}
                                        errorMimeType={AddEditAttributeDialog.errorMimeType}
                                        errorFileSize={AddEditAttributeDialog.errorFileSize} />}

                                    {!newFileUploadComponent && <MultiFileUpload
                                        id="klaro_attrchange_evidence"
                                        label="Evidence (optional)"
                                        files={files}
                                        disabled={employee === null}
                                        placeholder="Add supporting documents"
                                        accept='csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                                        onChange={this.onFilesChange.bind(this)}
                                        InputLabelProps={{ shrink: true }}
                                        helperText='Supported document types: PDF, JPEG, PNG, DOC, DOCX'
                                        allowedMimeTypes={AddEditAttributeDialog.allowedMimeTypes}
                                        maxFileSize={AddEditAttributeDialog.maxFileSize}
                                        errorMimeType={AddEditAttributeDialog.errorMimeType}
                                        errorFileSize={AddEditAttributeDialog.errorFileSize}
                                        sx={{ width: '100%' }}
                                    />}
                                </Grid>}
                            {!attribute.isParent && !this.isModeEqual(AddEditAttributeDialog.mode.details) &&
                                <Grid item xs={12} md={12} lg={12}>
                                    <TextField
                                        id="klaro_attrchange_comments"
                                        label="Comments (optional)"
                                        multiline
                                        value={comments}
                                        disabled={employee === null}
                                        rows={4}
                                        placeholder="Add any comments or notes"
                                        sx={{ width: '100%' }}
                                        clearable={false}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={this.onCommentsChange.bind(this)}
                                    />
                                </Grid>}

                            {attribute && !attribute.isParent && attribute?.trainingCourses?.length !== 0 &&
                                <TrainingCourseList
                                    style={{ paddingTop: '44px' }}
                                    courses={attribute.trainingCourses}
                                    organization={organization}
                                />}

                            {attribute.requestActivity &&
                                <RequestActivityFeed
                                    style={{ paddingTop: '24px' }}
                                    user={user}
                                    employee={employee}
                                    attribute={attribute}
                                    activity={attribute.requestActivity}
                                    onDocumentClick={this.onDocumentClick.bind(this)}
                                    sx={{ marginTop: '-20px' }} />
                            }

                            {attribute.isParent && !this.isModeEqual(AddEditAttributeDialog.mode.details) &&
                                <Grid item xs={12} md={12} lg={12}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <Alert severity='info'><b>{attribute.attribute}</b> requires the competencies below. A request will be sent for each missing competency.</Alert>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <AttributeTable
                                                theme={theme}
                                                paper={false}
                                                showHeader={false}
                                                showFooter={false}
                                                selectable={false}
                                                columns={[
                                                    { type: AttributeTable.columns.requestTitle },
                                                    { type: AttributeTable.columns.edit }
                                                ]}
                                                rows={attribute.childAttributes}
                                                rowHasAction={attr => !attr.alreadyHasAttribute}
                                                onAttributeEdit={this.onAttributeEdit.bind(this)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>}
                        </Grid>
                    </Grid>}


            </Grid>

            {showConfirmClose && (<ConfirmationDialog
                title='Cancel?'
                theme={theme}
                question={`Are you sure you want to cancel this ${this.isModeEqual(AddEditAttributeDialog.mode.add) ? 'add' : 'edit'} competency request?`}
                cancelButton='No'
                acceptButton='Yes'
                onCancel={this.onCancelClose.bind(this)}
                onAccept={this.onAcceptClose.bind(this)} />)}

            {editChildAttribute !== null &&
                <AddEditAttributeDialog
                    organization={organization}
                    user={user}
                    employee={employee}
                    profile={profile}
                    theme={theme}
                    onClose={this.onCloseEditChildAttributeDialog.bind(this)}
                    onSaveChildAttribute={this.onSaveChildAttribute.bind(this)}
                    attribute={editChildAttribute}
                    mode={AddEditAttributeDialog.mode.childDetails} />}

            {downloadingDoc && <Snackbar
                open={true}
                duration={6000}
                type='info'
                onClose={this.onDocumentDownloadSnackbarClose.bind(this)}
                message={'Downloaded document ' + downloadingDoc.documentName}
            />}

        </StyledAddEditAttributeDialog>
    }
}

const StyledAddEditAttributeDialog = styled(Dialog)`
    .MuiPaper-root {
        max-width: 640px;
    }

    .MuiDialogContent-root {
        padding: 0px 40px 0px 40px;
    }

    .MuiDialogTitle-root {
        color: ${KLAYO_COLORS.GreyGR900};
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: -0.5px;
    }

    .AddEditAttributeDialogSection {
        margin-top: 5px;
    }

    @media (max-width: 600px) {
        .MuiPaper-root {
            max-width: 375px;
        }

        .MuiDialogContent-root {
            padding: 0px 24px 24px 24px;
        }

        .MuiDialogTitle-root {
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.3px;
        }
    }
`;