import { React, Component } from 'react';
import { SingleSelector } from './SingleSelector';

export class AddressSelector extends Component {

    static defaultProps = {
        label: ''
    }

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {

        return (
            <SingleSelector
                {...this.props}
                hasNewItem={false}/>
        );
    }
}