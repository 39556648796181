import { Data } from "../Data";

export class TeamDataExport extends Data {

    static instance = null;

    constructor(options) {
        super({
            api: (options.groupId ? '/MyGroup/' + options.groupId : '/Teams') + '/Export?' + (options.filterModel === undefined ? '' : options.filterModel.toApiUrlParams()), callback: data => {
                options.resolve(this, false);
            }, ...options
        });
    }
    
    onDataReceived(data) {
    }

    static get(context, filterModel, groupId) {
        return new Promise((resolve, reject) => {

            if(filterModel === undefined && groupId === undefined) {
                if (TeamDataExport.instance) resolve(TeamDataExport.instance, true);
                else TeamDataExport.instance = new TeamDataExport({ resolve, reject, context });
            }
            else new TeamDataExport({ resolve, reject, filterModel, context, groupId });
        });
    }
}