import { Autocomplete, TextField as MuiTextField } from '@mui/material';
import { React, Component } from 'react';
import { MultiSelector } from './MultiSelector';

export class MultiAttributeSelector extends Component {

    static defaultProps = {
        label: 'Competencies'
    }

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        const { items, label, getItemLabel } = this.props;

        return (
            <MultiSelector
                {...this.props}
                hasNewItem={true} />
        );
    }
}