export const getSortByApiValue = ( orderByConvertList = [], field, sort) => {
	let sortBy = `${field} ${sort}`;

	if (orderByConvertList && orderByConvertList?.length > 0) {
		orderByConvertList.forEach(({tableFieldName, apiFieldName, subApiFieldName}, index) => {
			if (field === tableFieldName) {
				if (subApiFieldName) {
					sortBy = `${apiFieldName} ${sort}, ${subApiFieldName} ${sort}`;
				} else {
					sortBy = `${apiFieldName} ${sort}`;
				}
			} 
		});
	}

	return sortBy;
}