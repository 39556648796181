import { Data } from "../Data";

export class SubmitBillingDetails extends Data {

    constructor(options) {
        super({
            api: '/Organization/Admin/Billing', postData: options.details, callback: data => {
                options.resolve(this, false);
            }, ...options
        });
    }

    static post(context, details) {
        return new Promise((resolve, reject) => new SubmitBillingDetails({ resolve, reject, context, details, method: 'post' }));
    }

    static put(context, details) {
        return new Promise((resolve, reject) => new SubmitBillingDetails({ resolve, reject, context, details, method: 'put' }));
    }
}