import { ToggleButtonGroup, ToggleButton, InputLabel } from '@mui/material';
import { React, Component } from 'react';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

export class ProficiencySelector extends Component {

    render() {
        const { proficiency, originalProficiency, onChange, disabled, forceShowOriginal, label, error } = this.props;

        return (
            <div className={'klayo_proficiencyselector'+(error ? ' klayo_proficiencyselector--error' : '')}>
                <InputLabel disabled={disabled} sx={{ marginBottom: '6px' }}>{label || 'Proficiency'}</InputLabel>
                <ToggleButtonGroup
                    color="primary"
                    aria-label="Proficiency"
                    value={proficiency}
                    exclusive
                    disabled={disabled}
                    error={error}
                    onChange={onChange}
                >
                    {
                        [...Array(5)].map((x, i) =>
                            <ToggleButton key={i} disabled={disabled} value={i + 1}>{i + 1}</ToggleButton>
                        )
                    }
                </ToggleButtonGroup>
                {(originalProficiency !== proficiency || forceShowOriginal) && 
                <div className='klayo_proficiencyselector_original'>
                    {
                        [...Array(5)].map((x, i) =>
                            <div key={i} disabled={disabled} value={i + 1}>{i === originalProficiency-1 ? (<ArrowDropUpIcon />) : null}</div>
                        )
                    }
                </div>}
            </div>
        );
    }
}