import { Component } from "react";
import { Button } from "../../components/Button";
import { Alert } from "../../components/Alert";
import { Fade, Stack, Step, StepLabel, Stepper, Box, Tabs, Tab } from "@mui/material";
import { TextField } from "../../components/TextField";
import { ReactComponent as AddIcon } from '../../resources/images/icons-add.svg';
import { ReactComponent as SearchIcon } from '../../resources/images/icons-search.svg';
import { ReactComponent as RightIcon } from '../../resources/images/icons-arrow-right.svg';
import { ReactComponent as LeftIcon } from '../../resources/images/icons-arrow-left.svg';
import { AttributeSelectorDialog } from "./AttributeSelectorDialog";
import { AttributeTable } from "../../components/table/AttributeTable";
import { Data } from '../../data/Data';
import { ErrorModel } from '../../common/models/ErrorModel';
import { SettingsJob } from "../../data/settings/SettingsJob";
import { AppContext } from '../../common/AppContext';
import { TablePlaceholder } from "../../components/TablePlaceholder";
import { RichTextEditor } from "../../components/RichTextEditor";
import { ReactComponent as AttributesIcon } from '../../resources/images/icons-attribute.svg';
import { PaperOrDialog } from "../../components/PaperOrDialog";
import { EditTemplate } from "klayowebshared";
import { trimToLowercase } from "../../utilities";

export class AddEditJobView extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            job: props.job,
            saving: false,
            error: props.error,
            step: 0,
            showAttributeSelectDialog: false,
            search: null,
            descriptionEditorState: RichTextEditor.createEditorState(''),
            originalEditorState: RichTextEditor.createEditorState(''),
            hasEdit: false,
            saving: false
        };

        this.existingJob = new SettingsJob(props.job);
    }

    componentDidMount() {
        this.loadDetails();
    }

    componentDidUpdate(prevProps) {
        if (this.props.error !== prevProps.error) this.setState({ error: this.props.error });
    }

    loadDetails() {
        const { job, step } = this.state;
        const jobDefinitionId = job ? job.jobDefinitionId : this.props.jobId;

        if (!jobDefinitionId) return;

        this.context.setLoading('loading', true);

        Data.get(this.context, '/JobDefinition/' + jobDefinitionId, {
            withCredentials: true
        })
            .then(response => {
                const job = new SettingsJob(response.data.jobDefinition);
                this.existingJob = new SettingsJob(response.data.jobDefinition);

                this.setState({ job, descriptionEditorState: RichTextEditor.createEditorState(job.description), originalEditorState: RichTextEditor.createEditorState(job.description) });
            })
            .catch(e => {
                this.setState({ error: ErrorModel.parseServerError(e) });
            })
            .finally(() => {
                this.context.setLoading('loading', false);
            });
    }

    onNameChange(e) {
        const { job } = this.state;
        job.name = e.target.value;

        this.setState({ job });
    }

    onDescriptionChange(text) {
        const { job } = this.state;
        job.description = text;

        this.setState({ job });
    }

    onDescriptionEditorStateChange(descriptionEditorState) {
        this.setState({ descriptionEditorState });
    }

    isValid(job, step) {
        if (!job || !this.isNameValid(job)) return false;
        if (step === 0) return true;
        return this.isAttributesValid(job);
    }

    isNameValid(job) {
        return job && job.name != null && job.name.length > 2;
    }

    isAttributesValid(job) {
        if (!job) return false;
        let isValid = true;

        job.attributes.forEach(a => {
            if (a.isParent !== true && a.isChild !== true && !a.requirementType) isValid = false;

            /*a.childAttributes.forEach(c => {
                if (!c.requirementType === null) isValid = false;
            });*/
        });
        return isValid;
    }

    onSave() {
        const { onSave, editMode, onBlockNavigation } = this.props;
        const { job, step } = this.state;

        if (!editMode && step === 0 && this.isValid(job, 0)) this.setState({ step: 1 });
        if (onSave && (editMode || step === 1)) {
            if (onBlockNavigation) onBlockNavigation(false, 'Job');
            this.setState({ saving: true });
            onSave(job);
        }
    }

    onShowAttributeSelectDialog(e) {
        this.setState({ showAttributeSelectDialog: true });
    }

    onSearch(e) {
        this.setState({ search: e.target.value });
    }

    onAttributesAdded(addedAttributes) {
        let { job } = this.state;

        /*let childAttrs = [];
        addedAttributes.forEach(a => {
            childAttrs = childAttrs.concat(a.childAttributes);
        });

        job.attributes = [...job.attributes, ...addedAttributes, ...childAttrs];*/

        let childAttrs = [];
        addedAttributes.forEach(a => {
            a.childAttributes.forEach(c => {
                childAttrs.push(c);
                if (!addedAttributes.some(a => a.attributeDefinitionId === c.attributeDefinitionId) && !job.attributes.some(a => a.attributeDefinitionId === c.attributeDefinitionId)) addedAttributes.push(c.toStandardAttribute());
            });
        });

        job.attributes = [...job.attributes, ...addedAttributes, ...childAttrs];

        //job.addAttribute(addedAttributes);
        this.setState({ job, showAttributeSelectDialog: false });
    }

    onTabChange(e, newStep) {
        this.setState({ step: newStep });
    }

    onAttributeDelete(e, attr) {
        let { job } = this.state;

        job.attributes = job.attributes.filter(a => !(a.attributeId === attr.attributeId && !a.isChild) && a.parentAttributeId !== attr.attributeId);

        this.setState({ job });
    }

    onAttributeRequirementChange(attribute, requirementType) {
        let { job } = this.state;

        attribute.requirementType = requirementType;
        //job.attributes.update(attribute);
        this.forceUpdate();
    }

    onCloseAttributeSelectDialog() {
        this.setState({ showAttributeSelectDialog: false });
    }

    onBack() {
        this.setState({ step: 0 });
    }

    onNavigation(e, callback) {
        const { onNavigation } = this.props;
        return onNavigation && onNavigation(e, callback);
    }

    onCancel(e, source) {
        if (source === 'back') this.onNavigation(e, this.stopBlockingNavAndClose.bind(this));
        else this.stopBlockingNavAndClose(e);
    }

    stopBlockingNavAndClose(e) {
        const { onCancel, onBlockNavigation } = this.props;
        if (onBlockNavigation) onBlockNavigation(false, 'Job');
        if (onCancel) onCancel(e);
    }

    rowHasAction(attr) {
        return true;
    }

    rowActionDisabled(attr) {
        let { job } = this.state;
        return job.attributes.some(a => a.isParent && a.childAttributes.some(a2 => a2.attributeId === attr.attributeId)) !== false;
    }

    onEditStateChange(hasEdit) {
        this.setState({ hasEdit });
    }

    compareName(name, search) {
        return String(name || '')
            ?.toLowerCase()
            ?.includes(trimToLowercase(search || ''))
    }

    render() {
        const { organization, user, theme, attributes, editMode, onCreate, onLoadAttributes, onShowNewAttributeDialog, onAllowNavigation, onBlockNavigation } = this.props;
        const { error, job, step, showAttributeSelectDialog, search, descriptionEditorState, hasEdit, saving } = this.state;

        let filterItems = [{ id: 'search', columnField: 'attribute', operatorValue: 'contains', value: search }];
        const filterCompetencies = job && job.attributes
            ? job.attributes
                ?.filter(p => {
                    const matchChildren = p?.childAttributes?.some((c) => this.compareName(c?.name, search));
                    const matchAttribute = this.compareName(p?.name, search);
                    return matchAttribute || matchChildren;
                })
            : [];
        return <EditTemplate
            theme={theme}
            name='Job'
            onAllowNavigation={onAllowNavigation}
            onBlockNavigation={onBlockNavigation}
            onEditStateChange={this.onEditStateChange.bind(this)}
            detectEdit={!saving}
            compare={{
                existing: this.existingJob,
                editing: job,
                members: [
                    { name: 'name' },
                    {
                        name: 'description',
                        detectEdit: (existing, editing, isNew) => {
                            if (isNew) return editing.description && editing.description.trim() !== '<p></p>';
                            return existing.description !== editing.description && existing.description + '\n<p></p>' !== editing.description;
                        }
                    },
                    {
                        name: 'attributes',
                        detectEdit: (existing, editing) => !editing.isAttributesSame(existing.attributes)
                    }
                ]
            }}>
            <PaperOrDialog
                theme={theme}
                dialogToBreakpoint='md'
                disabledToBreakpoint='md'
                paperPadding={{ xs: '46px 24px', md: '60px' }}
                title={editMode ? 'Job details' : 'Create job'}
                titleSx={{ fontSize: { xs: '20px', md: '30px' }, fontWeight: 'bold' }}
                titleSize={1}
                backButton={editMode}
                onBack={this.onCancel.bind(this)}>

                <Stack
                    direction="column"
                    spacing={4}
                >
                    {error && <Alert
                        severity='error'
                        sx={{ width: '100%' }}>{error}</Alert>}

                    {!editMode ? <Stepper activeStep={step}>
                        <Step
                            onClick={this.onBack.bind(this)}
                            sx={{ cursor: step !== 0 ? 'pointer' : 'default' }}>
                            <StepLabel>Job details</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>Job competencies</StepLabel>
                        </Step>
                    </Stepper> :
                        <div>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs
                                    centered={true}
                                    value={step}
                                    onChange={this.onTabChange.bind(this)}
                                    variant='fullWidth'
                                    sx={{ width: '100%' }}>
                                    <Tab
                                        label="Information"
                                        value={0} />
                                    <Tab
                                        label="Competencies"
                                        value={1} />
                                </Tabs>
                            </Box>
                        </div>}

                    {step === 0 && <Fade in={true}>
                        <Stack
                            direction="column"
                            spacing={4}
                        >
                            <TextField
                                label="Job title (required)"
                                placeholder='e.g. Marketing Mananger'
                                autoComplete={false}
                                autoFocus={true}
                                validationMethod={(e, value) => this.isNameValid(job)}
                                validationText='Please enter a valid name (more than 2 characters)'
                                clearable={false}
                                sx={{ width: '100%' }}
                                value={job ? job.name : null}
                                onChange={this.onNameChange.bind(this)}
                            />
                            <RichTextEditor
                                label="Job description"
                                editorState={descriptionEditorState}
                                onChange={this.onDescriptionChange.bind(this)}
                                onChangeEditorState={this.onDescriptionEditorStateChange.bind(this)}
                            />
                        </Stack></Fade>}

                    {step === 1 && <Fade in={true}><div>
                        <Stack
                            direction="column"
                            spacing={2}
                        >
                            <div>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <TextField
                                        value={search}
                                        dense={true}
                                        placeholder='Search competency'
                                        disabled={job && job.attributes.length === 0}
                                        autoComplete={false}
                                        leadingIcon={<SearchIcon />}
                                        onChange={this.onSearch.bind(this)}
                                        sx={{ minWidth: '300px' }} />

                                    <Button
                                        onClick={this.onShowAttributeSelectDialog.bind(this)}
                                        size='md'
                                        variant='outlined'
                                        startIcon={<AddIcon />}
                                        label='Add competencies' />
                                </Stack>
                            </div>
                            <div>
                                <AttributeTable
                                    // rows={job ? job.attributes : null}
                                    rows={filterCompetencies}
                                    pagination={false}
                                    theme={theme}
                                    showFooter={false}
                                    rowHasAction={this.rowHasAction.bind(this)}
                                    rowActionDisabled={this.rowActionDisabled.bind(this)}
                                    filterItems={filterItems}
                                    hideFirstLastBorder={true}
                                    noRowsComponent={<TablePlaceholder text='Use Add competencies button to select competencies for this job' icon={<AttributesIcon />} />}
                                    dense={true}
                                    tree={true}
                                    onAttributeDelete={this.onAttributeDelete.bind(this)}
                                    onRequirementChange={this.onAttributeRequirementChange.bind(this)}
                                    paper={false}
                                    columns={[
                                        { type: AttributeTable.columns.attribute, label: 'Competency' },
                                        { type: AttributeTable.columns.requirementType, label: 'Priority', selectable: true, selectableOnChild: false },
                                        { type: AttributeTable.columns.requiredProficiency, label: 'Min Proficiency' },
                                        { type: AttributeTable.columns.delete }
                                    ]}
                                />
                            </div>
                        </Stack>
                    </div></Fade>}

                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        <Button
                            onClick={this.onCancel.bind(this)}
                            label='Cancel' />

                        <Stack
                            direction="row"
                            spacing={2}>
                            {step > 0 && !editMode && <Button
                                variant='outlined'
                                onClick={this.onBack.bind(this)}
                                startIcon={<LeftIcon />}
                                label='Back' />}
                            <Button
                                onClick={this.onSave.bind(this)}
                                variant='filled'
                                disabled={!this.isValid(job, step) || editMode && !hasEdit}
                                endIcon={!editMode && step === 0 ? <RightIcon /> : null}
                                label={editMode ? 'Save' : step === 0 ? 'Continue' : 'Create'} />
                        </Stack>
                    </Stack>

                </Stack>

                {showAttributeSelectDialog &&
                    <AttributeSelectorDialog
                        theme={theme}
                        attributes={attributes}
                        excludedAttributes={job ? job.attributes : null}    //.filter(a => !a.isChild)
                        allowChildren={false}
                        onLoadAttributes={onLoadAttributes}
                        onShowNewAttributeDialog={onShowNewAttributeDialog}
                        onClose={this.onCloseAttributeSelectDialog.bind(this)}
                        onSave={this.onAttributesAdded.bind(this)} />}

            </PaperOrDialog>
        </EditTemplate>
    }
}