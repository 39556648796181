import { React, Component } from 'react';
import { LoginLayout } from 'klayowebshared';
import { AppContext } from '../../common/AppContext';
import { loginRequest } from '../../common/AuthConfig';
import { Button } from '../../components/Button';
import { Stack } from '@mui/material';

export class NotLoggedInView extends Component {
    static contextType = AppContext;

    onSignIn() {
        this.props.msal.instance
            .loginRedirect(loginRequest)
            .catch(e => {
                console.error(e);
            });
    }

    render() {
        const { error, login } = this.props;

        return <LoginLayout
            heading='Welcome back!'
            text='Please log in to continue.'>
            <Stack spacing={6}>
                <Button
                    label='Log in'
                    variant='filled'
                    onClick={this.onSignIn.bind(this)} />
            </Stack>
        </LoginLayout>
    }
}