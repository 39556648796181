import { Data } from "../Data";
import { User } from "../users/User";

export class GroupMemberSelectorList extends Data {

    static instance = null;

    constructor(options) {
        super({
            api: '/Group/SearchEmployees?name=' + options.search, callback: data => {
                options.resolve(this, false);
            }, ...options
        });
    }
    
    onDataReceived(data) {
        this.employees = [];
        data.employees.forEach((employee, index) => this.employees.push(new User({ data: { id: index, ...employee } })));
    }

    static get(context, search) {
        return new Promise((resolve, reject) => {
            if (GroupMemberSelectorList.instance && search === '') resolve(GroupMemberSelectorList.instance, true);
            else GroupMemberSelectorList.instance = new GroupMemberSelectorList({ resolve, reject, search, context });
        });
    }
}